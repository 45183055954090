import React, { Component } from 'react'

import './SolicitudesMtoLista.css'; 
import MyButton from '../../../utils/MyButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import {postMtoCorrectivoConSolicitud,getSolicitudesCorrectivosCliente,clearErrors,deleteMtoCorrectivo} from '../../../redux/actions/dataactions';


import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FaArrowLeft,FaPlus } from "react-icons/fa";
export class SolicitudesMtoLista extends Component {
    constructor(){
        super();
        this.state={
            
            errors:{},
            open:false,
            opendelete:false,
            idsolicitud:'',
            fechasolicitud:'',
            horasolicitud:'',
            numsolicitud:'',
            fallareportada:'',
            obsolescencia:false,
            malaoperacion:false,
            malainst:false,
            accesorios:false,
            caida:false,
            desgaste:false,
            corto:false,
            sinfalla:false,
            paciente:false,
            desconocido:false,
            otro:'',
            descactividad:'',
            descfalla:'',
            observaciones:'',
            apto:'',
            retirado:'',
            nombrerepuesto:'',
            nombrerepuesto1:'',
            nombrerepuesto2:'',
            cantidad:0,
            cantidad1:0,
            cantidad2:0,
            costo:0,
            costo1:0,
            costo2:0,
            subtotal:0,
            subtotal1:0,
            subtotal2:0,
            totalrepuesto:0,
            horainicio:'',
            horafinal:'',
            firmarecibe:'',
            cargorecibe:'',
            fecha:'',
            solicitud:{},
            tiemporespuesta:'',
            tiemporespuestahora:''
        };
        
    };

    componentDidMount(){
        this.props.getSolicitudesCorrectivosCliente(this.props.match.params.clienteId);
       }

       handleOpen=(rawdata)=>{
        const rawdatastate=rawdata; 
        console.log(rawdata);
        console.log(rawdatastate);
       
        let fecharaw=new Date(rawdata.datecreation); 
        let fecha=fecharaw.toLocaleDateString(); 
        let hora=fecharaw.toLocaleTimeString();
        
        this.setState({open:true,solicitud:rawdatastate,solicitudid:rawdatastate.solicitudid,fechasolicitud:fecha,fallareportada:rawdatastate.fallareportada,horasolicitud:hora});
        console.log(this.state);
        };

        handleChange=(event)=>{
            this.setState({ [event.target.name]:event.target.value});
            
       };

       handleClose=()=>{
        // this.props.clearErrors();
         this.setState({open:false,errors:{}});
         };

         handlePostMto=()=>{
            const newMtoCorrectivo={
              fechasolicitud:this.state.fechasolicitud,
              horasolicitud:this.state.horasolicitud,
              numsolicitud:this.state.numsolicitud,
              fallareportada:this.state.fallareportada,
              obsolescencia:this.state.obsolescencia,
              malaoperacion:this.state.malaoperacion,
              malainst:this.state.malainst,
              accesorios:this.state.accesorios,
              caida:this.state.caida,
              desgaste:this.state.desgaste,
              corto:this.state.corto,
              sinfalla:this.state.sinfalla,
              paciente:this.state.paciente,
              desconocido:this.state.desconocido,
              otro:this.state.otro,
              descactividad:this.state.descactividad,
              descfalla:this.state.descfalla,
              apto:this.state.apto,
              retirado:this.state.retirado,
              nombrerepuesto:this.state.nombrerepuesto,
              nombrerepuesto1:this.state.nombrerepuesto1,
              nombrerepuesto2:this.state.nombrerepuesto2,
              cantidad:this.state.cantidad,
              cantidad1:this.state.cantidad1,
              cantidad2:this.state.cantidad2,
              costo:this.state.costo,
              costo1:this.state.costo1,
              costo2:this.state.costo2,
              subtotal:this.state.subtotal,
              subtotal1:this.state.subtotal1,
              subtotal2:this.state.subtotal2, 
              totalrepuesto:this.state.totalrepuesto,
              observaciones:this.state.observaciones,
             horainicio:this.state.horainicio,
             horafinal:this.state.horafinal,
             firmarecibe:this.state.firmarecibe,
             cargorecibe:this.state.cargorecibe,
             fecha:this.state.fecha,
             solicitud:this.state.solicitud
                
         };

       

        let segundosatiempo=(s)=>{

            const addZ=(n)=>{
                return (n<10? '0':'') + n;       
                
            }
            var ms = s % 1000;
	         s = (s - ms) / 1000;
	        var secs = s % 60;
	         s = (s - secs) / 60;
	         var mins = s % 60;
	         var hrs = (s - mins) / 60;

	  return addZ(hrs) + ':' + addZ(mins) + ':' + addZ(secs)+ '.' + addZ(ms);

        }

            let tiempoinicial=newMtoCorrectivo.solicitud.datecreation;
            let inicialmilis = new Date(tiempoinicial).getTime();
        

            let tiempofinal=Date.now();
            let tiemporespuesta= tiempofinal-inicialmilis; 
  

           let conversion = segundosatiempo(tiemporespuesta);

           newMtoCorrectivo.tiemporespuesta=tiemporespuesta;
           newMtoCorrectivo.tiemporespuestahora=conversion 

           console.log(newMtoCorrectivo)
        
           console.log(newMtoCorrectivo.solicitud.equipoid); 
           console.log(newMtoCorrectivo.solicitud.idprestador);
         this.props.postMtoCorrectivoConSolicitud(newMtoCorrectivo,newMtoCorrectivo.solicitud.equipoid,newMtoCorrectivo.solicitud.idprestador,this.state.solicitudid); 
       
           
     
         }

  render() {
    
    const {solicitudescorr,loadingsolcorr}= this.props.data; 
    const {errors}=this.state;
    const { UI:{loading}}=this.props; 
    this.state.subtotal=this.state.cantidad*this.state.costo;
    this.state.subtotal1=this.state.cantidad1*this.state.costo1;
    this.state.subtotal2=this.state.cantidad2*this.state.costo2;
    this.state.totalrepuesto=this.state.subtotal+this.state.subtotal1+this.state.subtotal2; 
    
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />)
      };
    
    const columns=[
        {title:'Fecha',field:'datecreation'},
        {title:'Id',field:'idinventario'},
        {title:'Equipo',field:'nombreequipo'},
        {title:'Servicio',field:'servicio'},
        {title:'Solicitante',field:'nombresolicita'},
        {title:'Falla',field:'fallareportada'},
        {title:'Estado',field:'estado'}
       
    ]

    let mapeosolicitudescorrectivos=!loadingsolcorr ?(
        <div>
   <MaterialTable
           columns={columns}
           data={solicitudescorr}
           title=''
           icons={tableIcons}
           options={{
            cellStyle: {
                fontSize: 11,
                fontWeight:500,
                color:'#696B6D'
              
             
              },
              headerStyle: {
                fontSize: 12,
                fontWeight: 'bolder',
                color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
              }
          
          }}
           actions={[
            rowData => ({
              icon: () =>  <Link to={`/dashboard/perfilcliente/equipo/${this.props.match.params.clienteId}/${rowData.equipoid}`} style={{ color: '#03178C' }} ><ChevronRight  /></Link>,
              tooltip: 'Ver Perfil ',
              onClick: (rowData)
            })
            ,
            
            rowData => ({
              icon: tableIcons.Add,
              tooltip: 'Hacer Mantenimiento Correctivo',
              onClick: (event, rowData) => {
                 this.handleOpen(rowData);
              },
              disabled: rowData.estado==='Atendida'
            })
         
            ]}
         
         
   
           >
   
           </MaterialTable>

    
        </div>
          

       ):(  <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
           </div>);



    return (
        <div>
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Solicitudes de Mantenimiento Correctivo</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>

        <div className='div-tablacorr'>
            {mapeosolicitudescorrectivos}
        </div>

        <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Realizar Reporte de Mantenimiento Correctivo</DialogTitle>
            <DialogContent>
            <form>
            <h4>Información Solicitud</h4>
            <div className="div-input-doble-mtoprog">
           
            <TextField className="login-input-email"
                      required
                      type="text"
                      name="fechasolicitud"
                      id="fechasolicitud"
                      label="Fecha de Solicitud"
                      value={this.state.fechasolicitud}
                     
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}                  
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
            <TextField className="login-input-email"
                      required
                      type="text"
                      name="horasolicitud"
                      id="horasolicitud"
                      label="Hora de Solicitud"
                      value={this.state.horasolicitud}
                     
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}                  
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />

              
          
            </div>
            <TextField className="login-input-email"
                     
                     type="Number"
                     name="numsolicitud"
                     id="numsolicitud"
                     label="Numero de solicitud"
                     value={this.state.numsolicitud}
                     onChange={this.handleChange}
                     fullWidth
                     helperText={errors.numsolicitud}
                     error={errors.numsolicitud?true:false}
                     />
            <TextField className="login-input-email"
                      
                      multiline
                      type="Text"
                      name="fallareportada"
                      id="fallareportada"
                      label="Falla Reportada"
                      rows={4}
                      value={this.state.fallareportada}
                      onChange={this.handleChange}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      />
             <h4 className="text-title-accorion">Causas de la Falla</h4>

             <div className="div-accordion-container">

  
        <div className="div-input-doble-mtoprog">
        <FormControl className="formcontrol-nuevomto">
                <InputLabel>Obsolescencia</InputLabel>
                  <Select  required  name="obsolescencia" id="obsolescencia" value={this.state.obsolescencia} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.obsolescencia}</FormHelperText>
            </FormControl>  

           
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Mala Operación</InputLabel>
                  <Select  required  name="malaoperacion" id="malaoperacion" value={this.state.malaoperacion} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.malaoperacion}</FormHelperText>
            </FormControl> 
            </div>
            <div className="div-input-doble-mtoprog">
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Mala Instalación</InputLabel>
                  <Select  required  name="malainst" id="malainst" value={this.state.malainst} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.malainst}</FormHelperText>
            </FormControl>  
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Desgaste</InputLabel>
                  <Select  required  name="desgaste" id="desgaste" value={this.state.desgaste} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.desgaste}</FormHelperText>
            </FormControl> 
            </div>
            
            <div className="div-input-doble-mtoprog">
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Caída</InputLabel>
                  <Select  required  name="caida" id="caida" value={this.state.caida} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.caida}</FormHelperText>
            </FormControl>  
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Corto Circuito</InputLabel>
                  <Select  required  name="corto" id="corto" value={this.state.corto} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.corto}</FormHelperText>
            </FormControl> 
            </div>
            
            <div className="div-input-doble-mtoprog">
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Accesorios</InputLabel>
                  <Select  required  name="accesorios" id="accesorios" value={this.state.accesorios} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.accesorios}</FormHelperText>
            </FormControl>  
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Paciente</InputLabel>
                  <Select  required  name="paciente" id="paciente" value={this.state.paciente} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.paciente}</FormHelperText>
            </FormControl> 
            </div>
            
            <div className="div-input-doble-mtoprog">
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Sin Falla</InputLabel>
                  <Select  required  name="sinfalla" id="sinfalla" value={this.state.sinfalla} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.sinfalla}</FormHelperText>
            </FormControl>  
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Desconocido</InputLabel>
                  <Select  required  name="desconocido" id="desconocido" value={this.state.desconocido} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.desconocido}</FormHelperText>
            </FormControl> 
            </div>
            <TextField className="login-input-email"
                      
                      multiline
                      type="Text"
                      name="otro"
                      id="otro"
                      label="Otro"
                      rows={1}
                      value={this.state.otro}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.otro}
                      error={errors.otro?true:false}
                   
                      />
            </div>

            <h4 className="text-title-accorion">Descripción de la Actividad</h4>

            <div className="div-accordion-container">
        <TextField className="login-input-email"
                      
                      multiline
                      type="Text"
                      name="descfalla"
                      id="descfalla"
                      label="Descripción de la Falla Encontrada"
                      rows={4}
                      value={this.state.descfalla}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.descfalla}
                      error={errors.descfalla?true:false}
                   
                      />

<TextField className="login-input-email"
                      
                      multiline
                      type="Text"
                      name="descactividad"
                      id="descactividad"
                      label="Descripción de la Actividad Realizada"
                      rows={4}
                      value={this.state.descactividad}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.descactividad}
                      error={errors.descactividad?true:false}
                   
                      />
          <TextField className="login-input-email"
                      
                      multiline
                      type="Text"
                      name="observaciones"
                      id="observaciones"
                      label="Observaciones"
                      rows={4}
                      value={this.state.observaciones}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.observaciones}
                      error={errors.observaciones?true:false}
                   
                      />

          </div>
        

          <h4 className="text-title-accorion">Repuestos Utilizados</h4>

          <div className="div-accordion-container">

          <div className="div-mediciones-container">
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                    
                      type="Text"
                      name="nombrerepuesto"
                      id="nombrerepuesto"
                      label="Repuesto"
                      value={this.state.nombrerepuesto}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombrerepuesto}
                      error={errors.nombrerepuesto?true:false}
                   
                      />
              </div>
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Number"
                      name="cantidad"
                      id="cantidad"
                      label="Cantidad"
                      value={this.state.cantidad}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.cantidad}
                      error={errors.cantidad?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Text"
                      name="costo"
                      id="costo"
                      label="Costo"
                      value={this.state.costo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.costo}
                      error={errors.costo?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Text"
                      name="subtotal"
                      id="subtotal"
                      label="subtotal"
                      value={this.state.subtotal}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.subtotal}
                      error={errors.subtotal?true:false}
                      InputProps={{
                        readOnly: true,
                      }}
                   
                      />
              </div>
        </div>


        <div className="div-mediciones-container">
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                    
                      type="Text"
                      name="nombrerepuesto1"
                      id="nombrerepuesto1"
                      label="Repuesto"
                      value={this.state.nombrerepuesto1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombrerepuesto1}
                      error={errors.nombrerepuesto1?true:false}
                   
                      />
              </div>
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Number"
                      name="cantidad1"
                      id="cantidad1"
                      label="Cantidad"
                      value={this.state.cantidad1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.cantidad1}
                      error={errors.cantidad1?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Text"
                      name="costo1"
                      id="costo1"
                      label="Costo"
                      value={this.state.costo1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.costo1}
                      error={errors.costo1?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Text"
                      name="subtotal1"
                      id="subtotal1"
                      label="subtotal"
                      value={this.state.subtotal1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.subtotal1}
                      error={errors.subtotal1?true:false}
                      InputProps={{
                        readOnly: true,
                      }}
                   
                      />
              </div>
        </div>


        <div className="div-mediciones-container">
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                    
                      type="Text"
                      name="nombrerepuesto2"
                      id="nombrerepuesto2"
                      label="Repuesto"
                      value={this.state.nombrerepuesto2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombrerepuesto2}
                      error={errors.nombrerepuesto2?true:false}
                   
                      />
              </div>
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Number"
                      name="cantidad2"
                      id="cantidad2"
                      label="Cantidad"
                      value={this.state.cantidad2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.cantidad2}
                      error={errors.cantidad2?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Text"
                      name="costo2"
                      id="costo2"
                      label="Costo"
                      value={this.state.costo2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.costo2}
                      error={errors.costo2?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Text"
                      name="subtotal2"
                      id="subtotal2"
                      label="Subtotal"
                      value={this.state.subtotal2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.subtotal2}
                      error={errors.subtotal2?true:false}
                      InputProps={{
                        readOnly: true,
                      }}
                   
                      />
              </div>
        </div>


        <div>
        
        <a>Total:  ${this.state.totalrepuesto}</a>

        </div>





            </div>

            <h4 className="text-title-accorion">Estado del Equipo</h4>

            <div className="div-accordion-container">
        <FormControl className="formcontrol-nuevomto">
                <InputLabel>¿Equipo Apto para su Uso?</InputLabel>
                  <Select    name="apto" id="apto" value={this.state.apto} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.apto}</FormHelperText>
            </FormControl> 
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>¿El Equipo debe ser Retirado? </InputLabel>
                  <Select    name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.retirado}</FormHelperText>
            </FormControl> 
        </div>


        <h4 className="text-title-accorion">Fecha y Hora</h4>

        <div className="div-accordion-container">
          <TextField className="login-input-email"
                      required
                      type="date"
                      name="fecha"
                      id="fecha"
                      label="Fecha de Realización"
                      value={this.state.fecha}
                      onChange={this.handleChange}
                      min="2019-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.fecha}
                      error={errors.fecha?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
          <div className="div-input-doble-mtoprog">
          <TextField className="login-input-email"
                      required
                      type="time"
                      name="horainicio"
                      id="horainicio"
                      label="Hora de Inicio"
                      value={this.state.horainicio}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.horainicio}
                      error={errors.horainicio?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
            <TextField className="login-input-email"
                      required
                      type="time"
                      name="horafinal"
                      id="horafinal"
                      label="Hora Final"
                      value={this.state.horafinal}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.horafinal}
                      error={errors.horafinal?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
        
            </div>
          </div>

          <h4 className="text-title-accorion">Personal que recibe la actividad</h4>

          <div className="div-accordion-container">
          <TextField className="login-input-email"
                      required
                      type="Text"
                      name="firmarecibe"
                      id="firmarecibe"
                      label="Nombre de Quien Recibe La Actividad"
                      value={this.state.firmarecibe}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.firmarecibe}
                      error={errors.firmarecibe?true:false}
                   
                      />

<TextField className="login-input-email"
                      required
                      type="Text"
                      name="cargorecibe"
                      id="cargorecibe"
                      label="Cargo de Quien Recibe la Actividad"
                      value={this.state.cargorecibe}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.cargorecibe}
                      error={errors.cargorecibe?true:false}
                   
                      />
          </div>


           
            </form>  
            </DialogContent>

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Realizar Mantenimiento" onClick={this.handlePostMto}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
           </DialogActions>

            </Dialog>
    


        </div>
    )
  }
}

SolicitudesMtoLista.propTypes = {
    
    getSolicitudesCorrectivosCliente: PropTypes.func.isRequired,
    postMtoCorrectivoConSolicitud:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteMtoCorrectivo:PropTypes.func.isRequired
  };

const mapActionsToProps={getSolicitudesCorrectivosCliente,clearErrors,deleteMtoCorrectivo,postMtoCorrectivoConSolicitud};

const mapStateToProps = (state)=>({
    data:state.data,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(SolicitudesMtoLista);
