import React, { Component } from 'react';

import { connect } from 'react-redux';

import {getDocsLegal} from '../../../redux/actions/dataactions';
import {Link } from 'react-router-dom';


import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import './DocumentacionLegalLista.css';
import { FaFileInvoice, FaUserNurse } from 'react-icons/fa';
import DocumentoSubir from './DocumentoSubir';
import DocumentoEliminar from './DocumentoEliminar';
import DocumentoEditar from './DocumentoEditar';



export class DocumentacionLegalLista extends Component {

    componentDidMount(){
        this.props.getDocsLegal(this.props.clienteId);
      }
      
    render() {

        const {doclegales,loadingdata}= this.props.data; 

        let mapeodocs=!loadingdata?
        (doclegales.length>0?(
            doclegales.map((doc)=>
            <div key={doc.docid} className="cliente-card">
            <div className="div-info-docs">

            {doc.url==='Sin Url'?<div className="dashing-cardicon-orange">
                          <FaFileInvoice color="#FFFFFF" className="dashing-icon"/>
            </div>:<div className="dashing-cardicon-green">
                          <FaFileInvoice color="#FFFFFF" className="dashing-icon"/>
            </div>}
            
          

            <div className="div-doc-infocontainer">

            <div className="div-doc-titulo">
             <h5 className="doc-titulo-text">{doc.tipodoc}</h5>
             <p className="doc-text">{doc.consecutivo}</p>
            </div>
            <div className="div-cliente-info">
            <p className="doc-descripcion-text">{doc.descripcion}</p>
            { doc.url==='Sin Url'?<a className='doc-link-text'>Sin Documento Adjunto</a>:<a className='doc-link-text-link' href={doc.url} target="_blank" rel="noopener noreferrer">Ver Documento</a>}
            
          
            </div>


            </div>
            

           

            </div>

            <div className="div-acciones">
            
            <DocumentoSubir docId={doc.docid} clienteId={this.props.clienteId}/>
            <DocumentoEliminar docId={doc.docid} clienteId={this.props.clienteId} />
            <DocumentoEditar  doc={doc}  clienteId={this.props.clienteId} docId={doc.docid} />
          
            
            </div>          
            
            
              
              </div>
             )

           
        
        ):( <div className="item-market-card">
            <div className='item-market-container'>
            <div className='item-market-descripcion'>
            <h3 className="titulo-market-text">Añade un Documento para Empezar</h3>
        </div>
            </div>
            </div>)):
        (<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>)
           


        return (
            <div>
                {mapeodocs}
            </div>
        )
    }
}

DocumentacionLegalLista.propTypes={
    getDocsLegal:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired
}
const mapStateToProps=state=>({
    data:state.data
})
export default connect(mapStateToProps,{getDocsLegal}) (DocumentacionLegalLista);

