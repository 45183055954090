import React, { Component, Fragment  } from 'react';
import './ProgramarCalibracion.css'; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus, FaLaptopMedical } from "react-icons/fa";

import {editEstado,clearErrors} from '../../../redux/actions/dataactions';

export class EstadoEquipo extends Component {

    constructor(){
        super();
        this.state={
            estado:'',
            nuevoestado:'',
            errors:{},
            open:false
        };
        
    };

    
  mapUserDetailsToState = (equipo) => {
    this.setState({
        estado:equipo.estado?equipo.estado:'',
        nuevoestado:equipo.estado?equipo.estado:''
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
    this.mapUserDetailsToState(this.props.equipo);
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    const { equipo } = this.props;
    this.mapUserDetailsToState(equipo);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
            const newEquipoData={
                estado:this.state.estado,
                nuevoestado:this.state.nuevoestado,
                idprestador:this.props.equipo.idprestador  
          };
    console.log(newEquipoData);
     this.props.editEstado(newEquipoData,this.props.equipoId);
    this.handleClose();
  };

    render() {
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;

        return (
            <div>
                <Fragment>
            <MyButton tip="Cambiar Estado Equipo" onClick={this.handleOpen}>
            <FaLaptopMedical color="#03178C" className="icon-crearcliente"/>
             </MyButton>

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >
            <DialogTitle>Cambiar Estado del Equipo</DialogTitle>
               
            <DialogContent>
                    <form>
                    
                    
                      
                      
                      <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Estado Equipo</InputLabel>
                            <Select
                            required
                            name="nuevoestado"
                            id="nuevoestado"
                            value={this.state.nuevoestado}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Funcionando">Funcionando</MenuItem>
                            <MenuItem value="Correctivo">Correctivo</MenuItem>
                            <MenuItem value="De Baja">De Baja</MenuItem>
                            </Select>
                            <FormHelperText>{errors.nuevoestado}</FormHelperText>
                        </FormControl>
                        </div>
                        




                      </form>
                      
            
            </DialogContent>
            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Cambiar Estado" onClick={this.handleSubmit}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
            
            
          </DialogActions>

            </Dialog>
             </Fragment>

            </div>
        )
    }
}

EstadoEquipo.propTypes = {
    editEstado: PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

  const mapActionsToProps={editEstado,clearErrors};

  const mapStateToProps = (state) => ({
    equipo: state.data.equipo.credentials,
    UI:state.UI
  });
  
  export default connect(mapStateToProps,mapActionsToProps)(EstadoEquipo);

