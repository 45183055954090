//USER REDUCER TYPES 
export const SET_AUTHENTICATED='SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED='SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER='LOADING_USER';
export const LOADING_PERFIL='LOADING_PERFIL';

export const LOADING_CLIENTE='LOADING_CLIENTE';
export const SET_PERFIL='SET_PERFIL';
export const LOADING_DATA_CAL='LOADING_DATA_CAL';

export const SET_USUARIOS = 'SET_USUARIOS';
export const SET_TECNICOS = 'SET_TECNICOS';


//DATA REDUCER TYPES 
export const SET_INDICADORES='SET_INDICADORES'; 
export const POST_CLIENTE='POST_CLIENTE';
export const EDIT_CLIENTE='EDIT_CLIENTE';
export const SET_CLIENTES='SET_CLIENTES';
export const SET_CLIENTE='SET_CLIENTE';
export const POST_EQUIPO='POST_EQUIPO';
export const SET_EQUIPOS='SET_EQUIPOS';
export const SET_EQUIPO='SET_EQUIPO';
export const POST_PMTO='POST_PMTO';
export const SET_PROGMTOS='SET_PROGMTOS';
export const SET_MTOSPREV='SET_MTOSPREV';
export const SET_MTOPREV='SET_MTOPREV';

export const POST_MTOPREVENTIVO='POST_MTOPREVENTIVO';
export const POST_CALIBRACION='POST_CALIBRACION';
export const POST_PCAL='POST_PCAL';
export const POST_MTOCORRECTIVO='POST_MTOCORRECTIVO';
export const SET_MTOSCORRECTIVOS='SET_MTOSCORRECTIVOS';
export const SET_PROGCALS='SET_PROGCALS';
export const SET_CALIBRACIONES='SET_CALIBRACIONES';
export const SET_CALIBRACIONES_TABLA='SET_CALIBRACIONES_TABLA';
export const SET_EQUIPOS_MTO='SET_EQUIPOS_MTO';
export const SET_EQUIPOS_CAL='SET_EQUIPOS_CAL';
export const LOADING_CAL='LOADING_CAL';
export const LOADING_MTO='LOADING_MTO';
export const LOADING_INDP='LOADING_INDP'; 
export const SET_INDICADORES_P='SET_INDICADORES_P';
export const POST_CRONOGRAMA_AUTO='POST_CRONOGRAMA_AUTO';
export const POST_CRONOGRAMACAL_AUTO='POST_CRONOGRAMACAL_AUTO';
export const POST_EDITPROGMTO='POST_EDITPROGMTO';
export const POST_EDITPROGCAL='POST_EDITPROGCAL';

export const POST_DOCLEGAL='POST_DOCLEGAL';
export const SET_DOCSLEGAL='SET_DOCSLEGAL';
export const EDIT_DOCLEGAL='EDIT_DOCLEGAL'

export const LOADING_SOL_CORR='LOADING_SOL_CORR';
export const SET_SOL_CORR='SET_SOL_CORR';


//UI REDUCER TYPES
export const SET_ERRORS='SET_ERRORS'; 
export const SET_OK='SET_OK';
export const LOADING_UI='LOADING_UI'; 
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const LOADING_DATA='LOADING_DATA';
export const STOP_LOADING_UI='STOP_LOADING_UI';
export const STOP_LOADING_DATA = 'STOP_LOADING_DATA'
//DATA SALES REDUCER

export const LOADING_DATA_SALES='LOADING_DATA_SALES';
export const POST_COTIZACION='POST_COTIZACION';
export const SET_COTIZACIONES='SET_COTIZACIONES';
export const SET_COTIZACION='SET_COTIZACION';
export const POST_ITEM='POST_ITEM';
export const POST_ESTADO_PUBLICAR='POST_ESTADO_PUBLICAR';
export const POST_OFERTA='POST_OFERTA';
export const SET_OFERTAS='SET_OFERTAS';
export const SET_OFERTA='SET_OFERTA';
export const POST_OFERTA_APROBAR='POST_OFERTA_APROBAR';
export const POST_OFERTA_RECHAZAR='POST_OFERTA_RECHAZAR';


//DATA ASISTENCIAL REDUCER
export const LOADING_DATA_ASISTENCIAL='LOADING_DATA_ASISTENCIAL';
export const POST_SOLICITUD_MTO='POST_SOLICITUD_MTO';
export const POST_SOLICITUD_COMPRAS='POST_SOLICITUD_COMPRAS';
export const SET_SOLICITUDES_SINENVIAR='SET_SOLICITUDES_SINENVIAR';
export const SET_SOLICITUD='SET_SOLICITUD';
export const POST_ITEM_ASISTENCIAL='POST_ITEM_ASISTENCIAL';
export const POST_SOLICITUD_ENVIAR='POST_SOLICITUD_ENVIAR';
export const SET_SOLICITUDES_ENVIADAS='SET_SOLICITUDES_ENVIADAS';
export const SET_SOLICITUDES_APROBADAS='SET_SOLICITUDES_APROBADAS';
export const SET_SOLICITUDES_RECHAZADAS='SET_SOLICITUDES_RECHAZADAS';


//IA Functions

export const POST_EQUIPO_IA='POST_EQUIPO_IA'
export const SET_EQUIPOIA='SET_EQUIPOIA'
export const LOADING_DATA_DATA='LOADING_DATA_DATA'
export const SET_OK_DATA='SET_OK_DATA'
export const LOADING_DATA_UI='LOADING_DATA_UI'
export const SET_ERRORS_DATA='SET_ERRORS_DATA'
export const CLEAR_EQUIPOS_IA='CLEAR_EQUIPOS_IA'


export const POST_RECOMENDACION_IA = 'POST_RECOMENDACION_IA'

export const SET_REPUESTOS='SET_REPUESTOS'


//INFRAESTRUCTURA
export const LOADING_DATA_INF='LOADING_DATA_INF'
export const SET_AREAS='SET_AREAS'
export const SET_AREA='SET_AREA'
export const SET_PROGMTOS_INFRA='SET_PROGMTOS_INFRA'
export const LOADING_DATA_MTO_INFRA='LOADING_DATA_MTO_INFRA';
export const SET_MTOS_INFRA='SET_MTOS_INFRA';
export const SET_MTO_INFRA='SET_MTO_INFRA';
export const SET_CORRECTIVOS_INFRA='SET_CORRECTIVOS_INFRA';
export const SET_CORRECTIVO_INFRA='SET_CORRECTIVO_INFRA';
export const SET_SOLICITUDESCORR_INFRA='SET_SOLICITUDESCORR_INFRA';

//TECNOVIGILANCIA
export const LOADING_CASOS_TECNO = 'LOADING_CASOS_TECNO'
export const SET_CASOS_TECNO = 'SET_CASOS_TECNO';
export const SET_CASO_TECNO = 'SET_CASO_TECNO'


//IA Cursos 
export const GET_CURSOS = 'GET_CURSOS';
export const GET_CURSOS_RECOMENDADOS = 'GET_CURSOS_RECOMENDADOS'
export const POST_RUTA_APRENDIZAJE = 'POST_RUTA_APRENDIZAJE'

//TAREAS
export const SET_TAREAS_CLIENTE='SET_TAREAS_CLIENTE';

//Indicadores NEW 
export const SET_INDICADORES_NEW='SET_INDICADORES_NEW';

//Obsolescencia
export const GET_ANALISIS_OBSOLESCENCIA_USUARIO = 'GET_ANALISIS_OBSOLESCENCIA_USUARIO';
export const GET_ANALISIS_OBSOLESCENCIA_PRESTADOR = 'GET_ANALISIS_OBSOLESCENCIA_PRESTADOR';


//MOBILIARIO TYPES 

export const LOADING_DATA_MOB='LOADING_DATA_MOB';
export const LOADING_DATA_MTO_MOB='LOADING_DATA_MTO_MOB';
export const SET_MOBILIARIOS='SET_MOBILIARIOS';


