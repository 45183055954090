import React from 'react';
import './Planes.css';
import { FaCheck } from 'react-icons/fa';
import Button from '../../../Elements/Button';



function Planes() {
    let urlencodedtext='Hola estoy interesado en un plan de alpha biomédica podrías darme más información?'
  return (
    <div className='container-planes'>
          <div className="container-internoplanes">
               <div className="seccion-titulo-plan">
                <h1 className="titulo-planes">
                Conoce Nuestros Planes
                </h1>
                <hr className="separador-planes"/>
              </div>

               <div className='seccion-plan-content'>

                        <div className='item-plan'>
                            <div className='item-titulo-plan'>
                                <h3 className='item-titulo-h3'>Profesionales Independientes</h3>
                                <hr className="item-separador-titulo-plan"/>
                                <p className='item-parrafo-titulo-plan'>El plan perfecto para que gestiones los equipos médicos en tu consultorio</p>
                            </div>

                            <div className='item-infocontainer-plan'>
                                <div className='item-container-precio'>
                                    <h3 className='dolar-plan'>$</h3>
                                    <h1 className='precio-plan'>55</h1>
                                    <h3 className='duracion-plan'>k/mensual</h3>
                                </div>

                                <div className='container-lista-ventajas-plan'>
                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>La documentación de tus equipos en la nube</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Manejo de Inventario y Cronogramas en linea</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Reportes de Mantenimiento y Calibración Digital</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Ahorra conectandote con proveedores directos</p>
                                    </div>

                                </div>

                                <div className='container-btn-plan'>
                                <a className="linkbtn" href={`https://wa.me/573218472298/?text=${urlencodedtext}`} target="_blank">
                                <Button buttonSize="btn--medium" buttonColor='blue'>
                                    Comprar
                                </Button>
                                </a>

                                </div>

                            </div>
                            
                        </div>

                        <div className='item-plan'>
                            <div className='item-titulo-plan'>
                                <h3 className='item-titulo-h3'>Instituciones Prestadoras de Salud </h3>
                                <hr className="item-separador-titulo-plan"/>
                                <p className='item-parrafo-titulo-plan'>Los equipos médicos de tu hospital conectados en una plataforma.</p>
                            </div>

                            <div className='item-infocontainer-plan'>
                                <div className='item-container-precio'>
                                    <h3 className='dolar-plan'>$</h3>
                                    <h1 className='precio-plan'>250</h1>
                                    <h3 className='duracion-plan'>k/mensual</h3>
                                </div>

                                <div className='container-lista-ventajas-plan'>
                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Control total de tus equipos médicos</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Documentación e Indicadores en un solo lugar</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Manejo de múltiples sedes y servicios</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Compra con proveedores directos y ahorra.</p>
                                    </div>

                                </div>

                                <div className='container-btn-plan'>
                                <a className="linkbtn" href={`https://wa.me/573218472298/?text=${urlencodedtext}`} target="_blank">
                                <Button buttonSize="btn--medium" buttonColor='blue'>
                                    Comprar
                                </Button>
                                </a>

                                </div>

                            </div>
                            
                        </div>

                        <div className='item-plan'>
                            <div className='item-titulo-plan'>
                                <h3 className='item-titulo-h3'>Empresas e Ingenieros Biomédicos</h3>
                                <hr className="item-separador-titulo-plan"/>
                                <p className='item-parrafo-titulo-plan'>Ofrece a tus clientes un plus digital con este plan.</p>
                            </div>

                            <div className='item-infocontainer-plan'>
                                <div className='item-container-precio'>
                                    <h3 className='dolar-plan'>$</h3>
                                    <h1 className='precio-plan'>150</h1>
                                    <h3 className='duracion-plan'>k/mensual</h3>
                                </div>

                                <div className='container-lista-ventajas-plan'>
                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Maneja todos los equipos de tus clientes.</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Toda la documentación biomédica la puedes hacer más fácil</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Ofreceles acceso a tus clientes y conectalos.</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>Dale a tu equipo de trabajo esta poderosa herramienta.</p>
                                    </div>

                                </div>

                                <div className='container-btn-plan'>
                                <a className="linkbtn" href={`https://wa.me/573218472298/?text=${urlencodedtext}`} target="_blank">
                                <Button buttonSize="btn--medium" buttonColor='blue'>
                                    Comprar
                                </Button>
                                </a>
                                </div>

                            </div>
                            
                        </div>

                        <div className='item-plan'>
                            <div className='item-titulo-plan'>
                                <h3 className='item-titulo-h3'>Proveedores para Servicios de Salud</h3>
                                <hr className="item-separador-titulo-plan"/>
                                <p className='item-parrafo-titulo-plan'>El plan perfecto para que gestiones los equipos médicos en tu consultorio</p>
                            </div>

                            <div className='item-infocontainer-plan'>
                                <div className='item-container-precio'>
                                    <h3 className='dolar-plan'>$</h3>
                                    <h1 className='precio-plan'>250</h1>
                                    <h3 className='duracion-plan'>k/mensual</h3>
                                </div>

                                <div className='container-lista-ventajas-plan'>
                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>La documentación de tus equipos online</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>La documentación de tus equipos online</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>La documentación de tus equipos online</p>
                                    </div>

                                    <div className='item-ventaja-plan'>
                                        <div className='div-icon-ventaja'>
                                            <FaCheck />
                                        </div>
                                        <p className='item-parrafo-ventaja-plan'>La documentación de tus equipos online</p>
                                    </div>

                                </div>

                                <div className='container-btn-plan'>
                                <a className="linkbtn" href={`https://wa.me/573218472298/?text=${urlencodedtext}`} target="_blank">
                                <Button buttonSize="btn--medium" buttonColor='blue'>
                                    Comprar
                                </Button>
                                </a>

                                </div>

                            </div>
                            
                        </div>


               </div>

        
   
    </div>
    </div>
  )
}

export default Planes