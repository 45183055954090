import React, { Component, Fragment  } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SolicitarMtoCorrectivo.css';



import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus,FaCogs,FaCheckCircle, FaCheck } from "react-icons/fa";

import {postSolicitudMtoInfra,getSolicitudesCorrInfra} from '../../../redux/actions/infraactions';

export class SolicitarMtoArea extends Component {
    
    constructor(){
        super();
        this.state={
            infosolicitud:'',
            fechasolicitud:'',
            horasolicitud:'',
            aprobado:{},
            errors:{},
            open:false
        };
        
    };

    static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

  
  handleOpen=()=>{
    this.setState({open:true,errors:{},aprobado:{}});
   
    };

    handleClose=()=>{


        if(this.state.aprobado.mensaje){
            //getEquipos this.handleEditProgMto();
            this.props.getSolicitudesCorrInfra(this.props.areaId);
            this.setState({
                fechasolicitud:'',
                horasolicitud:'',
                infosolicitud:'',
                open:false,errors:{},aprobado:{}});
        }else{
            this.setState({
                fechasolicitud:'',
                horasolicitud:'',
                infosolicitud:'',
                open:false,errors:{},aprobado:{}});
        }
       
       
                    
        };
    
 handleChange=(event)=>{
 this.setState({[event.target.name]:event.target.value});
 
 };

 
handleSubmit=(event)=>{
    event.preventDefault();

   

   


    const newSolicitudMto={
        infosolicitud:this.state.infosolicitud, 
        fechasolicitud:this.state.fechasolicitud,
        horasolicitud:this.state.horasolicitud,
        areaid:this.props.areaId,
        clienteid:this.props.user.credentials.idprestador, 
        idusersolicita:this.props.user.credentials.userId,
        handlesolicita:this.props.user.credentials.handle,
        nombresolicita:this.props.user.credentials.nombre,
        telnotificacion:this.props.user.credentials.telefono,
        emailnotificacion:this.props.area.cliente.email,
    
         
         
  };

  console.log(newSolicitudMto); 
  this.props.postSolicitudMtoInfra(newSolicitudMto,this.props.areaId); 
   
    //Aqui se postea al cliente
 

    }
  render() {
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;

    return (
        <div>
            <Fragment>
        <MyButton tip="Solicitar Mantenimiento Correctivo" onClick={this.handleOpen}>
        <FaCogs color="#03178C" className="icon-crearcliente"/>
         </MyButton>

         <Dialog
         open={this.state.open}
         onClose={this.handleClose}
         fullWidth
        maxWidth="sm"
        >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>¡Solicitud de Mantenimiento Exitosa!</h2>
              </div>
                
                ) : (<div>
                    <DialogTitle>Solicitar Mantenimiento Infraestructura</DialogTitle>

<DialogContent>
        <form>

              
        <div className="div-input-doble-mtoprog">
        <TextField className="login-input-email"
                        required
                        type="date"
                        name="fechasolicitud"
                        id="fechasolicitud"
                        label="Fecha Solicitud de Mantenimiento"
                        value={this.state.fechasolicitud}
                        onChange={this.handleChange}
                        min="2019-01-01" max="2022-01-01"
                        fullWidth
                        helperText={errors.fechasolicitud}
                        error={errors.fechasolicitud?true:false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        />

                  <TextField className="login-input-email"
                        required
                        type="time"
                        name="horasolicitud"
                        id="horasolicitud"
                        label="Hora Solicitud de Mantenimiento"
                        value={this.state.horasolicitud}
                        onChange={this.handleChange}
                        min="2000-01-01" max="2022-01-01"
                        fullWidth
                        helperText={errors.horasolicitud}
                        error={errors.horasolicitud?true:false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        />
            </div>
        <TextField className="login-input-email"
          required
          type="text"
          name="infosolicitud"
          id="infosolicitud"
          label="Describa la Falla"
          value={this.state.infosolicitud}
          onChange={this.handleChange}
          min="2019-01-01" max="2022-01-01"
          fullWidth
          helperText={errors.infosolicitud}
          error={errors.infosolicitud?true:false}
          InputLabelProps={{
            shrink: true,
          }}
          />

   


</form>
</DialogContent>
                </div>
            )
            }
        

        <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Realizar Solicitud" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            

            
          </DialogActions>
    
        </Dialog>

         </Fragment>

        </div>
    )
  }
}

SolicitarMtoArea.propTypes={
    postSolicitudMtoInfra:PropTypes.func.isRequired,
    getSolicitudesCorrInfra:PropTypes.func.isRequired,
   
    UI:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
    data:PropTypes.object.isRequired,
    equipo:PropTypes.object.isRequired
  };
  const mapStateToProps=(state)=>({
    UI:state.UI,
    user:state.user,
    equipo: state.data.equipo.credentials,
    data:state.data,
    area:state.datainfra.area,
  });
  
  export default connect (mapStateToProps,{postSolicitudMtoInfra,getSolicitudesCorrInfra}) (SolicitarMtoArea)
  
  