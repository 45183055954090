import React from 'react';
import './InventarioVerWebPDF.css';
const InventarioVerWebPDF = ({ equipos }) => {
    let mapeoequipos=
        (equipos.length>0?(
            equipos.map((equipo)=>
            <div className='itemcard'>
                <div className='itemelement'>
                <p className='titleitem-text'>Id Inventario</p>
                <p className="item-text">{equipo.idinventario}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Nombre</p>
                <p className="item-text">{equipo.nombre}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Marca</p>
                <p className="item-text">{equipo.marca?equipo.marca:'No Registra'}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Serie</p>
                <p className="item-text">{equipo.serie}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Ubicación</p>
                <p className="item-text">{equipo.ubicacion}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Servicio</p>
                <p className="item-text">{equipo.servicio}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Estado</p>
                <p className="item-text">{equipo.estado}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>R. Invima</p>
                <p className="item-text">{equipo.registroinvima}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Riesgo</p>
                <p className="item-text">{equipo.riesgo}</p>
                </div>
            
            </div>
           
            )
        ):(<div>
                <p>no hay nada</p>
        </div>)
        )
    
    return (
        <div className='ContainerMainInventario'>
            <div className='DivTitleInventario'>
                <h1 className='TitleInventario'>Inventario de Equipos Biomédicos</h1>
            </div>
          
         {mapeoequipos}
        </div>
        
        )
}

export default InventarioVerWebPDF