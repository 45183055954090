import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {postCronogramaMtoAuto} from '../../../redux/actions/dataactions';
import {progMtoAutoInfra,getProgMtosInfraClienteFiltrado} from '../../../redux/actions/infraactions';
import {clearErrors} from '../../../redux/actions/dataactions';
import CircularProgress from '@material-ui/core/CircularProgress';



import { FaArrowLeft,FaPlus,FaFirstAid, FaChartBar, FaCheckCircle, FaCheck } from "react-icons/fa";
import './PlanMtoCronoAuto.css';

export class PlanMtoCronoAuto extends Component {
    constructor(){
        super();
        this.state={
           
            idprestador:'',
            fecha:'',
            taskElectrica:'Revisar y probar paneles y tomacorrientes',
            taskHidraulica:'Inspeccionar y probar tuberías',
            taskComunicaciones:'Comprobar puntos de red y telefonicos',
            taskGases:'Inspeccionar y verificar sistema y alarmas',
            taskInstalaciones:'Verificar pisos, techos y paredes',
            errors:{},
            aprobado:{},
            open:false
        };
        
    };

    static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

 

    handleOpen=()=>{
        this.setState({open:true,aprobado:{}});
        };
 handleClose=()=>{
            this.props.clearErrors();
            this.setState({
            servicio:'',
            idprestador:'',
            fecha:'',
            open:false,
            errors:{}});
 };


 handleClose=()=>{


    if(this.state.aprobado.mensaje){
        //getEquipos this.handleEditProgMto();
        this.props.getProgMtosInfraClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
        this.setState({
            fecha:'',
            taskElectrica:'Revisar y probar paneles y tomacorrientes',
            taskHidraulica:'Inspeccionar y probar tuberías',
            taskComunicaciones:'Comprobar puntos de red y telefonicos',
            taskGases:'Inspeccionar y verificar sistema y alarmas',
            taskInstalaciones:'Verificar pisos, techos y paredes',
            open:false,errors:{},aprobado:{}});
    }else{
        this.setState({
            
            open:false,errors:{},aprobado:{}});
    }
   
   
                
    };



        handleChange=(event)=>{
            this.setState({[event.target.name]:event.target.value});
            };


    handleSubmit=(event)=>{
            event.preventDefault();
            let fechamto=new Date(this.state.fecha);
            let day;
            let mes;
            let newfecha;
            let year;
            let mesnombre;
    
            if(fechamto.getDate()>=30){
              day=1;
              
              mes=fechamto.getMonth()+1;
              if(mes>11){
                mes=0;
                year=fechamto.getFullYear()+1;
              }else{
                year=fechamto.getFullYear();
              }
              newfecha=fechamto.setDate(day);
              newfecha=fechamto.setMonth(mes);
              newfecha=new Date(newfecha);
              mes=mes+1;
              mesnombre=newfecha.toLocaleString('es-Es', {
                month: 'long',
              });
            }else{
              day=fechamto.getDate()+1;
              mes=fechamto.getMonth()+1;
              year=fechamto.getFullYear();
              mesnombre=fechamto.toLocaleString('es-Es', {
                month: 'long',
              });
            }
            
            const newUserData={
                fecha:this.state.fecha,
                idprestador:this.props.clienteId,
                dia:day?day:"",
                mes:mes?mes:"",
                year:year?year:"",
                mesnombre:mesnombre?mesnombre:"" ,
                taskElectrica:this.state.taskElectrica,
                taskHidraulica:this.state.taskHidraulica,
                taskComunicaciones:this.state.taskComunicaciones,
                taskGases:this.state.taskGases,
                taskInstalaciones:this.state.taskInstalaciones,

             
          };

          console.log(newUserData)

       this.props.progMtoAutoInfra(newUserData,this.props.clienteId);
           
           
         
     }

    render() {
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;
        return (
          

        
            <Fragment>

                  <div className='div-card-link' onClick={this.handleOpen}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                            Generar Cronograma Automático
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon">
                                            <FaFirstAid color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>
  
  
                </div>
           {/* <MyButton tip="Crear Equipo" onClick={this.handleOpen}>
            <FaFirstAid color="#03178C" className="icon-crearcliente"/>
        </MyButton>*/}

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>¡Programación de Mantenimiento Exitosa!</h2>
              </div>
                
                ) : (<div>
<DialogTitle className="text-title-accorion">Generar Cronograma Automático</DialogTitle>
               
               <DialogContent>
   
                       <form>
   
                       <div className="flex-accordion">
                       <h4 className="text-title-accorion">Fecha a Programar Mantenimiento</h4>
                       <div className="input_divido">
   
   
                         <div className="input_divido_children">
                         <TextField className="login-input-email"
                         required
                         type="date"
                         name="fecha"
                         id="fecha"
                         label="Fecha de Mantenimiento"
                         value={this.state.fecha}
                         onChange={this.handleChange}
                         min="2019-01-01" max="2029-01-01"
                         fullWidth
                         helperText={errors.fecha}
                         error={errors.fecha?true:false}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         />
                        
                           </div>
                           
                           </div>
                          
   
                           </div>
                   
             
   {/*
   taskElectrica:'',
               taskHidraulica:'',
               taskComunicaciones:'',
               taskGases:'',
               taskInstalaciones:'',
   */
   }
             <div className="flex-accordion">
             <h4 className="text-title-accorion">Actividades para la Red Electrica</h4>
             <TextField className="login-input-email"
                         required
                         type="text"
                         name="taskElectrica"
                         id="taskElectrica"
                         label="Actividades para el mantenimiento de la red electrica"
                         value={this.state.taskElectrica}
                         onChange={this.handleChange}
                         fullWidth
                         helperText={errors.taskElectrica}
                         error={errors.taskElectrica?true:false}
                         />
       
                         <br/>
           
   
             </div>
   
             <div className="flex-accordion">
             <h4 className="text-title-accorion">Actividades para la Red Hidraulica</h4>
             <TextField className="login-input-email"
                         required
                         type="text"
                         name="taskHidraulica"
                         id="taskHidraulica"
                         label="Actividades para el mantenimiento de la red hidraulica"
                         value={this.state.taskHidraulica}
                         onChange={this.handleChange}
                         fullWidth
                         helperText={errors.taskHidraulica}
                         error={errors.taskHidraulica?true:false}
                         />
       
                         <br/>
           
   
             </div>
   
             <div className="flex-accordion">
             <h4 className="text-title-accorion">Actividades para Red de Comunicaciones y Datos</h4>
             <TextField className="login-input-email"
                         required
                         type="text"
                         name="taskComunicaciones"
                         id="taskComunicaciones"
                         label="Actividades para el mantenimiento de la red de Comunicaciones y Datos"
                         value={this.state.taskComunicaciones}
                         onChange={this.handleChange}
                         fullWidth
                         helperText={errors.taskComunicaciones}
                         error={errors.taskComunicaciones?true:false}
                         />
       
                         <br/>
           
   
             </div>
   
             <div className="flex-accordion">
             <h4 className="text-title-accorion">Actividades para Red de Gases</h4>
             <TextField className="login-input-email"
                         required
                         type="text"
                         name="taskGases"
                         id="taskGases"
                         label="Actividades para el mantenimiento de la red de Gases"
                         value={this.state.taskGases}
                         onChange={this.handleChange}
                         fullWidth
                         helperText={errors.taskGases}
                         error={errors.taskGases?true:false}
                         />
       
                         <br/>
           
   
             </div>
   
             <div className="flex-accordion">
             <h4 className="text-title-accorion">Actividades de Instalaciones Fisicas</h4>
             <TextField className="login-input-email"
                         required
                         type="text"
                         name="taskInstalaciones"
                         id="taskInstalaciones"
                         label="Actividades de Instalaciones Fisicas"
                         value={this.state.taskInstalaciones}
                         onChange={this.handleChange}
                         fullWidth
                         helperText={errors.taskInstalaciones}
                         error={errors.taskInstalaciones?true:false}
                         />
       
                         <br/>
           
   
             </div>
             
           
                       
   
            
         
          
           
   
    </form>
                         
               
               </DialogContent>
                </div>)}
            
          
          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Realizar Programación" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            

            
          </DialogActions>
            </Dialog>
            </Fragment>
            
        )
    }
}

PlanMtoCronoAuto.propTypes = {
    progMtoAutoInfra: PropTypes.func.isRequired,
    getProgMtosInfraClienteFiltrado: PropTypes.func.isRequired,
    clienteId: PropTypes.string.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

const mapActionsToProps={progMtoAutoInfra,getProgMtosInfraClienteFiltrado,clearErrors};

const mapStateToProps = (state)=>({
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(PlanMtoCronoAuto);
