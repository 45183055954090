import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getEquipo, getCliente, getMtoPrev } from '../../../redux/actions/dataactions';
import { PDFViewer } from '@react-pdf/renderer';
import './InventarioPDF.css';
import ReporteMtoDocuPDF from './ReporteMtoDocuPDF';

const ReporteMtoPDF = ({ getEquipo, getCliente, getMtoPrev, data, match }) => {
  const [verWeb, setVerWeb] = useState(false);
  const [verPDF, setVerPDF] = useState(false);
  const history = useHistory();

  const { equipo, cliente, mtopreventivo, loading } = data;

  useEffect(() => {
    // get MTO PREVENTIVO DEBE ENVIAR DESDE LA LISTA EL ID DEL REPORTE DE MTO TAMBIÉN
    getMtoPrev(match.params.mtoid);
    // GET EQUIPO
    getEquipo(match.params.equipoId);
    // get cliente
    getCliente(match.params.clienteId);
  }, [getMtoPrev, getEquipo, getCliente, match.params.mtoid, match.params.equipoId, match.params.clienteId]);

  const handleOpenPDF = () => {
    setVerPDF(!verPDF);
    setVerWeb(false);
  };

  const goBack = () => {
    history.goBack(); // Esto llevará a la página anterior, independientemente de su origen.
  };

  return (
    <div className='div-inventaripdfgeneral'>
      <div className='div-inventaripdftitulo'>
        <div className="div_boton">
          <MyButton tip="Volver" onClick={goBack}>
            <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
          </MyButton>
        </div>
        <ButtonGroup variant="text" aria-label="text button group" color='primary'>
          <Button onClick={handleOpenPDF}>
            {verPDF ? "Ocultar PDF" : "Ver PDF"}
          </Button>
        </ButtonGroup>
      </div>
      <div className='div-inventariopdfcontent'>
        {equipo && cliente ? (
          <>
            {verPDF ? <PDFViewer style={{ width: "100%", height: "90vh" }}>
              <ReporteMtoDocuPDF equipo={equipo} cliente={cliente} mtopreventivo={mtopreventivo} />
            </PDFViewer> : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

ReporteMtoPDF.propTypes = {
  getEquipo: PropTypes.func.isRequired,
  getCliente: PropTypes.func.isRequired,
  getMtoPrev: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  data: state.data
})

export default connect(mapStateToProps, { getEquipo, getCliente, getMtoPrev })(ReporteMtoPDF);
