import React, { Component } from 'react';
import './ItemsCotizacion.css';
import MyButton from '../../../utils/MyButton';
import {FaArrowCircleLeft,FaPlus,FaSignInAlt} from "react-icons/fa";
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import {getCotizacion,clearErrors} from '../../../redux/actions/datasalesactions';
import AddItem from './AddItem';
import PublicarCotizacion from './PublicarCotizacion';

export class ItemsCotizacion extends Component {

  componentDidMount(){
        this.props.getCotizacion(this.props.match.params.cotizacionId);
       }

  render() {

    const {cotizacion,loadingdatasales}= this.props.dataSales;  
 

    let cargarCotizacion= !loadingdatasales ? (
        <div className="card-equipo">
            <div className="div-informacion-market">
            

            <div className="div-infocontainer-market">
            <div className="div-titulocliente">
       
            <h3 className="text-titulo-cliente">{cotizacion.credentials.titulo}</h3>
        
            </div>

            <div className="div-equipo-info-market">
                <div className="div-perfil-info">
                <hr className="clientes_separator"></hr>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Estado: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.estado}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Ciudad: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.ciudad}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Departamento: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.departamento}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Categoria: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.categoria}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Tipo Cotizacion: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.tipocotizacion}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Fecha de Vencimiento: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.fechavencimiento}</p>
                </div>


                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Forma de Pago: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.formadepago}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Nombre Responsable: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.nombreresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Cargo Responsable: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.cargoresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Telefono Responsable: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.cargoresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Email Responsable: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.emailresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Dirección Entrega: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.direccionentrega}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Nit Facturación: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.nit}</p>
                </div>

                          
                </div>

                <div className='terminos-container-market'>

                
                <hr className="clientes_separator"></hr> 
                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Términos: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.terminos}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Observaciones: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.observaciones}</p>
                </div>

                </div>
            </div>

           </div>
            </div>
            </div>

          
         
       

        
        )
        
        :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);
    

let mapeoitems=!loadingdatasales?
(cotizacion.credentials.items!==undefined?(
    cotizacion.credentials.items.map((item)=>
    <div key={item.datecreation} className="item-market-card">
    <div className='item-market-container'>

  
    <div className='item-market-item'>
  
    <h3 className="titulo-market-text">Nombre</h3>
    <h5 className="item-market-text">{item.nombre}</h5>
    </div>

    <div className='item-market-item'>
  
    <h3 className="titulo-market-text">Marca</h3>
    <h5 className="item-market-text">{item.marca}</h5>
    </div>

    <div className='item-market-item'>
  
  <h3 className="titulo-market-text">Cantidad</h3>
  <h5 className="item-market-text">{item.cantidad}</h5>
  </div>

  <div className='item-market-descripcion'>
  
  <h3 className="titulo-market-text">Descripcion</h3>
  <h5 className="item-market-text">{item.descripcion}</h5>
  </div>
   
  </div>
  
    </div>
    )
   

):( <div className="item-market-card">
    <div className='item-market-container'>
    <div className='item-market-descripcion'>
    <h3 className="titulo-market-text">Añade un Item para Empezar</h3>
</div>
    </div>
    </div>)):
(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>)
   
    
    return (
        <div>

        

        
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Items de Cotizacion</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <Link to={`/homemarket/miscotizaciones`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>


        <div className='container_content_items'>
           {cargarCotizacion}

        </div>
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Añade un Item</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <AddItem cotizacionId={this.props.match.params.cotizacionId}></AddItem>
        </div>
        </div>
        
        <div>
            {mapeoitems}

            <div className='div-publicar-market'>
            <PublicarCotizacion cotizacionId={this.props.match.params.cotizacionId} history={this.props.history}></PublicarCotizacion>
            </div>

        </div>

       

        </div>
      
    )
  }
}

ItemsCotizacion.propTypes = {
    
    getCotizacion: PropTypes.func.isRequired,
    dataSales:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    
  };

const mapActionsToProps={getCotizacion,clearErrors};

const mapStateToProps = (state)=>({
    dataSales:state.dataSales,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(ItemsCotizacion);


