import React, { Component, Fragment } from 'react';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getListaMtos,deleteProgMtoTabla,postMtoTabla,editProgMto} from '../../../../redux/actions/dataactions';
import MaterialTable from 'material-table';

import MyButton from '../../../../utils/MyButton';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Link } from 'react-router-dom';


import { FaArrowLeft, FaClipboardList, FaFilePdf, FaPlus} from "react-icons/fa";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';


export class TablaCronoMtoTec extends Component {

  constructor(){
    super();
    this.state={
        chasis:false,
        vaccesorios:false,
        vcontroles:false,
        vgeneral:false,
        limpieza:false,
        lelectronica:false,
        pcuantitativa:false,
        selectrica:false,
        lchasis:false,
        lubricacion:false,
        prueba1:'',
        instrumento1:'',
        valor1:'',
        aprobo1:'',
        prueba2:'',
        instrumento2:'',
        valor2:'',
        aprobo2:'',
        observaciones:'',
        horainicio:'',
        horafinal:'',
        firmarecibe:'',
        cargorecibe:'',
        fecha:'',
        errors:{},
        open:false,
        opendelete:false,
        progid:'',
        clienteid:'',
        equipoid:'',
    };
    
};
    componentDidMount(){
        this.props.getListaMtos(this.props.clienteId);
      }

    static getDerivedStateFromProps(props, state) {
        return props.UI.errors ? {errors: props.UI.errors} : null;
    }
     

      
    handleOpen=(rawdataprogid,rawdataequipoid,rawdatafecha,rawdatahora,rawdataactividades)=>{

      this.setState({open:true,progid:rawdataprogid,fecha:rawdatafecha,horainicio:rawdatahora,equipoid:rawdataequipoid,observaciones:rawdataactividades});
  
      };
      handleClose=()=>{
        // this.props.clearErrors();
         this.setState({open:false,errors:{}});
         };


         handleOpenDelete=(rawdataprogid,rawdataclienteid,rawdataequipoid)=>{
    
          this.setState({opendelete:true,progid:rawdataprogid,clienteid:rawdataclienteid,equipoid:rawdataequipoid});
         
          };
  
handleCloseDelete=()=>{
             // this.props.clearErrors();
              this.setState({opendelete:false,errors:{}});
              };
handleDelete=()=>{

this.props.deleteProgMtoTabla(this.state.clienteid,this.state.progid,this.state.equipoid);
this.handleCloseDelete();
}
handleEditProgMto=()=>{

  this.props.editProgMto({nuevoestado:'Realizado'},this.state.progid,this.state.equipoid); 
  this.handleClose();
}
handleChange=(event)=>{
                this.setState({ [event.target.name]:event.target.value});
                
           };


           handlePostMto=()=>{
            const newMtoPreventivo={
             chasis:this.state.chasis,
             vaccesorios:this.state.vaccesorios,
             vcontroles:this.state.vcontroles,
             vgeneral:this.state.vgeneral,
             limpieza:this.state.limpieza,
             lelectronica:this.state.lelectronica,
             pcuantitativa:this.state.pcuantitativa,
             selectrica:this.state.selectrica,
             lchasis:this.state.lchasis,
             lubricacion:this.state.lubricacion,
             prueba1:this.state.prueba1,
             instrumento1:this.state.instrumento1,
             valor1:this.state.valor1,
             aprobo1:this.state.aprobo1,
             prueba2:this.state.prueba2,
             instrumento2:this.state.instrumento2,
             valor2:this.state.valor2,
             aprobo2:this.state.aprobo2,
             observaciones:this.state.observaciones,
             horainicio:this.state.horainicio,
             horafinal:this.state.horafinal,
             fecha:this.state.fecha,
             firmarecibe:this.state.firmarecibe,
             cargorecibe:this.state.cargorecibe,
                
         };
           console.log(newMtoPreventivo);
           console.log(this.state.equipoid); 
           console.log(this.props.clienteId);
          this.props.postMtoTabla(newMtoPreventivo,this.state.equipoid,this.props.clienteId,this.state.progid); 
          
         }


    render() {
        const {equiposmto,loadingmto}= this.props.data;
        const {errors}=this.state;
        const { UI:{loading}}=this.props; 


        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };

        const columns=[
            {title:'Nombre',field:'nombre'},
            {title:'Marca',field:'marca'},
            {title:'Modelo',field:'modelo'},
            {title:'Serie',field:'serie'},
            {title:'Ubicación',field:'ubicacion'},
            {title:'Estado',field:'estado'},
            {title:'Fecha',field:'fecha'},
            {title:'Mes',field:'mesnombre'},
            {title:'Actividades',field:'actividades'}
            
        ]
        
        let mapeoequipos=!loadingmto?(
        <div>
          <div className='div-container-links-inventario'>

          
          <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/cronograma/${this.props.clienteId}`} state={equiposmto} >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Ver Cronograma en PDF
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
          </div>
        <MaterialTable
        columns={columns}
        data={equiposmto}
        title={
          <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'bold' }}></h2>
        }
        icons={tableIcons}
        options={{
          cellStyle: {
              fontSize: 11,
              fontWeight:500,
              color:'#696B6D'
            
           
            },
            headerStyle: {
              fontSize: 12,
              fontWeight: 'bolder',
              color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
            }
        
        }}

        actions={[
          rowData => ({
            icon: () =>  <Link to={`/dashboard/perfilclientetec/equipo/${this.props.clienteId}/${rowData.equipoid}`} style={{ color: '#03178C' }} ><ChevronRight  /></Link>,
            tooltip: 'Ver Perfil ',
            onClick: (rowData)
          })
          ,
                
          rowData => ({
            
            icon: tableIcons.Add,
            tooltip: 'Hacer Mantenimiento',
            onClick: (event, rowData) => {
                this.handleOpen(rowData.progid,rowData.equipoid,rowData.fecha,rowData.hora,rowData.actividades);
            },
            disabled: rowData.estado==='Realizado'
          })
         ,
            
            {
              icon: tableIcons.Delete,
              tooltip: 'Borrar Programación',
              onClick: (event, rowData) => {
                  
                this.handleOpenDelete(rowData.progid,rowData.clienteid,rowData.equipoid);
              }
            }
            
          ]}
        >

        </MaterialTable>

        
        </div>
        ):(
        <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
        )


        return (
            <div>
                {mapeoequipos}

                <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Realizar Reporte de Mantenimiento Preventivo</DialogTitle>
            <DialogContent>
            <form>
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
             <h4 className="text-title-accorion">Actividades Realizadas</h4>
        </AccordionSummary>
        <AccordionDetails>
        <div className="div-accordion-container">
        <div className="div-input-doble-mtoprog">
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Inspección Chásis</InputLabel>
                  <Select  required  name="chasis" id="chasis" value={this.state.chasis} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.chasis}</FormHelperText>
            </FormControl>  
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Verificar Accesorios</InputLabel>
                  <Select  required  name="vaccesorios" id="vaccesorios" value={this.state.vaccesorios} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.vaccesorios}</FormHelperText>
            </FormControl> 
            </div>
            <div className="div-input-doble-mtoprog">
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Verificar Controles</InputLabel>
                  <Select  required  name="vcontroles" id="vcontroles" value={this.state.vcontroles} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.vcontroles}</FormHelperText>
            </FormControl>  
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Prueba Cuantitativa</InputLabel>
                  <Select  required  name="pcuantitativa" id="pcuantitativa" value={this.state.pcuantitativa} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.pcuantitativa}</FormHelperText>
            </FormControl> 
            </div>
            
            <div className="div-input-doble-mtoprog">
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Seguridad Eléctrica</InputLabel>
                  <Select  required  name="selectrica" id="selectrica" value={this.state.selectrica} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.selectrica}</FormHelperText>
            </FormControl>  
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Limpieza</InputLabel>
                  <Select  required  name="limpieza" id="limpieza" value={this.state.limpieza} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.limpieza}</FormHelperText>
            </FormControl> 
            </div>
            
            <div className="div-input-doble-mtoprog">
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Lubricación de partes</InputLabel>
                  <Select  required  name="lubricacion" id="lubricacion" value={this.state.lubricacion} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.lubricacion}</FormHelperText>
            </FormControl>  
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Limpieza Electronica</InputLabel>
                  <Select  required  name="lelectronica" id="lelectronica" value={this.state.lelectronica} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.lelectronica}</FormHelperText>
            </FormControl> 
            </div>
            
            <div className="div-input-doble-mtoprog">
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Limpieza de Chásis</InputLabel>
                  <Select  required  name="lchasis" id="lchasis" value={this.state.lchasis} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem> 
                             </Select>
                            <FormHelperText>{errors.lchasis}</FormHelperText>
            </FormControl>  
            <FormControl className="formcontrol-nuevomto">
                <InputLabel>Verificación General</InputLabel>
                  <Select  required  name="vgeneral" id="vgeneral" value={this.state.vgeneral} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.vgeneral}</FormHelperText>
            </FormControl> 
            </div>

        </div>

          </AccordionDetails>
          </Accordion>

          <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <h4 className="text-title-accorion">Mediciones Realizadas</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="div-accordion-container">
          <div className="div-mediciones-container">
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                    
                      type="Text"
                      name="prueba1"
                      id="prueba1"
                      label="Prueba"
                      value={this.state.prueba1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.prueba1}
                      error={errors.prueba1?true:false}
                   
                      />
              </div>
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Text"
                      name="instrumento1"
                      id="instrumento1"
                      label="Instrumento"
                      value={this.state.instrumento1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.instrumento1}
                      error={errors.instrumento1?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Text"
                      name="valor1"
                      id="valor1"
                      label="Valor"
                      value={this.state.valor1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.valor1}
                      error={errors.valor1?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <FormControl className="formcontrol-nuevomto">
                <InputLabel>Aprobó</InputLabel>
                  <Select    name="aprobo1" id="aprobo1" value={this.state.aprobo1} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.aprobo1}</FormHelperText>
            </FormControl> 


              </div>
             


            </div>

            <div className="div-mediciones-container">
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                      
                      type="Text"
                      name="prueba2"
                      id="prueba2"
                      label="Prueba"
                      value={this.state.prueba2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.prueba2}
                      error={errors.prueba2?true:false}
                   
                      />
              </div>
              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                   
                      type="Text"
                      name="instrumento2"
                      id="instrumento2"
                      label="Instrumento"
                      value={this.state.instrumento2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.instrumento2}
                      error={errors.instrumento2?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <TextField className="login-input-email"
                     
                      type="Text"
                      name="valor2"
                      id="valor2"
                      label="Valor"
                      value={this.state.valor2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.valor2}
                      error={errors.valor2?true:false}
                   
                      />
              </div>

              <div className="div-mediciones-item">
              <FormControl className="formcontrol-nuevomto">
                <InputLabel>Aprobó</InputLabel>
                  <Select    name="valor2" id="valor2" value={this.state.valor2} onChange={this.handleChange}
                            fullWidth>
                            <MenuItem value=""><em></em></MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                             </Select>
                            <FormHelperText>{errors.valor2}</FormHelperText>
            </FormControl> 


              </div>
             


            </div>


          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
           <h4 className="text-title-accorion">Observaciones</h4>
        </AccordionSummary>
        <AccordionDetails>
        <TextField className="login-input-email"
                      
                      multiline
                      type="Text"
                      name="observaciones"
                      id="observaciones"
                      label="Observaciones"
                      rows={4}
                      value={this.state.observaciones}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.observaciones}
                      error={errors.observaciones?true:false}
                   
                      />
        </AccordionDetails>
      </Accordion>
           
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <h4 className="text-title-accorion">Fecha y Hora</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="div-accordion-container">
          <TextField className="login-input-email"
                      required
                      type="date"
                      name="fecha"
                      id="fecha"
                      label="Fecha de Realización"
                      value={this.state.fecha}
                      onChange={this.handleChange}
                      min="2019-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.fecha}
                      error={errors.fecha?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
          <div className="div-input-doble-mtoprog">
          <TextField className="login-input-email"
                      required
                      type="time"
                      name="horainicio"
                      id="horainicio"
                      label="Hora de Inicio"
                      value={this.state.horainicio}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.horainicio}
                      error={errors.horainicio?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
            <TextField className="login-input-email"
                      required
                      type="time"
                      name="horafinal"
                      id="horafinal"
                      label="Hora Final"
                      value={this.state.horafinal}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.horafinal}
                      error={errors.horafinal?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
        
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         
         <h4 className="text-title-accorion">Personal que recibe la actividad</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="div-accordion-container">
          <TextField className="login-input-email"
                      required
                      type="Text"
                      name="firmarecibe"
                      id="firmarecibe"
                      label="Nombre de Quien Recibe La Actividad"
                      value={this.state.firmarecibe}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.firmarecibe}
                      error={errors.firmarecibe?true:false}
                   
                      />

<TextField className="login-input-email"
                      required
                      type="Text"
                      name="cargorecibe"
                      id="cargorecibe"
                      label="Cargo de Quien Recibe la Actividad"
                      value={this.state.cargorecibe}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.cargorecibe}
                      error={errors.cargorecibe?true:false}
                   
                      />
          </div>
        </AccordionDetails>
      </Accordion>


            </form>
            </DialogContent>

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Realizar Mantenimiento" onClick={this.handlePostMto}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
           </DialogActions>
            </Dialog>

            <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Estas seguro de borrar programación?</DialogTitle>

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Borrar Programación" onClick={this.handleDelete}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
           </DialogActions>
            </Dialog>
        

            </div>
        )
    }
}
TablaCronoMtoTec.propTypes={
    getListaMtos:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clienteId: PropTypes.string.isRequired,
    UI:PropTypes.object.isRequired, 
    deleteProgMtoTabla:PropTypes.func.isRequired,
    editProgMto:PropTypes.func.isRequired,
    postMtoTabla:PropTypes.func.isRequired, 
}
const mapStateToProps=state=>({
    data:state.data,
    UI:state.UI
})

export default connect(mapStateToProps,{getListaMtos,deleteProgMtoTabla,editProgMto,postMtoTabla}) (TablaCronoMtoTec);

