import React, { Component, Fragment  } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';



import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus, FaPencilAlt } from "react-icons/fa";

import {editDocLegal,clearErrors} from '../../../redux/actions/dataactions';


export class DocumentoEditar extends Component {
    constructor(){
        super();
        this.state={
            tipodoc:'',
            descripcion:'',
            consecutivo:'',
            errors:{},
            open:false
        };
        
    };

    mapUserDetailsToState = (doc) => {
        this.setState({
            tipodoc:doc.tipodoc?doc.tipodoc:'',
            descripcion:doc.descripcion?doc.descripcion:'',
            consecutivo:doc.consecutivo?doc.consecutivo:'',
        
        });
        };
        
        static getDerivedStateFromProps(props, state) {
            return props.UI.errors ? {errors: props.UI.errors} : null;
        }
      
        handleOpen = () => {
            this.setState({ open: true });
            this.mapUserDetailsToState(this.props.doc);
          };
          handleClose = () => {
            this.setState({ open: false });
          };
          componentDidMount() {
            const { doc } = this.props;
            this.mapUserDetailsToState(doc);
          }
        
          handleChange = (event) => {
            this.setState({
              [event.target.name]: event.target.value
            });
          };

          handleSubmit = (event) => {
            event.preventDefault();
                    const newDocData={
                        tipodoc:this.state.tipodoc,
                        descripcion:this.state.descripcion,
                        consecutivo:this.state.consecutivo,
                        clienteid:this.props.clienteId,
                     
                  };
            this.props.editDocLegal(newDocData,this.props.clienteId,this.props.docId);
          
          };
        

  render() {
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;

    return (
        <div>
        <Fragment>
        <div className="div-botoneliminarcliente">
    <MyButton tip="Editar Documento" onClick={this.handleOpen}>
    <FaPencilAlt color="#03178C" className="icon-eliminarcliente"/>
     </MyButton>
     </div>
     <Dialog
     open={this.state.open}
     onClose={this.handleClose}
     fullWidth
     maxWidth="sm"
    >
   
    <DialogTitle>Editar Documento</DialogTitle>
                 
                <DialogContent>
                    <form>
                    <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Tipo Documento</InputLabel>
                            <Select
                            required
                         name="tipodoc"
                         id="tipodoc"
                         value={this.state.tipodoc}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Contrato">Contrato</MenuItem>
                            <MenuItem value="Factura">Factura</MenuItem>
                            <MenuItem value="Hoja de Vida">Hoja de Vida Ingeniero</MenuItem>
                            <MenuItem value="Registro Invima">Registro Invima</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipodoc}</FormHelperText>
                        </FormControl>

                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="descripcion"
                      id="descripcion"
                      label="Descripción"
                      value={this.state.descripcion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.descripcion}
                      error={errors.descripcion?true:false}
                      />

                <TextField className="login-input-email"
                      required
                      type="text"
                      name="consecutivo"
                      id="consecutivo"
                      label="Consecutivo"
                      value={this.state.consecutivo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.consecutivo}
                      error={errors.consecutivo?true:false}
                      />

                       
                    
                       
                    </form>
                </DialogContent>


                <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Editar Documento" onClick={this.handleSubmit}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
            
            
          </DialogActions>
    </Dialog>
    </Fragment>
    </div>
    )
  }
}
DocumentoEditar.propTypes = {
    editDocLegal: PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

  const mapActionsToProps={editDocLegal,clearErrors};

  const mapStateToProps = (state) => ({
    equipo: state.data.equipo.credentials,
    UI:state.UI
  });
  
 export default connect(mapStateToProps,mapActionsToProps)(DocumentoEditar);
