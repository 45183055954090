import React, { Component , Fragment } from 'react';
import './NuevaSolicitudCliente.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaHospital,FaArrowLeft,FaPlus,FaFileUpload} from "react-icons/fa";

import {postSolicitudComprasCliente,clearErrors} from '../../../redux/actions/dataasistencialactions';

export class NuevaSolicitudCliente extends Component {
    constructor(){
        super();
        this.state={
            fechavencimiento:'',           
            tipocotizacion:'',            
            observaciones:'',          
            categoria:'',
            nombreresponsable:'',
            cargoresponsable:'',
            telefonoresponsable:'',
            emailresponsable:'',
            titulo:'',
            servicio:'',
            area:'',
            errors:{},
            open:false,
            openalert:false
        };
        
    };
    static getDerivedStateFromProps(props, state) {
      return props.UI.errors ? {errors: props.UI.errors} : null;
  }
  

    handleOpen=()=>{
        this.setState({open:true});
        };
    handleOpenAlert=()=>{
            this.setState({openalert:true});
            };

     handleClose=()=>{
            this.props.clearErrors();
            this.setState({fechavencimiento:'',           
            tipocotizacion:'',            
            observaciones:'',          
            categoria:'',
            nombreresponsable:'',
            cargoresponsable:'',
            telefonoresponsable:'',
            emailresponsable:'',
            titulo:'',
            servicio:'',
            area:'',
            open:false,
            errors:{}});
            };
     handleCloseAlert=()=>{
                this.props.clearErrors();
                this.setState({openalert:false,errors:{}});
    };
        
     handleChange=(event)=>{
     this.setState({[event.target.name]:event.target.value});
     
     };

     handlePostSolicitudCompras=()=>{
        const newSolicitudCompras={
                    tipocotizacion:this.state.tipocotizacion,
          
            observaciones:this.state.observaciones,
            
            categoria:this.state.categoria,
            nombreresponsable:this.state.nombreresponsable,
            cargoresponsable:this.state.cargoresponsable,
            telefonoresponsable:this.state.telefonoresponsable,
            emailresponsable:this.state.emailresponsable,
            titulo:this.state.titulo,
            area:this.state.area,
            servicio:this.state.servicio,
            idprestador:this.props.clienteId,
         
            
     };
      console.log(this.props.history);
    this.props.postSolicitudComprasCliente(newSolicitudCompras,this.props.history,this.props.clienteId);
     
     
     
       

   
       
 
     }

     

 render(){
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;

    return(
        
        <Fragment>
        
        <div className="dashing-div-card">
                <div className="dashing-card-market" onClick={this.handleOpen}>
                    <div className="dashing-cardcontainer-market">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Crear nueva Solicitud de Compras
                                </p>
                             </div>
                            <div className="dashing-cardicon-green">
                                <FaFileUpload color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
                    </div>            
                </div>
            </div>

            <Dialog
         open={this.state.openalert}
         onClose={this.handleCloseAlert}
         fullWidth
        maxWidth="sm"
        >
        <DialogTitle>Tu cotización fue publicada con Exito</DialogTitle>

        <DialogContent>
            <a>Ahora dirigete al panel de Mis Cotizaciones, Añade los items que deseas y Publica tu Cotización</a>
        </DialogContent>

        <DialogActions>
        <MyButton tip="Cerrar" onClick={this.handleCloseAlert}>
        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
         </MyButton>
         </DialogActions>
       
        </Dialog>




         <Dialog
         open={this.state.open}
         onClose={this.handleClose}
         fullWidth
        maxWidth="sm"
        >
        <DialogTitle>Nueva Solicitud de Compras</DialogTitle>

        <DialogContent>
                <form>
                <TextField className="login-input-email"
                  required
                  type="text"
                  name="titulo"
                  id="titulo"
                  label="Titulo de la Solicitud"
                  value={this.state.titulo}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.titulo}
                  error={errors.titulo?true:false}                 
                  />


      
           
         

        <FormControl className="formcontrol-nuevocliente">
                        <InputLabel>Categoria*</InputLabel>
                        <Select
                        required
                     name="categoria"
                     id="categoria"
                     value={this.state.categoria}
                        onChange={this.handleChange}
                        fullWidth
                        >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value="equiposmedicos">Equipos Medicos</MenuItem>
                        <MenuItem value="repuestos">Repuestos</MenuItem>
                        <MenuItem value="serviciosmtocal">Servicios Mantenimiento/Calibración</MenuItem>
                        <MenuItem value="insumosdispositivos">Insumos/Dispositivos Medicos</MenuItem>
                        <MenuItem value="computo">Equipos de Computo</MenuItem>
                        <MenuItem value="mobiliario">Mobiliario Hospitalario</MenuItem>
                       
                     
                        </Select>
                        <FormHelperText>{errors.categoria}</FormHelperText>
                    </FormControl>  

                



                <div className='divdoblecotizacion'>
                    

                    <div className='divdobleunit'>
                    <FormControl className="formcontrol-nuevocliente">
                        <InputLabel>Tipo de Cotización*</InputLabel>
                        <Select
                        required
                     name="tipocotizacion"
                     id="tipocotizacion"
                     value={this.state.tipocotizacion}
                        onChange={this.handleChange}
                        fullWidth
                        >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value="De Urgencia">De Urgencia</MenuItem>
                        <MenuItem value="Normal">Normal</MenuItem>
                        <MenuItem value="Estudio de Mercado">Estudio de Mercado</MenuItem>
                     
                     
                        </Select>
                        <FormHelperText>{errors.tipocotizacion}</FormHelperText>
                    </FormControl>  

                    </div>
                    </div>

                    <TextField className="login-input-email"
                  required
                  type="text"
                  name="servicio"
                  id="servicio"
                  label="Servicio"
                  value={this.state.servicio}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.servicio}
                  error={errors.servicio?true:false}                 
                  />

                <TextField className="login-input-email"
                  required
                  type="text"
                  name="area"
                  id="area"
                  label="Área o Ubicación"
                  value={this.state.area}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.area}
                  error={errors.area?true:false}                 
                  />


                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="observaciones"
                  id="observaciones"
                  label="Observaciones"
                  value={this.state.observaciones}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.observaciones}
                  error={errors.observaciones?true:false}
                  multiline
                  rows={4}
                
                               
                 
                  />
                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="nombreresponsable"
                  id="nombreresponsable"
                  label="Nombre Responsable"
                  value={this.state.nombreresponsable}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.nombreresponsable}
                  error={errors.nombreresponsable?true:false}                 
                  />

<TextField className="login-input-email"
                  required
                  type="text"
                  name="cargoresponsable"
                  id="cargoresponsable"
                  label="Cargo Responsable"
                  value={this.state.cargoresponsable}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.cargoresponsable}
                  error={errors.cargoresponsable?true:false}                 
                  />
                  
                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="emailresponsable"
                  id="emailresponsable"
                  label="Email del Responsable"
                  value={this.state.emailresponsable}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.emailresponsable}
                  error={errors.emailresponsable?true:false}                 
                  />
                  
                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="telefonoresponsable"
                  id="telefonoresponsable"
                  label="Telefono"
                  value={this.state.telefonoresponsable}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.telefonoresponsable}
                  error={errors.telefonoresponsable?true:false}                 
                  />

   
        </form>
        </DialogContent>

       
        <DialogActions>
        <MyButton tip="Cerrar" onClick={this.handleClose}>
        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
         </MyButton>

         {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                      <MyButton tip="Programar Mantenimiento" onClick={this.handlePostSolicitudCompras}>
                      <FaPlus  color="#03178C" className="icon-formulario"/>
                       </MyButton>}
        
        

        
      </DialogActions>

        </Dialog>

        </Fragment>
       )


 }
}

NuevaSolicitudCliente.propTypes={
    postSolicitudComprasCliente:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired
  };
  const mapStateToProps=(state)=>({
    UI:state.UI,
    user:state.user
  });
  
  export default connect (mapStateToProps,{postSolicitudComprasCliente,clearErrors}) (NuevaSolicitudCliente)
