import {SET_USER,LOADING_DATA,SET_UNAUTHENTICATED,SET_INDICADORES,SET_AUTHENTICATED,LOADING_USER,LOADING_PERFIL,SET_PERFIL, SET_TECNICOS, SET_USUARIOS} from '../types'; 

const initialState={
    authenticated:false, 
    loading:false,
    loadingperfil:false,
    loadingdata:false,
    perfil:{},
    credentials:{},
    likes:[],
    notifications:[],
    indicadores:[],
    usuarios: [],
    tecnicos: [],
};

export default function(state=initialState,action){
    switch(action.type){
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated:true
            };
        case SET_UNAUTHENTICATED:
            return initialState; 
        case SET_USER: 
        return { 
            ...action.payload,
            loading:false,
            authenticated:true,
        };
        case LOADING_USER:
            return{
                ...state,
                loading:true
            }
        case LOADING_DATA:
            return {
                ...state,
                loadingdata:true
            }
        case LOADING_PERFIL:
            return{
                ...state,
                loadingperfil:true
            }
        case SET_PERFIL:
            return{ 
                ...action.payload,
                loadingperfil:false,
                authenticated:true
            };
        case SET_INDICADORES:
                return{
                    ...state,
                    indicadores:action.payload,
                    loading:false,
                    loadingdata:false
           }

        case SET_USUARIOS:
            return {
              ...state,
              usuarios: action.payload,
              loading: false,
              loadingdata: false
            }
        case SET_TECNICOS:
            return {
              ...state,
              tecnicos: action.payload,
              loading: false,
              loadingdata: false
            }
            
        default: 
        return state; 
    }

}