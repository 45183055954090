import React, { Component } from 'react';
import './DashboardCompras.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {getIndicadoresPrestadorCompras} from '../../../redux/actions/dataasistencialactions';


import { Doughnut } from 'react-chartjs-2';

import CircularProgress from '@material-ui/core/CircularProgress';


import {FaUsersCog,FaTasks,FaRuler,FaCogs,FaCalendarCheck,FaRegCalendarCheck,FaLaptopMedical,FaWrench,FaRocket,FaTimesCircle,FaPhoneSquareAlt} from "react-icons/fa";


export class DashboardCompras extends Component {
  componentDidMount(){
   this.props.getIndicadoresPrestadorCompras(this.props.user.credentials.idprestador);  
 }

  render() {
    const {indicadoresp,loadingindp}= this.props.data;

    let mapeoindicadores=!loadingindp ?( 
    <div className='main-container-asistencial'>       
    <div className="dashing-rowindicators">  
                    <div className="dashing-div-card">
                        <div className="dashing-card">
                            <div className="dashing-cardcontainer">
                                <div className="dashing-cardcontent">
                                    <div className="dashing-cardtext">
                                        <p className="dashing-text-card">
                                        Solicitudes de Compras
                                        </p>
                    <h3 className="dashing-value-card">{indicadoresp.solicitudescompras}</h3>
                                    </div>
                                    <div className="dashing-cardicon">
                                        <FaLaptopMedical color="#FFFFFF" className="dashing-icon"/>
                                    </div>
                                </div>
                                

                            </div>
                            
                        </div>
                        </div>


                        <div className="dashing-div-card">
                              <div className="dashing-card">
                                  <div className="dashing-cardcontainer">
                                      <div className="dashing-cardcontent">
                                          <div className="dashing-cardtext">
                                              <p className="dashing-text-card">
                                              Solicitudes Aprobadas
                                              </p>
                                              <h3 className="dashing-value-card">{indicadoresp.solicitudesaprobadas}</h3>
                                          </div>
                                          <div className="dashing-cardicon">
                                              <FaWrench color="#FFFFFF" className="dashing-icon"/>
                                          </div>
                                      </div>
                                    

                                  </div>
                          </div>
                          </div>


                          <div className="dashing-div-card">
        
                        <div className="dashing-card">
                            <div className="dashing-cardcontainer">
                                <div className="dashing-cardcontent">
                                    <div className="dashing-cardtext">
                                        <p className="dashing-text-card">
                                     Solicitudes Rechazadas
                                        </p>
                    <h3 className="dashing-value-card">{indicadoresp.solicitudesrechazadas}</h3>
                                    </div>
                                    <div className="dashing-cardicon">
                                        <FaRocket color="#FFFFFF" className="dashing-icon"/>
                                    </div>
                                </div>
                              

                            </div>
                            
                        </div>
                        
                        </div>
    </div>

    <div className="dashing-rowindicators">  

<div className="dashing-div-card">
    <div className="dashing-card">
        <div className="dashing-cardcontainer">
            <div className="dashing-cardcontent">
                <div className="dashing-cardtext">
                    <p className="dashing-text-card">
                    Cotizaciones 
                    </p>
    <h3 className="dashing-value-card">{indicadoresp.cotizaciones}</h3>
                </div>
                <div className="dashing-cardicon-blue">
                    <FaRuler color="#FFFFFF" className="dashing-icon"/>
                </div>
            </div>
        </div>
    </div>
</div>  

<div className="dashing-div-card">
    <div className="dashing-card">
        <div className="dashing-cardcontainer">
            <div className="dashing-cardcontent">
                <div className="dashing-cardtext">
                    <p className="dashing-text-card">
                Ofertas Aprobadas
                    </p>
                    <h3 className="dashing-value-card">{indicadoresp.ofertasaprobadas}</h3>
                </div>
                <div className="dashing-cardicon-blue">
                    <FaTasks color="#FFFFFF" className="dashing-icon"/>
                </div>
            </div>
        </div>
    </div>
</div>



<div className="dashing-div-card">
    <div className="dashing-card">
        <div className="dashing-cardcontainer">
            <div className="dashing-cardcontent">
                <div className="dashing-cardtext">
                    <p className="dashing-text-card">
                    Ofertas Rechazadas
                    </p>
                <h3 className="dashing-value-card">{indicadoresp.ofertasrechazadas}</h3>
                </div>
                <div className="dashing-cardicon-blue">
                    <FaRegCalendarCheck color="#FFFFFF" className="dashing-icon"/>
                </div>
            </div>
        </div>
    </div>
</div>


</div>  






    </div>):(
      <div className="div_cargando">
      <CircularProgress size={50}color="primary" className="login-progress" />
   </div>
    )
    return (
       <div>
                {mapeoindicadores}
            </div>
    )
  }
}
DashboardCompras.propTypes={
  getIndicadoresPrestadorCompras:PropTypes.func.isRequired,
  data:PropTypes.object.isRequired,
  user:PropTypes.object.isRequired,
  clienteId: PropTypes.string.isRequired  
}
const mapStateToProps=state=>({
  data:state.data,
  user:state.user
})
export default connect(mapStateToProps,{getIndicadoresPrestadorCompras}) (DashboardCompras);

