import React, { Component } from 'react'
import './HistorialSolicitudes.css'; 
import {getSolicitudesCorrectivos,clearErrors,deleteMtoCorrectivo} from '../../../redux/actions/dataactions';
import {getSolicitudesCorrInfra,subirEvidenciaSolCorrInfra } from '../../../redux/actions/infraactions';
import {subirEvidenciaSolicitudCorrectivo} from '../../../redux/actions/dataasistencialactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft,FaCheck,FaPlus } from "react-icons/fa";
import MaterialTable from 'material-table';


import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import FormHelperText from '@material-ui/core/FormHelperText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PhotoIcon from '@material-ui/icons/Photo';


export class TablaSolicitudesMtoInfra extends Component {

    constructor(){
        super();
        this.state={
            
            errors:{},
            open:false,
            opendelete:false,
            idsolicitud:'',
            fechasolicitud:'',
            horasolicitud:'',
            numsolicitud:'',
            fallareportada:'',

            aprobado: {},
            selectedFile: null,
            selectedFileUrl: null,          
            accion: null,
            imageUrl: null


          
        };
        
    };

    componentDidMount(){
        this.props.getSolicitudesCorrInfra(this.props.areaId);
       }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
          newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
          newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

    handleOpen = (rowdatasolicitudid, rowDataimageUrl , accion) => {
      this.setState({ open: true, solicitudid: rowdatasolicitudid, accion: accion, imageUrl : rowDataimageUrl,aprobado:{} });
    };
  
    onFileChange = event => {
      // Update the state with the selected file
      this.setState({ selectedFile: event.target.files[0] });
  
      // Create a URL for the selected file and update the state with it
      this.setState({ selectedFileUrl: URL.createObjectURL(event.target.files[0]) });
    };
  
    onFileUpload = (event) => {
      event.preventDefault();
      // Create an object of formData 
      const formData = new FormData();
  
      // Update the formData object 
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
      //this.props.subirImagenPerfil(formData);
      this.props.subirEvidenciaSolCorrInfra(formData, this.state.solicitudid)
    }
  
    handleClose = () => {
      if (this.state.aprobado.mensaje) {
        console.log(this.state.aprobado)
       
        this.setState({ open: false, selectedFileUrl: null, errors: {}, accion: null, aprobado: {}, imageUrl: null });
        this.props.getSolicitudesCorrInfra(this.props.areaId);
      }
      else {
        this.setState({ open: false, selectedFileUrl: null, errors: {}, accion: null, imageUrl: null });
      }
    };
    
  
    


  render() {

    const { solicitudescorr, loadingmto}= this.props.datainfra;
    const { errors } = this.state;
    const { UI: { loading } } = this.props;

        
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />),
            AddAPhotoIcon: forwardRef((props, ref) => <AddAPhotoIcon {...props} ref={ref}  style={{ color: '#03178c' }}/>),
            photo: forwardRef((props, ref) => <PhotoIcon {...props} ref={ref} style={{ color: '#03178c' }} />)

          };
        
        const columns=[
            {title:'Fecha',field:'solicitud.fechasolicitud'},
            {title:'Hora',field:'solicitud.nombresolicita'},
            {title:'Falla',field:'solicitud.infosolicitud'},
            {title:'Estado',field:'solicitud.estado'}
           
        ]

        let mapeosolicitudescorrectivos=!loadingmto ?(
            <div>
       <MaterialTable
               columns={columns}
               data={solicitudescorr}
               title={
                <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Solicitudes de Mantenimiento Infraestructura</h2>
              }
               icons={tableIcons}
               options={{
                cellStyle: {
                    fontSize: 12,
                    fontWeight:500,
                    color:'#696B6D'
                  
                 
                  },
                  headerStyle: {
                    fontSize: 14,
                    fontWeight: 'bolder',
                    color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                  }
              
              }}

              actions={[
                rowData => ({
                  title: "CargarEvidencia",
                 icon: props => {
                    const disabledColor = rowData.estado === 'Atendida' ? '#CCCCCC' : '#03178c'; // Define el color deshabilitado y habilitado
                    return (
                        <AddBox {...props} style={{ color: disabledColor }} />
                    );
                  },
                  tooltip: 'Añadir Evidencia',
                  disabled: rowData.estado === 'Atendida',
                  onClick: (event, rowData) => {
                    this.handleOpen(rowData.solicitudId, rowData.solicitud.imageUrl, "agregar");
                  },
                })
                ,
                {
                  title: "EvidenciaCargada",
                  icon: tableIcons.photo,
                  tooltip: 'Ver evidencia cargada',
                  onClick: (event, rowData) => {
                    this.handleOpen(rowData.solicitudId, rowData.solicitud.imageUrl, "ver");
                  },
                }
              ]}
    
       
         
             
             
       
               >
       
               </MaterialTable>
   
        
            </div>
              
   
           ):(  <div className="div_cargando">
               <CircularProgress size={50}color="primary" className="login-progress" />
               </div>);


    return (
        <div className="listamtoprog-container">
              
        <div className="listamtoprog-table">
        {mapeosolicitudescorrectivos}
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Cargar Evidencia fotográfica de la solicitud</DialogTitle>

          <DialogContent>
            {
              this.state.accion == "agregar" ? (
                this.state.aprobado.mensaje ? (
                  <div className='div-aprobado'>
                    <div className='div-icono-aprobado'>
                      <FaCheck color="#ffffff" className='icono-aprobado' />
                    </div>
                    <h2 className='text-aprobado'>Evidencia cargada Exitosamente</h2>
                  </div>
                ) : (
                  <form>
             
                   <input type="file" onChange={this.onFileChange} accept="image/png,image/jpg" />
                      {this.state.selectedFileUrl?(<div className='div-image-carga'>
                      <img src={this.state.selectedFileUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                      </div>):(<div></div>)}
                   
                  </form>)

              ) : (
                this.state.imageUrl ? (
                  <div>
                    <p> Esta es la evidencia cargada para esta solicitud </p>
                    <div className='div-image-carga'>

                      <img src={this.state.imageUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                    </div>
                  </div>
                ) : (
                  <p>No hay evidencia cargada para mostrar</p>
                )
              )}
          </DialogContent>

          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
              <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
            </MyButton>

            {loading ? <CircularProgress size={30} color="primary" className="login-progress" /> :
              this.state.accion == "agregar" ? (
                this.state.aprobado.mensaje ?
                  (
                    <></>
                  ) : (
                    <MyButton tip="Subir evidencia fotográfica" onClick={this.onFileUpload}>
                      <FaPlus color="#03178C" className="icon-formulario" />
                    </MyButton>
                  )
              ) : (
                <></>
              )

            }
          </DialogActions>
        </Dialog>


      </div>
    )
  }
}


TablaSolicitudesMtoInfra.propTypes = {    
    getSolicitudesCorrInfra: PropTypes.func.isRequired,
    subirEvidenciaSolCorrInfra :PropTypes.func.isRequired,
    datainfra:PropTypes.object.isRequired,
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteMtoCorrectivo:PropTypes.func.isRequired
  };

const mapActionsToProps={subirEvidenciaSolCorrInfra,getSolicitudesCorrInfra,clearErrors,deleteMtoCorrectivo};

const mapStateToProps = (state)=>({
    data:state.data,
    UI:state.UI,
    datainfra:state.datainfra
})



export default connect(mapStateToProps,mapActionsToProps)(TablaSolicitudesMtoInfra);

