import React, { Component } from 'react';
import './OfertaDetails.css';
import MyButton from '../../../utils/MyButton';

import {FaArrowCircleLeft,FaRegCheckSquare,FaRegWindowClose,FaArrowLeft,FaUpload,FaPlus,FaSignInAlt} from "react-icons/fa";
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import {getOferta,postAprobarOferta,postRechazarOferta,clearErrors} from '../../../redux/actions/datasalesactions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import dayjs from 'dayjs'; 
import relativeTime from 'dayjs/plugin/relativeTime'; 

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export class OfertaDetails extends Component {

    constructor(){
        super();
        this.state={
            cotizacion:{},
            ofertaid:'',
            items:[],
            itemsaprobados:[],
            userempresa:{},
            estadodeventa:'',
            errors:{},
            totalaprobado:0, 
            totalcomision:0,
            open:false,
            openrechazar:false
        };
        
    };

    

componentDidMount(){
    console.log(this.props.match.params.ofertaid);
    this.props.getOferta(this.props.match.params.ofertaid);
   
           
    }
    componentDidUpdate(prevProps) {
     
        // Uso tipico (no olvides de comparar las props):
        if (this.props.dataSales.loadingdatasales!== prevProps.dataSales.loadingdatasales) {
          
            let items=this.props.dataSales.oferta.items;
            let cotizacion=this.props.dataSales.oferta.cotizacion;
            let userempresa=this.props.dataSales.oferta.user;
            let ofertaid=this.props.match.params.ofertaid;
            let estadodeventa=this.props.dataSales.oferta.estadodeventa; 
          
            console.log(this.props.dataSales);
           
           
           
      this.setState({ estadodeventa:estadodeventa,items:items,cotizacion:cotizacion,userempresa:userempresa, ofertaid:ofertaid}, () => {
                console.log(this.state);
              });
           
        }

       
      }

      handleChangeEstado = (event) => {
     
       let value=event.target.value;
        let name=event.target.name;
        
        this.setState(prevState => ({

            items: prevState.items.map(
              item => item.datecreation === name? { ...item, estado: value }: item
            )
          
          }))
        
      };

      handleOpen=()=>{
        this.setState({open:true});
        console.log(this.state.items);

        const itemsaprobados = this.state.items.filter(item => item.estado == 'Aprobado');
        const sumall = itemsaprobados.map(item => item.preciototalitem).reduce((prev, curr) => prev + curr, 0);
        const comision=Math.round(sumall*0.07);
        console.log(itemsaprobados);
        console.log('Total Aprobado  $'+sumall);
        console.log('Comision Alpha Biomedica  $'+comision);
        this.setState({totalaprobado:sumall,totalcomision:comision,itemsaprobados:itemsaprobados});
       
       
    
        };

        handleClose=()=>{
            this.props.clearErrors();

            this.setState({
            open:false
            });
            };
    


          handleOpenRechazar=()=>{
        this.setState({openrechazar:true});
       
        };

        handleSubmit=(event)=>{
            event.preventDefault();

            if(this.state.totalaprobado>0){
                let data={
                    estadodeventa:'Aprobada',
                    totalaprobado:this.state.totalaprobado,
                    itemsaprobados:this.state.itemsaprobados,
                    totalcomision:this.state.totalcomision
                }
            
             
              console.log(data);
              this.props.postAprobarOferta(data,this.props.match.params.ofertaid,this.props.history);

            }
            else{
                alert("Debes Aprobar un Item");
            }
         
        
            } 

            handleSubmitRechazar=(event)=>{
                event.preventDefault();
                this.props.postRechazarOferta(this.props.match.params.ofertaid,this.props.history);

                
            }


  render() {

    const {oferta,loadingdatasales}= this.props.dataSales; 
    const { UI:{loading}}=this.props; 
    dayjs.extend(relativeTime); 

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })

    let cargarCotizacion= !loadingdatasales ? (
        <div className="card-equipo">
            <div className="div-informacion-market">
            

            <div className="div-infocontainer-market">
            <div className="div-titulocliente">
       
            <h3 className="text-titulo-cliente">{oferta.cotizacion.titulo}</h3>
        
            </div>

            <div className="div-equipo-info-market">
                <div className="div-perfil-info">
                <hr className="clientes_separator"></hr>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Estado: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.estado}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Ciudad: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.ciudad}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Departamento: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.departamento}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Categoria: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.categoria}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Tipo Cotizacion: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.tipocotizacion}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Fecha de Vencimiento: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.fechavencimiento}</p>
                </div>


                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Forma de Pago: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.formadepago}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Nombre Responsable: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.nombreresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Cargo Responsable: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.cargoresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Telefono Responsable: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.cargoresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Email Responsable: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.emailresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Dirección Entrega: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.direccionentrega}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Nit Facturación: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.nit}</p>
                </div>

               
                
                </div>

                <div className='terminos-container-market'>

                
                <hr className="clientes_separator"></hr> 
                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Términos: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.terminos}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Observaciones: </h5>    
                <p className="perfilequipo-text">{oferta.cotizacion.observaciones}</p>
                </div>

                </div>
            </div>

           </div>
            </div>
            </div>

          
         
       

        
        )
        
        :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);
    
    let cargarOferente= !loadingdatasales ? (
            <div className="card-equipo">
                <div className="div-informacion-market">
                
    
                <div className="div-infocontainer-market">
                <div className="div-titulocliente">
           
                <h3 className="text-titulo-cliente">Oferente: @{oferta.user.handle}</h3>
            
                </div>
    
                <div className="div-equipo-info-market">
                    <div className="div-perfil-info">
                    <hr className="clientes_separator"></hr>  
    
                    <div className="div-perfil-detalle">
                    <h5 className="equipo-detalle">Nombre: </h5>    
                    <p className="perfilequipo-text">{oferta.user.nombre}</p>
                    </div>
    
                    <div className="div-perfil-detalle">
                    <h5 className="equipo-detalle">Ciudad: </h5>    
                    <p className="perfilequipo-text">{oferta.user.ciudad}</p>
                    </div>
    
                    <div className="div-perfil-detalle">
                    <h5 className="equipo-detalle">Dirección: </h5>    
                    <p className="perfilequipo-text">{oferta.user.direccion}</p>
                    </div>
    
                                       
                 
    
                    <div className="div-perfil-detalle">
                    <h5 className="equipo-detalle">Teléfono: </h5>    
                    <p className="perfilequipo-text">{oferta.user.telefono}</p>
                    </div>
    
                    <div className="div-perfil-detalle">
                    <h5 className="equipo-detalle">Email: </h5>    
                    <p className="perfilequipo-text">{oferta.user.email}</p>
                    </div>
    
            
                    <div className="div-perfil-detalle">
                    <h5 className="equipo-detalle">Nit: </h5>    
                    <p className="perfilequipo-text">{oferta.user.ccnit}</p>
                    </div>

                    <div className="div-perfil-detalle">
                    <h5 className="equipo-detalle">Tipo de Usuario: </h5>    
                    <p className="perfilequipo-text">{oferta.user.tipouser}</p>
                    </div>
                    <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">En Alpha Biomedica desde: </h5>    
                <p className="perfilequipo-text">{dayjs(oferta.user.datecreation).fromNow()}</p>
                </div>

                              
                    </div>
    
                    
                </div>
    
               </div>
                </div>
                </div>
    
              
             
           
    
            
            )
            
            :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);

    let mapeoitems=!loadingdatasales?
(this.state.items!==undefined?(
    this.state.items.map((item)=>
    <div key={item.datecreation} className="item-market-card">
   
   <div className='container-market-item'>
   <div className='item-market-container'>

  
<div className='item-market-item'>

<h3 className="titulo-market-text">Nombre</h3>
<h5 className="item-market-text">{item.nombre}</h5>
</div>

<div className='item-market-item'>

<h3 className="titulo-market-text">Marca</h3>
<h5 className="item-market-text">{item.marca}</h5>
</div>

<div className='item-market-item'>

<h3 className="titulo-market-text">Cantidad</h3>
<h5 className="item-market-text">{item.cantidad}</h5>
</div>

<div className='item-market-descripcion'>

<h3 className="titulo-market-text">Descripcion</h3>
<h5 className="item-market-text">{item.descripcion}</h5>
</div>

<div className='item-market-descripcion'>
                      <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Aprobar</InputLabel>
                            <Select
                            required
                            name={item.datecreation}
                            value={item.estado}
                            onChange={this.handleChangeEstado}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Aprobado">Aprobar</MenuItem>
                            <MenuItem value="Rechazar">Rechazar</MenuItem>
                            <MenuItem value="Sin Aprobar">Sin Aprobar</MenuItem>
                             </Select>
                            
                        </FormControl>
                        </div>



</div>

<div className='item-market-container'>

<div className='item-market-item'> 
<h3 className="titulo-market-text">Precio</h3>
<h5 className="item-market-text">{formatterPeso.format(item.precio)}</h5>
</div>

<div className='item-market-item'>

<h3 className="titulo-market-text">Marca Ofertada</h3>
<h5 className="item-market-text">{item.marcarespuesta}</h5>
</div>

<div className='item-market-item'>

<h3 className="titulo-market-text">Presentación</h3>
<h5 className="item-market-text">{item.presentacion}</h5>
</div>

<div className='item-market-descripcion'>

<h3 className="titulo-market-text">Observaciones</h3>
<h5 className="item-market-text">{item.observaciones}</h5>
</div>

<div className='item-market-descripcion'>

<h3 className="titulo-market-text">Precio Total Item</h3>
<h5 className="item-market-text">{formatterPeso.format(item.preciototalitem)}</h5>
</div>
</div>



    
  
  
  
    
  </div>
  </div>


   
    )
   

):( <div className="item-market-card">
    <div className='item-market-container'>
    <div className='item-market-descripcion'>
    <h3 className="titulo-market-text">Añade un Item para Empezar</h3>
</div>
    </div>
    </div>)):
(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>)
   


    return (
        <div>
  

        
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Evaluar Oferta</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <Link to={`/homemarket/ofertascliente`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>


        <div className='container_content_items'>
           {cargarCotizacion}
           {cargarOferente}

        </div>

        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Items a Evaluar</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        
        </div>
        
        <div>
            {mapeoitems}
        <div className='containerbuttons-market'>
        <MyButton tip="Rechazar" onClick={this.handleOpenRechazar}>
        <FaRegWindowClose color="#ff1744" className="icon-crearcliente"/>
         </MyButton>

        <MyButton tip="Aprobar" onClick={this.handleOpen}>
        <FaRegCheckSquare color="#52b202" className="icon-crearcliente"/>
         </MyButton>

         


         </div>

            

            

        </div>

        
        <Dialog
         open={this.state.open}
         onClose={this.handleClose}
         fullWidth
        maxWidth="sm"
        >
        <DialogTitle className="titulo-market-text">Aprobar Oferta</DialogTitle>

        <DialogContent>
        <form>
        <h3 className="titulo-market-text">¿Esta seguro de aprobar esta Oferta?</h3>
        <h3 className="titulo-market-text">Total a Aprobar : {formatterPeso.format(this.state.totalaprobado)} COP IVA INCLUIDO</h3>
        

   
        </form>
        </DialogContent>

       
        <DialogActions>
        <MyButton tip="Cerrar" onClick={this.handleClose}>
        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
         </MyButton>

         {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                      <MyButton tip="Aprobar Oferta" onClick={this.handleSubmit}>
                      <FaRegCheckSquare color="#52b202" className="icon-formulario"/>
                       </MyButton>}
        
        

        
      </DialogActions>

        </Dialog>

        <Dialog
         open={this.state.openrechazar}
         onClose={this.handleClose}
         fullWidth
        maxWidth="sm"
        >
        <DialogTitle className="titulo-market-text">Rechazar Oferta</DialogTitle>

        <DialogContent>
        <form>
        <h3 className="titulo-market-text">¿Esta seguro de rechazar esta Oferta?</h3>
       

   
        </form>
        </DialogContent>

       
        <DialogActions>
        <MyButton tip="Cerrar" onClick={this.handleClose}>
        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
         </MyButton>

         {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                      <MyButton tip="Rechazar Oferta" onClick={this.handleSubmitRechazar}>
                      <FaRegWindowClose color="#ff1744" className="icon-formulario"/>
                       </MyButton>}
        
        

        
      </DialogActions>

        </Dialog>


        </div>

    )
  }
}

OfertaDetails.propTypes = {
    
    getOferta: PropTypes.func.isRequired,
    dataSales:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    postAprobarOferta:PropTypes.func.isRequired,
    postRechazarOferta:PropTypes.func.isRequired
    
  };

const mapActionsToProps={getOferta,postAprobarOferta,postRechazarOferta,clearErrors};

const mapStateToProps = (state)=>({
    dataSales:state.dataSales,
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(OfertaDetails);
