import React, { Component, Fragment  } from 'react';
import './ProgramarCalibracion.css'; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus,FaCalendarDay, FaCheck, FaCheckCircle } from "react-icons/fa";

import {progCalibracion,clearErrors} from '../../../redux/actions/dataactions';

export class ProgramarCalibracion extends Component {

  
    constructor(){
        super();
        this.state={
            fecha:'',
            hora:'',
            duracion:'',
            lugar:'',
            errors:{},
            aprobado:{},
            open:false
        };
        
    };
    static getDerivedStateFromProps(props, state) {
      const newState = {};
  
      if (props.UI.errors) {
          newState.errors = props.UI.errors;
      }
  
      if (props.UI.aprobado) {
         newState.aprobado = props.UI.aprobado;
      }
  
      return Object.keys(newState).length > 0 ? newState : null;
  }
  

   

    handleOpen=()=>{
        this.setState({open:true,aprobado:{}});
        };

     handleClose=()=>{
            this.props.clearErrors();
            this.setState({open:false,errors:{},aprobado:{}});
            };
        
     handleChange=(event)=>{
     this.setState({[event.target.name]:event.target.value});
     
     };



     
    handleSubmit=(event)=>{
        event.preventDefault();

        let fechacal=new Date(this.state.fecha);

        let day=fechacal.getDate()+1;
        let mes=fechacal.getMonth()+1;
        let mesnombre=fechacal.toLocaleString('es-Es', {
          month: 'long',
        });
        let year=fechacal.getFullYear();

        const newProgramacion={
          fecha:this.state.fecha,
          hora:this.state.hora,
          duracion:this.state.duracion,
          nombre:this.props.equipo.nombre,
          marca:this.props.equipo.marca,
          lugar:this.state.lugar,
          modelo:this.props.equipo.modelo,
          serie:this.props.equipo.serie,
          ubicacion:this.props.equipo.ubicacion,
          idinventario:this.props.equipo.idinventario,
          dia:day,
          mes:mes,
          mesnombre:mesnombre,
          year:year
             
      };

      console.log(newProgramacion);
       
       this.props.progCalibracion(newProgramacion,this.props.clienteId,this.props.equipoId); 
       
        //Aqui se postea al cliente
     
    
        }

    render() {
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;

        return (
            <div>
                <Fragment>
            <MyButton tip="Programar Calibración" onClick={this.handleOpen}>
            <FaCalendarDay color="#03178C" className="icon-crearcliente"/>
             </MyButton>

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
   {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>¡Programación de Calibración Exitosa!</h2>
              </div>
                
                ) : (<div>
                        <DialogTitle>Programar Calibración</DialogTitle>

                      <DialogContent>
                              <form>
                              <TextField className="login-input-email"
                                required
                                type="date"
                                name="fecha"
                                id="fecha"
                                label="Fecha de Calibracion"
                                value={this.state.fecha}
                                onChange={this.handleChange}
                                min="2019-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.fecha}
                                error={errors.fecha?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />

                          <TextField className="login-input-email"
                                required
                                type="time"
                                name="hora"
                                id="hora"
                                label="Hora de Calibracion"
                                value={this.state.hora}
                                onChange={this.handleChange}
                                min="2000-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.hora}
                                error={errors.hora?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />

                      <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Duración en Horas</InputLabel>
                                      <Select
                                      required
                                  name="duracion"
                                  id="duracion"
                                  value={this.state.duracion || ''}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="0.5">0.5</MenuItem>
                                      <MenuItem value="1">1</MenuItem>
                                      <MenuItem value="2">2</MenuItem>
                                      <MenuItem value="3">3</MenuItem>
                                      <MenuItem value="4">4</MenuItem>
                                      <MenuItem value="5">5</MenuItem>
                                      <MenuItem value="6">6</MenuItem>
                                      <MenuItem value="7">7</MenuItem>
                                      <MenuItem value="8">8</MenuItem>
                                  
                                      </Select>
                                      <FormHelperText>{errors.duracion}</FormHelperText>
                                  </FormControl>  
                            

                                  <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Lugar de Calibracion</InputLabel>
                                      <Select
                                      required
                                  name="lugar"
                                  id="lugar"
                                  value={this.state.lugar || ''}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="Laboratorio">Laboratorio</MenuItem>
                                      <MenuItem value="En Sitio">En Sitio</MenuItem>
                                
                                  
                                      </Select>
                                      <FormHelperText>{errors.lugar}</FormHelperText>
                                  </FormControl>  



                      </form>
                      </DialogContent>

                      
                 
                </div>
               
                )}

        

           
            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Programar Calibración" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            

            
          </DialogActions>

            </Dialog>

             </Fragment>

            </div>
        )
    }
}

ProgramarCalibracion.propTypes={
    progCalibracion:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired
  };
  const mapStateToProps=(state)=>({
    UI:state.UI,
    equipo: state.data.equipo.credentials
  });
  
  export default connect (mapStateToProps,{progCalibracion,clearErrors}) (ProgramarCalibracion)
  
  
