import React, { Component } from 'react'; 
import './HistorialPrevAsis.css'; 

import {getmtosprev,clearErrors,deleteMtoPreventivo,postMto} from '../../../redux/actions/dataactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft, FaCheck, FaCheckCircle, FaExclamationTriangle, FaFilePdf } from "react-icons/fa";
import MaterialTable from 'material-table';

import { postAprobarMtoPerfil } from '../../../redux/actions/dataasistencialactions';


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';

import AssignmentIcon from '@material-ui/icons/Assignment';
import {Link} from 'react-router-dom';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import MyButton from '../../../utils/MyButton';



//getmtosprev
export class HistorialPrevAsis extends Component {

  constructor(){
    super();
    this.state={
      errors:{},
      aprobado:{},
      open:false,
      opendelete:false,
      mtoid:'',
      clienteid:'',
      equipoid:'',
    };
    
};

       componentDidMount(){
        this.props.getmtosprev(this.props.equipoId);
       }

       static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }


       handleOpen=(rawdatamtoid)=>{

        this.setState({open:true,mtoid:rawdatamtoid,errors:{},aprobado:{}});
    
        };

        handleClose=()=>{
          // this.props.clearErrors();
          if(this.state.aprobado.mensaje||this.state.errors.error){
            this.props.getmtosprev(this.props.equipoId);
            this.setState({open:false,errors:{},aprobado:{}});
          }

         this.setState({open:false,errors:{},aprobado:{}});
           };
       

            // hay que hacer otro aprobar mto 
      handleSubmit=()=>{


        this.props.postAprobarMtoPerfil(this.state.mtoid)
      }
    
      
    render() {

         const {mtospreventivos,loadingmto,equipo}= this.props.data; 
         const { UI:{loading}}=this.props;
         const {errors}=this.state;
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />)
          };
        
        const columns=[
            {title:'Fecha',field:'fecha'},
            {title:'Hora Inicio',field:'horainicio'},
            {title:'Hora Final',field:'horafinal'},
            {
              title: 'Aprobado',
              field: 'aprobado',
              render: rowData => {
                  // Verifica si el campo 'aprobado' existe en los datos de la fila
                  if (rowData.hasOwnProperty('aprobado')) {
                      // Si 'aprobado' es true, muestra 'Sí'
                      if (rowData.aprobado === true) {
                          return 'Sí';
                      }
                      // Si 'aprobado' es false o cualquier otro valor, muestra 'No'
                      return 'No';
                  }
                  // Si el campo 'aprobado' no existe, muestra 'Aprobado No'
                  return 'No';
              }
          }
           
        ]


        
     let mapeomtospreventivos=!loadingmto ?(
         <div>
    <MaterialTable
            columns={columns}
            data={mtospreventivos}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Mantenimientos Preventivos</h2>
            }
            icons={tableIcons}
    
            actions={[
              rowData => ({
                icon: () =>  <Link to={`/dashboard/perfilcliente/equipo/hojadevidapdf/reportepdfmto/${equipo.credentials.idprestador}/${this.props.equipoId}/${rowData.mtoid}`} style={{ color: '#03178C' }} ><FaFilePdf  /></Link>,
                tooltip: 'Ver Reporte PDF ',
                onClick: (rowData)
              }),
              rowData => ({
                icon:  () => <tableIcons.Check style={{ color: rowData.aprobado ? 'green' : 'red' }} />,
                tooltip: 'Aprobar Mantenimiento',
                onClick: (event, rowData) => {
                    this.handleOpen(rowData.mtoid);
                },
                disabled: rowData.aprobado===true
              })
              ]}

              options={{


                cellStyle: {
                    fontSize: 12,
                    fontWeight:500,
                    color:'#696B6D'
                  
                 
                  },
                  headerStyle: {
                    fontSize: 14,
                    fontWeight: 'bolder',
                    color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                  }
              
              }}
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
        return (
              <div className="listamtoprog-container">
              
              <div className="listamtoprog-table">
              {mapeomtospreventivos}
              </div>
              <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
              <div className='div-icono-aprobado'>
                <FaCheck color="#ffffff" className='icono-aprobado'/>
              </div>
              <h2 className='text-aprobado'>Reporte Aprobado Exitosamente</h2>
             </div>
                
                ):(errors.error?( <div className='div-aprobado'>
                <div className='div-icono-advertencia'>
                  <FaExclamationTriangle color="#ffffff" className='icono-aprobado'/>
                </div>
                <h2 className='text-aprobado'>{errors.error}</h2>
            </div>):(<div>
                <DialogTitle>¿Estás seguro de Aprobar este Mantenimiento?</DialogTitle>
                </div>))}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20b90c" className="icon-formulario" />
                  </MyButton>
                ) : ( errors.error?(
                  <Link to={`/dashboard/inventarioequiposasistencial/hojadevidaequipo/${this.props.equipoId}`} style={{ color: '#ffd631' }}>
                  <FaCheckCircle color="#ffd631" className="icon-formulario" />
                </Link>
                ):(
                  <MyButton tip="Aprobar Mantenimiento" onClick={this.handleSubmit}>
                    <FaCheckCircle color="#03178C" className="icon-formulario" />
                  </MyButton>)
                )
              )}
            
           </DialogActions>
            </Dialog>
     



            

             

  
            </div>
        )
    }
}


HistorialPrevAsis.propTypes = {
    
      getmtosprev: PropTypes.func.isRequired,
      data:PropTypes.object.isRequired,
      clearErrors:PropTypes.func.isRequired,
      UI:PropTypes.object.isRequired,
      deleteMtoPreventivo:PropTypes.func.isRequired,
      postAprobarMtoPerfil:PropTypes.func.isRequired 
    };
  
  const mapActionsToProps={getmtosprev,clearErrors,deleteMtoPreventivo,postMto,postAprobarMtoPerfil };
  
  const mapStateToProps = (state)=>({
      data:state.data,
      UI:state.UI
  })
  
  
  
  export default connect(mapStateToProps,mapActionsToProps)(HistorialPrevAsis);
  
