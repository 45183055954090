import {SET_ERRORS,CLEAR_ERRORS, SET_AREAS, LOADING_DATA_INF, SET_OK, LOADING_UI, SET_AREA, SET_PROGMTOS_INFRA, LOADING_DATA_MTO_INFRA, SET_MTOS_INFRA, SET_MTO_INFRA, SET_CORRECTIVOS_INFRA, SET_CORRECTIVO_INFRA, SET_SOLICITUDESCORR_INFRA} from '../types';
import axios from 'axios'; 

export const getAreas =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA_INF});
    axios.get(`/getAreas/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_AREAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const postArea=(newArea, clienteId)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/creararea/${clienteId}`,newArea,)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());      

      
        
              
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const subirImagenArea=(formData,areaId,clienteId)=> (dispatch)=>{
    dispatch({type:LOADING_UI});
    
    axios.post(`/subirimagenarea/${areaId}`,formData)
    .then(()=>{
      dispatch(getAreas(clienteId));
    })
    .catch(err=>console.log(err)); 
  };

  export const getArea =(areaId,clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA_INF});
    axios.get(`/getarea/${areaId}/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_AREA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
}

export const editArea=(newArea, areaId)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/editarea/${areaId}`,newArea,)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());      

      
        
              
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const progMtoInfra=(newpmto,areaId)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/progmtoinfra/${areaId}`,newpmto)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

     
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const progMtoAutoInfra=(newpmto,clienteId)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/cronogramamtoautoinfra/${clienteId}`,newpmto)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

     
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};


export const cambiarEstadoArea=(areaId,nuevoEstado)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post(`/cambiarestado/${areaId}/${nuevoEstado}`)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

     
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const getProgMtosInfra =(areaId)=>dispatch=>{
    dispatch({type:LOADING_DATA_MTO_INFRA});
    axios.get(`/getprogmtosinfra/${areaId}`)
    .then(res=>{
        dispatch({
            type:SET_PROGMTOS_INFRA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getProgMtosInfraCliente =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA_MTO_INFRA});
    axios.get(`/getprogmtosinfracliente/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_PROGMTOS_INFRA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getProgMtosInfraClienteFiltrado = (clienteId, fechaInicio, fechaFin) => dispatch => {
    
    axios.get(`/getListaMtosInfraClienteFiltrado/${clienteId}`, {
      params: {
        fechaInicio: fechaInicio,
        fechaFin: fechaFin
      }
    })
      .then(res => {
        dispatch({
          type: SET_PROGMTOS_INFRA,
          payload: res.data
        });
        dispatch(clearErrors());
      }).catch(err => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
        console.log(err.response.data)
      })
  }
  

export const postMtoInfra=(newpmto,areaId,clienteid,handleuser,progid)=>dispatch=>{
    ///postmtoinfra/:clienteid/:areaid/:handleuser/:progid
    dispatch({type:LOADING_UI});
    axios
    .post(`/postmtoinfra/${clienteid}/${areaId}/${handleuser}/${progid}`,newpmto)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

     
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const deleteProgMtoInfra =(progId)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
   
    axios
    .post(`/deleteprogmtoinfra/${progId}`)
    .then((res) => {
        dispatch({
            type:SET_OK,
            payload:res.data
        });
      
        
    })
    .catch((err) => console.log(err));
}



export const getMtosInfra =(areaId)=>dispatch=>{
    dispatch({type:LOADING_DATA_MTO_INFRA});
    axios.get(`/getmtosinfra/${areaId}`)
    .then(res=>{
        dispatch({
            type:SET_MTOS_INFRA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getMtoInfra =(areaId,clienteId,mtoId)=>dispatch=>{
    dispatch({type:LOADING_DATA_MTO_INFRA});
    axios.get(`/getmtoinfra/${areaId}/${clienteId}/${mtoId}`)
    .then(res=>{
        dispatch({
            type:SET_MTO_INFRA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const deleteMtoInfra =(mtoId,progId)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
   
    axios
    .post(`/deletemtoinfra/${mtoId}/${progId}`)
    .then((res) => {
        dispatch({
            type:SET_OK,
            payload:res.data
        });
      
        
    })
    .catch((err) => console.log(err));
}

export const postCorrectivoInfra=(newpmto,areaId,clienteid,handleuser)=>dispatch=>{
    ///postmtoinfra/:clienteid/:areaid/:handleuser/:progid
    dispatch({type:LOADING_UI});
    axios
    .post(`/postcorrinfra/${clienteid}/${areaId}/${handleuser}`,newpmto)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

     
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const getMtosCorrectivosInfra =(areaId)=>dispatch=>{
    dispatch({type:LOADING_DATA_MTO_INFRA});
    axios.get(`/getcorrectivosinfra/${areaId}`)
    .then(res=>{
        dispatch({
            type:SET_CORRECTIVOS_INFRA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getCorrInfra =(areaId,clienteId,solicitudId)=>dispatch=>{
    dispatch({type:LOADING_DATA_MTO_INFRA});
    axios.get(`/getcorrectivoinfra/${areaId}/${clienteId}/${solicitudId}`)
    .then(res=>{
        dispatch({
            type:SET_CORRECTIVO_INFRA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const deleteCorrInfra =(mtoId,progId)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
   
    axios
    .post(`/deletecorrectivoinfra/${mtoId}/${progId}`)
    .then((res) => {
        dispatch({
            type:SET_OK,
            payload:res.data
        });
      
        
    })
    .catch((err) => console.log(err));
}
export const subirEvidenciaMtoCorrInfra = (formData, mtoid) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post(`/subirevidenciamtocorrinfra/${mtoid}`, formData)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        })
        dispatch(clearErrors());
     
      })
      .catch(err => console.log(err));
  };
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };


  export const postSolicitudMtoInfra=(newsolicitud,areaId)=>dispatch=>{
    ///postmtoinfra/:clienteid/:areaid/:handleuser/:progid
    dispatch({type:LOADING_UI});
    axios
    .post(`/solicitarmtoinfra/${areaId}`,newsolicitud)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());

     
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};

export const getSolicitudesCorrInfra =(areaId)=>dispatch=>{
    dispatch({type:LOADING_DATA_MTO_INFRA});
    axios.get(`/getsolicitudesinfra/${areaId}`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUDESCORR_INFRA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}


export const getSolicitudesInfraClienteFiltrado = (clienteId, fechaInicio, fechaFin) => dispatch => {
    dispatch({ type: LOADING_DATA_MTO_INFRA });
    axios.get(`/getsolicitudesinfrafiltrado/${clienteId}`, {
      params: {
        fechaInicio: fechaInicio,
        fechaFin: fechaFin
      }
    })
      .then(res => {
        dispatch({
        type:SET_SOLICITUDESCORR_INFRA,
          payload: res.data
        });
        dispatch(clearErrors());
      }).catch(err => console.log(err))
  }

export const subirEvidenciaSolCorrInfra = (formData, solicitudid) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post(`/subirevidenciasolicitudinfra/${solicitudid}`, formData)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        })
        dispatch(clearErrors());
     
      })
      .catch(err => console.log(err));
  };

  export const postAprobarMtoInfraAsis=(mtoid)=>(dispatch)=>{
    dispatch({type:LOADING_UI})

    axios.post(`/aprobarmtoinfra/${mtoid}`)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });

    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}
