import React from 'react';
import './Recientes.css'; 
import moment from 'moment'; 

import { Link } from 'react-router-dom';


function Recientes({post}) {
  return (
    <div className='cardrecientes'>
        <div className='divcardrecientes'>
            <div className='divimgrecientes'>
            <img className='imgrecientes' src={post.featuredImage.url}>
            </img>
            </div>

            <div className='divtitulorecientes'>
            <Link to={`/blog/${post.id}`} className='linktitle' params={post} >
            <h1 className='titulorecientes'>{post.title}</h1>
            </Link>
           
            <div className='divsubtitulorecientes'>
            <p className='fecharecientes'>{moment(post.createdAt).format('MM, DD, YYYY')}</p>
            <p className='categoriarecientes'>{post.author.name}</p>
            </div>
            </div>
        </div>

      
    </div>
  )
}

export default Recientes