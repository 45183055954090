import React, { Component } from 'react'; 
import './TablaMtoArea.css'; 

import {getmtosprev,clearErrors,deleteMtoPreventivo,postMto} from '../../../redux/actions/dataactions';
import {getMtosInfra,deleteMtoInfra} from '../../../redux/actions/infraactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft,FaPlus,FaFilePdf, FaTrash, FaCheckCircle } from "react-icons/fa";
import MaterialTable from 'material-table';
import {Link} from 'react-router-dom';

import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';

import AssignmentIcon from '@material-ui/icons/Assignment';


//getmtosprev
export class TablaMtoArea extends Component {

  constructor(){
    super();
    this.state={
        mtoid:'',
        progid:'',
        errors:{},
        aprobado:{},
        open:false,
        opendelete:false
    };
    
};

       componentDidMount(){
       
        this.props.getMtosInfra(this.props.areaId);
       }

       static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }
       

       

                  
              handleOpenDelete=(rawdatamtoid,rawdataprogid)=>{
                this.setState({opendelete:true,mtoid:rawdatamtoid,progid:rawdataprogid,errors:{},aprobado:{}});
              
                };

          handleCloseDelete=()=>{
            if(this.state.aprobado.mensaje){
                //this.handleEditProgMto();
                //get prog mtos 
                this.props.getMtosInfra(this.props.areaId);
                this.setState({opendelete:false,errors:{},aprobado:{}});
             }else{
              this.setState({opendelete:false,errors:{},aprobado:{}});
             }
                  
                    };

        handleDelete=()=>{
            console.log('MtoID'+this.state.mtoid);
            console.log('ProgID'+this.state.progid);
           this.props.deleteMtoInfra(this.state.mtoid,this.state.progid);
                     
                     
                    
                  
                    }
          
      
    render() {

        const {mtosinfra,loadingmto}= this.props.datainfra; 
         const { UI:{loading}}=this.props;
        
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />)
          };
        
        const columns=[
            {title:'Fecha',field:'fechainicio'},
            {title:'Hora Inicio',field:'horainicio'},
            {title:'Hora Final',field:'horafinal'},
            {
              title: 'Aprobado',
              field: 'aprobado',
              render: rowData => {
                  // Verifica si el campo 'aprobado' existe en los datos de la fila
                  if (rowData.hasOwnProperty('aprobado')) {
                      // Si 'aprobado' es true, muestra 'Sí'
                      if (rowData.aprobado === true) {
                          return 'Sí';
                      }
                      // Si 'aprobado' es false o cualquier otro valor, muestra 'No'
                      return 'No';
                  }
                  // Si el campo 'aprobado' no existe, muestra 'Aprobado No'
                  return 'No';
              }
          },
           
        ]


        
     let mapeomtospreventivos=!loadingmto ?(
         <div>
    <MaterialTable
            columns={columns}
            data={mtosinfra}
         
            icons={tableIcons}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Mantenimientos Infraestructura Realizados</h2>
            }
            options={{
              cellStyle: {
                  fontSize: 12,
                  fontWeight:500,
                  color:'#696B6D'
                
               
                },
                headerStyle: {
                  fontSize: 14,
                  fontWeight: 'bolder',
                  color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                }
            
            }}
            actions={[
             
              rowData => ({
                icon: () =>  <Link to={`/dashboard/perfilcliente/${this.props.clienteId}/infraestructura/${this.props.areaId}/reportepdfmto/${rowData.mtoId}`} style={{ color: '#03178C' }} ><FaFilePdf  /></Link>,
                tooltip: 'Ver Reporte PDF ',
                onClick: (rowData)
              }),
                {
                  icon: tableIcons.Delete,
                  tooltip: 'Borrar Programación',
                  onClick: (event, rowData) => {
                      
                    this.handleOpenDelete(rowData.mtoId,rowData.progid);
                  }
                }
              ]}
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
        return (
              <div className="listamtoprog-container">
              
              <div className="listamtoprog-table">
              {mapeomtospreventivos}
              </div>

             
              <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-delete'>
                    <FaTrash color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Borrado Exitosamente</h2>
              </div>
                
                ):(<div>
                <DialogTitle>¿Estás seguro de borrar este Mantenimiento ?</DialogTitle>
                </div>)}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseDelete}>
                    <FaCheckCircle color="#f9044e" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Borrar Mantenimiento" onClick={this.handleDelete}>
                    <FaTrash color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
           </DialogActions>
            </Dialog>
        

  
            </div>
        )
    }
}


TablaMtoArea.propTypes = {
    
    getMtosInfra: PropTypes.func.isRequired,
      data:PropTypes.object.isRequired,
      user:PropTypes.object.isRequired,
      clearErrors:PropTypes.func.isRequired,
      UI:PropTypes.object.isRequired,
      deleteMtoPreventivo:PropTypes.func.isRequired,
      datainfra:PropTypes.object.isRequired,
      deleteMtoInfra:PropTypes.func.isRequired
    };
  
  const mapActionsToProps={getMtosInfra,deleteMtoInfra,clearErrors,deleteMtoPreventivo,postMto};
  
  const mapStateToProps = (state)=>({
      data:state.data,
      user:state.user,
      UI:state.UI,
      datainfra:state.datainfra
  })
  
  
  
  export default connect(mapStateToProps,mapActionsToProps)(TablaMtoArea);
  
