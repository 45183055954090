import React, { Component , Fragment } from 'react';
import './ProgMtoInfra.css';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus,FaCalendarPlus, FaCheckCircle, FaCheck } from "react-icons/fa";

import {progMtoInfra,getProgMtosInfra,clearErrors} from '../../../redux/actions/infraactions';


export class ProgMtoInfra extends Component {

    constructor(){
        super();
        this.state={
            fecha:'',
            hora:'',
            duracion:'',
            taskElectrica:'',
            taskHidraulica:'',
            taskComunicaciones:'',
            taskGases:'',
            taskInstalaciones:'',
            errors:{},
            aprobado:{},
            open:false
        };
        
    };
    static getDerivedStateFromProps(props, state) {
      const newState = {};
  
      if (props.UI.errors) {
          newState.errors = props.UI.errors;
      }
  
      if (props.UI.aprobado) {
         newState.aprobado = props.UI.aprobado;
      }
  
      return Object.keys(newState).length > 0 ? newState : null;
  }
  

    handleOpen=()=>{
        this.setState({open:true,aprobado:{}});
        };

        handleClose=()=>{


            if(this.state.aprobado.mensaje){
                //getEquipos this.handleEditProgMto();
                this.props.getProgMtosInfra(this.props.areaId);
                this.setState({
                    nombre:'',
                    servicio:'',
                    piso:'',
                    estado:'',
                    ubicacion:'',
                    open:false,errors:{},aprobado:{}});
            }else{
                this.setState({
                    nombre:'',
                    servicio:'',
                    piso:'',
                    estado:'',
                    ubicacion:'',
                    open:false,errors:{},aprobado:{}});
            }
           
           
                        
            };
        
     handleChange=(event)=>{
     this.setState({[event.target.name]:event.target.value});
     
     };

     
    handleSubmit=(event)=>{
        event.preventDefault();

        let fechamto=new Date(this.state.fecha);
        let day;
        let mes;
        let newfecha;
        let year;
        let mesnombre;

        if(fechamto.getDate()>=30){
          day=1;
          
          mes=fechamto.getMonth()+1;
          if(mes>11){
            mes=0;
            year=fechamto.getFullYear()+1;
          }else{
            year=fechamto.getFullYear();
          }
          newfecha=fechamto.setDate(day);
          newfecha=fechamto.setMonth(mes);
          newfecha=new Date(newfecha);
          mes=mes+1;
          mesnombre=newfecha.toLocaleString('es-Es', {
            month: 'long',
          });
        }else{
          day=fechamto.getDate()+1;
          mes=fechamto.getMonth()+1;
          year=fechamto.getFullYear();
          mesnombre=fechamto.toLocaleString('es-Es', {
            month: 'long',
          });
        }
        
        
       

        const newProgramacion={
          fecha:this.state.fecha,
          hora:this.state.hora,
          dia:day,
          mes:mes,
          mesnombre:mesnombre,
          year:year,
          duracion:this.state.duracion,
          areaId:this.props.areaId,
          clienteId:this.props.clienteId,
          taskElectrica:this.state.taskElectrica,
            taskHidraulica:this.state.taskHidraulica,
            taskComunicaciones:this.state.taskComunicaciones,
            taskGases:this.state.taskGases,
            taskInstalaciones:this.state.taskInstalaciones,

             
      };

      console.log(newProgramacion);

      this.props.progMtoInfra(newProgramacion,this.props.areaId); 
        //Aqui se postea al cliente
     
    
        }
        

    render() {

        const { UI:{loading}}=this.props; 
        const {errors}=this.state;

        return (
            <Fragment>
            <MyButton tip="Programar Mantenimiento Preventivo" onClick={this.handleOpen}>
            <FaCalendarPlus color="#03178C" className="icon-crearcliente"/>
             </MyButton>

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
                {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>¡Programación de Mantenimiento Exitosa!</h2>
              </div>
                
                ) : (<div>
                    <DialogTitle>Programar Mantenimiento Infraestructura</DialogTitle>

                      <DialogContent>
                              <form>
                              <TextField className="login-input-email"
                                required
                                type="date"
                                name="fecha"
                                id="fecha"
                                label="Fecha de Mantenimiento"
                                value={this.state.fecha}
                                onChange={this.handleChange}
                                min="2019-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.fecha}
                                error={errors.fecha?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />

                          <TextField className="login-input-email"
                                required
                                type="time"
                                name="hora"
                                id="hora"
                                label="Hora de Mantenimiento"
                                value={this.state.hora}
                                onChange={this.handleChange}
                                min="2000-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.hora}
                                error={errors.hora?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                      

                      <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Duración en Horas</InputLabel>
                                      <Select
                                      required
                                  name="duracion"
                                  id="duracion"
                                  value={this.state.duracion}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="1">1</MenuItem>
                                      <MenuItem value="2">2</MenuItem>
                                      <MenuItem value="4">4</MenuItem>
                                      <MenuItem value="8">8</MenuItem>
                                      <MenuItem value="12">12</MenuItem>
                                      <MenuItem value="24">24</MenuItem>
                                      <MenuItem value="48">48</MenuItem>
                                      <MenuItem value="72">72</MenuItem>
                                      <MenuItem value="96">96</MenuItem>
                                  
                                      </Select>
                                      <FormHelperText>{errors.duracion}</FormHelperText>
                                  </FormControl>  

                                  <TextField className="login-input-email"
                                required
                                type="text"
                                name="taskElectrica"
                                id="taskElectrica"
                                label="Actividades de Red Electrica y Luminarias"
                                value={this.state.taskElectrica}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskElectrica}
                                error={errors.taskElectrica?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />

<TextField className="login-input-email"
                                required
                                type="text"
                                name="taskHidraulica"
                                id="taskHidraulica"
                                label="Actividades de Red Hidraulica y Sanitaria"
                                value={this.state.taskHidraulica}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskHidraulica}
                                error={errors.taskHidraulica?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />

<TextField className="login-input-email"
                                required
                                type="text"
                                name="taskComunicaciones"
                                id="taskComunicaciones"
                                label="Actividades de Red Comunicaciones y Datos"
                                value={this.state.taskComunicaciones}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskComunicaciones}
                                error={errors.taskComunicaciones?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />

<TextField className="login-input-email"
                                required
                                type="text"
                                name="taskGases"
                                id="taskGases"
                                label="Actividades de Red Gases, Vapor y Otros"
                                value={this.state.taskGases}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskGases}
                                error={errors.taskGases?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />

<TextField className="login-input-email"
                                required
                                type="text"
                                name="taskInstalaciones"
                                id="taskInstalaciones"
                                label="Actividades de Instalaciones Fisicas, Pisos, Techos y Otros"
                                value={this.state.taskInstalaciones}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskInstalaciones}
                                error={errors.taskInstalaciones?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />


                      </form>
                      </DialogContent>
                 
                </div>
               
                )}
            

           
            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Realizar Programación" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            

            
          </DialogActions>

            </Dialog>

            </Fragment>
        )
    }
}


//progMto
ProgMtoInfra.propTypes={
    progMtoInfra:PropTypes.func.isRequired,
    getProgMtosInfra:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
  };
  const mapStateToProps=(state)=>({
    UI:state.UI,
    equipo: state.data.equipo.credentials
  });
  
  export default connect (mapStateToProps,{progMtoInfra,getProgMtosInfra,clearErrors}) (ProgMtoInfra)
  
  
  

