import React, { Component } from 'react';

import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getAreas} from '../../../redux/actions/infraactions';
import {subirImagenArea} from '../../../redux/actions/infraactions';

import {deleteRepuesto} from '../../../redux/actions/dataactions';
import {editRepuesto} from '../../../redux/actions/dataactions';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import './TablaAreas.css'
import {Link} from 'react-router-dom';

import MyButton from '../../../utils/MyButton';


import { Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';


import { FaArrowLeft,FaPlus,FaFilePdf, FaTrash, FaCheck, FaCheckCircle, FaRegEdit, FaCogs, FaPhone } from "react-icons/fa";

import TextField from '@material-ui/core/TextField';
import CrearArea from './CrearArea';




export class TablaAreas extends Component {
    
    state = { 
        // Initially, no file is selected 
        selectedFile: null,
        open: false,        
        opendelete:false,
        aprobado:{},
        errors:{},
        openedit:false,
        areaid:'',
        areaedit:{},
        nombre:'',
        servicio:'',
        piso:'',
        estado:'',
        ubicacion:'',
      }; 


      static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }
      handleOpen=(areaid)=>{
        this.setState({open:true,areaid:areaid});
        
    };

    handleClose=()=>{
        this.setState({open:false,repuestoid:''});
     };

     onFileChange = event => { 
     
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] }); 
       
    }; 

    onFileUpload = () => { 
     
        // Create an object of formData 
        const formData = new FormData(); 
       
        // Update the formData object 
        formData.append( 
          "myFile", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        ); 
        console.log("area id "+this.state.areaid);
  
        this.props.subirImagenArea(formData,this.state.areaid,this.props.clienteId);
        //Imagen subida
        console.log("imagen subida");
        //
        this.setState({open:false});

    }; 

    componentDidMount(){
        console.log(this.props.clienteId)
        this.props.getAreas(this.props.clienteId);
      }

      handleOpenDelete=(rawdatarepid)=>{
        console.log('repuestoid'+rawdatarepid)
        this.setState({opendelete:true,repuestoid:rawdatarepid,aprobado:{}});
       
        };

        handleCloseDelete=()=>{
            // this.props.clearErrors();
            if(this.state.aprobado.mensaje){
                //getEquipos this.handleEditProgMto();
                this.props.getRepuestos(this.props.user.credentials.handle);
                this.setState({opendelete:false,errors:{},aprobado:{}});
            }else{
             this.setState({opendelete:false,errors:{},aprobado:{}});
            }
             
             };

             handleDelete=()=>{
                console.log('Borrando repuesto'+this.state.repuestoid);
                this.props.deleteRepuesto(this.state.repuestoid);

                //this.props.deleteProgMto(this.state.clienteid,this.state.progid,this.props.equipoId,credentials.handleuser);
                         
              }



    
              handleOpenEdit=(rawdata)=>{
                console.log('rowdata'+rawdata)
                this.setState({openedit:true,
                    codigo:rawdata.codigo,
                    nombre:rawdata.nombre,
                    marca:rawdata.marca,
                    modelo:rawdata.modelo,
                    serie:rawdata.serie,
                    cantidad:rawdata.cantidad,
                    valorunitario:rawdata.valorunitario,
                    proveedor:rawdata.proveedor,
                    proveedortel:rawdata.proveedortel,
                    proveedoremail:rawdata.proveedoremail,
                    repuestoid:rawdata.repuestoId
                    ,aprobado:{}});
               
                };

                handleCloseEdit=()=>{

                    if(this.state.aprobado.mensaje){
                        //getEquipos this.handleEditProgMto();
                        this.props.getRepuestos(this.props.user.credentials.handle);
                        this.setState({openedit:false,
                            codigo:'',
                            nombre:'',
                            marca:'',
                            modelo:'',
                            serie:'',
                            cantidad:'',
                            valorunitario:'',
                            proveedor:'',
                            proveedortel:'',
                            proveedoremail:'',
                            aprobado:{}});
                    }else{
                        this.setState({openedit:false,
                            codigo:'',
                            nombre:'',
                            marca:'',
                            modelo:'',
                            serie:'',
                            cantidad:'',
                            valorunitario:'',
                            proveedor:'',
                            proveedortel:'',
                            proveedoremail:'',
                            aprobado:{}});
                    }
                   
                   
                                
                    };

                handleChange=(event)=>{
                    this.setState({[event.target.name]:event.target.value});
                    };


                    handleEdit = () => {
                        let Data = {
                            codigo:this.state.codigo,
                            nombre:this.state.nombre,
                            marca:this.state.marca,
                            modelo:this.state.modelo,
                            serie:this.state.serie,
                            cantidad:this.state.cantidad,
                            valorunitario:this.state.valorunitario,
                            proveedor:this.state.proveedor,
                            proveedortel:this.state.proveedortel,
                            proveedoremail:this.state.proveedoremail,
                        }
                        console.log(Data)
                      this.props.editRepuesto(Data,this.state.repuestoid,this.props.user.credentials.handle);
                      }

       

  render() {
    const {areas,loadingdata}= this.props.datainfra;
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        MiIcon:forwardRef((props, ref) => <AddToPhotosIcon {...props} ref={ref}/>),
        MiIcon2:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
        MiIcon3:forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
        MiIcon4:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
        editRegister: forwardRef((props, ref) => <FaRegEdit {...props} ref={ref} />)
      };

    const columns=[
        {
            field: 'imagenurl',
            title: 'Avatar',
            render: rowData => <img src={rowData.imagenurl} style={{width: 50,height:50, borderRadius: '50%'}}/>
          },
    
        
        {title:'Nombre',field:'nombre'},
        {title:'Servicio',field:'servicio'},
        {title:'Ubicación',field:'ubicacion'},
        {title:'Piso',field:'piso'},
        {title:'Estado',field:'estado'},
        
        
    ]

    let mapeoareas=!loadingdata ?(
        <div>

<div className='div-container-links-inventario'>
                        <CrearArea clienteId={this.props.clienteId}></CrearArea>
                        <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/${this.props.clienteId}/planmtoinfra`} >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Plan de Mantenimiento
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaCogs color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>

                    

                        <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/${this.props.clienteId}/solicitudesmtoinfra`} >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Solicitudes
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-orange">
                                                  <FaPhone color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
                    </div>
            
             

        <MaterialTable
        columns={columns}
        data={areas}
        title={
            <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'bold' }}></h2>
          }
        icons={tableIcons}
        options={{
            cellStyle: {
                fontSize: 11,
                fontWeight:500,
                color:'#696B6D'
              
             
              },
              headerStyle: {
                fontSize: 12,
                fontWeight: 'bolder',
                color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
              }
          
          }}

        actions={[
            rowData => ({
                icon: () =>  <Link to={`/dashboard/perfilcliente/${this.props.clienteId}/infraestructura/${rowData.areaId}`} style={{ color: '#03178C' }} ><AssignmentIcon  /></Link>,
                tooltip: 'Ver Perfil ',
                onClick: (rowData)
              }),
          
            
            {
                icon: tableIcons.MiIcon3,
                tooltip: 'Subir Imagen',
                onClick: (event, rowData) => this.handleOpen(rowData.areaId) 
              },

             
             
           
          ]}
         
        >
            {//window.location.href=`equipo/${this.props.clienteId}/${rowData.equipoid}`/dashboard/perfilcliente/equipo/:clienteId/:equipoId
            }
        </MaterialTable>

{/*//aqui va el dialogo */}
<Dialog
                open={this.state.open}
                onClose={this.handleClose}
                 fullWidth
                maxWidth="sm"
                >
                 <DialogTitle>Subir Imagen del Area</DialogTitle>
                 
                <DialogContent>
                
                </DialogContent>
                <input type="file" onChange={this.onFileChange} accept="image/png,image/jpeg"   /> 
                <DialogActions>
                    <MyButton tip="Cerrar" onClick={this.handleClose}>
                    <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                    </MyButton>
                    <MyButton tip="Subir Imagen del Area" onClick={this.onFileUpload}>
                    <FaPlus  color="#03178C" className="icon-formulario"/>
                    </MyButton>
                    
                </DialogActions>

                </Dialog>

        
        </div>
        ):(
        <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
        )

    return (
        <div>
        {mapeoareas}

       
    </div>
    )
  }
}

TablaAreas.propTypes={
    getAreas:PropTypes.func.isRequired,
    subirImagenArea:PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,
    clienteId: PropTypes.string.isRequired,
   
    UI:PropTypes.object.isRequired,  
}
const mapStateToProps=state=>({
    datainfra:state.datainfra,
    user:state.user,
    UI:state.UI
})
export default connect(mapStateToProps,{getAreas,subirImagenArea}) (TablaAreas);

