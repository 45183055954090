import React from 'react'; 
import './Categorias.css'; 
import { Link } from 'react-router-dom'; 

export default function ({categorie}) {
  return (
    <div className='cardcategoria'>
          <Link to={`/blog/categorias/${categorie.name}`} className="txtlinkcategoria" params={categorie} >
          <h3 className='txtcategoria'>{categorie.name}</h3>
          </Link>
        
    </div>
  )
}
