import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

const RouteWithLayout = props => {
  const { layout: Layout,authenticated,loading, component: Component, ...rest } = props;

if(!loading){
    if(authenticated){
        return (
     
            <Route
              {...rest}
              render={matchProps => (
                <Layout><Component {...matchProps} /></Layout> 
                )}
            />
          );
    }else{
        return (
        <Redirect to="/"/>
        );
    }
}else{
    return(
      <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
      </div>
    )
    
}
  
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    loading:state.user.loading
  });

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    authenticated:PropTypes.bool.isRequired,
    loading:PropTypes.bool.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
    user: PropTypes.object
  };
 
  
  export default connect(mapStateToProps)(RouteWithLayout);
