import React, { Component } from 'react';
import './ListaClientesTecnico.css';
import { connect } from 'react-redux';

import {getClientes} from '../../../../redux/actions/dataactions';
import {Link } from 'react-router-dom';


import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';




export class ListaClientesTecnico extends Component {

    componentDidMount(){
        this.props.getClientes();
      }
      
    render() {

        const {clientes,loading}= this.props.data; 

        let mapeoclientes=!loading?
        (clientes.length>0?(
            clientes.map((cliente)=>
            <div key={cliente.clienteid} className="cliente-card">
            <div className="div-info">
            <div className="div-cliente-imagen">
              <img src={cliente.imagenurl} alt="equipo" className="imagen-cliente"/>
              
            </div>

            <div className="div-cliente-infocontainer">
            <div className="div-cliente-titulo">
            <Link to={`/dashboard/perfilclientetec/${cliente.clienteid}`} className="link_nombre_cliente"> <h3 className="titulo-cliente-text">{cliente.nombre}</h3>  </Link> 
            <h5 className="ciudad-cliente-text">{cliente.ciudad}</h5>
            </div>
            <div className="div-cliente-info">
            <p className="cliente-text">CC/NIT: {cliente.ccnit}</p>
            <p className="cliente-text">Dirección: {cliente.direccion}</p>
            <p className="cliente-text">Email: {cliente.email}</p>
            <p className="cliente-text">Telefono: {cliente.telefono}</p>
            <p className="cliente-text">Codigo REPS: {cliente.codigoreps? cliente.codigoreps:'No Registra'}</p>
            <p className="cliente-text">Sede: {cliente.sede?cliente.sede:'No Registra'}</p>
            </div>


            </div>
            

           

            </div>

            <div className="div-acciones">
            
            </div>          
            
            
              
              </div>
             )

           
        
        ):( <div className="item-market-card">
            <div className='item-market-container'>
            <div className='item-market-descripcion'>
            <h3 className="titulo-market-text">El Perfil de Ingenieria debe enlazarte a un prestador para empezar</h3>
        </div>
            </div>
            </div>)):
        (<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>)
           


        return (
            <div>
                {mapeoclientes}
            </div>
        )
    }
}

ListaClientesTecnico.propTypes={
    getClientes:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired
}
const mapStateToProps=state=>({
    data:state.data
})
export default connect(mapStateToProps,{getClientes}) (ListaClientesTecnico);

