import {SET_SOLICITUDES_RECHAZADAS,SET_SOLICITUDES_APROBADAS,SET_SOLICITUDES_ENVIADAS,POST_SOLICITUD_ENVIAR,POST_SOLICITUD_COMPRAS,SET_SOLICITUDES_SINENVIAR,LOADING_DATA_ASISTENCIAL
    , SET_SOLICITUD,POST_ITEM_ASISTENCIAL} from '../types';
const initialState={
    
    loadingdataasistencial:true,
    solicitud:[],
    solicitudes:[],
    requerimiento:[],
    requerimientos:[]
}


export default function (state=initialState,action){
    switch(action.type){
        case LOADING_DATA_ASISTENCIAL:
            return {
                ...state,
                loadingdatasasistencial:true
            }
      case POST_SOLICITUD_COMPRAS:
        return {
            ...state,
            solicitudes: [action.payload, ...state.solicitudes]
        } 
    
        case SET_SOLICITUDES_SINENVIAR:
            return{
                ...state,
                solicitudes:action.payload,     
                loadingdataasistencial:false   
            }
    case SET_SOLICITUDES_ENVIADAS:
        return{
            ...state,
            solicitudes:action.payload,
            loadingdataasistencial:false
        }
    case SET_SOLICITUDES_APROBADAS:
        return{
            ...state,
            solicitudes:action.payload,
            loadingdataasistencial:false
        }
        case SET_SOLICITUDES_RECHAZADAS:
            return{
                ...state,
                solicitudes:action.payload,
                loadingdataasistencial:false
            }

     case SET_SOLICITUD:
            return{
                ...state,
                solicitud:action.payload,
                loadingdataasistencial:false
            }

        case POST_ITEM_ASISTENCIAL:
                return{
                    ...state,
                    solicitudes: [action.payload, ...state.solicitudes]
                }
      case POST_SOLICITUD_ENVIAR:
            return{
                        ...state,
            }       
        default:
            return{
                ...state
            }

        
        
            
    }
}