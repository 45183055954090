import React, { Component } from 'react';
import './HomeMarketEmpresa.css';
 

import {Link } from 'react-router-dom';


import {FaHandsHelping,FaCashRegister,FaTimes,FaFileInvoiceDollar,FaFileSignature} from "react-icons/fa";




export class HomeMarketEmpresa extends Component {
  render() {
    return (
        <div className="market_container">
        <div className="titulomarket_section">
        <div className="titulo_market">
        <h1 className="text-clientes"> MarketPlace Empresas</h1>
        <hr className="clientes_separator"></hr>  

        </div>

        <div className="divmarket_boton">

        </div>
        </div>

        <div className="market_categorias_container">

        <div className="subcontainer-market">

        <div className="dashing-div-card">
                <Link to='/homemarketempresa/categoriasmarket' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Oportunidades de Venta
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaHandsHelping color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>
                </div>

                <div className="subcontainer-market">
                <div className="dashing-div-card">
                <Link to='/homemarketempresa/ofertasenviadas' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                   Mis Ofertas<br/>En Evaluación
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon-orange">
                                    <FaFileSignature color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

                <div className="dashing-div-card">
                <Link to='/homemarketempresa/ofertasaprobadas' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Mis Ofertas<br/>Aprobadas
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon-green">
                                    <FaCashRegister color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>
                <div className="dashing-div-card">
                <Link to='/homemarketempresa/ofertasrechazadas' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Mis Ofertas <br/>Rechazadas
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon-red">
                                    <FaTimes color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

        
        
        
        </div>

      </div>
      </div>
    )
  }
}

export default HomeMarketEmpresa