import React, { Component, Fragment } from 'react'

import './CronogramaMto.css';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getListaMtos} from '../../../redux/actions/dataactions';
import { postAprobarMto } from '../../../redux/actions/dataasistencialactions';
import MaterialTable from 'material-table';

import {Link } from 'react-router-dom';
import {FaArrowCircleLeft, FaArrowLeft, FaCheck, FaCheckCircle, FaExclamationTriangle, FaFilePdf, FaTrash} from "react-icons/fa";

import MyButton from '../../../utils/MyButton';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FaClipboardList} from "react-icons/fa";
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';

export class CronogramaMto extends Component {
  constructor(){
    super();
    this.state={
        errors:{},
        aprobado:{},
        open:false,
        opendelete:false,
        progid:'',
        clienteid:'',
        equipoid:'',
    };
    
};
    componentDidMount(){
        this.props.getListaMtos(this.props.user.credentials.idprestador);
       }
  
       static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

       handleOpen=(rawdataprogid,rowdataequipoid)=>{

        this.setState({open:true,progid:rawdataprogid,equipoid:rowdataequipoid,errors:{},aprobado:{}});
    
        };
        handleClose=()=>{
          // this.props.clearErrors();
          if(this.state.aprobado.mensaje||this.state.errors.error){

            this.props.getListaMtos(this.props.user.credentials.idprestador);
            this.setState({open:false,errors:{},aprobado:{}});
          }

         this.setState({open:false,errors:{},aprobado:{}});
           };
          
      // hay que hacer otro aprobar mto 
      handleSubmit=()=>{
        this.props.postAprobarMto(this.state.progid);
      }
  
 
  render() {

    const {equiposmto,loadingmto}= this.props.data;
    const { UI:{loading}}=this.props;
    const {errors}=this.state;
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    const columns=[
      
        {title:'Id',field:'idinventario'},
        {title:'Nombre',field:'nombre'},
        {title:'Marca',field:'marca'},
        {title:'Modelo',field:'modelo'},
        {title:'Serie',field:'serie'},
        {title:'Ubicación',field:'ubicacion'},
        {title:'Estado',field:'estado'},
        {title:'Fecha',field:'fecha'},
        {title:'Hora', field:'hora'},
        {title:'Actividades',field:'actividades'},
        {
          title: 'Aprobado',
          field: 'aprobado',
          render: rowData => {
              // Verifica si el campo 'aprobado' existe en los datos de la fila
              if (rowData.hasOwnProperty('aprobado')) {
                  // Si 'aprobado' es true, muestra 'Sí'
                  if (rowData.aprobado === true) {
                      return 'Sí';
                  }
                  // Si 'aprobado' es false o cualquier otro valor, muestra 'No'
                  return 'No';
              }
              // Si el campo 'aprobado' no existe, muestra 'Aprobado No'
              return 'No';
          }
      },
        
    ]
    
    let mapeoequipos=!loadingmto?(
    <div>
  <div className='div-container-links-inventario'>
  < Fragment>
                        <Link className='div-card-link' to={`/dashboard/cronogramamtoasistencial/${this.props.user.credentials.idprestador}`} state={equiposmto} >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Ver Cronograma en PDF
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
                        </div>
    <MaterialTable
    columns={columns}
    data={equiposmto}
    title={
      <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'bold' }}>Cronograma Mantenimiento Preventivo</h2>
    } icons={tableIcons}
    options={{
      cellStyle: {
          fontSize: 11,
          fontWeight:500,
          color:'#696B6D'
        
       
        },
        headerStyle: {
          fontSize: 12,
          fontWeight: 'bolder',
          color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
        }
    
    }}

    actions={[
      rowData => ({
        icon: () =>  <Link to={`/dashboard/inventarioequiposasistencial/hojadevidaequipo/${rowData.equipoid}`} style={{ color: '#03178C' }} ><ChevronRight  /></Link>,
        tooltip: 'Ver Perfil ',
        onClick: (rowData)
      }),
      rowData => ({
        icon:  () => <tableIcons.Check style={{ color: rowData.aprobado ? 'green' : 'red' }} />,
        tooltip: 'Aprobar Mantenimiento',
        onClick: (event, rowData) => {
            this.handleOpen(rowData.progid,rowData.equipoid);
        },
        disabled: rowData.aprobado===true
      }) 
           ,
       
      ]}
      
    >

    </MaterialTable>
    </div>
    ):(
    <div className="div_cargando">
       <CircularProgress size={50}color="primary" className="login-progress" />
    </div>
    )

    return (
        <div className='inventario-container'>

        <div className="titulomarket_section">
        <div className="titulo_market">
        <h1 className="text-clientes">Cronograma Mantenimiento</h1>
        <hr className="clientes_separator"></hr>  

        </div>

        <div className="divmarket_boton">
      
        <Link to={`/dashboard/equiposasistencial`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>


        </div>
        </div>
        {mapeoequipos}

        <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
              <div className='div-icono-aprobado'>
                <FaCheck color="#ffffff" className='icono-aprobado'/>
              </div>
              <h2 className='text-aprobado'>Reporte Aprobado Exitosamente</h2>
             </div>
                
                ):(errors.error?( <div className='div-aprobado'>
                <div className='div-icono-advertencia'>
                  <FaExclamationTriangle color="#ffffff" className='icono-aprobado'/>
                </div>
                <h2 className='text-aprobado'>{errors.error}</h2>
            </div>):(<div>
                <DialogTitle>¿Estás seguro de Aprobar este Mantenimiento?</DialogTitle>
                </div>))}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20b90c" className="icon-formulario" />
                  </MyButton>
                ) : ( errors.error?(
                  <Link to={`/dashboard/inventarioequiposasistencial/hojadevidaequipo/${this.state.equipoid}`} style={{ color: '#ffd631' }}>
                  <FaCheckCircle color="#ffd631" className="icon-formulario" />
                </Link>
                ):(
                  <MyButton tip="Aprobar Mantenimiento" onClick={this.handleSubmit}>
                    <FaCheckCircle color="#03178C" className="icon-formulario" />
                  </MyButton>)
                )
              )}
            
           </DialogActions>
            </Dialog>
     
    </div>
    )
  }
}

CronogramaMto.propTypes={
    getListaMtos:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,  
    UI:PropTypes.object.isRequired, 
    postAprobarMto:PropTypes.func.isRequired, 
}
const mapStateToProps=state=>({
    data:state.data,
    user:state.user,
    UI:state.UI
})
export default connect(mapStateToProps,{getListaMtos,postAprobarMto}) (CronogramaMto);

