import React, { Component } from 'react'
import {getTareasClienteFiltrado,editTarea,postAprobarTarea,deleteTarea,getClienteTareas,clearErrors} from '../../../redux/actions/dataactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft,FaCheck,FaPlus,FaCheckCircle, FaCircle, FaExclamationTriangle, FaTrash, FaFirstAid } from "react-icons/fa";
import MaterialTable from 'material-table';
import CrearTarea from './CrearTarea';


import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import FormHelperText from '@material-ui/core/FormHelperText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PhotoIcon from '@material-ui/icons/Photo';
import './TablaTareas.css';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import Button from '../../Elements/Button';

import moment from 'moment';
import TareasIndicadoresDialog from './TareasIndicadoresDialog';

export class TablaTareas extends Component {

    constructor(){
        super();
        this.state={
          tareaid:'',
            nombretarea:'', 
            detalles:'',
            fechaplazo:'', 
            horaplazo:'',            
            prioridad:'',
            handleasignado:'', 
            tipotarea:'',
            errors:{},
            open:false,
            openaprobado:false,
          opendelete:false,
          openedit:false, 
          selected:'',
         fechaInicio:'',
         fechaFin:'',

            aprobado: {},
           


          
        };
        
    };

    componentDidMount(){
        this.props.getTareasClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
       }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
          newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
          newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

    handleOpen=(rowdata)=>{
        
        this.setState({handlecreador:'', 
        nombretarea:rowdata.nombretarea, 
        detalles:rowdata.detalles,
        fechaplazo:rowdata.fechaplazo, 
        horaplazo:rowdata.horaplazo,            
        prioridad:rowdata.prioridad,
        handleasignado:rowdata.handleasignado, 
        tipotarea:rowdata.tipotarea,open:true,aprobado:{}});
        };
        handleClose=()=>{
        
          this.setState({handlecreador:'', 
          nombretarea:'', 
          detalles:'',
          fechaplazo:'', 
          horaplazo:'',            
          prioridad:'',
          handleasignado:'', 
          tipotarea:'',open:false,aprobado:{}});
          };


          handleOpenAprobado=(rawdatatareaid)=>{

            this.setState({openaprobado:true,tareaid:rawdatatareaid,errors:{},aprobado:{}});
        
            };
            handleCloseAprobado=()=>{
              // this.props.clearErrors();
              if(this.state.aprobado.mensaje||this.state.errors.error){
    
                this.props.getTareasClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
                this.setState({openaprobado:false,errors:{},tareaid:{},aprobado:{}});
              }
    
             this.setState({openaprobado:false,errors:{},tareaid:{},aprobado:{}});
               };
              
          // hay que hacer otro aprobar mto 
          handleSubmit=()=>{
            this.props.postAprobarTarea(this.state.tareaid);
          }

          handleOpenDelete=(rawdatatareaid)=>{
    
            this.setState({opendelete:true,tareaid:rawdatatareaid,errors:{},aprobado:{}});
           
            };
    
handleCloseDelete=()=>{

    if(this.state.aprobado.mensaje){
        //this.handleEditProgMto();
        //get prog mtos 
        this.props.getTareasClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
        this.setState({opendelete:false,errors:{},aprobado:{}});
     }else{
      this.setState({opendelete:false,errors:{},aprobado:{}});
     }
               // this.props.clearErrors();
};
handleDelete=()=>{
 
  this.props.deleteTarea(this.state.tareaid);
}


handleOpenEdit=(rowdata)=>{
  this.props.getClienteTareas(this.props.clienteId)
  this.setState({ 
    tareaid:rowdata.tareaid,
    nombretarea: rowdata.nombretarea, 
    detalles:rowdata.detalles,
    fechaplazo:rowdata.fechaplazo, 
    horaplazo:rowdata.horaplazo,            
    prioridad:rowdata.prioridad,
    handleasignado:rowdata.handleasignado, 
    tipotarea:rowdata.tipotarea,openedit:true,aprobado:{}});
  };


  handleCloseEdit=()=>{


    if(this.state.aprobado.mensaje){
        //getEquipos this.handleEditProgMto();
        this.props.getTareasClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
       
        this.setState({
            tareaid:'',
            nombretarea:'', 
            detalles:'',
            fechaplazo:'', 
            horaplazo:'',            
            prioridad:'',
            handleasignado:'', 
            tipotarea:'',
            openedit:false,errors:{},aprobado:{}});
    }else{
        this.setState({
            
            openedit:false,errors:{},aprobado:{}});
    }
   
   
                
    };

    handleChange=(event)=>{
      this.setState({[event.target.name]:event.target.value});
      };

      handleSubmitEdit=(event)=>{
        event.preventDefault();
          
        const newUserData={
        
            nombretarea:this.state.nombretarea, 
            detalles:this.state.detalles,
            fechaplazo:this.state.fechaplazo, 
            horaplazo:this.state.horaplazo, 
            prioridad:this.state.prioridad,
            handleasignado:this.state.handleasignado, 
            tipotarea:this.state.tipotarea

         
      };

      console.log(newUserData)

        this.props.editTarea(newUserData,this.state.tareaid);
       
       
     
 }

 handleFiltrar = (filtro) => () => {
  if(filtro=='todo'){
    this.props.getTareasClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
  }

  if(filtro=='vencenhoy'){
    const hoy = new Date();
    const dia = String(hoy.getDate()).padStart(2, '0');
    const mes = String(hoy.getMonth() + 1).padStart(2, '0');
    const anio = hoy.getFullYear();
    const fechaHoy = `${dia}/${mes}/${anio}`;
    console.log('Fecha hoy'+fechaHoy);
    this.props.getTareasClienteFiltrado(this.props.clienteId, fechaHoy, fechaHoy);
  }

  if(filtro=='proximamente'){
    const hoy = new Date();
    const dia = String(hoy.getDate()).padStart(2, '0');
    const mes = String(hoy.getMonth() + 1).padStart(2, '0');
    const anio = hoy.getFullYear();
    const fechaHoy = `${dia}/${mes}/${anio}`;
    console.log('Fecha hoy'+fechaHoy);
    this.props.getTareasClienteFiltrado(this.props.clienteId, fechaHoy, "01/01/2035");
  }

  if(filtro=='atrasadas'){
    const hoy = new Date();
    const dia = String(hoy.getDate()).padStart(2, '0');
    const mes = String(hoy.getMonth() + 1).padStart(2, '0');
    const anio = hoy.getFullYear();
    const fechaHoy = `${dia}/${mes}/${anio}`;
    console.log('Fecha hoy'+fechaHoy);
    this.props.getTareasClienteFiltrado(this.props.clienteId,"01/01/1950", fechaHoy);
  }
  console.log('Filtrado ejecutado con filtro:', filtro);
  this.setState({selected:filtro})
};

handleFiltrarFuncion = () => {
  console.log(this.state.fechaFin, this.state.fechaFin)
  this.props.getTareasClienteFiltrado(this.props.clienteId, moment(this.state.fechaInicio).format('DD/MM/YYYY'), moment(this.state.fechaFin).format('DD/MM/YYYY'));
 
}

  render() {
    const { UI:{loading}}=this.props; 
    const { tareas, loadingdata}= this.props.data;
    const { errors } = this.state;
    const {ingenieros}=this.props.data.cliente; 
    
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />),
        AddAPhotoIcon: forwardRef((props, ref) => <AddAPhotoIcon {...props} ref={ref}  style={{ color: '#03178c' }}/>),
        photo: forwardRef((props, ref) => <PhotoIcon {...props} ref={ref} style={{ color: '#03178c' }} />)

      };
    
    const columns=[
      {
        title: 'Realizada',
        field: 'realizado',
        render: rowData => {
            // Verifica si el campo 'realizado' existe en los datos de la fila
            if (rowData.hasOwnProperty('realizado')) {
                // Si 'realizado' es true, muestra 'Sí'
                if (rowData.realizado === true) {
                    return 'Sí';
                }
                // Si 'realizado' es false o cualquier otro valor, muestra 'No'
                return 'No';
            }
            // Si el campo 'realizado' no existe, muestra 'realizado No'
            return 'No';
        }
    },
        {title:'Nombre Tarea',field:'nombretarea'},
        {title:'Fecha Plazo',field:'fechaplazo'},
        {title:'Mes',field:'mesnombre'},
        {title:'Prioridad',field:'prioridad'},
        
       
    ]

    let mapeotareas=!loadingdata ?(
        <div>
   <MaterialTable
           columns={columns}
           data={tareas}
           title={
            <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Mis Tareas</h2>
          }
           icons={tableIcons}
           options={{
            cellStyle: {
                fontSize: 12,
                fontWeight:500,
                color:'#696B6D'
              
             
              },
              headerStyle: {
                fontSize: 14,
                fontWeight: 'bolder',
                color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
              }
          
          }}

          actions={[
            {
                title: "Ver Tarea",
                icon: tableIcons.AssignmentIcon,
                tooltip: 'Ver Tarea',
                onClick: (event, rowData) => {
                  this.handleOpen(rowData, "ver");
                },
              }
            ,

          
            {
              icon: tableIcons.Delete,
              tooltip: 'Borrar Tarea',
              onClick: (event, rowData) => {
                  
                this.handleOpenDelete(rowData.tareaid);
              }
            },
            rowData => ({
              icon:  () => <tableIcons.Edit style={{ color: rowData.realizado ? 'gray' : 'black' }} />,
              tooltip: 'Marcar Como Realizada',
              onClick: (event, rowData) => {
                  this.handleOpenEdit(rowData);
              },
              disabled: rowData.realizado===true
            }) ,
            rowData => ({
              icon:  () => <tableIcons.Check style={{ color: rowData.realizado ? 'green' : 'red' }} />,
              tooltip: 'Marcar Como Realizada',
              onClick: (event, rowData) => {
                  this.handleOpenAprobado(rowData.tareaid);
              },
              disabled: rowData.realizado===true
            }) ,
            
          ]}

   
     
         
         
   
           >
   
           </MaterialTable>

    
        </div>
          

       ):(  <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
           </div>);
    return (
      <div>
          <div className='div-container-links-inventario'>
          <CrearTarea clienteId={this.props.clienteId}></CrearTarea>
          <TareasIndicadoresDialog clienteId={this.props.clienteId}></TareasIndicadoresDialog>
          </div>
      
        <div className='container-botones-tareas'> 
       
       
        <div className={`div-card-link-tareas ${this.state.selected === 'todo' ? 'selected' : ''}`} onClick={this.handleFiltrar('todo')}>
                    <div className="dashing-cardcontent-tareas">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                          Todo
                                            </p>
                        
                                        </div>
                                       
                                    </div>
  
  
                </div>

                <div className={`div-card-link-tareas ${this.state.selected === 'vencenhoy' ? 'selected' : ''}`} onClick={this.handleFiltrar('vencenhoy')}>
                    <div className="dashing-cardcontent-tareas">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                          Vencen Hoy
                                            </p>
                        
                                        </div>
                                       
                                    </div>
  
  
                </div>
                <div className={`div-card-link-tareas ${this.state.selected === 'atrasadas' ? 'selected' : ''}`} onClick={this.handleFiltrar('atrasadas')}>
                    <div className="dashing-cardcontent-tareas">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                          Atrasadas
                                            </p>
                        
                                        </div>
                                       
                                    </div>
  
  
                </div>

                <div className={`div-card-link-tareas ${this.state.selected === 'proximamente' ? 'selected' : ''}`} onClick={this.handleFiltrar('proximamente')}>
                    <div className="dashing-cardcontent-tareas">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                          Proximamente
                                            </p>
                        
                                        </div>
                                       
                                    </div>
  
  
                </div>


        </div>

        {(this.state.selected === 'todo' || this.state.selected === '') && (
         <div className='div-container-tecno-prestador'>
         <div className="input_divido_tecno_cliente">
           <div className="input_divido_children_tecno">
             <TextField className="login-input-email"
               required
               type="date"
               name="fechaInicio"
               id="fechaInicio"
               label="Desde"
               value={this.state.fechaInicio}
               onChange={this.handleChange}
               fullWidth
               helperText={errors.fechaInicio}
               error={errors.fechaInicio ? true : false}
               InputLabelProps={{
                 shrink: true,
               }}
               
             />
           </div>
           <div className="input_divido_children_tecno">
             <TextField className="login-input-email"
               required
               type="date"
               name="fechaFin"
               id="fechaFin"
               label="Hasta"
               value={this.state.fechaFin}
               onChange={this.handleChange}
               fullWidth
               helperText={errors.fechaFin}
               error={errors.fechaFin ? true : false}
               InputLabelProps={{
                 shrink: true,
               }}
               
             />
           </div>
           <div className='input_divido_tecno_boton'>
             <Button type="submit" buttonSize="btn--medium" buttonColor='blue' onClick={this.handleFiltrarFuncion}>
               Filtrar</Button>
           </div>
         </div>



       </div>
        )}
        <div className="listamtoprog-table">
        {mapeotareas}
        </div>

        <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>¡Creación de Tarea Exitosa!</h2>
              </div>
                
                ) : (<div>

<DialogTitle className="text-title-accorion">{this.state.nombretarea}</DialogTitle>
               
               <DialogContent>
   
                       <form>
                      

             <div className="flex-accordion">
             <h4 className="text-title-accorion">Detalles de la Tarea</h4>
             <p className='detalles-tarea-dialog'>{this.state.detalles}</p> 
             
       
                       
           
   
             </div>
                       <div className="flex-accordion">
                       <h4 className="text-title-accorion">Fecha de Plazo de la Tarea</h4>
                       <div className="input_divido">
   
   
                         <div className="input_divido_children">
                          <div className='tarea-info-column'> 
                            <p className='detalles-tarea-titulo-dialog'>Fecha</p>
                          <p className='detalles-tarea-dialog'>{this.state.fechaplazo}</p> 
                          </div>
                           </div>

                           <div className="input_divido_children">
                          <div className='tarea-info-column'> 
                            <p className='detalles-tarea-titulo-dialog'>Hora</p>
                          <p className='detalles-tarea-dialog'>{this.state.horaplazo}</p> 
                          </div>
                       
                        
                        
                           </div>


                          
                           
                           </div>
                          
   
                           </div>

                           <div className="flex-accordion">
             <h4 className="text-title-accorion">Prioridad y Tipo de Tarea</h4>
             <div className="input_divido">

             <div className="input_divido_children">
                          <div className='tarea-info-column'> 
                            <p className='detalles-tarea-titulo-dialog'>Tipo Tarea</p>
                          <p className='detalles-tarea-dialog'>  {this.state.tipotarea}</p> 
                          </div>
                           </div>

                           <div className="input_divido_children">
                          <div className='tarea-info-column'> 
                          <p className='detalles-tarea-titulo-dialog'>Prioridad</p>
                           
                              {
                                this.state.prioridad==="Alta"?(
                                  <div className='div-tarea-prioridad'> 
                                   <FaCircle color="#f2545b"></FaCircle>
                                  <p className='detalles-tarea-dialog'>{this.state.prioridad} </p>
                                  </div>
                                ):(<></>)
                              }

                              {
                                this.state.prioridad==="Media"?(
                                  <div className='div-tarea-prioridad'> 
                                   <FaCircle color="#f5c26b"></FaCircle>
                                  <p className='detalles-tarea-dialog'>{this.state.prioridad} </p>
                                  </div>
                                ):(<></>)
                              }

                                {
                                this.state.prioridad==="Baja"?(
                                  <div className='div-tarea-prioridad'> 
                                   <FaCircle color="#00bda5"></FaCircle>
                                  <p className='detalles-tarea-dialog'>{this.state.prioridad} </p>
                                  </div>
                                ):(<></>)
                              }
                        
                            
                         
                          </div>
                           </div>
          
            

                   </div>
             </div>
                 

                  
             

             <div className="flex-accordion">
             <h4 className="text-title-accorion">Personal Designado</h4>
             <div className="input_divido_children">
                          <div className='tarea-info-column'> 
                            <p className='detalles-tarea-titulo-dialog'>Nombre de Usuario</p>
                          <p className='detalles-tarea-dialog'>  @{this.state.handleasignado}</p> 
                          </div>
                           </div>
                  
                    </div>
   
           
           
                       
   
            
         
          
           
   
    </form>
                         
               
               </DialogContent>
                </div>)}
            
          
          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

          
            

            
          </DialogActions>
            </Dialog>


            <Dialog
             open={this.state.openaprobado}
             onClose={this.handleCloseAprobado}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
              <div className='div-icono-aprobado'>
                <FaCheck color="#ffffff" className='icono-aprobado'/>
              </div>
              <h2 className='text-aprobado'>Tarea Realizada Exitosamente</h2>
             </div>
                
                ):(errors.error?( <div className='div-aprobado'>
                <div className='div-icono-advertencia'>
                  <FaExclamationTriangle color="#ffffff" className='icono-aprobado'/>
                </div>
                <h2 className='text-aprobado'>{errors.error}</h2>
            </div>):(<div>
                <DialogTitle>¿Estás seguro de marcar esta tarea como realizada?</DialogTitle>
                </div>))}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseAprobado}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseAprobado}>
                    <FaCheckCircle color="#20b90c" className="icon-formulario" />
                  </MyButton>
                ) : ( errors.error?(
                  <Link to={`/dashboard/perfilcliente/${this.props.clienteId}/tareas`} style={{ color: '#ffd631' }}>
                  <FaCheckCircle color="#ffd631" className="icon-formulario" />
                </Link>
                ):(
                  <MyButton tip="Aprobar Mantenimiento" onClick={this.handleSubmit}>
                    <FaCheckCircle color="#03178C" className="icon-formulario" />
                  </MyButton>)
                )
              )}
            
           </DialogActions>
            </Dialog>

                   
            <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-delete'>
                    <FaTrash color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Tarea Borrada Exitosamente</h2>
              </div>
                
                ):(<div>
                <DialogTitle>¿Estás seguro de borrar esta Tarea?</DialogTitle>
                </div>)}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseDelete}>
                    <FaCheckCircle color="#f9044e" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Borrar Tarea" onClick={this.handleDelete}>
                    <FaTrash color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
           </DialogActions>
            </Dialog>

            <Dialog
             open={this.state.openedit}
             onClose={this.handleCloseEdit}
             fullWidth
             maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>¡Creación de Tarea Exitosa!</h2>
              </div>
                
                ) : (<div>

<DialogTitle className="text-title-accorion">Editar Tarea</DialogTitle>
               
               <DialogContent>
   
                       <form>
                       <div className="flex-accordion">
             <h4 className="text-title-accorion">Nombre de la Tarea</h4>
             <TextField className="login-input-email"
                         required
                         type="text"
                         name="nombretarea"
                         id="nombretarea"
                         label="Nombre de la Tarea"
                         value={this.state.nombretarea}
                         onChange={this.handleChange}
                         fullWidth
                         helperText={errors.nombretarea}
                         error={errors.nombretarea?true:false}
                         minRows={1}
                         
                         />
       
                         <br/>
           
   
             </div>

             <div className="flex-accordion">
             <h4 className="text-title-accorion">Detalles de la Tarea</h4>
             <TextField className="login-input-email"
                         required
                         type="text"
                         name="detalles"
                         id="detalles"
                         label="Detalles de la Tarea"
                         value={this.state.detalles}
                         onChange={this.handleChange}
                         fullWidth
                         helperText={errors.detalles}
                         error={errors.detalles?true:false}
                         minRows={3}
                         
                         />
       
                         <br/>
           
   
             </div>
                       <div className="flex-accordion">
                       <h4 className="text-title-accorion">Fecha de Plazo de la Tarea</h4>
                       <div className="input_divido">
   
   
                         <div className="input_divido_children">
                         <TextField className="login-input-email"
                         required
                         type="date"
                         name="fechaplazo"
                         id="fechaplazo"
                         label="Fecha de Vencimiento Tarea"
                         value={this.state.fechaplazo}
                         onChange={this.handleChange}
                         min="2019-01-01" max="2029-01-01"
                         fullWidth
                         helperText={errors.fechaplazo}
                         error={errors.fechaplazo?true:false}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         />
                        
                           </div>

                           <div className="input_divido_children">
                         <TextField className="login-input-email"
                         required
                         type="time"
                         name="horaplazo"
                         id="horaplazo"
                         label="Hora de Vencimiento Tarea"
                         value={this.state.horaplazo}
                         onChange={this.handleChange}
                        
                         fullWidth
                         helperText={errors.horaplazo}
                         error={errors.horaplazo?true:false}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         />
                        
                           </div>
                           
                           </div>
                          
   
                           </div>

                           <div className="flex-accordion">
             <h4 className="text-title-accorion">Prioridad y Tipo de Tarea</h4>
             <div className="input_divido">
             <div className="input_divido_children">
                 <FormControl className="formcontrol-nuevocliente" error={errors.prioridad?true:false}>
                               <InputLabel> Prioridad</InputLabel>
                               <Select
                               required
                            name="prioridad"
                            id="prioridad"
                            value={this.state.prioridad}
                               onChange={this.handleChange}
                               fullWidth
                               >
                               <MenuItem value="">
                                   <em></em>
                               </MenuItem>
                               <MenuItem value="Alta">Alta</MenuItem>
                               <MenuItem value="Media">Media</MenuItem>
                               <MenuItem value="Baja">Baja</MenuItem>
                               
                               </Select>
                               <FormHelperText>{errors.prioridad}</FormHelperText>
                           </FormControl>
                   </div>
                   <div className="input_divido_children">
                 <FormControl className="formcontrol-nuevocliente" error={errors.tipotarea?true:false}>
                               <InputLabel> Tipo de Tarea</InputLabel>
                               <Select
                               required
                            name="tipotarea"
                            id="tipotarea"
                            value={this.state.tipotarea}
                               onChange={this.handleChange}
                               fullWidth
                               >
                               <MenuItem value="">
                                   <em></em>
                               </MenuItem>
                               <MenuItem value="Biomedica">Biomedica</MenuItem>
                               <MenuItem value="Infraestructura">Infraestructura</MenuItem>
                               <MenuItem value="Computo">Computo</MenuItem>
                               <MenuItem value="Mobiliario">Mobiliario</MenuItem>
                               <MenuItem value="Industrial">Industrial</MenuItem>
                              
                               </Select>
                               <FormHelperText>{errors.tipotarea}</FormHelperText>
                           </FormControl>
                   </div>

                   </div>
             </div>
                 

                  
             

             <div className="flex-accordion">
             <h4 className="text-title-accorion">Personal Designado</h4>

                    {ingenieros && ingenieros.length > 0 ? (
                    <FormControl fullWidth error={errors && errors.handleasignado ? true : false}>
                        <InputLabel id="tecnico-label">Personal designado para esta tarea</InputLabel>
                        <Select
                        required
                        name="handleasignado"
                        id="handleasignado"
                        value={this.state.handleasignado}
                        onChange={this.handleChange}
                        fullWidth
                        error={errors.handle ? true : false}
                        >
                        {ingenieros.map((ingeniero, index) => (
                            <MenuItem key={index} value={ingeniero}>{ingeniero}</MenuItem>
                        ))}
                        </Select>
                        {errors && errors.handleasignado && (
                        <FormHelperText>{errors.handleasignado}</FormHelperText>
                        )}
                    </FormControl>
                    ) : (
                    <p> Este perfil de ingeniería no tiene ténicos asociados, por favor cree uno o más téncicos para que pueda asociarlos a diferentes prestadores</p>
                    )}
                    </div>
   
           
           
                       
   
            
         
          
           
   
    </form>
                         
               
               </DialogContent>
                </div>)}
            
          
          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseEdit}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseEdit}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Crear Tarea" onClick={this.handleSubmitEdit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            

            
          </DialogActions>
            </Dialog>
      </div>
    )
  }
}

TablaTareas.propTypes = {    
    getTareasClienteFiltrado: PropTypes.func.isRequired,
    postAprobarTarea:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteTarea:PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,  
    editTarea:PropTypes.func.isRequired,
    getClienteTareas:PropTypes.func.isRequired
  };

const mapActionsToProps={getTareasClienteFiltrado,editTarea,deleteTarea,postAprobarTarea,getClienteTareas,clearErrors};

const mapStateToProps = (state)=>({
    data:state.data,
    UI:state.UI,
    
    user:state.user,
})



export default connect(mapStateToProps,mapActionsToProps)(TablaTareas);