import React from 'react';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import './CasoTecnoDocuPDF.css';
import alphalogo from '../../../utils/alphahorizontal.png'
import membretetecno from '../../../utils/reportetecnotemplate.png'


const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    viewcard:{
        display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "10px",
                 marginBottom: "5px",
                
    },
    text:{
        fontSize:"9",
        color:"#03178c",
        fontWeight:"bold"
    },
    textdos:{
        fontSize:"7",
        color:"#03178c",
        fontWeight:"light"
    },
    itemelement:{
        width: "15%",
        marginRight:"1%",
        alignItems:"start"
    },
    vieWgeneral:{
        padding:"10px",
        minWidth: '100%',
        minHeight: '100%',
        height: '100%',
        width: '100%',
    },
  
    imagenlogo:{
        width:"100px",
        height:"100px"
    },
    
    pageGeneral:{
        padding:"2px",
        marginLeft:"10px",
        marginTop:"10px"
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
      },

      imagencliente:{
        width:"65px",
        height:"65px",
        borderColor:"#e2e2e2",
        borderRadius:"10%",
        objectFit:"cover",
        borderWidth:"3px"
      },
      viewmapeo:{
        minWidth: '100%',
        minHeight: '100%',
        height: '100%',
        width: '100%',
        display:'flex',
        flexDirection:'column'
      },
      cardcliente:{
        display:'flex',
        flexDirection:'row',
        
        marginTop:'100px',
        marginLeft:'25px',
        width:'510px',
        height:'85px',
        alignItems:'center'
      }
      ,
      infocliente:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'95%',
        marginLeft:'10px'
        
      }
      ,
      viewText:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        marginTop:'10px'
      },
      tituloinfo:{
        fontSize:'11',
        marginLeft:'5px',
        color:'#005695'

      },
      textinfo:{
        fontSize:'10',
        color:'#0067b2'
      },
      viewimgcliente:{
        width:'auto'
      },
      viewcardequipo:{
        display:'flex',
        flexDirection:'column',
        marginTop:'25px',
        
        width:'515px',
        height:'120px',
        marginLeft:'20px'
      },
      equipoinfo:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
      },
      imagenequipo:{
        width:"65px",
        height:"65px",
        borderColor:"#e2e2e2",
        borderRadius:"10%",
        objectFit:"cover",
        borderWidth:"2px"
      },
      viewimagenquipo:{
        marginLeft:'5px',
      },
      viewinfoequipo:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'100%',
        marginLeft:'2px'
      },
      viewinfoequipo2:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'100%',
        marginLeft:'2px'
      },
      viewcardhistorial:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        
        marginTop:'21px',
        width:'517px',
        height:'90px',
        marginLeft:'18px'
      },
      viewcardtecnico:{
        display:'flex',
        flexDirection:'column',
        marginTop:'30px',
        width:'90%',
        marginLeft:'15px'
      },
      viewpruebas:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      },
      viewcardobservaciones:{
        display:'flex',
        flexDirection:'column',
        marginTop:'40px',
        width:'90%',
        marginLeft:'15px'
      },
      viewfecha:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      }
      ,
      viewcardpersonal:{
        display:'flex',
        flexDirection:'column',
        marginTop:'40px',
        width:'90%',
        marginLeft:'15px'
      },
      viewpersonal:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      },
      viewTextObservaciones:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        marginTop:'10px',
        marginLeft:'3px'

      },
      textobs:{
        fontSize:'10',
        color:'#0067b2'
      },
      viewTextRealizado:{
        marginTop:'2px',
        display:'flex',
        flexDirection:'row'
      },
      viewTextEquipo:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        marginTop:'7px'
      },
      viewcardPaciente:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
       
        marginTop:'24px',
        width:'517px',
        height:'70px',
        marginLeft:'18px'
      },
      viewcardGestion:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        
        marginTop:'18px',
        width:'517px',
        height:'100px',
        marginLeft:'18px'
      },
      viewcardReportante:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
       
        marginTop:'22px',
        width:'517px',
        height:'70px',
        marginLeft:'18px'
      }


  });
  
  


const casoTecnoDocuPDF = ({ caso }) => {

  const imagenUrl = caso.clienteData.imagenurl ? caso.clienteData.imagenurl : 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';
  const imagenEquipoUrl=caso.equipoData.imagenurl?caso.equipoData.imagenurl : 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';
    let mapeocaso=
    (caso?(
       
        <View  style={styles.viewmapeo}>
            
            <View style={styles.cardcliente}>
            <View style={styles.viewimgcliente}>
            <Image src={imagenUrl}style={styles.imagencliente} />
            </View>
            <View style={styles.infocliente}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>NOMBRE: </Text>
                <Text style={styles.textinfo}>{caso.clienteData.nombre}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CIUDAD: </Text>
                <Text style={styles.textinfo}>{caso.clienteData.ciudad}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CC/NIT: </Text>
                <Text style={styles.textinfo}>{caso.clienteData.ccnit}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DIRECCIÓN: </Text>
                <Text style={styles.textinfo}>{caso.clienteData.direccion}</Text>
                </View>

               

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>TELÉFONO: </Text>
                <Text style={styles.textinfo}>{caso.clienteData.telefono}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CODIGO REPS: </Text>
                <Text style={styles.textinfo}>{caso.clienteData.codigoreps}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>E-MAIL: </Text>
                <Text style={styles.textinfo}>{caso.clienteData.email}</Text>
                </View>
                

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SEDE: </Text>
                <Text style={styles.textinfo}>{caso.clienteData.sede}</Text>
                </View>
               
               
                

            </View>
            </View>


            <View style={styles.viewcardequipo}>
            <View style={styles.equipoinfo}>
            
            <View style={styles.viewinfoequipo}>

            <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>NOMBRE: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.nombre}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>NOMBRE COMERCIAL: </Text>
                <Text style={styles.textinfo}>{caso.casoData.nombreComercialDispositivo}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>REGISTRO INVIMA: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.registroinvima}</Text>
                </View>
              
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>ID INVENTARIO: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.idinventario}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>ESTADO: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.estado}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>MARCA: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.marca}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>MODELO: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.modelo}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>SERIE: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.serie}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>LOTE: </Text>
                <Text style={styles.textinfo}>{caso.casoData.lote}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>UBICACIÓN: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.ubicacion}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>SERVICIO: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.servicio}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>LOCALIZACIÓN: </Text>
                <Text style={styles.textinfo}>{caso.casoData.areaAccidente}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>MÁS DE UN USO: </Text>
                <Text style={styles.textinfo}>{caso.casoData.masDeUnUso}</Text>
                </View>
              
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>REFERENCIA: </Text>
                <Text style={styles.textinfo}>{caso.casoData.referencia}</Text>
                </View>

                

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>PROVEEDOR: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.proveedor}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>EMAIL: </Text>
                <Text style={styles.textinfo}>{caso.equipoData.proveedoremail}</Text>
                </View>
                </View>


                
            </View>
            </View>
           

            <View style={styles.viewcardhistorial}>
                 <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>FECHA EVENTO: </Text>
                <Text style={styles.textinfo}>{caso.casoData.fechaDelEvento}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>FECHA REPORTE: </Text>
                <Text style={styles.textinfo}>{caso.casoData.fechaReporte}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CLASIFICACIÓN: </Text>
                <Text style={styles.textinfo}>{caso.casoData.clasificacionCaso}</Text>
                </View>
                
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DESCRIPCIÓN: </Text>
                <Text style={styles.textinfo}>{caso.casoData.descripcionCaso}</Text>
                </View>
                

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DETECCIÓN: </Text>
                <Text style={styles.textinfo}>{caso.casoData.momentoDeteccion}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DESENLACE: </Text>
                <Text style={styles.textinfo}>{caso.casoData.desenlaceCaso}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>OTRO: </Text>
                <Text style={styles.textinfo}>{caso.casoData.otro}</Text>
                </View>

                
               
            </View>
            <View style={styles.viewcardPaciente}>

            <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>TIPO ID: </Text>
                <Text style={styles.textinfo}>{caso.casoData.tipoIdentificacion}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>NUMERO ID: </Text>
                <Text style={styles.textinfo}>{caso.casoData.numeroIdentificacion}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>SEXO: </Text>
                <Text style={styles.textinfo}>{caso.casoData.sexo}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>EDAD: </Text>
                <Text style={styles.textinfo}>{caso.casoData.edad}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>EDAD EN: </Text>
                <Text style={styles.textinfo}>{caso.casoData.edadEn}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>EPS: </Text>
                <Text style={styles.textinfo}>{caso.casoData.eps}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>DIAGNOSTICO: </Text>
                <Text style={styles.textinfo}>{caso.casoData.diagnosticoInicialPaciente}</Text>
                </View>



                </View>

            <View style={styles.viewcardGestion}>
            <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>CAUSA PROBABLE: </Text>
                <Text style={styles.textinfo}>{caso.casoData.causaCaso}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>ACCIONES INICIADAS: </Text>
                <Text style={styles.textinfo}>{caso.casoData.accionTomada}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>¿REPORTÓ AL FABRICANTE/IMPORTADOR/DISTRIBUIDOR? </Text>
                <Text style={styles.textinfo}>{caso.casoData.reporteDistibuidor}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>FECHA REPORTE: </Text>
                <Text style={styles.textinfo}>{caso.casoData.fechaReporteDistribuidor}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>¿ENVIÓ AL FABRICANTE/IMPORTADOR/DISTRIBUIDOR? </Text>
                <Text style={styles.textinfo}>{caso.casoData.envioDistribuidor}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>FECHA ENVÍO: </Text>
                <Text style={styles.textinfo}>{caso.casoData.fechaEnvioDistribuidor}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>¿DISPONIBLE PARA EVALUACIÓN? </Text>
                <Text style={styles.textinfo}>{caso.casoData.disponibleEvaluacion}</Text>
                </View>
                

             

            </View>
            
            <View style={styles.viewcardReportante}>
            
            <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>NOMBRE: </Text>
                <Text style={styles.textinfo}>{caso.casoData.nombreReportante}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>PROFESION: </Text>
                <Text style={styles.textinfo}>{caso.casoData.profesion}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>AREA: </Text>
                <Text style={styles.textinfo}>{caso.casoData.servicioPerteneciente}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>DIRECCIÓN: </Text>
                <Text style={styles.textinfo}>{caso.casoData.direccion}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>TELEFONO: </Text>
                <Text style={styles.textinfo}>{caso.casoData.telefono}</Text>
                </View>
                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>CORREO: </Text>
                <Text style={styles.textinfo}>{caso.casoData.correoInstitucional}</Text>
                </View>

                <View style={styles.viewTextEquipo}>
                <Text style={styles.tituloinfo}>ESTADO DEL REPORTE: </Text>
                <Text style={styles.textinfo}>{caso.casoData.estadoReporte==='A'?('Abierto'):('Cerrado')}</Text>
                </View>

               
             

            </View>
            
            
        </View>
       
      
    ):(<View>
            <Text>no hay nada</Text>
    </View>)
    )
  
  
    return (

        <Document>
             <Page size="a4" style={styles.pageGeneral}>
            
            <View style={styles.vieWgeneral}>
            <Image src={membretetecno} style={styles.pageBackground} />
            
         
               
               {mapeocaso}
            </View>
            </Page>
          
         </Document>
        
        
        )
}

export default casoTecnoDocuPDF