import React, { Component } from 'react';
import './Cursos.css';
import PropTypes from 'prop-types';


import CardCurso from './CardCurso';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { cursosRecomendados, getCursosRecomendados, getTotalCursos, postRutaDeAprendizaje, totalCursos } from '../../../redux/actions/dataactions';
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Link } from '@material-ui/core';
import Button from '../../Elements/Button';
import MyButton from '../../../utils/MyButton';
import { FaCheckCircle, FaPlus, FaTimes } from 'react-icons/fa';
import NuevaRuta from './NuevaRuta';
import CardCursoRecomendado from './CardCursoRecomendado';
import AgregarRuta from './AgregarRuta';


const preferences = [
  { value: 'pref1', label: 'Equipos de cirugía' },
  { value: 'pref2', label: 'Equipos de laboratorio médico' },
  { value: 'pref3', label: 'Unidades de esterilización' },
  { value: 'pref4', label: 'Equipos neonatales' },
  { value: 'pref5', label: 'Gestión de técnología hospitalaria' },
  { value: 'pref6', label: 'Equipos de Diagnóstico y Monitoreo' },
  { value: 'pref7', label: 'Dialisis' },
  { value: 'pref8', label: 'Odontología' },
  { value: 'pref9', label: 'Cuidado Intensivo' },
  {value:'pref10', label:'Imagenes Diagnosticas'}
];


export class Cursos extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      aprobado: {},
      openRuta: false,
      openCurso: false,
      selectedPreferences: {} // Asegúrate de inicializar selectedPreferences si planeas usarlo en handlePreferenceChange
    }
  }

  componentDidMount() {
    this.props.getTotalCursos()
    this.props.getCursosRecomendados(this.props.userHandle)
  }

  handleOpenRuta = () => {
    this.setState({
      openRuta: true
    })
  }

  handleCloseRuta = () => {
    this.setState({
      openRuta: false
    })
  }

  handlePreferenceChange = (event) => {
    const { value, checked } = event.target;
    const label = this.getLabelFromValue(value); // Obtener el label del array preferences
    this.setState((prevState) => ({
      selectedPreferences: {
        ...prevState.selectedPreferences,
        [value]: label,
      }
    }));
  }

  getLabelFromValue = (value) => {
    const preference = preferences.find(preference => preference.value === value);
    return preference ? preference.label : ''; // Obtener el label correspondiente al valor
  }

  handleCrearRuta = () => {
    const selectedPreferences = this.state.selectedPreferences;
    const selectedPreferencesArray = Object.values(selectedPreferences);
    const message = selectedPreferencesArray.join(' , ');

    this.props.postRutaDeAprendizaje({ preferencias: message })
  }

  handleEditarRuta = () => {

    //this.props.postRutaDeAprendizaje({ preferencias: message })
  }

  handleAprobarRuta = () => {

  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (props.UI.errors) {
      newState.errors = props.UI.errors;
    }

    if (props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }


  render() {

    const { UI: { loading } } = this.props;
    const { errors } = this.state;
    const cursosRecomendados = this.props.cursosRecomendados
    const loadingdata = this.props.loadingdata
    const recomendacionCursos = this.props.recomendacionCursos

    // Crear la constante 'cursos' con la información de los cursos
    

    return (
      <div>
        {loading ? (
          <CircularProgress size={30} color="primary" className="login-progress" />
        ) : (
          <div className="market_container">
            <div className="titulomarket_section">
              <div className="titulo_market">
                <h1 className="text-clientes"> Educación</h1>
                <hr className="clientes_separator"></hr>
              </div>

             
               
            </div>

            <div className="titulo_market">

             
              <div>
                <h5 className='text-recomendaciones-cursos'>Recomendaciones para ti</h5>
                <hr className="clientes_separator_cursos"></hr>
              </div>
              <div className="cursos_categorias_container">
                {/* Mapear la constante 'cursos' para renderizar cada curso */}
                <AgregarRuta/>

                {
                  cursosRecomendados && cursosRecomendados.length > 0 ? (
                   
                    this.props.cursosRecomendados.map(curso => (
                      <CardCursoRecomendado
                        key={curso.id}
                        imagen={curso.imagen}
                        titulo={curso.nombre}
                        precioAhora={curso.precioAhora}
                        precioAntes={curso.precioNormal}
                      />
                    ))
                  ) : (
                    <AgregarRuta/>
                  )
                }
              </div>
            </div>

            <div className='titulo_market'>
            <div>
                <h5 className='text-recomendaciones-cursos'>Todos los cursos</h5>
                <hr className="clientes_separator_cursos"></hr>
              </div>
              <div className="cursos_categorias_container">
                {/* Mapear la constante 'cursos' para renderizar cada curso */}

                {this.props.cursosTotal.map(curso => (
                  <CardCursoRecomendado
                    key={curso.id}
                    imagen={curso.imagen}
                    titulo={curso.nombre}
                    precioAhora={curso.precioAhora}
                    precioAntes={curso.precioNormal}
                  />
                ))}

            
              </div>
            </div>

            <Dialog
              open={this.state.openRuta}
              onClose={this.handleCloseRuta}
              fullWidth
              maxWidth="sm">

              <DialogTitle>Crear ruta de aprendizaje</DialogTitle>

              <DialogContent>

                {
                  recomendacionCursos.razon ? (
                    <div>
                      <h2> Recomendaciones: </h2>
                      <p> Basado en tus preferencias, estos son los cursos que te recomendamos:</p>
                        <p>Aqui van los cursos</p>
                      <h2> Razón: </h2>
                      <p>{recomendacionCursos.razon}</p>
                    </div>
                  ) : (
                    <div>
                      <p>Seleccione las temáticas de su preferencia basado en sus intereses, actividades principales en la institución
                        o tema que desee reforzar. La plataforma le recomendará una lista de cursos afines con sus preferencias y usted
                        podrá decidir si desea tomar estas recomendaciones, hacer una nueva consulta o directamente no aceptarlas.</p>
                      <p></p>
                      <p>Seleccione una o más preferencias:</p>
                      <FormGroup>
                        {preferences.map(preference => (
                          <FormControlLabel
                            key={preference.value}
                            control={
                              <Checkbox
                                value={preference.value}
                                onChange={this.handlePreferenceChange}
                              />
                            }
                            label={preference.label}
                          />
                        ))}
                      </FormGroup>
                    </div>
                  )
                }

              </DialogContent>

              <DialogActions>

                {loadingdata ? (
                  <CircularProgress size={30} color="primary" className="login-progress" />
                ) : (

                  recomendacionCursos.razon ? (
                    <div>
                      <MyButton tip="Editar preferencias" onClick={this.handleEditarRuta}>
                        <FaPlus color="#03178C" className="icon-formulario" />
                      </MyButton>
                      <MyButton tip="Volver a intentar" onClick={this.handleCrearRuta}>
                        <FaPlus color="#03178C" className="icon-formulario" />
                      </MyButton>
                      <MyButton tip="Aprobar recomendaciones" onClick={this.handleAprobarRuta}>
                        <FaPlus color="#03178C" className="icon-formulario" />
                      </MyButton>

                    </div>
                  ) : (
                    <MyButton tip="Crear " onClick={this.handleCrearRuta}>
                      <FaPlus color="#03178C" className="icon-formulario" />
                    </MyButton>
                  )


                )}
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    )
  }
}

Cursos.propTypes = {
  user: PropTypes.object.isRequired,
  cursosTotal: PropTypes.object.isRequired,
  cursosRecomendados: PropTypes.object.isRequired,
  recomendacionCursos: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  loadingdata: PropTypes.bool.isRequired,

  totalCursos: PropTypes.func.isRequired,
  getCursosRecomendados: PropTypes.func.isRequired,
  postRutaDeAprendizaje: PropTypes.func.isRequired
}


const mapActionsToProps = { getTotalCursos, getCursosRecomendados, postRutaDeAprendizaje }

const mapStateToProps = (state) => ({
  userHandle: state.user.credentials.handle,
  cursosTotal: state.data.cursosTotal,
  cursosRecomendados: state.data.cursosRecomendados,
  recomendacionCursos: state.data.recomendacionCursos,
  loadingdata: state.UI.loadingdata,
  UI: state.UI
})

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Cursos));