import React from 'react';
import './ListaRepuestosVerWebPDF.css';
const ListaRepuestosVerWebPDF = ({ repuestos }) => {
    let mapeorepuestos=
        (repuestos.length>0?(
            repuestos.map((repuesto)=>
            <div className='itemcard'>
                <div className='itemelement'>
                <p className='titleitem-text'>Codigo</p>
                <p className="item-text">{repuesto.idinventario}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Nombre</p>
                <p className="item-text">{repuesto.nombre}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Marca</p>
                <p className="item-text">{repuesto.marca?repuesto.marca:'No Registra'}</p>
                </div>

                <div className='itemelement'>
                <p className='titleitem-text'>Modelo</p>
                <p className="item-text">{repuesto.modelo}</p>
                </div>

                <div className='itemelement'>
                <p className='titleitem-text'>Serie</p>
                <p className="item-text">{repuesto.serie}</p>
                </div>

                <div className='itemelement'>
                <p className='titleitem-text'>Cantidad</p>
                <p className="item-text">{repuesto.cantidad}</p>
                </div>

                <div className='itemelement'>
                <p className='titleitem-text'>Valor Unt</p>
                <p className="item-text">{repuesto.valorunitario}</p>
                </div>

                <div className='itemelement'>
                <p className='titleitem-text'>Proveedor</p>
                <p className="item-text">{repuesto.proveedor}</p>
                </div>

                
              
            
            </div>
           
            )
        ):(<div>
                <p>no hay nada</p>
        </div>)
        )
    
    return (
        <div className='ContainerMainInventario'>
            <div className='DivTitleInventario'>
                <h1 className='TitleInventario'>Listado de Repuestos</h1>
            </div>
          
         {mapeorepuestos}
        </div>
        
        )
}

export default ListaRepuestosVerWebPDF