import React, { Component, Fragment } from 'react';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { FaArrowLeft, FaCheck, FaCheckCircle, FaFirstAid, FaPlus, FaUserPlus } from 'react-icons/fa';

import { getUsuarios } from '../../../redux/actions/useractions';
import { clearErrors } from '../../../redux/actions/dataactions';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { data } from 'browserslist';
import { getCliente } from '../../../redux/actions/dataactions';
import { agregarUsuario} from '../../../redux/actions/useractions'
import MyButton from '../../../utils/MyButton';



export class CrearUsuario extends Component {
  constructor() {
    super();
    this.state = {
      nombre: '',
      email: '',
      password: '',
      confirmPassword: '',
      handle: '',
      showPassword: false,
      showConfirmPassword: false,

      idprestador: '', // Obtenerlos al renderizar el componente
      tipouser: '',
      handleuser: '',

      ccnit: '',
      ciudad: '',
      direccion: '',
      telefono: '',

      errors: {},
      aprobado: {},
      open: false,
    };
  }

  componentDidMount() {
    this.props.getCliente(this.props.clienteId);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (props.UI.errors) {
      newState.errors = props.UI.errors;
    }

    if (props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }

  handleOpen = () => {
    this.setState({ open: true });
    console.log(this.state.aprobado)
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = () => {

    if(this.state.aprobado.mensaje){
        this.props.getUsuarios(this.props.clienteId);
        this.setState({ nombre: '',
        email: '',
        password: '',
        confirmPassword: '',
        handle: '',
        showPassword: false,
        showConfirmPassword: false,  
        idprestador: '', // Sólo para asistencial o compras
        tipoprestador: '',
        tipouser: '',
        handleuser: '',  
        ccnit: '',
        ciudad: '',
        direccion: '',
        nombre: '',
        telefono: '',open: false,errors:{},aprobado:{}});
        console.log("Get Usuarios en el close")
     }else{
        this.props.clearErrors();
        console.log("Clear Errors en el close")
      this.setState({open: false,errors:{},aprobado:{}});
     }


   
 
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const newUserData = {

      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      handle: this.state.handle,

      tipoprestador: this.props.tipouser,
      tipouser: this.state.tipouser,
      handleuser: this.props.handleuser,

      ccnit: this.state.ccnit,
      ciudad: this.state.ciudad,
      direccion: this.state.direccion,
      nombre: this.state.nombre,
      telefono: this.state.telefono,
    };
    console.log(newUserData)
    this.props.agregarUsuario(newUserData, this.props.clienteId);
  }

  render() {
    const { UI: { loading} } = this.props;
    const { errors } = this.state;
    const {aprobado}=this.props.UI; 

    return (

      <Fragment>

        <div className='div-card-link' onClick={this.handleOpen}>
          <div className="dashing-cardcontent">
            <div className="dashing-cardtext">
              <p className="dashing-text-card">
                Crear nuevo usuario
              </p>
            </div>
            <div className="dashing-cardicon">
              <FaUserPlus color="#FFFFFF" className="dashing-icon" />
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm">
          <DialogTitle>
            Agregar usuario
          </DialogTitle>

          <DialogContent>

            {aprobado ? (

              <div className='div-aprobado'>
                <div className='div-icono-aprobado'>
                  <FaCheck color="#ffffff" className='icono-aprobado' />
                </div>
                <h2 className='text-aprobado'>¡Usuario creado exitosamente!</h2>
              </div>

            ) : (

              <form>

                <TextField className="login-input-email"
                  required
                  type="text"
                  name="nombre"
                  id="nombre"
                  autoComplete="name"
                  label="Nombre de la persona"
                  value={this.state.nombre}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.nombre}
                  error={errors.nombre ? true : false}
                />

                <FormControl className="formcontrol-nuevocliente">
                  <InputLabel>Tipo Usuario</InputLabel>

                  {this.props.tipouser == 'IPS' ? (
                    <Select
                      required
                      name="tipouser"
                      id="tipouser"
                      value={this.state.tipouser}
                      onChange={this.handleChange}
                      fullWidth
                      error={errors.tipouser ? true : false}
                    >
                      <MenuItem value="">
                        <em></em>
                      </MenuItem>
                      <MenuItem value="Asistencial">Asistencial</MenuItem>
                      <MenuItem value="Compras">Compras</MenuItem>
                      <MenuItem value="Tecnico">Tecnico</MenuItem>
                      <FormHelperText>{errors.tipouser}</FormHelperText>
                    </Select>
                  ) : (
                    <Select
                      required
                      name="tipouser"
                      id="tipouser"
                      value={this.state.tipouser}
                      onChange={this.handleChange}
                      fullWidth
                      error={errors.tipouser ? true : false}
                    >
                      <MenuItem value="">
                        <em></em>
                      </MenuItem>
                      <MenuItem value="Asistencial">Asistencial</MenuItem>
                      <MenuItem value="Tecnico">Tecnico</MenuItem>
                      <FormHelperText>{errors.tipouser}</FormHelperText>
                    </Select>
                  )}

                </FormControl>

                <div className="input_divido">
                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      required
                      type="text"
                      name="handle"
                      id="handle"
                      autoComplete="username"
                      label="Handle/nombre del usuario a crear"
                      value={this.state.handle}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.handle}
                      error={errors.handle ? true : false}
                    />
                    <br />
                  </div>

                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      required
                      type="email"
                      name="email"
                      id="email"
                      label="Email"
                      autoComplete="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.email}
                      error={errors.email ? true : false}
                    />
                    <br />
                  </div>
                </div>

                <div className="input_divido">
                  <div className="input_divido_children">
                    <TextField
                      className="login-input-contraseña"
                      name="password"
                      id="password"
                      label="Contraseña"
                      type={this.state.showPassword ? 'text' : 'password'} // Mostrar u ocultar la contraseña según el estado
                      autoComplete="new-password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.password}
                      error={errors.password ? true : false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={() =>
                                this.setState({
                                  showPassword: !this.state.showPassword,
                                })
                              }
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <br />

                    <TextField
                      className="login-input-contraseña"
                      name="confirmPassword"
                      id="confirmPassword"
                      label="Confirmar Contraseña"
                      type={this.state.showConfirmPassword ? 'text' : 'password'} // Mostrar u ocultar la contraseña según el estado
                      autoComplete="new-password"
                      value={this.state.confirmPassword}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.confirmPassword}
                      error={errors.confirmPassword ? true : false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={() =>
                                this.setState({
                                  showConfirmPassword: !this.state.showConfirmPassword,
                                })
                              }
                            >
                              {this.state.showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                <div className="input_divido">
                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      required
                      type="number"
                      name="ccnit"
                      id="ccnit"
                      label="Cédula o NIT"
                      value={this.state.ccnit}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ccnit}
                      error={errors.ccnit ? true : false}
                    />
                    <br />
                  </div>
                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      required
                      type="text"
                      name="ciudad"
                      id="ciudad"
                      label="Ciudad"
                      value={this.state.ciudad}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ciudad}
                      error={errors.ciudad ? true : false}
                    />
                    <br />
                  </div>
                </div>

                <div className="input_divido">
                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      required
                      type="text"
                      name="direccion"
                      id="direccion"
                      label="Dirección"
                      autoComplete='street-address'
                      value={this.state.direccion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.direccion}
                      error={errors.direccion ? true : false}
                    />
                    <br />
                  </div>

                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      required
                      type="number"
                      name="telefono"
                      id="telefono"
                      autoComplete='tel'
                      label="Teléfono del usuario"
                      value={this.state.telefono}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.telefono}
                      error={errors.telefono ? true : false}
                    />
                    <br />
                  </div>
                </div>

              </form>
            )}

          </DialogContent>

          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
              <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
            </MyButton>

            {loading ? (
              <CircularProgress size={30} color="primary" className="login-progress" />
            ) : (
                    aprobado ? (
                <MyButton tip="Continuar" onClick={this.handleClose}>
                  <FaCheckCircle color="#20B90C" className="icon-formulario" />
                </MyButton>
              ) : (
                <MyButton tip="Crear usuario" onClick={this.handleSubmit}>
                  <FaPlus color="#03178C" className="icon-formulario" />
                </MyButton>
              )
            )}

          </DialogActions>

        </Dialog>
      </Fragment>

    )
  }
}

CrearUsuario.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  clienteId: PropTypes.string.isRequired,
  tipouser: PropTypes.string.isRequired,
  handleuser: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  UI: state.UI,
  data: state.data,
  handleuser: state.user.credentials.handle,
  tipouser: state.data.cliente.tipouser
})

const mapActionsToProps = { clearErrors, getCliente, agregarUsuario,getUsuarios };


export default connect(mapStateToProps, mapActionsToProps)(CrearUsuario);