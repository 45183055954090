import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';
import TablaCronogramaMto from './TablaCronogramaMto';

function CronogramaMtoPerfil(props) {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack(); // Esto llevará al usuario una página hacia atrás en el historial de navegación.
  };

  return (
    <div>
      <div className="titulo_section">
        <div className="titulo_clientes">
          <h1 className="text-clientes">Cronograma de Mantenimiento Preventivo</h1>
          <hr className="clientes_separator"></hr>
        </div>

        <div className="div_boton">
          <MyButton tip="Volver" onClick={handleGoBack}>
            <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
          </MyButton>
        </div>
      </div>

      <div className="div-tabla-equipos">
        <TablaCronogramaMto clienteId={props.match.params.clienteId} history={props.history}></TablaCronogramaMto>
      </div>
    </div>
  );
}

export default CronogramaMtoPerfil;
