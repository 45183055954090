import React, { Component } from 'react';
import './ListaCalProgAsis.css';

import {getprogcals,clearErrors,deleteProgCal,postCalibracion, editProgCal} from '../../../redux/actions/dataactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';




import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';

export class ListaCalProgAsis extends Component {

  constructor(){
    super();
    this.state={
        horainicio:'',
        horafinal:'',
        fecha:'',
        numcertificado:'',
        errors:{},
        open:false,
        opendelete:false,
        progid:'',
        selectedFile: null,
    };
    
};


    
    componentDidMount(){
        this.props.getprogcals(this.props.equipoId);
       }
    
     

    render() {

        const {progracals,loadingcal}= this.props.data; 
        const {errors}=this.state;
        const { UI:{loading}}=this.props; 
        
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const columns=[
            {title:'Fecha',field:'fecha'},
            {title:'Hora ',field:'hora'},
            {title:'Duración',field:'duracion'},
            {title:'Estado',field:'estado'},
            {title:'Lugar',field:'lugar'}
           
        ]


        
     let mapeoprogcals=!loadingcal ?(
         <div>
    <MaterialTable
            columns={columns}
            data={progracals}
            title={
                <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Calibraciones Programadas</h2>
              }
            icons={tableIcons}
            options={{
                cellStyle: {
                    fontSize: 12,
                    fontWeight:500,
                    color:'#696B6D'
                  
                 
                  },
                  headerStyle: {
                    fontSize: 14,
                    fontWeight: 'bolder',
                    color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                  }
              
              }}
          
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
        return (
            <div className="listamtoprog-container">
              
            <div className="listamtoprog-table">
            {mapeoprogcals}
            </div>
           
          </div>
        )
    }
}

ListaCalProgAsis.propTypes = {
    
    getprogcals: PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteProgCal:PropTypes.func.isRequired,
    editProgCal:PropTypes.func.isRequired
  };

const mapActionsToProps={getprogcals,clearErrors,deleteProgCal, editProgCal,postCalibracion};

const mapStateToProps = (state)=>({
    data:state.data,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(ListaCalProgAsis);

