import {SET_EQUIPOIA,LOADING_DATA_DATA,POST_EQUIPO_IA,EDIT_DOCLEGAL,SET_DOCSLEGAL,POST_DOCLEGAL,SET_CALIBRACIONES_TABLA,LOADING_DATA_CAL,POST_CRONOGRAMACAL_AUTO,POST_CRONOGRAMA_AUTO,LOADING_CLIENTE,SET_MTOPREV,EDIT_CLIENTE,SET_SOL_CORR,LOADING_SOL_CORR,POST_SOLICITUD_MTO,POST_EDITPROGCAL,POST_EDITPROGMTO,SET_INDICADORES_P,LOADING_INDP,LOADING_MTO, LOADING_CAL,SET_EQUIPOS_CAL,SET_EQUIPOS_MTO,SET_CALIBRACIONES,SET_PROGCALS,SET_MTOSCORRECTIVOS,POST_MTOCORRECTIVO,SET_MTOSPREV,POST_PCAL,POST_MTOPREVENTIVO,SET_PROGMTOS,POST_PMTO,SET_EQUIPOS,SET_EQUIPO,LOADING_DATA, POST_CLIENTE,SET_CLIENTES,SET_CLIENTE,POST_EQUIPO, POST_CALIBRACION, CLEAR_EQUIPOS_IA, POST_RECOMENDACION_IA, SET_REPUESTOS, LOADING_DATA_INF, SET_AREAS, SET_AREA, SET_PROGMTOS_INFRA,  LOADING_DATA_MTO_INFRA, SET_MTOS_INFRA, SET_MTO_INFRA, SET_CORRECTIVOS_INFRA, SET_CORRECTIVO_INFRA, SET_SOLICITUDESCORR_INFRA} from '../types';

const initialState={
    
    
    loadingdata:true,
    loadingmto:true,
    areas:[],
    area:{},
    programto:[],
    progmtosinfra:[],
    solicitudcorr:[],
    solicitudescorr:[],
    mtoinfra:{},
    mtosinfra:[],
    mtocorrectivoinfra:{},
    mtoscorrectivosinfra:[],
    solicitudesmtoinfra:[],
    }

    export default function (state=initialState,action){
        switch(action.type){
            case LOADING_DATA_INF:
                return {
                    ...state,
                    loadingdata:true
                }
            case LOADING_DATA_MTO_INFRA:
                    return {
                        ...state,
                        loadingmto:true
                    }

            case SET_AREAS:
                    return{
                        ...state,
                        areas:action.payload,
                        loadingdata:false,
                     
                    }
            case SET_AREA:
                        return{
                            ...state,
                            area:action.payload,
                            loadingdata:false
                        }

            case SET_PROGMTOS_INFRA:
                return{
                    ...state,
                    progmtosinfra:action.payload,
                    loadingmto:false
                }
            case SET_MTOS_INFRA:
                return{
                    ...state,
                    mtosinfra:action.payload,
                    loadingmto:false
                }

            case SET_MTO_INFRA:
                return{
                    ...state,
                    mtoinfra:action.payload,
                    loadingmto:false
                }

                case SET_CORRECTIVOS_INFRA:
                    return{
                        ...state,
                        mtoscorrectivosinfra:action.payload,
                        loadingmto:false
                    }

            case SET_CORRECTIVO_INFRA:
                return{
                    ...state,
                    mtocorrectivoinfra:action.payload,
                    loadingmto:false

                }

            case SET_SOLICITUDESCORR_INFRA:
                return{
                    ...state,
                    solicitudescorr:action.payload,
                    loadingmto:false
                }

            default:
                    return{
                        ...state
                    }
            
            }
         
        }