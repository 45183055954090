import React, { Component, Fragment  } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus, FaPencilAlt } from "react-icons/fa";

import {editCliente,clearErrors} from '../../../redux/actions/dataactions';


export class DetallesCliente extends Component {
    constructor(){
        super();
        this.state={
            nombre:'',
            ccnit:'',
            email:'',
            tipouser:'',
            telefono:'',
            direccion:'',
            ciudad:'',
            codigoreps:'',
            sede:'',
            errors:{},
            open:false
        };
        
    };

    mapUserDetailsToState = (cliente) => {
        this.setState({
            nombre:cliente.nombre?cliente.nombre:'',
            ccnit:cliente.ccnit?cliente.ccnit:'',
            email:cliente.email?cliente.email:'',
            tipouser:cliente.tipouser?cliente.user:'',
            telefono:cliente.telefono?cliente.telefono:'',
            direccion:cliente.direccion?cliente.direccion:'',
            ciudad:cliente.ciudad?cliente.ciudad:'',
            codigoreps:cliente.codigoreps?cliente.codigoreps:'',
            sede:cliente.sede?cliente.sede:'',
        
        });
        };
        
        static getDerivedStateFromProps(props, state) {
            return props.UI.errors ? {errors: props.UI.errors} : null;
        }
      
        handleOpen = () => {
            this.setState({ open: true });
            //getequipo
            
            this.mapUserDetailsToState(this.props.cliente);
          };
          handleClose = () => {
            this.setState({ open: false });
          };
          componentDidMount() {
            const { cliente } = this.props;
            this.mapUserDetailsToState(cliente);
          }
        
          handleChange = (event) => {
            this.setState({
              [event.target.name]: event.target.value
            });
          };

          handleSubmit = (event) => {
            event.preventDefault();
                    const newClienteData={
                        nombre:this.state.nombre,
                        ccnit:this.state.ccnit,
                        email:this.state.email,
                        tipouser:this.state.tipouser,
                        telefono:this.state.telefono,
                        direccion:this.state.direccion,
                        ciudad:this.state.ciudad,
                        codigoreps:this.state.codigoreps,
                        sede:this.state.sede,
                       
                     
                  };
            console.log(newClienteData)
            this.props.editCliente(newClienteData,this.props.clienteId);
          
          };
        

  render() {
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;

    return (
        <div>
        <Fragment>
        <div className="div-botoneliminarcliente">
    <MyButton tip="Editar Información" onClick={this.handleOpen}>
    <FaPencilAlt color="#03178C" className="icon-eliminarcliente"/>
     </MyButton>
     </div>
     <Dialog
     open={this.state.open}
     onClose={this.handleClose}
     fullWidth
     maxWidth="sm"
    >
   
    <DialogTitle>Editar Detalles Prestador</DialogTitle>
                 
                <DialogContent>
                    <form>
                    <TextField className="login-input-email"
                      required
                      type="text"
                      name="nombre"
                      id="nombre"
                      label="Nombre Completo"
                      value={this.state.nombre}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombre}
                      error={errors.nombre?true:false}
                      />
                      <br/>
                      <TextField className="login-input-email"
                      required
                      type="email"
                      name="email"
                      id="email"
                      label="Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.email}
                      error={errors.email?true:false}
                      />
                       <TextField className="login-input-email"
                      required
                      type="number"
                      name="ccnit"
                      id="ccnit"
                      label="Numero de Documento CC/NIT"
                      value={this.state.ccnit}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ccnit}
                      error={errors.ccnit?true:false}
                      
                      />

                    <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Tipo Prestador</InputLabel>
                            <Select
                            required
                         name="tipouser"
                         id="tipouser"
                         value={this.state.tipouser}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="IPS">IPS</MenuItem>
                            <MenuItem value="Consultorio">Consultorio</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipouser}</FormHelperText>
                        </FormControl>

                        <TextField className="login-input-email"
                      required
                      type="tel"
                      name="telefono"
                      id="telefono"
                      label="Número de Contacto"
                      value={this.state.telefono}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.telefono}
                      error={errors.telefono?true:false}
                      />
                    <TextField className="login-input-email"
                      required
                      type="text"
                      name="direccion"
                      id="direccion"
                      label="Dirección"
                      value={this.state.direccion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.direccion}
                      error={errors.direccion?true:false}
                      />
                         <TextField className="login-input-email"
                      required
                      type="text"
                      name="ciudad"
                      id="ciudad"
                      label="Ciudad"
                      value={this.state.ciudad}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ciudad}
                      error={errors.ciudad?true:false}
                      />
                       <TextField className="login-input-email"
                      required
                      type="number"
                      name="codigoreps"
                      id="codigoreps"
                      label="Código REPS del prestador"
                      value={this.state.codigoreps}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.codigoreps}
                      error={errors.codigoreps?true:false}
                      
                      />

<TextField className="login-input-email"
                      required
                      type="text"
                      name="sede"
                      id="sede"
                      label="Nombre de la Sede"
                      value={this.state.sede}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.sede}
                      error={errors.sede?true:false}
                      />

                       
                    </form>
                </DialogContent>


                <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Crear Cliente" onClick={this.handleSubmit}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
            
            
          </DialogActions>
    </Dialog>
    </Fragment>
    </div>
    )
  }
}
DetallesCliente.propTypes = {
    editCliente: PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

  const mapActionsToProps={editCliente,clearErrors};

  const mapStateToProps = (state) => ({
    equipo: state.data.equipo.credentials,
    UI:state.UI
  });
  
 export default connect(mapStateToProps,mapActionsToProps)(DetallesCliente);
