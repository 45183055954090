import React, { Component } from 'react';

import './ListaCalibracionesAsis.css'; 

import {getCalibraciones,clearErrors,deleteCalibracion,subirReporteCalibracion,deleteDataCalibracion} from '../../../redux/actions/dataactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft,FaPlus } from "react-icons/fa";
import MaterialTable from 'material-table';

import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachFileIcon from '@material-ui/icons/AttachFile';




export class ListaCalibracionesAsis extends Component {
  constructor(){
    super();
    this.state={
      selectedFile: null,
      progid:'',
      openatach: false,
      open:false,
      opendelete:false,
      fecha:'',
      horainicio:'',
      horafinal:'',
      numcertificado:'',
      reporteurl:'',
      reportefilename:'',
      calibracionid:'',
    }
  }
  

  componentDidMount(){
    this.props.getCalibraciones(this.props.equipoId);
   }


//********************Ver reporte

handleOpen=(rawdata)=>{
  this.mapUserDetailsToState(rawdata);
  this.setState({open:true})
}
handleClose=()=>{
  this.setState({open:false});
};

mapUserDetailsToState = (calibracion) => {
  this.setState({
    fecha:calibracion.fecha?calibracion.fecha:'',
    horainicio:calibracion.horainicio?calibracion.horainicio:'',
    horafinal:calibracion.horafinal?calibracion.horafinal:'',
    numcertificado:calibracion.numcertificado?calibracion.numcertificado:'',
    reporteurl:calibracion.reporteurl?calibracion.reporteurl:'',
    
    
  });
};







   
    


    render() {

        
        const {calibraciones,loadingcal}= this.props.data; 
        
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),            
            AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />),
            AttachFileIcon:forwardRef((props, ref) => <AttachFileIcon {...props} ref={ref} />)
          };
        
        const columns=[
            {title:'Fecha',field:'fecha'},
            {title:'No. Certificado',field:'numcertificado'},
            {title:'Link Certificado',field:'reporteurl', render: rowData => 
            rowData.reporteurl==='false'?<a>Sin Certificado Adjunto</a>:<a href={rowData.reporteurl} target="_blank" rel="noopener noreferrer">Link del Certificado</a>
              },
           
           
        ]


        
     let mapeocalibraciones=!loadingcal ?(
         <div>
    <MaterialTable
            columns={columns}
            data={calibraciones}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Calibraciones Realizadas</h2>
            }
            icons={tableIcons}
    
            actions={[
             
                {
                    icon: tableIcons.AssignmentIcon,
                    tooltip: 'Reporte de Calibración',
                    onClick: (event, rowData) => {
                        this.handleOpen(rowData);
                    }
                  }
              ]}
              options={{
                cellStyle: {
                    fontSize: 12,
                    fontWeight:500,
                    color:'#696B6D'
                  
                 
                  },
                  headerStyle: {
                    fontSize: 14,
                    fontWeight: 'bolder',
                    color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                  }
              
              }}
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
      
      let linkcalibracion=this.state.reporteurl==='false'?<a>Sin Certificado Adjunto</a>:<a href={this.state.reporteurl} target="_blank" rel="noopener noreferrer">Link del Certificado</a>
        return (
            <div className="listamtoprog-container">
              
            <div className="listamtoprog-table">
            {mapeocalibraciones}
            </div>

           

                <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Reporte de Calibración</DialogTitle>
            <DialogContent>
            <form>
            <div className="div-accordion-container">
          <TextField className="login-input-email"
                      required
                      type="date"
                      name="fecha"
                      id="fecha"
                      label="Fecha de Realización"
                      value={this.state.fecha}
                      onChange={this.handleChange}
                      min="2019-01-01" max="2022-01-01"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      />
          <div className="div-input-doble-mtoprog">
          <TextField className="login-input-email"
                      required
                      type="time"
                      name="horainicio"
                      id="horainicio"
                      label="Hora de Inicio"
                      value={this.state.horainicio}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      />
            <TextField className="login-input-email"
                      required
                      type="time"
                      name="horafinal"
                      id="horafinal"
                      label="Hora Final"
                      value={this.state.horafinal}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      />
        
            </div>

            <TextField className="login-input-email"
                      required
                      type="text"
                      name="numcertificado"
                      id="numcertificado"
                      label="Numero del Certificado"
                      value={this.state.numcertificado}
                      onChange={this.handleChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      />
            
            <div>
              {linkcalibracion}
            </div>
             
          </div>
 
 
       
            </form>
      
  


           
              
            </DialogContent>

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
           
           </DialogActions>
            </Dialog>


           



          </div>
        )
    }
}



ListaCalibracionesAsis.propTypes = {
    
    getCalibraciones: PropTypes.func.isRequired,
    subirReporteCalibracion:PropTypes.func.isRequired,
    deleteCalibracion:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteDataCalibracion:PropTypes.func.isRequired
  };

const mapActionsToProps={getCalibraciones,clearErrors,deleteCalibracion,deleteDataCalibracion,subirReporteCalibracion};

const mapStateToProps = (state)=>({
    data:state.data,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(ListaCalibracionesAsis);

