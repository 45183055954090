import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {getTareasClienteFiltrado,postTareaCliente,getClienteTareas} from '../../../redux/actions/dataactions';
import {clearErrors} from '../../../redux/actions/dataactions';
import CircularProgress from '@material-ui/core/CircularProgress';



import { FaArrowLeft,FaPlus,FaFirstAid, FaChartBar, FaCheckCircle, FaCheck } from "react-icons/fa";
import './CrearTarea.css';

export class CrearTarea extends Component {
    constructor(){
        super();
        this.state={
            handlecreador:'', 
            nombretarea:'', 
            detalles:'',
            fechaplazo:'', 
            horaplazo:'',            
            prioridad:'',
            handleasignado:'', 
            tipotarea:'',
            errors:{},
            aprobado:{},
            open:false
        };
        
    };

    static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

 

    handleOpen=()=>{
        this.props.getClienteTareas(this.props.clienteId)
        this.setState({handlecreador:'', 
        nombretarea:'', 
        detalles:'',
        fechaplazo:'', 
        horaplazo:'',            
        prioridad:'',
        handleasignado:'', 
        tipotarea:'',open:true,aprobado:{}});
        };


 handleClose=()=>{


    if(this.state.aprobado.mensaje){
        //getEquipos this.handleEditProgMto();
        this.props.getTareasClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
       
        this.setState({
            handlecreador:'', 
            nombretarea:'', 
            detalles:'',
            fechaplazo:'', 
            horaplazo:'',            
            prioridad:'',
            handleasignado:'', 
            tipotarea:'',
            open:false,errors:{},aprobado:{}});
    }else{
        this.setState({
            
            open:false,errors:{},aprobado:{}});
    }
   
   
                
    };



        handleChange=(event)=>{
            this.setState({[event.target.name]:event.target.value});
            };


    handleSubmit=(event)=>{
            event.preventDefault();
                const userhandle=this.props.user.credentials.handle; 

            const newUserData={
                handlecreador:userhandle, 
                nombretarea:this.state.nombretarea, 
                detalles:this.state.detalles,
                fechaplazo:this.state.fechaplazo, 
                horaplazo:this.state.horaplazo, 
                prioridad:this.state.prioridad,
                handleasignado:this.state.handleasignado, 
                tipotarea:this.state.tipotarea

             
          };

          console.log(newUserData)

            this.props.postTareaCliente(newUserData,this.props.clienteId);
           
           
         
     }

    render() {
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;
        const {ingenieros}=this.props.data.cliente; 
        return (
          

        
            <Fragment>

                  <div className='div-card-link' onClick={this.handleOpen}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                           Crear Tarea
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon">
                                            <FaFirstAid color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>
  
  
                </div>
       
             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>¡Creación de Tarea Exitosa!</h2>
              </div>
                
                ) : (<div>

<DialogTitle className="text-title-accorion">Crear Tarea</DialogTitle>
               
               <DialogContent>
   
                       <form>
                       <div className="flex-accordion">
             <h4 className="text-title-accorion">Nombre de la Tarea</h4>
             <TextField className="login-input-email"
                         required
                         type="text"
                         name="nombretarea"
                         id="nombretarea"
                         label="Nombre de la Tarea"
                         value={this.state.nombretarea}
                         onChange={this.handleChange}
                         fullWidth
                         helperText={errors.nombretarea}
                         error={errors.nombretarea?true:false}
                         minRows={1}
                         
                         />
       
                         <br/>
           
   
             </div>

             <div className="flex-accordion">
             <h4 className="text-title-accorion">Detalles de la Tarea</h4>
             <TextField className="login-input-email"
                         required
                         type="text"
                         name="detalles"
                         id="detalles"
                         label="Detalles de la Tarea"
                         value={this.state.detalles}
                         onChange={this.handleChange}
                         fullWidth
                         helperText={errors.detalles}
                         error={errors.detalles?true:false}
                         minRows={3}
                         
                         />
       
                         <br/>
           
   
             </div>
                       <div className="flex-accordion">
                       <h4 className="text-title-accorion">Fecha de Plazo de la Tarea</h4>
                       <div className="input_divido">
   
   
                         <div className="input_divido_children">
                         <TextField className="login-input-email"
                         required
                         type="date"
                         name="fechaplazo"
                         id="fechaplazo"
                         label="Fecha de Vencimiento Tarea"
                         value={this.state.fechaplazo}
                         onChange={this.handleChange}
                         min="2019-01-01" max="2029-01-01"
                         fullWidth
                         helperText={errors.fechaplazo}
                         error={errors.fechaplazo?true:false}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         />
                        
                           </div>

                           <div className="input_divido_children">
                         <TextField className="login-input-email"
                         required
                         type="time"
                         name="horaplazo"
                         id="horaplazo"
                         label="Hora de Vencimiento Tarea"
                         value={this.state.horaplazo}
                         onChange={this.handleChange}
                        
                         fullWidth
                         helperText={errors.horaplazo}
                         error={errors.horaplazo?true:false}
                         InputLabelProps={{
                           shrink: true,
                         }}
                         />
                        
                           </div>
                           
                           </div>
                          
   
                           </div>

                           <div className="flex-accordion">
             <h4 className="text-title-accorion">Prioridad y Tipo de Tarea</h4>
             <div className="input_divido">
             <div className="input_divido_children">
                 <FormControl className="formcontrol-nuevocliente" error={errors.prioridad?true:false}>
                               <InputLabel> Prioridad</InputLabel>
                               <Select
                               required
                            name="prioridad"
                            id="prioridad"
                            value={this.state.prioridad}
                               onChange={this.handleChange}
                               fullWidth
                               >
                               <MenuItem value="">
                                   <em></em>
                               </MenuItem>
                               <MenuItem value="Alta">Alta</MenuItem>
                               <MenuItem value="Media">Media</MenuItem>
                               <MenuItem value="Baja">Baja</MenuItem>
                               
                               </Select>
                               <FormHelperText>{errors.prioridad}</FormHelperText>
                           </FormControl>
                   </div>
                   <div className="input_divido_children">
                 <FormControl className="formcontrol-nuevocliente" error={errors.tipotarea?true:false}>
                               <InputLabel> Tipo de Tarea</InputLabel>
                               <Select
                               required
                            name="tipotarea"
                            id="tipotarea"
                            value={this.state.tipotarea}
                               onChange={this.handleChange}
                               fullWidth
                               >
                               <MenuItem value="">
                                   <em></em>
                               </MenuItem>
                               <MenuItem value="Biomedica">Biomedica</MenuItem>
                               <MenuItem value="Infraestructura">Infraestructura</MenuItem>
                               <MenuItem value="Computo">Computo</MenuItem>
                               <MenuItem value="Mobiliario">Mobiliario</MenuItem>
                               <MenuItem value="Industrial">Industrial</MenuItem>
                              
                               </Select>
                               <FormHelperText>{errors.tipotarea}</FormHelperText>
                           </FormControl>
                   </div>

                   </div>
             </div>
                 

                  
             

             <div className="flex-accordion">
             <h4 className="text-title-accorion">Personal Designado</h4>

                    {ingenieros && ingenieros.length > 0 ? (
                    <FormControl fullWidth error={errors && errors.handleasignado ? true : false}>
                        <InputLabel id="tecnico-label">Personal designado para esta tarea</InputLabel>
                        <Select
                        required
                        name="handleasignado"
                        id="handleasignado"
                        value={this.state.handleasignado}
                        onChange={this.handleChange}
                        fullWidth
                        error={errors.handle ? true : false}
                        >
                        {ingenieros.map((ingeniero, index) => (
                            <MenuItem key={index} value={ingeniero}>{ingeniero}</MenuItem>
                        ))}
                        </Select>
                        {errors && errors.handleasignado && (
                        <FormHelperText>{errors.handleasignado}</FormHelperText>
                        )}
                    </FormControl>
                    ) : (
                    <p> Este perfil de ingeniería no tiene ténicos asociados, por favor cree uno o más téncicos para que pueda asociarlos a diferentes prestadores</p>
                    )}
                    </div>
   
           
           
                       
   
            
         
          
           
   
    </form>
                         
               
               </DialogContent>
                </div>)}
            
          
          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Crear Tarea" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            

            
          </DialogActions>
            </Dialog>
            </Fragment>
            
        )
    }
}

CrearTarea.propTypes = {
    getTareasClienteFiltrado: PropTypes.func.isRequired,
    postTareaCliente: PropTypes.func.isRequired,
    getClienteTareas:PropTypes.func.isRequired,
    clienteId: PropTypes.string.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

const mapActionsToProps={getTareasClienteFiltrado,getClienteTareas,postTareaCliente,clearErrors};

const mapStateToProps = (state)=>({
    user:state.user,
    data:state.data,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(CrearTarea);
