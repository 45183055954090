import React, { Component, useState }  from 'react'
import { Link } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { PDFViewer } from '@react-pdf/renderer';
import './CronogramaMtoPDF.css';
import CronogramaMtoDocuPDF from './CronogramaMtoDocuPDF';










export class CronogramaMtoPDF extends Component {
    constructor(){
        super();
        this.state={
            equiposmto:{},
            errors:{},
            open:false,
            verWeb:false,
            verPDF:true
        };
        
    };
    
    componentDidMount(){
       
       
       
      
      }

      handleOpen = () => {
        if(this.state.verWeb){
            this.setState({ verWeb: false });
        }else{
            this.setState({ verWeb: true,verPDF:false});
        }
        
      };

      handleOpenPDF = () => {
        if(this.state.verPDF){
            this.setState({ verPDF: false });
        }else{
            this.setState({ verPDF: true,verWeb:false});
        }
        
      };

  render() {
    const {equiposmto,loading}= this.props.data;
    
    


    

    return (
      <div className='div-inventaripdfgeneral'>
      
      <div className='div-inventaripdftitulo'>


      <div className="div_boton">
                <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}/cronomtoperfil`}>
                <MyButton tip="Volver">
                 <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
                </MyButton>
                </Link>
                </div> 

        
        </div>

        <div className='div-inventariopdfcontent'>
        {equiposmto? (
        <>
        
        {this.state.verPDF ?  <PDFViewer style={{ width: "100%", height: "90vh" }}>
            <CronogramaMtoDocuPDF equipos={equiposmto} />
          </PDFViewer>  : null}
        
        </>
        ) : null}
        </div>
       
      </div>
    )
  }
}

CronogramaMtoPDF.propTypes={
  
  data: PropTypes.object.isRequired,
}
const mapStateToProps=state=>({
    data:state.data,
    datainfra:state.datainfra
})
export default connect(mapStateToProps) (CronogramaMtoPDF);



