import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'; 
import MyButton from '../../../utils/MyButton';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import {FaTrashAlt,FaArrowLeft} from "react-icons/fa";

//import {eliminarCliente} from '../../../redux/actions/dataactions';

import {deleteDocLegal} from '../../../redux/actions/dataactions';


import './DocumentoEliminar.css';

export class DocumentoEliminar extends Component {

    state={
        open:false
    };
    handleOpen=()=>{
        this.setState({open:true});
    }
    handleClose=()=>{
        this.setState({open:false});
    }
    delete = () => { 
        //this.props.deleteScream(this.props.screamId);
        console.log(this.props.clienteId);
        console.log(this.props.docId);
        this.props.deleteDocLegal(this.props.docId,this.props.clienteId);
        this.setState({open:false});
    }

    render() {
        
        return (
            <div>
                 <Fragment>
                 <div className="div-botoneliminarcliente">
                <MyButton tip="Eliminar Documento" onClick={this.handleOpen} >
                  <FaTrashAlt color="#03178C" className="icon-eliminarcliente"></FaTrashAlt>
                  </MyButton>
                </div>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    fullWidth
                    maxWidth="sm"
                    >
                        <DialogTitle>
                            ¿Estás seguro de eliminar este Documento? 
                        </DialogTitle>

                        <DialogActions>
                        <DialogActions>
                    <MyButton tip="Cerrar" onClick={this.handleClose}>
                    <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                    </MyButton>
                    <MyButton tip="Borrar Cliente" onClick={this.delete}>
                    <FaTrashAlt  color="#03178C" className="icon-formulario"/>
                    </MyButton>
                    
                </DialogActions>
                            
                            
                        </DialogActions>

                    </Dialog>




                 </Fragment>
            </div>
        )
    }
}

DocumentoEliminar.propTypes = {
    deleteDocLegal: PropTypes.func.isRequired,
    clienteId: PropTypes.string.isRequired  
  };

const mapActionsToProps={deleteDocLegal};

const mapStateToProps = (state)=>({
    user:state.user
})



export default connect(mapStateToProps,mapActionsToProps)(DocumentoEliminar);