import React, { Component } from 'react';
import './CompletarSolicitudCliente.css';
import MyButton from '../../../utils/MyButton';
import {FaArrowCircleLeft,FaPlus,FaSignInAlt} from "react-icons/fa";
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import dayjs from 'dayjs'; 
import relativeTime from 'dayjs/plugin/relativeTime'; 

import {getSolicitudCompra,clearErrors} from '../../../redux/actions/dataasistencialactions';
import AddItemSolicitud from '../DashboardAsistencial/AddItemSolicitud';
 
import EnviarSolicitudClient from './EnviarSolicitudClient';
//import PublicarCotizacion from './PublicarCotizacion';


export class CompletarSolicitudCliente extends Component {
    componentDidMount(){
        this.props.getSolicitudCompra(this.props.match.params.solicitudid);
       }

  render() {

    const {solicitud,loadingdataasistencial}= this.props.dataAsistencial;  
    dayjs.extend(relativeTime); 

    let cargarSolicitud= !loadingdataasistencial ? (
        <div className="card-equipo">
            <div className="div-informacion-market">
            

            <div className="div-infocontainer-market">
            <div className="div-titulocliente">
       
            <h3 className="text-titulo-cliente">{solicitud.credentials.titulo}</h3>
        
            </div>

            <div className="div-equipo-info-market">
                <div className="div-perfil-info">
                <hr className="clientes_separator"></hr>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Estado: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.estado}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Fecha de Solicitud: </h5>    
                <p className="perfilequipo-text">{dayjs(solicitud.credentials.datecreation).fromNow()}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Area: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.area}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Servicio: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.servicio}</p>
                </div>

               

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Categoria: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.categoria}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Tipo Cotizacion: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.tipocotizacion}</p>
                </div>

                
                

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Nombre Responsable: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.nombreresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Cargo Responsable: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.cargoresponsable}</p>
                </div>


                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Telefono Responsable: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.cargoresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Email Responsable: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.emailresponsable}</p>
                </div>

               

                          
                </div>

                <div className='terminos-container-market'>

                
                <hr className="clientes_separator"></hr> 
                

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Observaciones: </h5>    
                <p className="perfilequipo-text">{solicitud.credentials.observaciones}</p>
                </div>

                </div>
            </div>

           </div>
            </div>
            </div>

          
         
       

        
        )
        
        :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);
    

        let mapeoitems=!loadingdataasistencial?
        (solicitud.credentials.items!==undefined?(
            solicitud.credentials.items.map((item)=>
            <div key={item.datecreation} className="item-market-card">
            <div className='item-market-container'>
        
          
            <div className='item-market-item'>
          
            <h3 className="titulo-market-text">Nombre</h3>
            <h5 className="item-market-text">{item.nombre}</h5>
            </div>
        
            <div className='item-market-item'>
          
            <h3 className="titulo-market-text">Marca</h3>
            <h5 className="item-market-text">{item.marca}</h5>
            </div>
        
            <div className='item-market-item'>
          
          <h3 className="titulo-market-text">Cantidad</h3>
          <h5 className="item-market-text">{item.cantidad}</h5>
          </div>
        
          <div className='item-market-descripcion'>
          
          <h3 className="titulo-market-text">Descripcion</h3>
          <h5 className="item-market-text">{item.descripcion}</h5>
          </div>
           
          </div>
          
            </div>
            )
           
        
        ):( <div className="item-market-card">
            <div className='item-market-container'>
            <div className='item-market-descripcion'>
            <h3 className="titulo-market-text">Añade un Item para Empezar</h3>
        </div>
            </div>
            </div>)):
        (<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>)
           

 
    return (
        <div>

        

        
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Solicitud de Compra</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}/solicitudesinenviar`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>


        <div className='container_content_items'>

        {cargarSolicitud}

        </div>
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Añade un Item</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <AddItemSolicitud solicitudId={this.props.match.params.solicitudid}></AddItemSolicitud>
        
        </div>
        </div>
        
        <div>
            {mapeoitems}
            <div className='div-publicar-market'>
            <EnviarSolicitudClient solicitudId={this.props.match.params.solicitudid} history={this.props.history} clienteId={this.props.match.params.clienteId}/>
             </div>
        </div>

       

        </div>
      
    )
  }
}

CompletarSolicitudCliente.propTypes = {
    
    getSolicitudCompra: PropTypes.func.isRequired,
    dataAsistencial:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    
  };

const mapActionsToProps={getSolicitudCompra,clearErrors};

const mapStateToProps = (state)=>({
    dataAsistencial:state.dataAsistencial,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(CompletarSolicitudCliente);
