import React, { Component, Fragment  } from 'react';
import './ProgramarCalibracion.css'; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus,FaCalendarDay,FaTrash } from "react-icons/fa";

import {deleteEquipo,progCalibracion,clearErrors} from '../../../redux/actions/dataactions';

export class BorrarEquipo extends Component {

  
    constructor(){
        super();
        this.state={
            fecha:'',
            hora:'',
            duracion:'',
            errors:{},
            open:false
        };
        
    };
    static getDerivedStateFromProps(props, state) {
      return props.UI.errors ? {errors: props.UI.errors} : null;
  }


    handleOpenDelete=()=>{
        this.setState({opendelete:true});
       
        };
  
  handleCloseDelete=()=>{
           // this.props.clearErrors();
            this.setState({opendelete:false,errors:{}});
            };
  handleDelete=()=>{
  
 this.props.deleteEquipo(this.props.clienteId,this.props.equipoId,this.props.history);
 // this.handleCloseDelete();
  }

    

    render() {
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;

        return (
            <div>
                <Fragment>
            <MyButton tip="Borrar Equipo" onClick={this.handleOpenDelete}>
            <FaTrash color="#03178C" className="icon-crearcliente"/>
             </MyButton>

             <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Estas seguro de borrar este Equipo?</DialogTitle>

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
              
                          <MyButton tip="Borrar Equipo" onClick={this.handleDelete}>
                          <FaTrash color="#03178C" className="icon-formulario"/>
                           </MyButton>
           </DialogActions>
            </Dialog>

  

             </Fragment>

            </div>
        )
    }
}

BorrarEquipo.propTypes={
    deleteEquipo:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired
  };
  const mapStateToProps=(state)=>({
    UI:state.UI,
    equipo: state.data.equipo.credentials
  });
  
  export default connect (mapStateToProps,{deleteEquipo,clearErrors}) (BorrarEquipo)
  
  
