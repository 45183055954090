import {POST_OFERTA_RECHAZAR,POST_OFERTA_APROBAR,SET_OFERTA,SET_OFERTAS,POST_OFERTA,POST_ESTADO_PUBLICAR,POST_ITEM,LOADING_DATA_SALES,POST_COTIZACION,SET_COTIZACIONES,SET_COTIZACION} from '../types';
const initialState={
    
    loadingdatasales:true,
    cotizacion:[],
    cotizaciones:[],
    oferta:[],
    ofertas:[]
}

export default function (state=initialState,action){
    switch(action.type){
        case LOADING_DATA_SALES:
            return {
                ...state,
                loadingdatasales:true
            }
      
   
        case POST_COTIZACION:
            return{
                ...state,
                cotizaciones: [action.payload, ...state.cotizaciones]
            } 

        case POST_OFERTA:
            return{
                ...state,
                ofertas:[action.payload,...state.ofertas]
            }

        case SET_COTIZACIONES:
            return{
                 ...state,
                cotizaciones:action.payload,     
                loadingdatasales:false           
                    
            }
        case SET_OFERTAS:
            return{
                ...state,
                ofertas:action.payload,
                loadingdatasales:false
            }
        case SET_COTIZACION:
            return{
                ...state,
                cotizacion:action.payload,
                loadingdatasales:false
            }

        case SET_OFERTA:
            return{
                ...state,
                oferta:action.payload,
                loadingdatasales:false
            }
        case POST_ITEM:
            return{
                ...state,
                cotizaciones: [action.payload, ...state.cotizaciones]
            }
        case POST_ESTADO_PUBLICAR:
            return{
                ...state,
            }
        case POST_OFERTA_APROBAR:
            return{
                ...state
            }
        case POST_OFERTA_RECHAZAR:
            return{
                ...state
            }
       
            

        default:
            return{
                ...state
            }

        
        
            
    }
}