import React, { Component, Fragment  } from 'react';
import MyButton from '../../../utils/MyButton';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './CargarFirmaCliente.css'; 



import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FaPlusCircle,FaArrowLeft,FaPlus, FaSignature} from "react-icons/fa";
import { subirFirmaCliente } from '../../../redux/actions/dataactions';


export class CargarFirmaCliente extends Component {
    state = { 
        // Initially, no file is selected 
        selectedFile: null,
        open: false
      }; 
    handleOpen=()=>{
        this.setState({open:true});
    };
    
    handleClose=()=>{
       this.setState({open:false});
    };

    onFileChange = event => { 
     
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] }); 
       
    }; 

    onFileUpload = () => { 
     
        // Create an object of formData 
        const formData = new FormData(); 
       
        // Update the formData object 
        formData.append( 
          "myFile", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        ); 
        console.log(formData);
        console.log(this.props.clienteId);
        this.props.subirFirmaCliente(formData,this.props.clienteId);
        //Imagen subida
      
        //
        this.setState({open:false});

    }; 
       

    render() {
        return (
            <div>
               <Fragment>
               <div className="div-botoneliminarcliente">
                <MyButton tip="Subir Firma Cliente" onClick={this.handleOpen}>
                <FaSignature color="#03178C" className="icon-eliminarcliente"/>
                </MyButton>
                </div>

                <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                 fullWidth
                maxWidth="sm"
                >
                 <DialogTitle>Subir Firma Cliente</DialogTitle>
                 
                <DialogContent>
                
                </DialogContent>
                <input type="file" onChange={this.onFileChange} accept="image/png,image/jpg"   /> 
                <DialogActions>
                    <MyButton tip="Cerrar" onClick={this.handleClose}>
                    <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                    </MyButton>
                    <MyButton tip="Subir Imagen" onClick={this.onFileUpload}>
                    <FaPlus  color="#03178C" className="icon-formulario"/>
                    </MyButton>
                    
                </DialogActions>

                </Dialog>

            


               </Fragment>
                
            </div>
        )
    }
}

//en propiedades recibe el cliente id 
CargarFirmaCliente.propTypes = {
    subirFirmaCliente: PropTypes.func.isRequired,
    handleuser: PropTypes.string.isRequired  
  };

const mapActionsToProps={subirFirmaCliente};

const mapStateToProps = (state)=>({
    user:state.user
})



export default connect(mapStateToProps,mapActionsToProps)(CargarFirmaCliente);

