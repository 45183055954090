import React, { Component, Fragment } from 'react'
import './NuevoCliente.css';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaUserPlus,FaArrowLeft,FaPlus, FaCheck, FaCheckCircle } from "react-icons/fa";

import {postCliente,getClientes,clearErrors} from '../../../redux/actions/dataactions';

export class NuevoCliente extends Component {
    constructor(){
        super();
        this.state={
            nombre:'',
            ccnit:'',
            email:'',
            tipouser:'',
            telefono:'',
            direccion:'',
            ciudad:'',
            codigoreps:'',
            sede:'',
            errors:{},
            aprobado:{},
            open:false
        };
        
    };

    static getDerivedStateFromProps(props, state) {
      const newState = {};
  
      if (props.UI.errors) {
          newState.errors = props.UI.errors;
      }
  
      if (props.UI.aprobado) {
         newState.aprobado = props.UI.aprobado;
      }
  
      return Object.keys(newState).length > 0 ? newState : null;
  }
 
    handleOpen=()=>{
    this.setState({open:true,
       errors:{},
      aprobado:{},});
    };

    handleClose=()=>{
      if(this.state.aprobado.mensaje){
          //this.handleEditProgMto();
          //get prog mtos 
          this.props.getClientes();
          this.setState({open:false,errors:{},aprobado:{}});
       }else{
        this.setState({open:false,errors:{},aprobado:{}});
       }
      }

    handleChange=(event)=>{
    this.setState({[event.target.name]:event.target.value});
    };

    handleSubmit=(event)=>{
    event.preventDefault();
    const newUserData={
      nombre:this.state.nombre,
      ccnit:this.state.ccnit,
      email:this.state.email,
      tipouser:this.state.tipouser,
      telefono:this.state.telefono,
      direccion:this.state.direccion,
      ciudad:this.state.ciudad,
      codigoreps:this.state.codigoreps,
      sede:this.state.sede    
  };
    this.props.postCliente(newUserData); 
    //Aqui se postea al cliente

    }

    

    render() {
      const { UI:{loading}}=this.props; 
      const {errors}=this.state; 

        return (
            <Fragment>
            <MyButton tip="Crear Prestador" onClick={this.handleOpen}>
            <FaUserPlus color="#03178C" className="icon-crearcliente"/>
             </MyButton>

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Prestador Cargado Exitosamente</h2>
              </div>
                
                ) : (<div>
 <DialogTitle>Crear Nuevo Prestador</DialogTitle>
                 
                 <DialogContent>
                     <form>
                     <TextField className="login-input-email"
                       required
                       type="text"
                       name="nombre"
                       id="nombre"
                       label="Nombre Completo"
                       value={this.state.nombre}
                       onChange={this.handleChange}
                       fullWidth
                       helperText={errors.nombre}
                       error={errors.nombre?true:false}
                       />
                       <br/>
                       <TextField className="login-input-email"
                       required
                       type="email"
                       name="email"
                       id="email"
                       label="Email"
                       value={this.state.email}
                       onChange={this.handleChange}
                       fullWidth
                       helperText={errors.email}
                       error={errors.email?true:false}
                       />
                        <TextField className="login-input-email"
                       required
                       type="number"
                       name="ccnit"
                       id="ccnit"
                       label="Numero de Documento CC/NIT"
                       value={this.state.ccnit}
                       onChange={this.handleChange}
                       fullWidth
                       helperText={errors.ccnit}
                       error={errors.ccnit?true:false}
                       
                       />
 
                     <FormControl className="formcontrol-nuevocliente">
                             <InputLabel>Tipo Prestador</InputLabel>
                             <Select
                             required
                          name="tipouser"
                          id="tipouser"
                          value={this.state.tipouser}
                             onChange={this.handleChange}
                             fullWidth
                             >
                             <MenuItem value="">
                                 <em></em>
                             </MenuItem>
                             <MenuItem value="IPS">IPS</MenuItem>
                             <MenuItem value="Consultorio">Consultorio</MenuItem>
                             </Select>
                             <FormHelperText>{errors.tipouser}</FormHelperText>
                         </FormControl>
 
                         <TextField className="login-input-email"
                       required
                       type="tel"
                       name="telefono"
                       id="telefono"
                       label="Número de Contacto"
                       value={this.state.telefono}
                       onChange={this.handleChange}
                       fullWidth
                       helperText={errors.telefono}
                       error={errors.telefono?true:false}
                       />
                     <TextField className="login-input-email"
                       required
                       type="text"
                       name="direccion"
                       id="direccion"
                       label="Dirección"
                       value={this.state.direccion}
                       onChange={this.handleChange}
                       fullWidth
                       helperText={errors.direccion}
                       error={errors.direccion?true:false}
                       />
                          <TextField className="login-input-email"
                       required
                       type="text"
                       name="ciudad"
                       id="ciudad"
                       label="Ciudad"
                       value={this.state.ciudad}
                       onChange={this.handleChange}
                       fullWidth
                       helperText={errors.ciudad}
                       error={errors.ciudad?true:false}
                       />
                        <TextField className="login-input-email"
                       required
                       type="number"
                       name="codigoreps"
                       id="codigoreps"
                       label="Código REPS del prestador"
                       value={this.state.codigoreps}
                       onChange={this.handleChange}
                       fullWidth
                       helperText={errors.codigoreps}
                       error={errors.codigoreps?true:false}
                       
                       />
 
 <TextField className="login-input-email"
                       required
                       type="text"
                       name="sede"
                       id="sede"
                       label="Nombre de la Sede"
                       value={this.state.sede}
                       onChange={this.handleChange}
                       fullWidth
                       helperText={errors.sede}
                       error={errors.sede?true:false}
                       />
 
                        
                     </form>
                 </DialogContent>

                </div>)}
           
                <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#03178C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Crear Cliente" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
           </DialogActions>
              

            </Dialog>


            </Fragment>
        )
    }
}

NuevoCliente.propTypes={
  postCliente:PropTypes.func.isRequired,
  clearErrors:PropTypes.func.isRequired,
  UI:PropTypes.object.isRequired,
  getClientes:PropTypes.func.isRequired,
};
const mapStateToProps=(state)=>({
  UI:state.UI
});

export default connect (mapStateToProps,{postCliente,getClientes,clearErrors}) (NuevoCliente)


