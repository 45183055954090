import React, { useEffect,useState } from 'react';
import './PerfilArea.css'; 
import MyButton from '../../../utils/MyButton';
import {FaArrowCircleLeft,FaCogs,FaFilePdf, FaPhoneSquareAlt, FaRegCalendarCheck, FaRocket, FaSortDown, FaSortUp, FaThermometerHalf, FaWrench} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';





import { Button } from '@material-ui/core';
import { getArea } from '../../../redux/actions/infraactions';
import EditArea from './EditArea';
import ProgMtoInfra from './ProgMtoInfra';
import TablaProgMtoArea from './TablaProgMtoArea';
import TablaMtoArea from './TablaMtoArea';
import MtoCorrInfra from './MtoCorrInfra';
import TablaCorrArea from './TablaCorrArea';
import TablaSolicitudesInfra from './TablaSolicitudesInfra';
import CambiarEstadoArea from './CambiarEstadoArea';


function PerfilArea({ getArea, datainfra, match }) {
  const history = useHistory();
  const [accion, setAccion] = useState(null);


  const { area, loadingdata } = datainfra;
  
  const [perfilInfoVisible, setPerfilInfoVisible] = useState(false); // Estado para controlar la visibilidad

  const [mostrarComponente, setMostrarComponente] = useState(false); // Estado para controlar la visibilidad del componente

  const toggleAccion = (nuevaAccion) => {
    if (nuevaAccion === accion && mostrarComponente) {
      // Si se hace clic en el botón que ya está seleccionado y el componente está visible, ocultarlo
      setAccion("");
      setMostrarComponente(false);
    } else {
      setAccion(nuevaAccion);
      setMostrarComponente(true);
    }
  };

  
  useEffect(() => {
    console.log(match.params.areaId);
    getArea(match.params.areaId,match.params.clienteId);
  }, [getArea, match.params.areaId]);
      

        let cargarArea= !loadingdata && area.credentials ? (
            <div className="card-equipo">
                <div className="div-informacion">
                <div className="div-imagencliente">
                <img
                src={
                  area.credentials.imagenurl === undefined || area.credentials.imagenurl === "undefined"
                    ? 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media'
                    : area.credentials.imagenurl
                }
                alt="equipo"
                className="img-cliente"
              />
             

                </div>
    
                <div className="div-infocontainer">
                <div className="div-titulocliente">
           
                <h3 className="text-titulo-cliente">{area.credentials.nombre}</h3>
                <p className="perfilequipo-text">{area.credentials.estado}</p>
         
                </div>
                

                <div className="div-equipo-info">
                <div className="div-perfil-info">
                <hr className="clientes_separator"></hr>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Prestador: </h5>    
                <p className="perfilequipo-text">{area.cliente.nombre}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Ciudad: </h5>    
                <p className="perfilequipo-text">{area.cliente.ciudad}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Dirección: </h5>    
                <p className="perfilequipo-text">{area.cliente.direccion}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Sede: </h5>    
                <p className="perfilequipo-text">{area.cliente.sede}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Servicio: </h5>    
                <p className="perfilequipo-text">{area.credentials.servicio}</p>
                </div>
                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Ubicación: </h5>    
                <p className="perfilequipo-text">{area.credentials.ubicacion}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Piso: </h5>    
                <p className="perfilequipo-text">{area.credentials.piso}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Telefono: </h5>    
                <p className="perfilequipo-text">{area.cliente.telefono}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Email: </h5>    
                <p className="perfilequipo-text">{area.cliente.email}</p>
                </div>
                

             

                </div>


               </div>
                </div>
                </div>

                <div className="div-acciones-equipo">
                <EditArea clienteId={match.params.clienteId} areaId={match.params.areaId}></EditArea>
                <ProgMtoInfra clienteId={match.params.clienteId} areaId={match.params.areaId}></ProgMtoInfra>
                <MtoCorrInfra clienteId={match.params.clienteId} areaId={match.params.areaId}></MtoCorrInfra>   
                <CambiarEstadoArea areaId={match.params.areaId} clienteId={match.params.clienteId}></CambiarEstadoArea>
                </div>
             
            </div>

            
            )
            
            :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);
        const handleVolverClick = () => {
              history.goBack();
        };      

        return (
            <div>
                <div className="titulo_section">
                <div className="titulo_clientes">
                <h1 className="text-clientes">Perfil del Area</h1>
                <hr className="clientes_separator"></hr>            
                
                </div>

                <div className="div_boton">
                  <MyButton tip="Volver" onClick={handleVolverClick}>
                    <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
                  </MyButton>
                </div>
                </div>
            
               {cargarArea}
                
  
               <div className='container-botones-perfil'>
        <Button
            variant="contained"
            endIcon={<FaWrench style={{ color: accion === "MtosProg" ? "white" : "#03178c" }} />}
            onClick={() => toggleAccion("MtosProg")}
            style={{
                backgroundColor: accion === "MtosProg" ? "#0588FB" : "initial",
                color: accion === "MtosProg" ? "white" : "#03178c",
                marginRight:accion === "MtosProg" ? "10px" : "10px",
                textTransform: accion === "MtosProg" ? "initial" : "initial"
                
            }}
            >
            {accion === "MtosProg" ? "Mtos Programados" : "Mtos. Programados"}
            </Button>

            
        <Button
          variant="contained"
          endIcon={<FaRocket  style={{ color: accion === "MtosReal" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("MtosReal")} // Actualizar el estado al hacer clic en "MtosReal"
          style={{
            backgroundColor: accion === "MtosReal" ? "#0588FB" : "initial",
            color: accion === "MtosReal" ? "white" : "#03178c",
            marginRight:accion === "MtosReal" ? "10px" : "10px",
            textTransform: accion === "MtosReal" ? "initial" : "initial"
            
        }}
        >
          {accion === "MtosReal" ? "Mtos. Realizados" : "Mtos. Realizados"}
        </Button>
        <Button
          variant="contained"
          endIcon={<FaCogs  style={{ color: accion === "MtosCorr" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("MtosCorr")} // Actualizar el estado al hacer clic en "MtosCorr"
          style={{
            backgroundColor: accion === "MtosCorr" ? "#0588FB" : "initial",
            color: accion === "MtosCorr" ? "white" : "#03178c",
            marginRight:accion === "MtosCorr" ? "10px" : "10px",
            textTransform: accion === "MtosCorr" ? "initial" : "initial"
            
        }}
        >
          {accion === "MtosCorr" ? "Mtos. Correctivos" : "Mtos. Correctivos"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaPhoneSquareAlt  style={{ color: accion === "SolCorr" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("SolCorr")} // Actualizar el estado al hacer clic en "SolCorr"
          style={{
            backgroundColor: accion === "SolCorr" ? "#0588FB" : "initial",
            color: accion === "SolCorr" ? "white" : "#03178c",
            marginRight:accion === "SolCorr" ? "10px" : "10px",
            textTransform: accion === "SolCorr" ? "initial" : "initial"
            
        }}
        >
          {accion === "SolCorr" ? "Sol. Correctivos" : "Sol. Correctivos"}
        </Button>
       

        

       
      </div>

      <div className="acordion-container-equipo">
        {mostrarComponente && (
            <div>

             <div>
             {accion === "MtosProg" ? <div>
           
             <TablaProgMtoArea areaId={match.params.areaId} clienteId={match.params.clienteId}></TablaProgMtoArea>
             </div> : null}
             </div>

            <div>
            {accion === "MtosReal" ? <div>
            <TablaMtoArea areaId={match.params.areaId} clienteId={match.params.clienteId}></TablaMtoArea>
            </div> : null}
            </div>

         
            <div>
              {accion === "MtosCorr" ? <div>
              <TablaCorrArea areaId={match.params.areaId} clienteId={match.params.clienteId}></TablaCorrArea>
              
       
              </div> : null}
            </div>

            <div>
              {accion === "SolCorr" ? <div>
              <TablaSolicitudesInfra areaId={match.params.areaId} clienteId={match.params.clienteId}></TablaSolicitudesInfra>
              </div> : null}
            </div>

           </div>

        )}
       
      

        


       
      </div>

                
              
                 
            </div>
        )
        }
//en propiedades recibe el cliente id 

PerfilArea.propTypes = {
  getArea: PropTypes.func.isRequired,
  datainfra: PropTypes.object.isRequired
};

const mapActionsToProps = { getArea };

const mapStateToProps = (state) => ({
  datainfra: state.datainfra,
});

export default connect(mapStateToProps, mapActionsToProps)(PerfilArea);