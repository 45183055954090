import React, { Component } from 'react';
import './PlanMtoPerfil.css'
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';

import PlanMtoPerfilTabla from './PlanMtoPerfilTabla';
import { useHistory, useParams } from 'react-router-dom';
import SolicitudesInfraTabla from './SolicitudesInfraTabla';

function SolicitudesInfraPerfil() {
    const history = useHistory();
    const { clienteId } = useParams();
    const handleGoBack = () => {
      history.goBack(); // Esto llevará al usuario una página hacia atrás en el historial de navegación.
    };
  
    return (
      <div>
        
        <div className="titulo_section">
          <div className="titulo_clientes">
            <h1 className="text-clientes">Solicitudes de Mantenimiento Infraestructura</h1>
            <hr className="clientes_separator"></hr>
          </div>
  
          <div className="div_boton">
            <MyButton tip="Volver" onClick={handleGoBack}>
              <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
            </MyButton>
          </div>
        </div>
  
        <div className="div-tabla-equipos">
        <SolicitudesInfraTabla clienteId={clienteId}></SolicitudesInfraTabla>
        </div>
      </div>
    );
}

export default SolicitudesInfraPerfil