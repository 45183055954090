import React, { Component } from 'react';
import './TablaMtoProgInfraAsis.css'; 
import {getProgMtosInfra} from '../../../redux/actions/infraactions';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';



import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';





export class TablaMtoProgInfraAsis extends Component {
    
    constructor(){
        super();
        this.state={
          
            errors:{},
            aprobado:{},
            open:false,
            opendelete:false,
            progid:'',
           
        };
        
    };


    componentDidMount(){
        this.props.getProgMtosInfra(this.props.areaId);
      }
     
 
    
     
    
    render() {
        const {progmtosinfra,loadingmto}= this.props.datainfra; 
      
        

        
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const columns=[
            {title:'Fecha',field:'fecha'},
            {title:'Mes',field:'mesnombre'},
            {title:'Hora',field:'hora'},
            {title:'Duración',field:'duracion'},
            {title:'Estado',field:'estado'},
            { 
                title: 'Actividades', 
                render: rowData => `${rowData.taskElectrica}, ${rowData.taskGases}, ${rowData.taskComunicaciones}, ${rowData.taskHidraulica}, ${rowData.taskInstalaciones}`, 
                // Reemplaza variable1, variable2, variable3 con los nombres de las variables que deseas combinar
              },
           
        ]


        
     let mapeoprogmtos=!loadingmto?(
         <div>
    <MaterialTable
            columns={columns}
            data={progmtosinfra}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Mantenimientos Preventivos Programados</h2>
            }
            icons={tableIcons}
            options={{
              cellStyle: {
                  fontSize: 12,
                  fontWeight:500,
                  color:'#696B6D'
                
               
                },
                headerStyle: {
                  fontSize: 14,
                  fontWeight: 'bolder',
                  color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                }
            
            }}
    
            
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
       

        return (
           <div className="listamtoprog-container">
              
            <div className="listamtoprog-table">
            {mapeoprogmtos}
            </div>

           
        
           </div>

         
        )
    }
}

TablaMtoProgInfraAsis.propTypes = {
    
    getProgMtosInfra: PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,    
    data:PropTypes.object.isRequired,
    datainfra:PropTypes.object.isRequired,
    
    UI:PropTypes.object.isRequired,
   
    
  };

const mapActionsToProps={getProgMtosInfra};

const mapStateToProps = (state)=>({
    data:state.data,
    datainfra:state.datainfra,
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(TablaMtoProgInfraAsis);



