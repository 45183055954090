import React from 'react';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import './InventarioDocuPDF.css';
import alphalogo from '../../../utils/alphahorizontal.png'


const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    viewcard:{
        display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "10px",
                 marginBottom: "5px",
                 backgroundColor: "#f9f8f8",
                 border: "1px solid #e2e2e2"
    },
    text:{
        fontSize:"9",
        color:"#03178c",
        fontWeight:"bold"
    },
    textdos:{
        fontSize:"7",
        color:"#03178c",
        fontWeight:"light"
    },
    itemelement:{
        width: "15%",
        marginRight:"1%",
        alignItems:"start"
    },
    vieWgeneral:{
        padding:"10px",
        
    },
    viewTitulo:{
        alignItems:"center"
    },
    imagenlogo:{
        width:"200px",
        height:"50px"
    },
    textotitulo:{
        color:"#03178c",
        fontSize:"18",
        fontWeight:"bold"
    },
    pageGeneral:{
        padding:"10px",
    }
  });
  
  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const ubicacionA = a.ubicacion.toUpperCase();
    const ubicacionB = b.ubicacion.toUpperCase();
  
    let comparison = 0;
    if (ubicacionA > ubicacionB) {
      comparison = 1;
    } else if (ubicacionA < ubicacionB) {
      comparison = -1;
    }
    return comparison;
  } 


const InventarioDocuPDF = ({ equipos }) => {
  
    let equiposordenados=equipos.sort(compare);
   

    let mapeoequipos=
    (equiposordenados.length>0?(
        equiposordenados.map((equipo)=>
        <View  style={styles.viewcard}>
            <View style={styles.itemelement}>
            <Text style={styles.text} >No. Id</Text>
            <Text style={styles.textdos}>{equipo.idinventario}</Text>
            </View>

            <View style={styles.itemelement}>
            <Text style={styles.text} >Nombre</Text>
            <Text style={styles.textdos}>{equipo.nombre}</Text>
            </View>

            <View style={styles.itemelement}>
            <Text style={styles.text} >Marca</Text>
            <Text style={styles.textdos}>{equipo.marca}</Text>
            </View>
            <View style={styles.itemelement}>
            <Text style={styles.text} >Modelo</Text>
            <Text style={styles.textdos}>{equipo.modelo}</Text>
            </View>
            <View style={styles.itemelement}>
            <Text style={styles.text} >Serie</Text>
            <Text style={styles.textdos}>{equipo.serie}</Text>
            </View>

            <View style={styles.itemelement}>
            <Text style={styles.text} >Ubicación</Text>
            <Text style={styles.textdos}>{equipo.ubicacion}</Text>
            </View>

            <View style={styles.itemelement}>
            <Text style={styles.text} >Servicio</Text>
            <Text style={styles.textdos}>{equipo.servicio}</Text>
            </View>

            <View style={styles.itemelement}>
            <Text style={styles.text} >Estado</Text>
            <Text style={styles.textdos}>{equipo.estado}</Text>
            </View>

            <View style={styles.itemelement}>
            <Text style={styles.text} >R. Invima</Text>
            <Text style={styles.textdos}>{equipo.registroinvima}</Text>
            </View>

            <View style={styles.itemelement}>
            <Text style={styles.text} >Riesgo</Text>
            <Text style={styles.textdos}>{equipo.riesgo}</Text>
            </View>
        </View>
       
        )
    ):(<View>
            <Text>no hay nada</Text>
    </View>)
    )
  
  
    return (

        <Document>
             <Page size="a4" style={styles.pageGeneral}>
             
            <View style={styles.vieWgeneral}>
                <View style={styles.viewTitulo}>
     
                <Image src={alphalogo} style={styles.imagenlogo}></Image>
                <Text style={styles.textotitulo}>Inventario de Equipos Biomédicos</Text>
                </View>
               {mapeoequipos}
            </View>
            </Page>
         </Document>
        
        
        )
}

export default InventarioDocuPDF