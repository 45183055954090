
import React, { Component, Fragment  } from 'react';
import './CambiarEstadoArea.css'; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus, FaLaptopMedical, FaCheckCircle, FaCheck } from "react-icons/fa";

import {cambiarEstadoArea,clearErrors,getArea} from '../../../redux/actions/infraactions';

export class CambiarEstadoArea extends Component {
    constructor(){
        super();
        this.state={
            estado:'',
            nuevoestado:'',
            errors:{},
            open:false,
            aprobado:{}
        };
        
    };
    static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

    mapUserDetailsToState = (area) => {
        this.setState({
            estado:area.estado?area.estado:'',
            nuevoestado:area.estado?area.estado:''
        });
      };

      handleOpen = () => {
        this.setState({ open: true,aprobado:{} });
        this.mapUserDetailsToState(this.props.area);
      };

      handleClose=()=>{


        if(this.state.aprobado.mensaje){
            //getEquipos this.handleEditProgMto();
            this.props.getArea(this.props.areaId,this.props.clienteId);
            this.setState({
                
                open:false,errors:{},aprobado:{}});
        }else{
            this.setState({
                
                open:false,errors:{},aprobado:{}});
        }
       
       
                    
        };
      handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        });
      };
    
      handleSubmit = (event) => {
       console.log(this.props.areaId);
       console.log(this.state.nuevoestado);
        this.props.cambiarEstadoArea(this.props.areaId,this.state.nuevoestado);
        
      };
  render() {
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;
    return (
        <div>
        <Fragment>
    <MyButton tip="Cambiar Estado Equipo" onClick={this.handleOpen}>
    <FaLaptopMedical color="#03178C" className="icon-crearcliente"/>
     </MyButton>

     <Dialog
     open={this.state.open}
     onClose={this.handleClose}
     fullWidth
     maxWidth="sm"
    >
        {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Estado Actualizado Exitosamente</h2>
                  
                 
              </div>
                
                ) : (<div>
 <DialogTitle>Cambiar Estado del Area</DialogTitle>
       
       <DialogContent>
               <form>
               
               
                 
                 
                 <div className="input_divido_children">
                 <FormControl className="formcontrol-nuevocliente" error={errors.nuevoestado?true:false}>
                               <InputLabel> Nuevo Estado</InputLabel>
                               <Select
                               required
                            name="nuevoestado"
                            id="nuevoestado"
                            value={this.state.nuevoestado}
                               onChange={this.handleChange}
                               fullWidth
                               >
                               <MenuItem value="">
                                   <em></em>
                               </MenuItem>
                               <MenuItem value="construccion">Construcción</MenuItem>
                               <MenuItem value="funcionando">Funcionando</MenuItem>
                               <MenuItem value="remodelacion">Remodelación</MenuItem>
                               <MenuItem value="inactiva">Inactiva</MenuItem>
                               </Select>
                               <FormHelperText>{errors.nuevoestado}</FormHelperText>
                           </FormControl>
                   </div>
                   
   
   
   
   
                 </form>
                 
       
       </DialogContent>
                </div>)}
   
                <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                              
                  <MyButton tip="Cerrar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                  
                ) : (
                  <MyButton tip="Actualizar" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            
          </DialogActions>

    </Dialog>
     </Fragment>

    </div>
    )
  }
}

CambiarEstadoArea.propTypes = {
    cambiarEstadoArea: PropTypes.func.isRequired,
    getArea:PropTypes.func.isRequired,
  
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

  const mapActionsToProps={cambiarEstadoArea,getArea,clearErrors};

  const mapStateToProps = (state) => ({
    area: state.datainfra.area.credentials,
    UI:state.UI
  });
  
  export default connect(mapStateToProps,mapActionsToProps)(CambiarEstadoArea);


