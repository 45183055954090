import React, { Component } from 'react'; 
import './TablaMtoArea.css'; 

import {subirEvidenciaMtoCorrInfra,getMtosCorrectivosInfra,deleteCorrInfra} from '../../../redux/actions/infraactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft,FaPlus,FaFilePdf, FaTrash, FaCheckCircle, FaCheck } from "react-icons/fa";
import MaterialTable from 'material-table';
import {Link} from 'react-router-dom';

import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';

import AssignmentIcon from '@material-ui/icons/Assignment';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PhotoIcon from '@material-ui/icons/Photo';

//getmtosprev
export class TablaCorrArea extends Component {

  constructor(){
    super();
    this.state={
        mtoid:'',
        solicitudid:'',
        errors:{},
        aprobado:{},
        open:false,
        opendelete:false,
        openadd:false,
        imageUrl: null,
        selectedFile: null,
        selectedFileUrl: null,
    };
    
};

       componentDidMount(){
       
        this.props.getMtosCorrectivosInfra(this.props.areaId);
       }

       static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }
       

       

                  
              handleOpenDelete=(rawdatamtoid,rawdatasolicitudid)=>{
                console.log('Mto Id'+rawdatamtoid);
                console.log('Solicitud Id'+rawdatasolicitudid)
                this.setState({opendelete:true,mtoid:rawdatamtoid,solicitudid:rawdatasolicitudid,errors:{},aprobado:{}});
              
                };

          handleCloseDelete=()=>{
            if(this.state.aprobado.mensaje){
                //this.handleEditProgMto();
                //get prog mtos 
                this.props.getMtosCorrectivosInfra(this.props.areaId);
                this.setState({opendelete:false,errors:{},aprobado:{}});
             }else{
              this.setState({opendelete:false,errors:{},aprobado:{}});
             }
                  
                    };

        handleDelete=()=>{
            console.log('MtoID'+this.state.mtoid);
            console.log('SolicitudID'+this.state.solicitudid);
          this.props.deleteCorrInfra(this.state.mtoid,this.state.progid);
                     
                     
                    
                  
                    }


        handleOpenAdd = (rawdatamtoid) => {
                        this.setState({ openadd: true, mtoid: rawdatamtoid, errors: {}, aprobado: {}});
                    
                      };
                    
       handleCloseAdd = () => {
                        // this.props.clearErrors();
                        if (this.state.aprobado.mensaje) {
                          console.log(this.state.aprobado)
                          //get solicitudes de mto correctivo
                          this.props.getMtosCorrectivosInfra(this.props.areaId);
                            
                          this.setState({ openadd: false, selectedFileUrl: null, errors: {}, aprobado: {}, evidencia: null });
                        }
                        else {
                          this.setState({ openadd: false, selectedFileUrl: null, errors: {}, evidencia: null });
                        }
                      };
       onFileChange = event => {
                        // Update the state with the selected file
                        this.setState({ selectedFile: event.target.files[0] });
                    
                        // Create a URL for the selected file and update the state with it
                        this.setState({ selectedFileUrl: URL.createObjectURL(event.target.files[0]) });
                      };
                    
      onFileUpload = (event) => {
                        event.preventDefault();
                        // Create an object of formData 
                        const formData = new FormData();
                    
                        // Update the formData object 
                        formData.append(
                          "myFile",
                          this.state.selectedFile,
                          this.state.selectedFile.name
                        );
                        //this.props.subirImagenPerfil(formData);
                        console.log(this.state.mtoid);
               
                       this.props.subirEvidenciaMtoCorrInfra(formData, this.state.mtoid)
                      }

                      handleOpenSee = (rawdatamtoid, rawimageurl) => {
                        this.setState({ opensee: true, mtoid: rawdatamtoid, imageUrl: rawimageurl });
                    
                      };
                    
                      handleCloseSee = () => {
                        // this.props.clearErrors();
                        this.setState({ opensee: false, errors: {}, imageUrl:null });
                      };
          
      
    render() {

        const {mtoscorrectivosinfra,loadingmto}= this.props.datainfra; 
         const { UI:{loading}}=this.props;
        
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />),
            AddAPhotoIcon: forwardRef((props, ref) => <AddAPhotoIcon {...props} ref={ref}  style={{ color: '#03178c' }}/>),
            photo: forwardRef((props, ref) => <PhotoIcon {...props} ref={ref} style={{ color: '#03178c' }} />)
          };
        
        const columns=[
            {title:'Fecha',field:'reporte.fechainicio'},
            {title:'Mes',field:'reporte.mesnombre'},
            {title:'Hora Inicio',field:'reporte.horainicio'},
            {title:'Hora Final',field:'reporte.horafinal'},
            {title:'Solicitud',field:'reporte.solicitud.infosolicitud'},
            {
              title: 'Aprobado',
              field: 'aprobado',
              render: rowData => {
                  // Verifica si el campo 'aprobado' existe en los datos de la fila
                  if (rowData.hasOwnProperty('aprobado')) {
                      // Si 'aprobado' es true, muestra 'Sí'
                      if (rowData.aprobado === true) {
                          return 'Sí';
                      }
                      // Si 'aprobado' es false o cualquier otro valor, muestra 'No'
                      return 'No';
                  }
                  // Si el campo 'aprobado' no existe, muestra 'Aprobado No'
                  return 'No';
              }
          },
           
        ]


        
     let mapeomtoscorrectivos=!loadingmto ?(
         <div>
    <MaterialTable
            columns={columns}
            data={mtoscorrectivosinfra}
         
            icons={tableIcons}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Mantenimientos Infraestructura Realizados</h2>
            }
            options={{
              cellStyle: {
                  fontSize: 12,
                  fontWeight:500,
                  color:'#696B6D'
                
               
                },
                headerStyle: {
                  fontSize: 14,
                  fontWeight: 'bolder',
                  color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                }
            
            }}
            actions={[
             
              rowData => ({
                icon: () =>  <Link to={`/dashboard/perfilcliente/${this.props.clienteId}/infraestructura/${this.props.areaId}/reportepdfmtocorr/${rowData.mtoId}`} style={{ color: '#03178C' }} ><FaFilePdf  /></Link>,
                tooltip: 'Ver Reporte PDF ',
                onClick: (rowData)
              }),
                {
                  icon: tableIcons.Delete,
                  tooltip: 'Borrar Programación',
                  onClick: (event, rowData) => {
                      
                    this.handleOpenDelete(rowData.mtoId,rowData.reporte.solicitudid);
                  }
                },
                {
                    title: "EvidenciaCargada",
                    icon: tableIcons.photo,
                    tooltip: 'Ver evidencia cargada',
                    onClick: (event, rowData) => {
                      this.handleOpenSee(rowData.mtoId,rowData.reporte.imagenurl)
                     
                    },
                  },
                  rowData => ({
                    title: "CargarEvidencia",
                    icon: tableIcons.AddAPhotoIcon,
                    tooltip: 'Añadir Evidencia',
                    disabled: rowData.estado === 'Atendida',
                    onClick: (event, rowData) => {
                      this.handleOpenAdd(rowData.mtoId);
                    },
                  })
              ]}
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
        return (
              <div className="listamtoprog-container">
              
              <div className="listamtoprog-table">
              {mapeomtoscorrectivos}
              </div>

             
              <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-delete'>
                    <FaTrash color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Borrado Exitosamente</h2>
              </div>
                
                ):(<div>
                <DialogTitle>¿Estás seguro de borrar este Mantenimiento?</DialogTitle>
                </div>)}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseDelete}>
                    <FaCheckCircle color="#f9044e" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Borrar Mantenimiento" onClick={this.handleDelete}>
                    <FaTrash color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
           </DialogActions>
            </Dialog>
        
            <Dialog
             open={this.state.opensee}
             onClose={this.handleCloseSee}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Evidencia Cargada del Mto</DialogTitle>
              <DialogContent>
            {  this.state.imageUrl ? (
                  <div>
                    <p> Esta es la evidencia cargada para esta solicitud </p>
                    <div className='div-image-carga'>

                      <img src={this.state.imageUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                    </div>
                  </div>
                ) : (
                  <p>No hay evidencia cargada para mostrar</p>
                )
                }
            
            </DialogContent>
            </Dialog>

            <Dialog
             open={this.state.openadd}
             onClose={this.handleCloseAdd}
             fullWidth
            maxWidth="sm"
            >
              {this.state.aprobado.mensaje?(<></>):( <DialogTitle>Cargar Evidencia</DialogTitle>)}
           
            <DialogContent>

              {
                this.state.aprobado.mensaje ? (
                  <div className='div-aprobado'>
                    <div className='div-icono-aprobado'>
                      <FaCheck color="#ffffff" className='icono-aprobado' />
                    </div>
                    <h2 className='text-aprobado'>Evidencia cargada Exitosamente</h2>
                  </div>
                ) : (<form>
                  <input type="file" onChange={this.onFileChange} accept="image/png,image/jpg" />
                  <div className='div-image'>
                    {this.state.selectedFileUrl && (
                      <img src={this.state.selectedFileUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                    )}
                  </div>
                </form>)
              }
            
          </DialogContent>
          <DialogActions>

            <MyButton tip="Cerrar" onClick={this.handleCloseAdd}>
              <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
            </MyButton>

            {loading ? <CircularProgress size={30} color="primary" className="login-progress" /> :

              this.state.aprobado.mensaje ?
                (
                  <></>
                ) : (
                  <MyButton tip="Subir evidencia fotográfica" onClick={this.onFileUpload}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )}

            </DialogActions>

            </Dialog>
  
            </div>
        )
    }
}


TablaCorrArea.propTypes = {
    
    getMtosCorrectivosInfra: PropTypes.func.isRequired,
      data:PropTypes.object.isRequired,
      user:PropTypes.object.isRequired,
      subirEvidenciaMtoCorrInfra:PropTypes.func.isRequired,
      UI:PropTypes.object.isRequired,
      datainfra:PropTypes.object.isRequired,
      deleteCorrInfra:PropTypes.func.isRequired
    };
  
  const mapActionsToProps={subirEvidenciaMtoCorrInfra,getMtosCorrectivosInfra,deleteCorrInfra};
  
  const mapStateToProps = (state)=>({
      data:state.data,
      user:state.user,
      UI:state.UI,
      datainfra:state.datainfra
  })
  
  
  
  export default connect(mapStateToProps,mapActionsToProps)(TablaCorrArea);
  
