import React, { Component } from 'react'
import './RespuestaCotizacion.css';
import MyButton from '../../../utils/MyButton';

import {FaArrowCircleLeft,FaArrowLeft,FaUpload,FaPlus,FaSignInAlt} from "react-icons/fa";
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import {getCotizacion,postOferta,clearErrors} from '../../../redux/actions/datasalesactions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';


export class RespuestaCotizacion extends Component {

    constructor(){
        super();
        this.state={
           titulo:'',
           cotizacion:{},
           cotizacionid:'',
            items:[],
            user:{},
            errors:{},
            preciototaloferta:0,
            open:false
        };
        
    };

    

componentDidMount(){
     this.props.getCotizacion(this.props.match.params.cotizacionId);
    
    }
    componentDidUpdate(prevProps) {
        // Uso tipico (no olvides de comparar las props):
        if (this.props.dataSales.loadingdatasales!== prevProps.dataSales.loadingdatasales) {
            let items=this.props.dataSales.cotizacion.credentials.items;
            let cotizacion=this.props.dataSales.cotizacion.credentials;
            let user=this.props.user.credentials;
            let cotizacionid=this.props.match.params.cotizacionId;

          
            console.log(this.props.dataSales);
           
           
           
            this.setState({ items:items,cotizacion:cotizacion,user:user, cotizacionid:cotizacionid}, () => {
                console.log(this.state);
              }); 
           
        }

       
      }

      static getDerivedStateFromProps(props, state) {
        return props.UI.errors ? {errors: props.UI.errors} : null;
        
    }

    handleChange = (event,cantidad) => {
     
        let id=event.target.id; 
        let value=event.target.value;
   
       let preciototal=value*cantidad;
       let comision=Math.round(preciototal*0.07);
   
        this.setState(prevState => ({

            items: prevState.items.map(
              item => item.datecreation === id? { ...item, precio: value,preciototalitem:preciototal,comisionitem:comision}: item
              
            )
          
          }))
    
       
   
      };
    
      handleChangeMarcaRespuesta = (event) => {
     
        let id=event.target.id; 
        let value=event.target.value;
        this.setState(prevState => ({

            items: prevState.items.map(
              item => item.datecreation === id? { ...item, marcarespuesta: value }: item
            )
          
          }))
        
      };
      handleChangePresentacion = (event) => {
     
        let id=event.target.id; 
        let value=event.target.value;
        this.setState(prevState => ({

            items: prevState.items.map(
              item => item.datecreation === id? { ...item, presentacion: value }: item
            )
          
          }))
        
      };

      handleChangeObservaciones = (event) => {
     
        let id=event.target.id; 
        let value=event.target.value;
        this.setState(prevState => ({

            items: prevState.items.map(
              item => item.datecreation === id? { ...item, observaciones: value }: item
            )
          
          }))
        
      };

     
     

      handleSubmit=(event)=>{

       let itemsuma=this.state.items; 
       const sumall = itemsuma.map(item => item.preciototalitem).reduce((prev, curr) => prev + curr, 0);
      

        let respuesta={
            cotizacion:this.state.cotizacion,
            handleusercotizacion:this.state.cotizacion.handleuser,
            handleuseroferente:this.state.user.handle,
            cotizacionid:this.state.cotizacionid,
            user:this.state.user,
            items:this.state.items,
            preciototaloferta:sumall
        }

    console.log(this.props.history);
    console.log(respuesta);
    this.props.postOferta(respuesta,this.props.history);
    }

    handleOpen=()=>{
        this.setState({open:true});
        };
    
    handleClose=()=>{
            this.props.clearErrors();
            this.setState({ open:false,
            errors:{}});
            };
    
    

  render() {

    const {cotizacion,loadingdatasales}= this.props.dataSales; 
    const { UI:{loading}}=this.props;
    const {errors}=this.state;  

    const formatterPeso = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0
    })

    let cargarCotizacion= !loadingdatasales ? (
        <div className="card-equipo">
            <div className="div-informacion-market">
            

            <div className="div-infocontainer-market">
            <div className="div-titulocliente">
       
            <h3 className="text-titulo-cliente">{cotizacion.credentials.titulo}</h3>
        
            </div>

            <div className="div-equipo-info-market">
                <div className="div-perfil-info">
                <hr className="clientes_separator"></hr>  

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Estado: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.estado}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Ciudad: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.ciudad}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Departamento: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.departamento}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Categoria: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.categoria}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Tipo Cotizacion: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.tipocotizacion}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Fecha de Vencimiento: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.fechavencimiento}</p>
                </div>


                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Forma de Pago: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.formadepago}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Nombre Responsable: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.nombreresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Cargo Responsable: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.cargoresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Telefono Responsable: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.cargoresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Email Responsable: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.emailresponsable}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Dirección Entrega: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.direccionentrega}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Nit Facturación: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.nit}</p>
                </div>

                          
                </div>

                <div className='terminos-container-market'>

                
                <hr className="clientes_separator"></hr> 
                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Términos: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.terminos}</p>
                </div>

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Observaciones: </h5>    
                <p className="perfilequipo-text">{cotizacion.credentials.observaciones}</p>
                </div>

                </div>
            </div>

           </div>
            </div>
            </div>

          
         
       

        
        )
        
        :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);
    
    
let mapeoitems=!loadingdatasales?
(this.state.items!==undefined?(
    this.state.items.map((item)=>
    <div key={item.datecreation} className="item-market-card">
   
   <div className='container-market-item'>
   <div className='item-market-container'>

  
<div className='item-market-item'>

<h3 className="titulo-market-text">Nombre</h3>
<h5 className="item-market-text">{item.nombre}</h5>
</div>

<div className='item-market-item'>

<h3 className="titulo-market-text">Marca</h3>
<h5 className="item-market-text">{item.marca}</h5>
</div>

<div className='item-market-item'>

<h3 className="titulo-market-text">Cantidad</h3>
<h5 className="item-market-text">{item.cantidad}</h5>
</div>

<div className='item-market-descripcion'>

<h3 className="titulo-market-text">Descripcion</h3>
<h5 className="item-market-text">{item.descripcion}</h5>
</div>

<div className='item-market-descripcion'>

<h3 className="titulo-market-text">Precio total</h3>
<h5 className="item-market-text">{formatterPeso.format(item.preciototalitem)}</h5>
</div>

<div className='item-market-descripcion'>

<h3 className="titulo-market-text">Comision</h3>
<h5 className="item-market-text">{formatterPeso.format(item.comisionitem)}</h5>
</div>



</div>



<div className='item-market-container'>
   

    

<TextField
          required
          id={item.datecreation}
          value={item.precio}
          onChange={(e) => this.handleChange(e, item.cantidad)}
            type='number'
          label="Precio incluido IVA"
          InputProps={{ inputProps: { min: 0, max: 90000000000 } }}
          defaultValue=''
        />

<TextField
          required
          id={item.datecreation}
          value={item.marcarespuesta}
          onChange={this.handleChangeMarcaRespuesta}
            type='text'
          label="Marca Ofrecida"
          defaultValue=''
          
        />

<TextField
          required
          id={item.datecreation}
          value={item.presentacion}
          onChange={this.handleChangePresentacion}
            type='text'
          label="Presentación"
          defaultValue=''
          
        />

<TextField
          required
          id={item.datecreation}
          value={item.observaciones}
          onChange={this.handleChangeObservaciones}
            type='text'
          label="Observaciones"
          defaultValue=''
        />


  
  
  
    
  </div>


   </div>
    
  
  
    </div>
    )
   

):( <div className="item-market-card">
    <div className='item-market-container'>
    <div className='item-market-descripcion'>
    <h3 className="titulo-market-text">Añade un Item para Empezar</h3>
</div>
    </div>
    </div>)):
(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>)
   




    return (
        <div>
  

        
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Responder Cotizacion</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <Link to={`/homemarketempresa/categoriasmarket`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>


        <div className='container_content_items'>
           {cargarCotizacion}

        </div>

        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Elaborar Respuesta</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        
        </div>
        
        <div>
            {mapeoitems}
        
        <MyButton tip="Responder" onClick={this.handleOpen}>
        <FaUpload color="#03178C" className="icon-crearcliente"/>
         </MyButton>

            

            

        </div>

        

        <Dialog
         open={this.state.open}
         onClose={this.handleClose}
         fullWidth
        maxWidth="sm"
        >
        <DialogTitle>Publicar Oferta</DialogTitle>

        <DialogContent>
            <a>¿Estas Seguro de Publicar tu Oferta a esta Cotización?</a>
            <a> Revisa todos tus items antes de publicar tu respuesta</a>
            <a> para asegurar que tu oferta sea tenida en cuenta</a>
            <a className='error-respuesta'>{errors.error}</a>
        </DialogContent>

        <DialogActions>
        <MyButton tip="Cerrar" onClick={this.handleClose}>
        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
         </MyButton>

         {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                      <MyButton tip="Publicar Mi Oferta" onClick={this.handleSubmit}>
                      <FaPlus  color="#03178C" className="icon-formulario"/>
                       </MyButton>}
        


         </DialogActions>
       
        </Dialog>
  </div>
    )
  }
}
RespuestaCotizacion.propTypes = {
    
    getCotizacion: PropTypes.func.isRequired,
    dataSales:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    postOferta:PropTypes.func.isRequired
    
  };

const mapActionsToProps={getCotizacion,postOferta,clearErrors};

const mapStateToProps = (state)=>({
    dataSales:state.dataSales,
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(RespuestaCotizacion);
