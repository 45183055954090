import React, { Component } from 'react'

import {getprogcals,clearErrors,deleteProgCal,postCalibracion, editProgCal} from '../../../redux/actions/dataactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft,FaCheck,FaCheckCircle,FaPlus, FaTrash } from "react-icons/fa";
import MaterialTable from 'material-table';

import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';

export class ListaCalProg extends Component {

  constructor(){
    super();
    this.state={
        horainicio:'',
        horafinal:'',
        fecha:'',
        numcertificado:'',
        errors:{},
        open:false,
        opendelete:false,
        progid:'',
        selectedFile: null,
        aprobado:{}
    };
    
};


    
    componentDidMount(){
        this.props.getprogcals(this.props.equipoId);
       }

       static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }
    
       handleOpen=(rawdataprogid,rawdatafecha,rawdatahora)=>{

        this.setState({open:true,progid:rawdataprogid,fecha:rawdatafecha,horainicio:rawdatahora,aprobado:{}});
    
        };

        handleClose=()=>{
          // this.props.clearErrors();
          if(this.state.aprobado.mensaje){
             this.handleEditProgCal(this.state.progid);
             this.setState({open:false,errors:{},aprobado:{}});
          }else{
           this.setState({open:false,errors:{},aprobado:{}});
          }
           
           };
       
         handleOpenDelete=(rawdataprogid)=>{
              this.setState({opendelete:true,progid:rawdataprogid});
             
              };
  handleCloseDelete=()=>{
                // this.props.clearErrors();
                this.setState({opendelete:false,errors:{},aprobado:{}});
  };
      


  handleDelete=()=>{
    const {credentials}=this.props.user;

    if(credentials.tipouser==="Tecnico"){
      console.log("Prog Cal borrado desde:"+credentials.tipouser)
      console.log("Perfil de Ingenieria"+credentials.handleuser)
      //mandar el user de ingenieria
      this.props.deleteProgCal(this.state.progid,this.props.equipoId,this.props.clienteId,credentials.handleuser);
      
      
     // this.handleEditProgMto(this.state.progid); 
          }else{
            //mandar el handle user normal 
            console.log("Prog Cal desde:"+credentials.tipouser)
            console.log("Perfil de Ingenieria"+credentials.handle)
            this.props.deleteProgCal(this.state.progid,this.props.equipoId,this.props.clienteId,credentials.handle);
            //this.handleEditProgMto(this.state.progid); 
          }
                    
   }
   handleEditProgCal=()=>{

    this.props.editProgCal({nuevoestado:'Realizado'},this.state.progid,this.props.equipoId); 
 
  }      
  handlePostCal=()=>{
    const {credentials}=this.props.user;
      const newCalibracion={
        fecha:this.state.fecha,
        horainicio:this.state.horainicio,
        horafinal:this.state.horafinal,
        numcertificado:this.state.numcertificado           
    };

    if(credentials.tipouser==="Tecnico"){
      console.log("Calibración enviada desde:"+credentials.tipouser)
      console.log("Perfil de Ingenieria"+credentials.handleuser)
      //mandar el user de ingenieria
      this.props.postCalibracion(newCalibracion,this.props.clienteId,this.props.equipoId,credentials.handleuser); 
    
     // this.handleEditProgMto(this.state.progid); 
          }else{
            //mandar el handle user normal 
            console.log("Calibración enviada desde:"+credentials.tipouser)
            console.log("Perfil de Ingenieria"+credentials.handle)
            
      this.props.postCalibracion(newCalibracion,this.props.clienteId,this.props.equipoId,credentials.handle);
           
       
          }
 
      }
      
                       
             

    
    handleChange=(event)=>{
                  this.setState({ [event.target.name]:event.target.value});
                  
             };


    render() {

        const {progracals,loadingcal}= this.props.data; 
        const {errors}=this.state;
        const { UI:{loading}}=this.props; 
        
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const columns=[
            {title:'Fecha',field:'fecha'},
            {title:'Mes',field:'mesnombre'},
            {title:'Hora ',field:'hora'},
            {title:'Duración',field:'duracion'},
            {title:'Estado',field:'estado'},
            {title:'Lugar',field:'lugar'}
           
        ]


        
     let mapeoprogcals=!loadingcal ?(
         <div>
    <MaterialTable
            columns={columns}
            data={progracals}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Calibraciones Programadas</h2>
            }
            icons={tableIcons}
            options={{
              cellStyle: {
                  fontSize: 12,
                  fontWeight:500,
                  color:'#696B6D'
                
               
                },
                headerStyle: {
                  fontSize: 14,
                  fontWeight: 'bolder',
                  color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                }
            
            }}
    
            actions={[
             
              rowData => ({
                icon: tableIcons.Add,
                tooltip: 'Realizar Calibración',
                onClick: (event, rowData) => {
                    this.handleOpen(rowData.progid,rowData.fecha,rowData.hora);
                },
                disabled: rowData.estado==='Realizado'
              }),
                {
                  icon: tableIcons.Delete,
                  tooltip: 'Borrar Programación',
                  onClick: (event, rowData) => {
                      
                    this.handleOpenDelete(rowData.progid);
                  }
                }
              ]}
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
        return (
            <div className="listamtoprog-container">
              
            <div className="listamtoprog-table">
            {mapeoprogcals}
            </div>
            <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Cargado Exitosamente</h2>
              </div>
                
                ) : (<div>
                       <DialogTitle>Realizar Calibración</DialogTitle>
                          <DialogContent>
                          <form>
                          <div className="div-accordion-container">
                        <TextField className="login-input-email"
                                    required
                                    type="date"
                                    name="fecha"
                                    id="fecha"
                                    label="Fecha de Realización"
                                    value={this.state.fecha}
                                    onChange={this.handleChange}
                                    min="2019-01-01" max="2022-01-01"
                                    fullWidth
                                    helperText={errors.fecha}
                                    error={errors.fecha?true:false}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    />
                        <div className="div-input-doble-mtoprog">
                        <TextField className="login-input-email"
                                    required
                                    type="time"
                                    name="horainicio"
                                    id="horainicio"
                                    label="Hora de Inicio"
                                    value={this.state.horainicio}
                                    onChange={this.handleChange}
                                    min="2000-01-01" max="2022-01-01"
                                    fullWidth
                                    helperText={errors.horainicio}
                                    error={errors.horainicio?true:false}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    />
                          <TextField className="login-input-email"
                                    required
                                    type="time"
                                    name="horafinal"
                                    id="horafinal"
                                    label="Hora Final"
                                    value={this.state.horafinal}
                                    onChange={this.handleChange}
                                    min="2000-01-01" max="2022-01-01"
                                    fullWidth
                                    helperText={errors.horafinal}
                                    error={errors.horafinal?true:false}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    />
                      
                          </div>

                          <TextField className="login-input-email"
                                    required
                                    type="text"
                                    name="numcertificado"
                                    id="numcertificado"
                                    label="Numero del Certificado"
                                    value={this.state.numcertificado}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.numcertificado}
                                    error={errors.numcertificado?true:false}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    />
                        </div>
              
              
                    
                          </form>
                    
                


                        
                            
                          </DialogContent>
                </div>
               
                )}

          

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Realizar Calibración" onClick={this.handlePostCal}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
           </DialogActions>
            </Dialog>

            <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >
               {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-delete'>
                    <FaTrash color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Programación Borrada Exitosamente</h2>
              </div>
                
                ):(<div>
                <DialogTitle>¿Estás seguro de borrar programación de Calibración?</DialogTitle>
                </div>)}
     

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseDelete}>
                    <FaCheckCircle color="#f9044e" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Borrar Programación" onClick={this.handleDelete}>
                    <FaTrash color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
           </DialogActions>
            </Dialog>
        

          </div>
        )
    }
}

ListaCalProg.propTypes = {
    
    getprogcals: PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteProgCal:PropTypes.func.isRequired,
    editProgCal:PropTypes.func.isRequired
  };

const mapActionsToProps={getprogcals,clearErrors,deleteProgCal, editProgCal,postCalibracion};

const mapStateToProps = (state)=>({
    data:state.data,
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(ListaCalProg);



