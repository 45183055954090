import React, { Component, Fragment } from 'react';
import './TablaProgMtoArea.css'; 
import {getprogmtos,clearErrors,deleteProgMto,postMtoTabla,editProgMto} from '../../../redux/actions/dataactions';
import {getProgMtosInfraCliente,deleteProgMtoInfra,getProgMtosInfraClienteFiltrado} from '../../../redux/actions/infraactions';
import {postMtoInfra} from '../../../redux/actions/infraactions'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft,FaCheck,FaCheckCircle,FaFilePdf,FaPlus, FaTrash } from "react-icons/fa";
import MaterialTable from 'material-table';

import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import Button from '../../Elements/Button';
import PlanMtoCronoAuto from './PlanMtoCronoAuto';

import {Link} from 'react-router-dom';
import PlanMtoIndicadoresDialog from './PlanMtoIndicadoresDialog';

export class PlanMtoPerfilTabla extends Component {
    
    constructor(){
        super();
        this.state={
            fecha:'',
            hora:'',
            duracion:'',
            taskElectrica:'',
            taskHidraulica:'',
            taskComunicaciones:'',
            taskGases:'',
            taskInstalaciones:'',
            observaciones:'',
            horainicio:'',
            horafinal:'',
            firmarecibe:'',
            cargorecibe:'',
            fechafinal:'',
            areaId:'',
            errors:{},
            aprobado:{},
            open:false,
            opendelete:false,
            progid:'',
            clienteid:'',
            nombrerepuesto:'',
            nombrerepuesto1:'',
            nombrerepuesto2:'',
            cantidad:0,
            cantidad1:0,
            cantidad2:0,
            costo:0,
            costo1:0,
            costo2:0,
            subtotal:0,
            subtotal1:0,
            subtotal2:0,
            totalrepuesto:0,
            fechaInicio:'',
            fechaFin:''
        };
        
    };


    componentDidMount(){
      this.props.getProgMtosInfraClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
        
      }


    
      handleFiltrar = () => {
        console.log(this.state.fechaFin, this.state.fechaFin)
        this.props.getProgMtosInfraClienteFiltrado(this.props.clienteId, moment(this.state.fechaInicio).format('DD/MM/YYYY'), moment(this.state.fechaFin).format('DD/MM/YYYY'))
      }
    
      static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }
     

    handleOpen=(rawdata)=>{
       

        this.setState({open:true,
            areaId:rawdata.areaId,
            fechainicio:rawdata.fecha,
            duracion:rawdata.duracion,
            taskElectrica:rawdata.taskElectrica,
            taskHidraulica:rawdata.taskHidraulica,
            taskComunicaciones:rawdata.taskComunicaciones,
            taskGases:rawdata.taskGases,
            taskInstalaciones:rawdata.taskInstalaciones,
            horainicio:rawdata.hora,
            fechafinal:rawdata.fecha,
            progid:rawdata.progMtoId
            ,errors:{},aprobado:{}
            });
    
        };

     handleClose=()=>{
           // this.props.clearErrors();
           if(this.state.aprobado.mensaje){
              //this.handleEditProgMto();
              //get prog mtos 
              this.props.getProgMtosInfraClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
              this.setState({open:false,errors:{},aprobado:{}});
           }else{
            this.setState({open:false,errors:{},aprobado:{}});
           }
            
            };

    
     handleOpenDelete=(rawdataprogid)=>{
    
                this.setState({opendelete:true,progid:rawdataprogid,errors:{},aprobado:{}});
               
                };
        
    handleCloseDelete=()=>{

        if(this.state.aprobado.mensaje){
            //this.handleEditProgMto();
            //get prog mtos 
            this.props.getProgMtosInfraClienteFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
            this.setState({opendelete:false,errors:{},aprobado:{}});
         }else{
          this.setState({opendelete:false,errors:{},aprobado:{}});
         }
                   // this.props.clearErrors();
    };
    handleDelete=()=>{
      const {credentials}=this.props.user;
      if(credentials.tipouser==="Tecnico"){
        console.log("Mantenimiento borrado desde:"+credentials.tipouser)
        console.log("Perfil de Ingenieria"+credentials.handleuser)
        //mandar el user de ingenieria
        console.log("Prog id"+this.state.progid)
        this.props.deleteProgMtoInfra(this.state.progid);
        
       // this.handleEditProgMto(this.state.progid); 
            }else{
              //mandar el handle user normal 
              console.log("Mantenimiento enviado desde:"+credentials.tipouser)
              console.log("Perfil de Ingenieria"+credentials.handle)
              console.log("Prog id"+this.state.progid)
              this.props.deleteProgMtoInfra(this.state.progid);
              //this.handleEditProgMto(this.state.progid); 
            }
     
     
    
  
    }

   handleChange=(event)=>{
                                this.setState({ [event.target.name]:event.target.value});
                                
    };
    
 

    handlePostMto=()=>{
   
      const {credentials}=this.props.user;
       const newMtoPreventivo={
            fechainicio:this.state.fechainicio,
            horainicio:this.state.horainicio,
            fechafinal:this.state.fechafinal,
            horafinal:this.state.horafinal,
            duracion:this.state.duracion,
            taskElectrica:this.state.taskElectrica,
            taskHidraulica:this.state.taskHidraulica,
            taskComunicaciones:this.state.taskComunicaciones,
            taskGases:this.state.taskGases,
            taskInstalaciones:this.state.taskInstalaciones,
            observaciones:this.state.observaciones,
            firmarecibe:this.state.firmarecibe,
            cargorecibe:this.state.cargorecibe,
            areaId:this.state.areaId,
            nombrerepuesto:this.state.nombrerepuesto,
            nombrerepuesto1:this.state.nombrerepuesto1,
            nombrerepuesto2:this.state.nombrerepuesto2,
            cantidad:this.state.cantidad,
            cantidad1:this.state.cantidad1,
            cantidad2:this.state.cantidad2,
            costo:this.state.costo,
            costo1:this.state.costo1,
            costo2:this.state.costo2,
            subtotal:this.state.subtotal,
            subtotal1:this.state.subtotal1,
            subtotal2:this.state.subtotal2,
            totalrepuesto:this.state.totalrepuesto,
       
           
    };

    if(credentials.tipouser==="Tecnico"){
   /* console.log("Mantenimiento enviado desde:"+credentials.tipouser)
    console.log("Handle User"+credentials.handleuser)
    //mandar el user de ingenieria
    console.log("CLiente id"+this.props.clienteId)
    console.log("Prog id"+this.state.progid)
    console.log('Equipo Id'+this.props.equipoId);*/
    
    
   
   // newmtoprev,equipoid,clienteid,progid,handle
  //this.props.postMtoTabla(newMtoPreventivo,this.props.equipoId,this.props.clienteId,this.state.progid,credentials.handleuser);
    //this.props.postMto(newMtoPreventivo,this.props.equipoId,this.props.clienteId,credentials.handleuser); 
 
        }else{
          //mandar el handle user normal 
          console.log("Mantenimiento enviado desde:"+credentials.tipouser)
          console.log("Handle User"+credentials.handle)
          //mandar el user de ingenieria
          console.log("CLiente id"+this.props.clienteId)
          console.log("Prog id"+this.state.progid)
          console.log('Area Id'+this.props.areaId);
          console.log(newMtoPreventivo);
         //(newpmto,areaId,clienteid,handleuser,progid)
         this.props.postMtoInfra(newMtoPreventivo,this.props.areaId,this.props.clienteId,credentials.handle,this.state.progid,);  //postMtoTabla
        }
 
      
      

    }
 
    
     
    
    render() {
        const {progmtosinfra,loadingmto}= this.props.datainfra; 
      
        const {errors}=this.state;
        const{aprobado}=this.state;
        const { UI:{loading}}=this.props; 

        this.state.subtotal=this.state.cantidad*this.state.costo;
        this.state.subtotal1=this.state.cantidad1*this.state.costo1;
        this.state.subtotal2=this.state.cantidad2*this.state.costo2;
        this.state.totalrepuesto=this.state.subtotal+this.state.subtotal1+this.state.subtotal2; 
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const columns=[
            {title:'Area',field:'nombre'},
            {title:'Servicio',field:'servicio'},
            {title:'Ubicación',field:'ubicacion'},

            {title:'Fecha',field:'fecha'},
            {title:'Mes',field:'mesnombre'},
           
            {title:'Estado',field:'estado'},
            { 
                title: 'Actividades', 
                render: rowData => `${rowData.taskElectrica}, ${rowData.taskGases}, ${rowData.taskComunicaciones}, ${rowData.taskHidraulica}, ${rowData.taskInstalaciones}`, 
                // Reemplaza variable1, variable2, variable3 con los nombres de las variables que deseas combinar
              },
           
        ]


        
     let mapeoprogmtos=!loadingmto?(
         <div>
          <div className='div-container-links-inventario'>
          <PlanMtoCronoAuto clienteId={this.props.clienteId}></PlanMtoCronoAuto>
          <PlanMtoIndicadoresDialog clienteId={this.props.clienteId}></PlanMtoIndicadoresDialog>
          <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/${this.props.clienteId}/planmtoinfra/pdf`} >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Ver Cronograma en PDF
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
          </div>

<div className='div-container-tecno-prestador'>
          <div className="input_divido_tecno_cliente">
            <div className="input_divido_children_tecno">
              <TextField className="login-input-email"
                required
                type="date"
                name="fechaInicio"
                id="fechaInicio"
                label="Desde"
                value={this.state.fechaInicio}
                onChange={this.handleChange}
                fullWidth
                helperText={errors.fechaInicio}
                error={errors.fechaInicio ? true : false}
                InputLabelProps={{
                  shrink: true,
                }}
                
              />
            </div>
            <div className="input_divido_children_tecno">
              <TextField className="login-input-email"
                required
                type="date"
                name="fechaFin"
                id="fechaFin"
                label="Hasta"
                value={this.state.fechaFin}
                onChange={this.handleChange}
                fullWidth
                helperText={errors.fechaFin}
                error={errors.fechaFin ? true : false}
                InputLabelProps={{
                  shrink: true,
                }}
                
              />
            </div>
            <div className='input_divido_tecno_boton'>
              <Button type="submit" buttonSize="btn--medium" buttonColor='blue' onClick={this.handleFiltrar}>
                Filtrar</Button>
            </div>
          </div>



        </div>
    <MaterialTable
            columns={columns}
            data={progmtosinfra}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Mantenimientos Preventivos Programados</h2>
            }
            icons={tableIcons}
            options={{
              cellStyle: {
                  fontSize: 12,
                  fontWeight:500,
                  color:'#696B6D'
                
               
                },
                headerStyle: {
                  fontSize: 14,
                  fontWeight: 'bolder',
                  color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                }
            
            }}
    
            actions={[
                
              rowData => ({
                icon: tableIcons.Add,
                tooltip: 'Hacer Mantenimiento',
                onClick: (event, rowData) => {
                    this.handleOpen(rowData);
                },
                disabled: rowData.estado==='realizado'
              })
             ,
                
                {
                  icon: tableIcons.Delete,
                  tooltip: 'Borrar Programación',
                  onClick: (event, rowData) => {
                      
                    this.handleOpenDelete(rowData.progMtoId);
                  }
                }
              ]}
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
       

        return (
           <div className="listamtoprog-container">
              
            <div className="listamtoprog-table">
            {mapeoprogmtos}
            </div>

            <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
              {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Cargado Exitosamente</h2>
              </div>
                
                ) : (<div>
                     <DialogTitle>Realizar Reporte de Mantenimiento Infraestructura</DialogTitle>
                     <DialogContent>
                              <form>
                    


                              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                    <h4 className="text-title-accorion">Tiempos del Mantenimiento</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div className="div-accordion-container">
                <div className="div-input-doble-mtoprog">
                <TextField className="login-input-email"
                                required
                                type="date"
                                name="fechainicio"
                                id="fechainicio"
                                label="Fecha Inicio de Mantenimiento"
                                value={this.state.fechainicio}
                                onChange={this.handleChange}
                                min="2019-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.fechainicio}
                                error={errors.fechainicio?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />

                          <TextField className="login-input-email"
                                required
                                type="time"
                                name="horainicio"
                                id="horainicio"
                                label="Hora Inicio de Mantenimiento"
                                value={this.state.horainicio}
                                onChange={this.handleChange}
                                min="2000-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.horainicio}
                                error={errors.horainicio?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                    </div>
                    <div className="div-input-doble-mtoprog">
                <TextField className="login-input-email"
                                required
                                type="date"
                                name="fechafinal"
                                id="fechafinal"
                                label="Fecha Final del Mantenimiento"
                                value={this.state.fechafinal}
                                onChange={this.handleChange}
                                min="2019-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.fechafinal}
                                error={errors.fechafinal?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />

                          <TextField className="login-input-email"
                                required
                                type="time"
                                name="horafinal"
                                id="horafinal"
                                label="Hora Final del Mantenimiento"
                                value={this.state.horafinal}
                                onChange={this.handleChange}
                                min="2000-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.horafinal}
                                error={errors.horafinal?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                    </div>
                    <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Duración Esperada en Horas</InputLabel>
                                      <Select
                                      required
                                  name="duracion"
                                  id="duracion"
                                  value={this.state.duracion}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="1">1</MenuItem>
                                      <MenuItem value="2">2</MenuItem>
                                      <MenuItem value="4">4</MenuItem>
                                      <MenuItem value="8">8</MenuItem>
                                      <MenuItem value="12">12</MenuItem>
                                      <MenuItem value="24">24</MenuItem>
                                      <MenuItem value="48">48</MenuItem>
                                      <MenuItem value="72">72</MenuItem>
                                      <MenuItem value="96">96</MenuItem>
                                  
                                      </Select>
                                      <FormHelperText>{errors.duracion}</FormHelperText>
                                  </FormControl>  
                    </div>
                    </AccordionDetails>
                    </Accordion>


                    <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                    <h4 className="text-title-accorion">Actividades Realizadas</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div className="div-accordion-container">
                <TextField className="login-input-email"
                                required
                                type="text"
                                name="taskElectrica"
                                id="taskElectrica"
                                label="Actividades de Red Electrica y Luminarias"
                                value={this.state.taskElectrica}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskElectrica}
                                error={errors.taskElectrica?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />

<TextField className="login-input-email"
                                required
                                type="text"
                                name="taskHidraulica"
                                id="taskHidraulica"
                                label="Actividades de Red Hidraulica y Sanitaria"
                                value={this.state.taskHidraulica}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskHidraulica}
                                error={errors.taskHidraulica?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />

<TextField className="login-input-email"
                                required
                                type="text"
                                name="taskComunicaciones"
                                id="taskComunicaciones"
                                label="Actividades de Red Comunicaciones y Datos"
                                value={this.state.taskComunicaciones}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskComunicaciones}
                                error={errors.taskComunicaciones?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />

<TextField className="login-input-email"
                                required
                                type="text"
                                name="taskGases"
                                id="taskGases"
                                label="Actividades de Red Gases, Vapor y Otros"
                                value={this.state.taskGases}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskGases}
                                error={errors.taskGases?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />

<TextField className="login-input-email"
                                required
                                type="text"
                                name="taskInstalaciones"
                                id="taskInstalaciones"
                                label="Actividades de Instalaciones Fisicas, Pisos, Techos y Otros"
                                value={this.state.taskInstalaciones}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.taskInstalaciones}
                                error={errors.taskInstalaciones?true:false}
                                multiline
                                minRows={3}
                                                
                              
                                />
                    </div>
                    </AccordionDetails>
                    </Accordion>

                    <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="text-title-accorion">Observaciones</h4>
                </AccordionSummary>
                <AccordionDetails>
                <TextField className="login-input-email"
                              
                              multiline
                              type="Text"
                              name="observaciones"
                              id="observaciones"
                              label="Observaciones"
                              rows={4}
                              value={this.state.observaciones}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.observaciones}
                              error={errors.observaciones?true:false}
                          
                              />
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="text-title-accorion">Materiales Usados</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div className="div-accordion-container">

                        <div className="div-mediciones-container">
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="nombrerepuesto"
                                    id="nombrerepuesto"
                                    label="Material"
                                    value={this.state.nombrerepuesto}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.nombrerepuesto}
                                    error={errors.nombrerepuesto?true:false}
                                
                                    />
                            </div>
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Number"
                                    name="cantidad"
                                    id="cantidad"
                                    label="Cantidad"
                                    value={this.state.cantidad}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.cantidad}
                                    error={errors.cantidad?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="costo"
                                    id="costo"
                                    label="Costo"
                                    value={this.state.costo}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.costo}
                                    error={errors.costo?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="subtotal"
                                    id="subtotal"
                                    label="subtotal"
                                    value={this.state.subtotal}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.subtotal}
                                    error={errors.subtotal?true:false}
                                    InputProps={{
                                    readOnly: true,
                                    }}
                                
                                    />
                            </div>
                        </div>


                        <div className="div-mediciones-container">
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="nombrerepuesto1"
                                    id="nombrerepuesto1"
                                    label="Material"
                                    value={this.state.nombrerepuesto1}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.nombrerepuesto1}
                                    error={errors.nombrerepuesto1?true:false}
                                
                                    />
                            </div>
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Number"
                                    name="cantidad1"
                                    id="cantidad1"
                                    label="Cantidad"
                                    value={this.state.cantidad1}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.cantidad1}
                                    error={errors.cantidad1?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="costo1"
                                    id="costo1"
                                    label="Costo"
                                    value={this.state.costo1}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.costo1}
                                    error={errors.costo1?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="subtotal1"
                                    id="subtotal1"
                                    label="subtotal"
                                    value={this.state.subtotal1}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.subtotal1}
                                    error={errors.subtotal1?true:false}
                                    InputProps={{
                                    readOnly: true,
                                    }}
                                
                                    />
                            </div>
                        </div>


                        <div className="div-mediciones-container">
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="nombrerepuesto2"
                                    id="nombrerepuesto2"
                                    label="Material"
                                    value={this.state.nombrerepuesto2}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.nombrerepuesto2}
                                    error={errors.nombrerepuesto2?true:false}
                                
                                    />
                            </div>
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Number"
                                    name="cantidad2"
                                    id="cantidad2"
                                    label="Cantidad"
                                    value={this.state.cantidad2}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.cantidad2}
                                    error={errors.cantidad2?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="costo2"
                                    id="costo2"
                                    label="Costo"
                                    value={this.state.costo2}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.costo2}
                                    error={errors.costo2?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="subtotal2"
                                    id="subtotal2"
                                    label="Subtotal"
                                    value={this.state.subtotal2}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.subtotal2}
                                    error={errors.subtotal2?true:false}
                                    InputProps={{
                                    readOnly: true,
                                    }}
                                
                                    />
                            </div>
                        </div>


                        <div>

                        <a>Total:  ${this.state.totalrepuesto}</a>

                        </div>





                        </div>

                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                
                <h4 className="text-title-accorion">Personal que recibe la actividad</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="div-accordion-container">
                  <TextField className="login-input-email"
                              required
                              type="Text"
                              name="firmarecibe"
                              id="firmarecibe"
                              label="Nombre de Quien Recibe La Actividad"
                              value={this.state.firmarecibe}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.firmarecibe}
                              error={errors.firmarecibe?true:false}
                          
                              />

        <TextField className="login-input-email"
                              required
                              type="Text"
                              name="cargorecibe"
                              id="cargorecibe"
                              label="Cargo de Quien Recibe la Actividad"
                              value={this.state.cargorecibe}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.cargorecibe}
                              error={errors.cargorecibe?true:false}
                          
                              />
                  </div>
                </AccordionDetails>
              </Accordion>
                             
                      

                    

                                 




                      </form>
                      </DialogContent>
                </div>
               
                )}
            
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Realizar Mantenimiento" onClick={this.handlePostMto}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
           </DialogActions>

            </Dialog>
          
           
           
            <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-delete'>
                    <FaTrash color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Borrado Exitosamente</h2>
              </div>
                
                ):(<div>
                <DialogTitle>¿Estás seguro de borrar programación de Mto?</DialogTitle>
                </div>)}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseDelete}>
                    <FaCheckCircle color="#f9044e" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Borrar Programación" onClick={this.handleDelete}>
                    <FaTrash color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
           </DialogActions>
            </Dialog>
        
           </div>

         
        )
    }
}

PlanMtoPerfilTabla.propTypes = {
    postMtoInfra:PropTypes.func.isRequired,
    getProgMtosInfraCliente: PropTypes.func.isRequired,
    getProgMtosInfraClienteFiltrado: PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,    
    data:PropTypes.object.isRequired,
    datainfra:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteProgMtoInfra:PropTypes.func.isRequired,
    
  };

const mapActionsToProps={postMtoInfra,getProgMtosInfraCliente,clearErrors,deleteProgMtoInfra,getProgMtosInfraClienteFiltrado};

const mapStateToProps = (state)=>({
    data:state.data,
    datainfra:state.datainfra,
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(PlanMtoPerfilTabla);



