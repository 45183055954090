import React, { Component, forwardRef } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';

import { getAnalisisObsolescenciaUsuario, postAnalisisPrestador } from '../../../redux/actions/dataactions';

import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Input, Slider } from '@material-ui/core';
import Button from '../../Elements/Button';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable from 'material-table';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowUpward from '@material-ui/icons/ArrowUpward';


import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft, FaArrowLeft, FaFirstAid, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './ObsolUsuario.css';
import ObsolIndDialog from './ObsolIndDialog';
class ObsolUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estadoReporte: '',
      tipouser: 'Ingeniero', // Puedes cambiar este valor para probar diferentes casos
      En_Mantenimiento: 0.7,
      De_Baja: 0.75,
      Correctivos: 0.25,
      Solicitudes: 0.25,
      Mas_viejos: 0.5,
      vida: 0.75,
      casos_tecnovigilancia: 0.25,
      umbral: 0.85,

      openParametros: false,
      openIndicadores: false,
      openAnalisisPrev: false,
    };
  }

  componentDidMount() {
    // Consultar último reporte al iniciar la página

  }

  handleInputChange = (event) => {
    this.setState(event.target.value === '' ? Number(0) : Number(event.target.value));
  };

  handleSliderChange = (name) => (event, newValue) => {
    this.setState({ [name]: newValue });
  };

  handleAnalizar = () => {
    this.props.getAnalisisObsolescenciaUsuario(this.props.usuario.handle,
      this.state.En_Mantenimiento, this.state.De_Baja, this.state.Correctivos, this.state.Solicitudes,
      this.state.Mas_viejos, this.state.vida, this.state.casos_tecnovigilancia, this.state.umbral
    );
    this.setState({ openParametros: false });
  }

  handleOpenParametros = () => {
    this.setState({ openParametros: true });
  }

  handleCloseParametros = () => {
    this.setState({ openParametros: false });
  }

  handleOpenIndicadores = () => {
    this.setState({ openIndicadores: true });
  }
  handleCloseIndicadores = () => {
    this.setState({ openIndicadores: false });
  }

  handleOpenAnalisisPrevio = () => {
    this.setState({ openAnalisisPrev: true });
  }
  handleCloseAnalisisPrevio = () => {
    this.setState({ openAnalisisPrev: false });
  }

  handleGuardarAnalisis = () => {
    const data = {
      En_Mantenimiento: this.state.En_Mantenimiento, De_Baja: this.state.De_Baja, Correctivos: this.state.Correctivos, Solicitudes: this.state.Solicitudes,
      Mas_viejos: this.state.Mas_viejos, vida: this.state.vida, casos_tecnovigilancia: this.state.casos_tecnovigilancia, umbral: this.state.umbral
      , analisis: this.props.data.analisisObsolescenciaUsuario
      , handle: this.props.usuario.handle,
    }
    this.props.postAnalisisPrestador(data)
  }


  render() {
    const { analisisObsolescenciaUsuario } = this.props.data;
    const { loadingdata } = this.props.UI

    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      Description: forwardRef((props, ref) => <DescriptionIcon {...props} ref={ref} />),
      MiIcon: forwardRef((props, ref) => <AddToPhotosIcon {...props} ref={ref} />),
      MiIcon2: forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref} />),
      MiIcon3: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
      MiIcon4: forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref} />),
    };

    const columns = [
      {
        field: 'imagenurl',
        title: 'Avatar',
        render: rowData => <img src={rowData.imagenurl} style={{ width: 50, borderRadius: '50%' }} />
      },
      { title: 'Id inventario', field: 'idinventario' },
      { title: 'Prioridad', field: 'prioridad' },
      { title: 'Cliente/sede', field: 'nombresede' },
      { title: 'Nombre', field: 'nombre' },
      { title: 'Marca', field: 'marca' },
      { title: 'Modelo', field: 'modelo' },
      { title: 'Ubicación', field: 'ubicacion' },
    ]

    let mapeoObsolescencia = !loadingdata ? (
      analisisObsolescenciaUsuario.length > 0 ? (
        <div className='container-table-obsolescencia'>
          <MaterialTable
            columns={columns}
            data={analisisObsolescenciaUsuario}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c', fontWeight: '600' }}>Resultado del análisis de obsolescencia</h2>
            }
            icons={tableIcons}
            options={{
              cellStyle: {
                fontSize: 12,
                fontWeight: 500,
                color: '#696B6D'
              },
              headerStyle: {
                fontSize: 14,
                fontWeight: 'bolder',
                color: "#03178c" // Ajusta el tamaño de fuente según tus necesidades
              }
            }}
            actions={[
              rowData => ({
                icon: () => <Link to={`/dashboard/perfilcliente/equipo/${this.props.clienteId}/${rowData.equipoid}`} style={{ color: '#03178C' }} ><AssignmentIcon /></Link>,
                tooltip: 'Ver Perfil ',
                onClick: (rowData)
              })
              ,
              {
                icon: tableIcons.Export,
                tooltip: 'Guardar análisis',
                isFreeAction: true,
                onClick: this.handleGuardarAnalisis
              }
            ]}

          >
          </MaterialTable>
        </div >
      ) : (
        <p className='text-card-notificacion-obsol'> No tiene ningún registro guardado, por favor ajuste los parámetros de análisis según sus preferencias y realice la consulta</p>
      )

    ) : (
      <div className="div_cargando">
        <CircularProgress size={50} color="primary" className="login-progress" />
      </div>
    );

    return (
      <div>
        <div className="titulo_section">
          <div className="titulo_clientes">
            <h1 className="text-clientes">Análisis de obsolescencia de todos sus prestadores</h1>
            <hr className="clientes_separator"></hr>

          </div>
          <div className="div_boton">
            <Link to={`/dashboard`}>
              <MyButton tip="Volver">
                <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
              </MyButton>
            </Link>
          </div>
        </div>

        <div className='div-container-links-inventario'>
          <div className='div-card-link' onClick={this.handleOpenParametros}>
            <div className="dashing-cardcontent">
              <div className="dashing-cardtext">
                <p className="dashing-text-card">
                  Ajustar parámetetros
                </p>
              </div>
              <div className="dashing-cardicon">
                <FaFirstAid color="#FFFFFF" className="dashing-icon" />
              </div>
            </div>
          </div>

          <ObsolIndDialog ></ObsolIndDialog>

     

        
        </div>



        {mapeoObsolescencia}

        <Dialog
          open={this.state.openParametros}
          onClose={this.handleCloseParametros}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Ajustar los parámetros de análisis</DialogTitle>


          <DialogContent>
            <div>
              <div>
                <div>
                  <InputLabel>Equipos en mantenimiento</InputLabel>
                  <Slider
                    name="En_Mantenimiento"
                    value={this.state.En_Mantenimiento}
                    onChange={this.handleSliderChange('En_Mantenimiento')}
                    min={0.025}
                    max={1}
                    step={0.025}
                  />
                  <Input
                    name="En_Mantenimiento"
                    value={this.state.En_Mantenimiento}
                    margin="dense"
                    onChange={this.handleInputChange}
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </div>
                <div>
                  <InputLabel>Equipos de baja</InputLabel>
                  <Slider
                    name="De_Baja"
                    value={this.state.De_Baja}
                    onChange={this.handleSliderChange('De_Baja')}
                    min={0.025}
                    max={1}
                    step={0.025}
                  />
                  <Input
                    name="De_Baja"
                    value={this.state.De_Baja}
                    margin="dense"
                    onChange={this.handleInputChange}
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </div>
                <div>

                  <InputLabel>Equipos más antiguos</InputLabel>
                  <Slider
                    name="Mas_viejos"
                    value={this.state.Mas_viejos}
                    onChange={this.handleSliderChange('Mas_viejos')}
                    min={0.025}
                    max={1}
                    step={0.025}
                  />
                  <Input
                    name="Mas_viejos"
                    value={this.state.Mas_viejos}
                    margin="dense"
                    onChange={this.handleInputChange}
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />

                </div>
                <div>
                  <InputLabel>Equipos que cumplan la vida util este año</InputLabel>
                  <Slider
                    name="vida"
                    value={this.state.vida}
                    onChange={this.handleSliderChange('vida')}
                    min={0.025}
                    max={1}
                    step={0.025}
                  />
                  <Input
                    name="vida"
                    value={this.state.vida}
                    margin="dense"
                    onChange={this.handleInputChange}
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </div>
              </div>
              <div>
                <div>
                  <InputLabel>Cantidad de mantenimientos correctivos realizados</InputLabel>
                  <Slider
                    name="Correctivos"
                    value={this.state.Correctivos}
                    onChange={this.handleSliderChange('Correctivos')}
                    min={0.025}
                    max={1}
                    step={0.025}
                  />
                  <Input
                    name="Correctivos"
                    value={this.state.Correctivos}
                    margin="dense"
                    onChange={this.handleInputChange}
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </div>
                <div>
                  <InputLabel>Cantidad de solicitudes de mantenimiento correctivos</InputLabel>
                  <Slider
                    name="Solicitudes"
                    value={this.state.Solicitudes}
                    onChange={this.handleSliderChange('Solicitudes')}
                    min={0.025}
                    max={1}
                    step={0.025}
                  />
                  <Input
                    name="Solicitudes"
                    value={this.state.Solicitudes}
                    margin="dense"
                    onChange={this.handleInputChange}
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </div>
                <div>
                  <InputLabel>Cantidad de reportes de tecnovigilancia</InputLabel>
                  <Slider
                    name="casos_tecnovigilancia"
                    value={this.state.casos_tecnovigilancia}
                    onChange={this.handleSliderChange('casos_tecnovigilancia')}
                    min={0.025}
                    max={1}
                    step={0.025}
                  />
                  <Input
                    name="casos_tecnovigilancia"
                    value={this.state.casos_tecnovigilancia}
                    margin="dense"
                    onChange={this.handleInputChange}
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </div>
                <div>
                  <InputLabel>Umbral de clasificación</InputLabel>
                  <Slider
                    name="umbral"
                    value={this.state.umbral}
                    onChange={this.handleSliderChange('umbral')}
                    min={0.025}
                    max={1}
                    step={0.025}
                  />
                  <Input
                    value={this.state.umbral}
                    margin="dense"
                    onChange={this.handleInputChange}
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                    name="umbral"
                  />
                </div>
              </div>
            </div>
          </DialogContent>


          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseParametros}>
              <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
            </MyButton>
            <MyButton tip="Confirmar cambios" onClick={this.handleAnalizar}>
              <FaPlus color="#03178C" className="icon-formulario" />
            </MyButton>
          </DialogActions>

        </Dialog>


        <Dialog
          open={this.state.openIndicadores}
          onClose={this.handleCloseIndicadores}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Indicadores de este análisis</DialogTitle>


          <DialogContent>
          </DialogContent>


          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseIndicadores}>
              <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
            </MyButton>
          </DialogActions>

        </Dialog>

        <Dialog
          open={this.state.openAnalisisPrev}
          onClose={this.handleCloseAnalisisPrevio}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Revision de análisis previos</DialogTitle>


          <DialogContent>
          </DialogContent>


          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseAnalisisPrevio}>
              <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
            </MyButton>
          </DialogActions>

        </Dialog>
      </div>
    );
  }
}

ObsolUsuario.propTypes = {
  data: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapActionsToProps = {
  getAnalisisObsolescenciaUsuario,
  postAnalisisPrestador
};

const mapStateToProps = (state) => ({
  data: state.data,
  UI: state.UI,
  usuario: state.user.credentials
})

export default connect(mapStateToProps, mapActionsToProps)(ObsolUsuario);