import React,{Component} from 'react';
import imgsrc from '../Navbar/logohorizontal.svg';
import TextField from '@material-ui/core/TextField';
import Button from '../Elements/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
//REDUX
import { connect} from 'react-redux';
import {crearPerfilUser} from '../../redux/actions/useractions';
import { Typography } from '@material-ui/core';

import './CrearPerfil.css'; 

class CrearPerfil extends Component{
    constructor(){
        super();
        this.state={
            open:false,
            nombre:'',
            ccnit:'',
            tipouser:'',
            telefono:'',
            direccion:'',
            ciudad:'',
            idprestador:'',
            handleuser:null,  
            errors:{}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };
    

    static getDerivedStateFromProps(props, state) {
        return props.UI.errors ? {errors: props.UI.errors} : null;
    }

    

    handleSubmit=(event)=>{
        
        event.preventDefault(); 
       
        const newUserData={
            nombre:this.state.nombre,
            ccnit:this.state.ccnit,
            tipouser:this.state.tipouser,
            telefono:this.state.telefono,
            direccion:this.state.direccion,
            ciudad:this.state.ciudad,
            idprestador:this.state.idprestador,
            handleuser:this.state.handleuser    
        };
        console.log(newUserData);
        this.props.crearPerfilUser(newUserData, this.props.history); 
      
    };
    
   handleChange=(event)=>{
    this.setState({
        [event.target.name]:event.target.value
    });
       
   };


    render(){
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;
        
   let mapeoinput=this.state.tipouser=='Asistencial'||this.state.tipouser=='Compras'?(<div>

<TextField className="login-input-email"
                      required
                      type="text"
                      name="idprestador"
                      id="idprestador"
                      label="Id Alpha Biomédica del Prestador"
                      value={this.state.idprestador}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.idprestador}
                      error={errors.idprestador?true:false}
                      />
   </div>):(<div></div>);

   let mapeoinputtec=this.state.tipouser=='Tecnico'?(<div>
    <TextField className="login-input-email"
                      required
                      type="text"
                      name="handleuser"
                      id="handleuser"
                      label="User Handle de la cuenta de Ingeniería"
                      value={this.state.handleuser}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.handleuser}
                      error={errors.handleuser?true:false}
                      />

   </div>):(<div></div>);
        
        return (
            <div id="crearperfil">
            <div className="crearperfil-container">
                    <div className="crearperfil-card">
                        <div className="crearperfil-divtitulo">
                        <Link to="/" className='crearperfil-logo'>
                        <img src={imgsrc} alt="logo" />
                        </Link>
                        
                         <h1 className="crearperfil-titulo">Crea tu Perfil</h1>
                        </div>
    
                        <form className="crearperfil-form" noValidate onSubmit={this.handleSubmit}>
                    
                      <TextField className="login-input-email"
                      required
                      type="text"
                      name="nombre"
                      id="nombre"
                      label="Nombre Completo"
                      value={this.state.nombre}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombre}
                      error={errors.nombre?true:false}
                      />
                       
                       <TextField className="login-input-email"
                      required
                      type="number"
                      name="ccnit"
                      id="ccnit"
                      label="Numero de Documento CC/NIT"
                      value={this.state.ccnit}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ccnit}
                      error={errors.ccnit?true:false}
                      />

                    <FormControl>
                            <InputLabel>Tipo Usuario</InputLabel>
                            <Select
                            required
                         name="tipouser"
                         id="tipouser"
                         value={this.state.tipouser}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Empresa">Empresa</MenuItem>
                            <MenuItem value="Ingeniero">Ingeniero</MenuItem>
                            <MenuItem value="Asistencial">Asistencial</MenuItem>
                            <MenuItem value="Compras">Compras</MenuItem>
                            <MenuItem value="Tecnico">Tecnico</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipouser}</FormHelperText>
                        </FormControl>

                        {mapeoinput}
                        {mapeoinputtec}

                        <TextField className="login-input-email"
                      required
                      type="tel"
                      name="telefono"
                      id="telefono"
                      label="Número de Contacto"
                      value={this.state.telefono}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.telefono}
                      error={errors.telefono?true:false}
                      />
                    <TextField className="login-input-email"
                      required
                      type="text"
                      name="direccion"
                      id="direccion"
                      label="Dirección"
                      value={this.state.direccion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.direccion}
                      error={errors.direccion?true:false}
                      />
                         <TextField className="login-input-email"
                      required
                      type="text"
                      name="ciudad"
                      id="ciudad"
                      label="Ciudad"
                      value={this.state.ciudad}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ciudad}
                      error={errors.ciudad?true:false}
                      />
                                     
                                
                      
                        <br/>
                        {errors.general && (
                        <Typography variant="body2">{errors.general}</Typography>
                            )}
                       
    
    
                        {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                         <Button type="submit" buttonSize="btn--wide" buttonColor='blue'>
                           Crear Perfil</Button>}
                           <br/>
                         
                    </form>
    
    
                    </div>
    
                   
    
    
                </div>
            </div>
        )
    }
    
}

CrearPerfil.propTypes={
    user:PropTypes.object.isRequired,
    UI:PropTypes.object.isRequired,
    crearPerfilUser:PropTypes.func.isRequired
}

const mapStateToProps=(state)=>({
    user:state.user,
    UI:state.UI
})

export default connect(mapStateToProps,{crearPerfilUser}) (CrearPerfil);

