import React from 'react';
import './MobInventarioDocuPDF.css';
const MobInventarioWebPDF = ({ mobiliarios }) => {
    let mapeomobiliarios=
        (mobiliarios.length>0?(
            mobiliarios.map((mobiliario)=>
            <div className='itemcard'>
                <div className='itemelement'>
                <p className='titleitem-text'>Id Inventario</p>
                <p className="item-text">{mobiliario.idinventario}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Nombre</p>
                <p className="item-text">{mobiliario.nombre}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Marca</p>
                <p className="item-text">{mobiliario.marca?mobiliario.marca:'No Registra'}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Serie</p>
                <p className="item-text">{mobiliario.serie}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Ubicación</p>
                <p className="item-text">{mobiliario.ubicacion}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Servicio</p>
                <p className="item-text">{mobiliario.servicio}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Estado</p>
                <p className="item-text">{mobiliario.estado}</p>
                </div>
                <div className='itemelement'>
                <p className='titleitem-text'>Tipo</p>
                <p className="item-text">{mobiliario.tipo}</p>
                </div>
               
            
            </div>
           
            )
        ):(<div>
                <p>no hay nada</p>
        </div>)
        )
    
    return (
        <div className='ContainerMainInventario'>
            <div className='DivTitleInventario'>
                <h1 className='TitleInventario'>Inventario de Equipos Mobiliarios e Industriales</h1>
            </div>
          
         {mapeomobiliarios}
        </div>
        
        )
}

export default MobInventarioWebPDF