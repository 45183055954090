import React, { Component } from 'react';

import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getAreas} from '../../../redux/actions/infraactions';
import {subirImagenArea} from '../../../redux/actions/infraactions';

import {deleteRepuesto} from '../../../redux/actions/dataactions';
import {editRepuesto} from '../../../redux/actions/dataactions';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import './TablaAreasAsistencial.css'
import {Link} from 'react-router-dom';

import MyButton from '../../../utils/MyButton';


import { Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';


import { FaArrowLeft,FaPlus,FaFilePdf, FaTrash, FaCheck, FaCheckCircle, FaRegEdit } from "react-icons/fa";

import TextField from '@material-ui/core/TextField';





export class TablaAreasAsistencial extends Component {
    
    state = { 
        // Initially, no file is selected 
        selectedFile: null,
        open: false,        
        opendelete:false,
        aprobado:{},
        errors:{},
        openedit:false,
        areaid:'',
        areaedit:{},
        nombre:'',
        servicio:'',
        piso:'',
        estado:'',
        ubicacion:'',
      }; 


      static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }
     

    componentDidMount(){
        console.log(this.props.clienteId)
        this.props.getAreas(this.props.clienteId);
      }

     

       

  render() {
    const {areas,loadingdata}= this.props.datainfra;
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        MiIcon:forwardRef((props, ref) => <AddToPhotosIcon {...props} ref={ref}/>),
        MiIcon2:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
        MiIcon3:forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
        MiIcon4:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
        editRegister: forwardRef((props, ref) => <FaRegEdit {...props} ref={ref} />)
      };

    const columns=[
        {
            field: 'imagenurl',
            title: 'Avatar',
            render: rowData => <img src={rowData.imagenurl} style={{width: 50,height:50, borderRadius: '50%'}}/>
          },
    
        
        {title:'Nombre',field:'nombre'},
        {title:'Servicio',field:'servicio'},
        {title:'Ubicación',field:'ubicacion'},
        {title:'Piso',field:'piso'},
        {title:'Estado',field:'estado'},
        
        
    ]

    let mapeoareas=!loadingdata ?(
        <div>

<div className='div-container-links-inventario'>
                       
                        
                    </div>
            
             

        <MaterialTable
        columns={columns}
        data={areas}
        title={
            <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'bold' }}></h2>
          }
        icons={tableIcons}
        options={{
            cellStyle: {
                fontSize: 11,
                fontWeight:500,
                color:'#696B6D'
              
             
              },
              headerStyle: {
                fontSize: 12,
                fontWeight: 'bolder',
                color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
              }
          
          }}

        actions={[
            rowData => ({
                icon: () =>  <Link to={`/dashboard/infrasasistencial/${this.props.clienteId}/infraestructura/${rowData.areaId}`} style={{ color: '#03178C' }} ><AssignmentIcon  /></Link>,
                tooltip: 'Ver Perfil ',
                onClick: (rowData)
              }),
          
            
           

             
             
           
          ]}
         
        >
            {//window.location.href=`equipo/${this.props.clienteId}/${rowData.equipoid}`/dashboard/perfilcliente/equipo/:clienteId/:equipoId
            }
        </MaterialTable>

{/*//aqui va el dialogo */}


        
        </div>
        ):(
        <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
        )

    return (
        <div>
        {mapeoareas}

       
    </div>
    )
  }
}

TablaAreasAsistencial.propTypes={
    getAreas:PropTypes.func.isRequired,
    subirImagenArea:PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,
    clienteId: PropTypes.string.isRequired,
   
    UI:PropTypes.object.isRequired,  
}
const mapStateToProps=state=>({
    datainfra:state.datainfra,
    user:state.user,
    UI:state.UI
})
export default connect(mapStateToProps,{getAreas,subirImagenArea}) (TablaAreasAsistencial);

