import React, { Component } from 'react';
import MyButton from '../../../utils/MyButton';
import {FaHospitalAlt} from "react-icons/fa";
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

export class VerPerfil extends Component {
    render() {
        return (
            <div className="div-botonverperfil">
            <Link to={`/dashboard/perfilcliente/${this.props.clienteId}`}>
            <MyButton tip="Ver Perfil">
              <FaHospitalAlt color="#03178C" className="icon-eliminarcliente"/>
              </MyButton>
            </Link>

           
            </div>
        )
    }
}
VerPerfil.propTypes = {
    clienteId: PropTypes.string.isRequired  
};

export default VerPerfil
