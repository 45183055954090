import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {postCronogramaMtoAuto} from '../../../redux/actions/dataactions';
import {progMtoAutoInfra,getProgMtosInfraClienteFiltrado} from '../../../redux/actions/infraactions';
import {clearErrors} from '../../../redux/actions/dataactions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';



import { FaArrowLeft,FaPlus,FaFirstAid, FaChartBar, FaCheckCircle, FaCheck, FaCalendarWeek } from "react-icons/fa";
import './PlanMtoIndicadoresDialog.css';

export class PlanMtoIndicadoresDialog extends Component {
    constructor(){
        super();
        this.state={
            errors:{},
            aprobado:{},
            open:false
        };
        
    };

    handleOpen=()=>{
        this.setState({open:true});
        };


 handleClose=()=>{
    this.setState({
    open:false});
};

calculatePercentages = (progmtosinfra) => {
    if (!progmtosinfra || progmtosinfra.length === 0) {
        return { realizado: 0, sinRealizar: 0 };
    }

    const total = progmtosinfra.length;
    const realizados = progmtosinfra.filter(p => p.estado === 'realizado').length;
    const sinRealizar = total - realizados;
    console.log(realizados);
    console.log(sinRealizar);
    return {
        realizado: (realizados / total) * 100,
        sinRealizar: (sinRealizar / total) * 100
    };
}

calculateNumProgs=(progmtosinfra)=>{
    if (!progmtosinfra || progmtosinfra.length === 0) {
        return { realizado: 0, sinRealizar: 0 };
    }

    const total = progmtosinfra.length;
    const realizados = progmtosinfra.filter(p => p.estado === 'realizado').length;
    const sinRealizar = total - realizados;

    return {
        realizado: realizados,
        sinRealizar: sinRealizar
    };
}


render() {
        const { UI:{loading}}=this.props; 
        const {progmtosinfra}=this.props.datainfra;
        const {errors}=this.state;
        const percentages = this.calculatePercentages(progmtosinfra);
        const totalprog=progmtosinfra ? Object.keys(progmtosinfra).length : 0
        const numprogs=this.calculateNumProgs(progmtosinfra);

        const databar = {
            labels: ['Realizado', 'Sin Realizar'],
            datasets: [
              {
                
                backgroundColor: [
                    "#0962d5",
                    "#338bf7",
                  ],
                
                borderWidth: 1,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff',
                data: [numprogs.realizado,
                    numprogs.sinRealizar
                    ],
              },
            ],
          };

          const optionsbar = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false, // Oculta la leyenda, que incluye las etiquetas del dataset
              },
            title: {
                display: false, // Oculta el título del gráfico
              },
            scales: {
                xAxes: [{
                    gridLines: {
                        display:false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display:false
                    }  , 
                    ticks: {
                        display: false, // Oculta los valores del eje Y
                        beginAtZero: true, // Asegúrate de comenzar en cero
                      },
                }]
            },
            plugins: {
                
                tooltip: {
                enabled: false,
                mode: 'index',
                intersect: false,
              },
            },
          };
          const   datapie = {
            datasets: [
              {
                data: [percentages.realizado.toFixed(1),
                    percentages.sinRealizar.toFixed(1)],
                backgroundColor: [
                    "#0962d5",
                  "#338bf7",
               
                  
        
                ],
                borderWidth: 5,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff'
              }
            ],
            labels: ['Realizados', 'Sin Realizar']
          };
          const optionspie = {
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            cutoutPercentage: 5,
            layout: { padding: 5},
            tooltips: {
              enabled: true,
              mode: 'index',
              intersect: false,
              borderWidth: 2,
              borderColor: "#03178C",
              backgroundColor: "#FFF",
              titleFontColor: "#03178C",
              bodyFontColor: "#03178C",
              footerFontColor: "#03178C"
            }
          };
        return (
          

        
            <Fragment>

                  <div className='div-card-link' onClick={this.handleOpen}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                            Indicadores
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon">
                                            <FaChartBar color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>
  
  
                </div>
           {/* <MyButton tip="Crear Equipo" onClick={this.handleOpen}>
            <FaFirstAid color="#03178C" className="icon-crearcliente"/>
        </MyButton>*/}

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                 
                  <h2 className='text-aprobado'>¡Programación de Mantenimiento Exitosa!</h2>
              </div>
                
                ) : (<div>
<DialogTitle className="text-title-accorion">Indicadores</DialogTitle>
               
              <DialogContent>
               
                <div className='div-container-info-ind-mtoifra'>
                <div className="dashing-cardicon">
                                            <FaCalendarWeek color="#FFFFFF" className="dashing-icon"/>
                </div>
                <div className='div-texto-info-ind-mtoinfra'>
                <p className='text-info-ind-mtoinfra'>Numero Total de programaciones  </p>
                <p className='text-info-ind-mtoinfra-num'>{totalprog}</p>
                </div>
                
                </div>
                <div>
                <p className='text-info-ind-mtoinfra-gray'>Programaciones Realizadas Vs Sin Realizar </p>
                <hr className="separator_indmtoinfra"></hr>
                </div>
               
                        <div className="dashing-card-data-container">
                        <div className="dashing-chart-container-mtoinfra">
                                <Bar
                                options={optionsbar}
                                data={databar}
                              
                                />
                        </div>
                        <div className="dashing-card-data">

                        <p className="dashing-text-data"> Sin Realizar</p>
                        <h2 className="dashing-value-data-blue">{numprogs.sinRealizar}</h2>
                        </div>


                        <div className="dashing-card-data">

                        <p className="dashing-text-data"> Realizadas</p>
                        <h2 className="dashing-value-data-blue">{numprogs.realizado}</h2>
                        </div>


                        </div>

                        <div>
                <p className='text-info-ind-mtoinfra-gray'>Porcentaje Prog. Realizadas Vs Prog. Sin Realizar </p>
                <hr className="separator_indmtoinfra"></hr>
                </div>
                        <div className="dashing-chart-container">
                                <Pie
                                options={optionspie}
                                data={datapie}
                                
                                />
                               
                        </div>

                        <div className="dashing-card-data-container">

<div className="dashing-card-data">

<p className="dashing-text-data"> Realizadas</p>
<h2 className="dashing-value-data-blue">{percentages.realizado.toFixed(1)}%</h2>
</div>


<div className="dashing-card-data">

<p className="dashing-text-data"> Sin Realizar</p>
<h2 className="dashing-value-data-blue">{percentages.sinRealizar.toFixed(1)}%</h2>
</div>


</div>
             
             
              </DialogContent>
                </div>)}
            
          
          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

            
            
            

            
          </DialogActions>
            </Dialog>
            </Fragment>
            
        )
    }
}

PlanMtoIndicadoresDialog.propTypes = {
    
    clienteId: PropTypes.string.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

const mapActionsToProps={clearErrors};

const mapStateToProps = (state)=>({
    user:state.user,
    UI:state.UI,
    datainfra:state.datainfra
})



export default connect(mapStateToProps,mapActionsToProps)(PlanMtoIndicadoresDialog);
