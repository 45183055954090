import React from 'react'
import PropTypes from 'prop-types'

function PerfilMobiliaro(props) {
  return (
    <div>PerfilMobiliaro</div>
  )
}

PerfilMobiliaro.propTypes = {}

export default PerfilMobiliaro
