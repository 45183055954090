import React, { Component, Fragment } from 'react'
import './NuevaRuta.css';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { cursosRecomendados, getCursosRecomendados, getTotalCursos, postRutaDeAprendizaje,guardarRutaCursos, totalCursos } from '../../../redux/actions/dataactions';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaUserPlus,FaArrowLeft,FaPlus, FaCheck, FaCheckCircle, FaSchool, FaEdit, FaSave } from "react-icons/fa";

import {postCliente,getClientes,clearErrors} from '../../../redux/actions/dataactions';
const preferences = [
    { value: 'pref1', label: 'Equipos de cirugía' },
    { value: 'pref2', label: 'Equipos de laboratorio médico' },
    { value: 'pref3', label: 'Unidades de esterilización' },
    { value: 'pref4', label: 'Equipos neonatales' },
    { value: 'pref5', label: 'Gestión de técnología hospitalaria' },
    { value: 'pref6', label: 'Equipos de Diagnóstico y Monitoreo' },
    { value: 'pref7', label: 'Dialisis' },
    { value: 'pref8', label: 'Odontología' },
    { value: 'pref9', label: 'Cuidado Intensivo' },
    {value:'pref10', label:'Imagenes Diagnosticas'}
  ];
export class NuevaRuta extends Component {
    constructor(){
        super();
        this.state={
            
            errors:{},
            aprobado:{},
            selectedPreferences: {},
            open:false
        };
        
    };

    static getDerivedStateFromProps(props, state) {
      const newState = {};
  
      if (props.UI.errors) {
          newState.errors = props.UI.errors;
      }
  
      if (props.UI.aprobado) {
         newState.aprobado = props.UI.aprobado;
      }
  
      return Object.keys(newState).length > 0 ? newState : null;
  }
 
    handleOpen=()=>{
    this.setState({open:true,
       errors:{},
      aprobado:{},});
    };

    handleClose=()=>{
      if(this.state.aprobado.mensaje){
          //this.handleEditProgMto();
          //get prog mtos 
         this.props.getCursosRecomendados(this.props.userHandle)
          this.setState({open:false,errors:{},aprobado:{}});
       }else{
        this.setState({open:false,errors:{},aprobado:{}});
       }
      }

    
    handleCrearRuta = () => {
        const selectedPreferences = this.state.selectedPreferences;
        const selectedPreferencesArray = Object.values(selectedPreferences);
        const message = selectedPreferencesArray.join(' , ');
    
        this.props.postRutaDeAprendizaje({ preferencias: message })
      }

      handlePreferenceChange = (event) => {
        const { value, checked } = event.target;
        const label = this.getLabelFromValue(value); // Obtener el label del array preferences
        this.setState((prevState) => ({
          selectedPreferences: {
            ...prevState.selectedPreferences,
            [value]: label,
          }
        }));
      }
    
      getLabelFromValue = (value) => {
        const preference = preferences.find(preference => preference.value === value);
        return preference ? preference.label : ''; // Obtener el label correspondiente al valor
      }

      handleGuardarRuta = () => {
        const recomendacionescursos=this.props.recomendacionCursos; 

        if(recomendacionescursos.recomendacionCursos){
            const cursosguardar={
                cursosRecomendados:recomendacionescursos.recomendacionCursos,
                user:this.props.userHandle
            }
    
            console.log(cursosguardar);
            this.props.guardarRutaCursos(cursosguardar);
        }
       
      }

    render() {
      const { UI:{loading}}=this.props; 
      const {errors}=this.state; 
      const recomendacionCursos = this.props.recomendacionCursos
      const loadingdata = this.props.loadingdata
        return (
            <Fragment>
            <MyButton tip="Nueva Ruta de Aprendizaje" onClick={this.handleOpen}>
            <FaSchool color="#03178C" className="icon-crearcliente"/>
             </MyButton>

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
                   

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Ruta Cargada Exitosamente</h2>
              </div>
                
                ) : (
                
                <div>
                <DialogTitle>Nueva Ruta de Aprendizaje</DialogTitle>
                <DialogContent>

                    {
                    recomendacionCursos.razon ? (
                        <div>
                        <h2> Recomendaciones: </h2>
                        <p> Basado en tus preferencias, estos son los cursos que te recomendamos:</p>
                       { recomendacionCursos.recomendacionCursos ? (
                               recomendacionCursos.recomendacionCursos.map(curso => (
                              <div className='div-card-cursoia'>
                                <div className="div-cursoia-imagen">
                                <img src={curso.imagen} alt="cursoimagen" className="imagen-cursoia"/>
                                </div>
                                <div className='div-card-textocursoia'>
                                <p className='textocursoia'>{curso.nombre}</p>
                                <p className='textodescripcioncursoia'>{curso.descripcion}</p>
                                    
                                </div>
                              </div>
                                ))
                            ) : (
                                <p>Todavía no tienes cursos recomendados, primero crea tu ruta de aprendizaje</p>
                            ) 
                        }
                        <h2> Razón: </h2>
                        <p>{recomendacionCursos.razon}</p>
                        </div>
                    ) : (
                        <div>
                        <p>Seleccione las temáticas de su preferencia basado en sus intereses, actividades principales en la institución
                            o tema que desee reforzar. La plataforma le recomendará una lista de cursos afines con sus preferencias y usted
                            podrá decidir si desea tomar estas recomendaciones, hacer una nueva consulta o directamente no aceptarlas.</p>
                        <p></p>
                        <p>Seleccione una o más preferencias:</p>
                        <FormGroup>
                            {preferences.map(preference => (
                            <FormControlLabel
                                key={preference.value}
                                control={
                                <Checkbox
                                    value={preference.value}
                                    onChange={this.handlePreferenceChange}
                                />
                                }
                                label={preference.label}
                            />
                            ))}
                        </FormGroup>
                        </div>
                    )
                    }

                    </DialogContent>
                                    
                

                </div>)}
           
                <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loadingdata? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (

                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#03178C" className="icon-formulario" />
                  </MyButton>
                ) : (

                    recomendacionCursos.razon ? (
                        <div>
                          
                          
                          <MyButton tip="Guardar Recomendaciones" onClick={this.handleGuardarRuta}>
                            <FaSave color="#03178C" className="icon-formulario" />
                          </MyButton>
    
                        </div>
                      ) : (
                        <MyButton tip="Crear Ruta de Aprendizaje" onClick={this.handleCrearRuta}>
                        <FaPlus color="#03178C" className="icon-formulario" />
                      </MyButton>
                      )

                
                )
              )}
            
           </DialogActions>
              

            </Dialog>


            </Fragment>
        )
    }
}

NuevaRuta.propTypes={
  postCliente:PropTypes.func.isRequired,
  clearErrors:PropTypes.func.isRequired,
  UI:PropTypes.object.isRequired,
  getClientes:PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  cursosTotal: PropTypes.object.isRequired,
  cursosRecomendados: PropTypes.object.isRequired,
  recomendacionCursos: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  loadingdata: PropTypes.bool.isRequired,

  totalCursos: PropTypes.func.isRequired,
  getCursosRecomendados: PropTypes.func.isRequired,
  postRutaDeAprendizaje: PropTypes.func.isRequired,
  guardarRutaCursos:PropTypes.func.isRequired
};
const mapStateToProps=(state)=>({
    userHandle: state.user.credentials.handle,
    cursosTotal: state.data.cursosTotal,
    cursosRecomendados: state.data.cursosRecomendados,
    recomendacionCursos: state.data.recomendacionCursos,
    loadingdata: state.UI.loadingdata,
    UI: state.UI
});

export default connect (mapStateToProps,{postCliente,getClientes,clearErrors,getTotalCursos, getCursosRecomendados, postRutaDeAprendizaje,guardarRutaCursos}) (NuevaRuta)


