import React, { Component, Fragment } from 'react';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { FaArrowLeft, FaCheck, FaCheckCircle, FaFirstAid, FaPlus, FaUserFriends, FaUserPlus } from 'react-icons/fa';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { data } from 'browserslist';
import { getTecnicos, addNewTecnico } from '../../../redux/actions/useractions'
import { clearErrors } from '../../../redux/actions/dataactions';
import MyButton from '../../../utils/MyButton';



export class AgregarTecnico extends Component {
  constructor() {
    super();
    this.state = {
      tecncioSelected: '',
      aprobado: {},
      open: false,
      errors: {},
    };
  }

  componentDidMount() {
   
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (props.UI.errors) {
      newState.errors = props.UI.errors;

    }

    if (props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }

  handleOpen = () => {
    this.props.getTecnicos(this.props.handleuser);
    this.setState({ open: true });
    console.log(this.state.aprobado)
  };

  handleClose = () => {
    this.props.clearErrors();
    
    this.setState({
      open: false,
      tecncioSelected: '',
      errors: {},
      aprobado:{}
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAddTecnico = () => {
    console.log(this.state.tecncioSelected, this.props.clienteId)
    const userinfo = {
      handle: this.state.tecncioSelected,
    }
    this.props.addNewTecnico(userinfo, this.props.clienteId);
  }

  render() {
    const { UI: { loading,aprobado } } = this.props;
    const { errors } = this.state;
    const tecnicos = this.props.tecnicos;

    return (
      <Fragment>
        <div className='div-card-link' onClick={this.handleOpen}>
          <div className="dashing-cardcontent">
            <div className="dashing-cardtext">
              <p className="dashing-text-card">
                Agregar un ténico existente
              </p>
            </div>
            <div className="dashing-cardicon">
              <FaUserFriends color="#FFFFFF" className="dashing-icon" />
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Agregar técnico existente
          </DialogTitle>
          {
            aprobado ? (
              <div>
                <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado' />
                  </div>
                  <h2 className='text-aprobado'>¡Técnico agregado exitosamente!</h2>
                </div>

              </div>
            ) : (
              <DialogContent><div>

                {tecnicos && tecnicos.length > 0 ? (
                  <FormControl fullWidth error={errors && errors.tecnico ? true : false}>
                    <InputLabel id="tecnico-label">Seleccionar Técnico</InputLabel>
                    <Select
                      required
                      name="tecncioSelected"
                      id="tecnicoSelected"
                      value={this.state.tecnicoSelected}
                      onChange={this.handleChange}
                      fullWidth
                      error={errors.handle ? true : false}
                    >
                      {tecnicos.map((tecnico, index) => (
                        <MenuItem key={index} value={tecnico}>{tecnico}</MenuItem>
                      ))}
                    </Select>
                    {errors && errors.handle && (
                      <FormHelperText>{errors.handle}</FormHelperText>
                    )}
                  </FormControl>
                ) : (
                  <p> Este perfil de ingeniería no tiene ténicos asociados, por favor cree uno o más téncicos para que pueda asociarlos a diferentes prestadores</p>
                )}
              </div>

              </DialogContent>
            )
          }




          <DialogActions>
            <DialogActions>
              <MyButton tip="Cerrar" onClick={this.handleClose}>
                <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
              </MyButton>

              {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                aprobado ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Agregar técnico" onClick={this.handleAddTecnico}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}

            </DialogActions>
          </DialogActions>

        </Dialog>
      </Fragment >

    )
  }
}

AgregarTecnico.propTypes = {
  getTecnicos: PropTypes.func.isRequired,
  addNewTecnico: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,

  UI: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,

  clienteId: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  UI: state.UI,
  data: state.data,
  handleuser: state.user.credentials.handle,
  tecnicos: state.user.tecnicos,
})

const mapActionsToProps = { getTecnicos, addNewTecnico, clearErrors };


export default connect(mapStateToProps, mapActionsToProps)(AgregarTecnico);