import React from 'react';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import './ReporteMtoDocuPDF.css';
import alphalogo from '../../../utils/alphahorizontal.png'
import membrete from '../../../utils/mtocorrectivotemplate.jpg'


const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    viewcard:{
        display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "10px",
                 marginBottom: "5px",
                
    },
    text:{
        fontSize:"9",
        color:"#03178c",
        fontWeight:"bold"
    },
    textdos:{
        fontSize:"7",
        color:"#03178c",
        fontWeight:"light"
    },
    itemelement:{
        width: "15%",
        marginRight:"1%",
        alignItems:"start"
    },
    vieWgeneral:{
        padding:"10px",
        minWidth: '100%',
        minHeight: '100%',
        height: '100%',
        width: '100%',
    },
  
    imagenlogo:{
        width:"100px",
        height:"100px"
    },
    
    pageGeneral:{
        padding:"2px",
        marginLeft:"10px",
        marginTop:"10px"
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
      },

      imagencliente:{
        width:"80px",
        height:"80px",
        borderColor:"#e2e2e2",
        borderRadius:"10%",
        objectFit:"cover",
        borderWidth:"3px"
      },
      viewmapeo:{
        minWidth: '100%',
        minHeight: '100%',
        height: '100%',
        width: '100%',
        display:'flex',
        flexDirection:'column'
      },
      cardcliente:{
        display:'flex',
        flexDirection:'row',
        marginTop:'120px',
        marginLeft:'25px',
        alignItems:'center'
      }
      ,
      infocliente:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'75%',
        
      }
      ,
      viewText:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        marginTop:'10px'
      },
      tituloinfo:{
        fontSize:'11',
        marginLeft:'5px',
        color:'#005695'

      },
      textinfo:{
        fontSize:'10',
        color:'#0067b2'
      },
      viewimgcliente:{
        width:'auto'
      },
      viewcardequipo:{
        display:'flex',
        flexDirection:'column',
        marginTop:'30px',
        width:'90%',
        marginLeft:'15px'
      },
      equipoinfo:{
        display:'flex',
        flexDirection:'row'
      },
      imagenequipo:{
        width:"80px",
        height:"80px",
        borderColor:"#e2e2e2",
        borderRadius:"10%",
        objectFit:"cover",
        borderWidth:"2px"
      },
      viewimagenquipo:{
        marginLeft:'10px',
      },
      viewinfoequipo:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'80%',
        marginLeft:'10px'
      },
      viewcardhistorial:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        marginTop:'20px',
        width:'90%',
        marginLeft:'15px'
      },
      viewcardtecnico:{
        display:'flex',
        flexDirection:'column',
        marginTop:'15px',
        width:'90%',
        marginLeft:'15px'
      },
      viewcardrepuestos:{
        display:'flex',
        flexDirection:'column',
        marginTop:'15px',
        width:'90%',
        marginLeft:'15px'
      },
      viewpruebas:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      },
      viewcardobservaciones:{
        display:'flex',
        flexDirection:'column',
        marginTop:'30px',
        width:'90%',
        marginLeft:'15px'
      },
      viewfecha:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      }
      ,
      viewcarddetalles:{
        display:'flex',
        flexDirection:'column',
        marginTop:'25px',
        width:'90%',
        marginLeft:'15px'
      }
      ,
      viewcardpersonal:{
        display:'flex',
        flexDirection:'column',
        marginTop:'32px',
        width:'90%',
        marginLeft:'15px'
      },
      viewpersonal:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      },
      viewTextObservaciones:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        marginTop:'10px',
        marginLeft:'3px'

      },
      textobs:{
        fontSize:'10',
        color:'#0067b2'
      },
      viewTextRealizado:{
        marginTop:'2px',
        display:'flex',
        flexDirection:'row'
      }

  });
  
  


const ReporteCorrDocuPDF = ({ equipo,cliente,mtopreventivo }) => {

   

    let mapeoequipo=
    (equipo&&cliente?(
       
        <View  style={styles.viewmapeo}>
            
            <View style={styles.cardcliente}>
            <View style={styles.viewimgcliente}>
            <Image src={cliente.imagenurl}style={styles.imagencliente} />
            </View>
            <View style={styles.infocliente}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>NOMBRE: </Text>
                <Text style={styles.textinfo}>{cliente.nombre}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CIUDAD: </Text>
                <Text style={styles.textinfo}>{cliente.ciudad}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CC/NIT: </Text>
                <Text style={styles.textinfo}>{cliente.ccnit}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DIRECCIÓN: </Text>
                <Text style={styles.textinfo}>{cliente.direccion}</Text>
                </View>

               

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>TELÉFONO: </Text>
                <Text style={styles.textinfo}>{cliente.telefono}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>E-MAIL: </Text>
                <Text style={styles.textinfo}>{cliente.email}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CODIGO REPS: </Text>
                <Text style={styles.textinfo}>{cliente.codigoreps}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SEDE: </Text>
                <Text style={styles.textinfo}>{cliente.sede}</Text>
                </View>
               
               
                

            </View>
            </View>


            <View style={styles.viewcardequipo}>
            <View style={styles.equipoinfo}>
            <View style={styles.viewimagenquipo}>
            <Image src={equipo.credentials.imagenurl}style={styles.imagenequipo} />
            </View>
            <View style={styles.viewinfoequipo}>

            <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>NOMBRE: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.nombre}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>ID INVENTARIO: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.idinventario}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>ESTADO: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.estado}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>MARCA: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.marca}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>MODELO: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.modelo}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SERIE: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.serie}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>UBICACIÓN: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.ubicacion}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SERVICIO: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.servicio}</Text>
                </View>

                
            </View>
            </View>

          
            </View>

            <View style={styles.viewcardhistorial}>
                 <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>OBSOLESCENCIA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.obsolescencia?('Si'):('No')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>MALA OPERACIÓN: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.operacion?('Si'):('No')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>MALA INSTALACIÓN: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.malainst?('Si'):('No')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DESGASTE: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.desgaste?('Si'):('No')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CAIDA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.caida?('Si'):('No')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CORTO CIRCUITO: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.corto?('Si'):('No')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>ACCESORIOS: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.accesorios?('Si'):('No')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>PACIENTE: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.paciente?('Si'):('No')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SIN FALLA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.sinfalla?('Si'):('No')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DESCONOCIDO: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.desconocido?('Si'):('No')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>OTRO: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.otro?mtopreventivo.otro:('No')}</Text>
                </View>
              

               
            </View>

            <View style={styles.viewcardtecnico}>
               <View style={styles.viewpruebas}>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Descripción de la Falla: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.descfalla?(mtopreventivo.descfalla):('No Registra')}</Text>
                </View>

                </View>

                <View style={styles.viewpruebas}>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Actividad Realizada: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.descactividad?(mtopreventivo.descactividad):('No Registra')}</Text>
                </View>

                </View>

                <View style={styles.viewpruebas}>

                  <View style={styles.viewText}>
                  <Text style={styles.tituloinfo}>Observaciones: </Text>
                  <Text style={styles.textinfo}>{mtopreventivo.observaciones?(mtopreventivo.observaciones):('No Registra')}</Text>
                  </View>

                  </View>

                  <View style={styles.viewpruebas}>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>¿Equipo Apto para su Uso? </Text>
                <Text style={styles.textinfo}>{mtopreventivo.apto?('Si'):('No')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>¿Equipo debe ser Retirado? </Text>
                <Text style={styles.textinfo}>{mtopreventivo.retirado?('Si'):('No')}</Text>
                </View>

                </View>

               

              
            </View>

            <View style={styles.viewcardrepuestos}>
            <View style={styles.viewpruebas}>

              <View style={styles.viewText}>
              <Text style={styles.tituloinfo}>Repuesto: </Text>
              <Text style={styles.textinfo}>{mtopreventivo.nombrerepuesto?(mtopreventivo.nombrerepuesto):('No Registra')}</Text>
              </View>

              <View style={styles.viewText}>
              <Text style={styles.tituloinfo}>Cantidad: </Text>
              <Text style={styles.textinfo}>{mtopreventivo.cantidad?(mtopreventivo.cantidad):('No Registra')}</Text>
              </View>

              <View style={styles.viewText}>
              <Text style={styles.tituloinfo}>Costo: </Text>
              <Text style={styles.textinfo}>{mtopreventivo.costo?(mtopreventivo.costo):('No Registra')}</Text>
              </View>
              <View style={styles.viewText}>
              <Text style={styles.tituloinfo}>Subtotal: </Text>
              <Text style={styles.textinfo}>{mtopreventivo.subtotal?(mtopreventivo.subtotal):('No Registra')}</Text>
              </View>


              </View>

              <View style={styles.viewpruebas}>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Repuesto: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.nombrerepuesto1?(mtopreventivo.nombrerepuesto1):('No Registra')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Cantidad: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.cantidad1?(mtopreventivo.cantidad1):('0')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Costo: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.costo1?(mtopreventivo.costo1):('0')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Subtotal: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.subtotal1?(mtopreventivo.subtotal1):('0')}</Text>
                </View>


                </View>

                <View style={styles.viewpruebas}>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Repuesto: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.nombrerepuesto2?(mtopreventivo.nombrerepuesto2):('No Registra')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Cantidad: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.cantidad2?(mtopreventivo.cantidad2):('0')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Costo: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.costo2?(mtopreventivo.costo2):('0')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Subtotal: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.subtotal2?(mtopreventivo.subtotal2):('0')}</Text>
                </View>


                </View>

                <View style={styles.viewpruebas}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Total Invertido: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.totalrepuesto?(mtopreventivo.totalrepuesto):('0')}</Text>
                </View>
                  </View>

            </View>

            <View style={styles.viewcarddetalles}>
              

                <View style={styles.viewfecha}>
                

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>FECHA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.fecha?(mtopreventivo.fecha):('Ninguna')}</Text>
                </View>
                
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>HORA INICIO: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.horainicio?(mtopreventivo.horainicio):('Ninguna')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>HORA FINAL: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.horafinal?(mtopreventivo.horafinal):('Ninguna')}</Text>
                </View>
                </View>

                <View style={styles.viewfecha}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>No. Solicitud: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.numsolicitud?(mtopreventivo.numsolicitud):('Ninguna')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>FECHA SOL: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.fechasolicitud?(mtopreventivo.fechasolicitud):('Ninguna')}</Text>
                </View>
               
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Hora Solicitud: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.horasolicitud?(mtopreventivo.horasolicitud):('Ninguna')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>Tiempo Respuesta: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.tiemporespuestahora?(mtopreventivo.tiemporespuestahora):('Ninguna')}</Text>
                </View>
                </View>
                <View style={styles.viewfecha}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>RECIBIDO POR: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.firmarecibe?(mtopreventivo.firmarecibe):('Ninguna')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CARGO: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.cargorecibe?(mtopreventivo.cargorecibe):('Ninguna')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>REALIZADO POR: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.handleuser?(mtopreventivo.handleuser):('Ninguna')}</Text>
                </View>
               
                </View>

            </View>

            

           
            
            
        </View>
       
      
    ):(<View>
            <Text>no hay nada</Text>
    </View>)
    )
  
  
    return (

        <Document>
             <Page size="a4" style={styles.pageGeneral}>
            
            <View style={styles.vieWgeneral}>
            <Image src={membrete} style={styles.pageBackground}/>
            
         
               
               {mapeoequipo}
            </View>
            </Page>
         </Document>
        
        
        )
}

export default ReporteCorrDocuPDF