import React, { Component } from 'react'
import MyButton from '../../../utils/MyButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import AsisDocLista from './AsisDocLista'

export class AsisDocLegal extends Component {
  render() {
    return (
        <div>

        
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Documentación Legal </h1>
        <hr className="clientes_separator"></hr>            
        
        </div>
        <div className="div_boton">
               
                </div>

        <div className="div_boton">
      
        <Link to={`/dashboard/equiposasistencial`}>
        <MyButton tip="Volver">
        <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>

        <div className="listaclientes_container">
                <AsisDocLista clienteId={this.props.match.params.clienteId}/>
                </div>
        </div>
    )
  }
}

export default AsisDocLegal 