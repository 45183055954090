import React,{Component} from 'react'
import {connect} from 'react-redux'; 
import PropTypes from 'prop-types';
import DashboardEmpresa from '../Dashboard/DashboardEmpresa/DashboardEmpresa';
import DashboardIngeniero from './DashboardIngeniero/DashboardIngeniero';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import './Dashboard.css';
import {getIndicadores,getIndicadoresTecnico,getPerfil} from '../../redux/actions/useractions'
import DashboardCompras from './DashboardCompras/DashboardCompras';
import DashboardAsistencial from './DashboardAsistencial/DashboardAsistencial';
import DashboardTecnico from './DashboardTecnico/DashboardTecnico';



class Dashboard extends Component{

    //Componente did mount get indicadores y pasarlos por props
    componentDidMount(){
        const {credentials}=this.props.user;

        if(credentials.tipouser==="Tecnico"){
            console.log(credentials.tipouser);
            this.props.getIndicadoresTecnico(credentials.handleuser); 
            //getindicadores tecnico
        }else{
            this.props.getIndicadores();
        }
       
    }
    render(){
        const {credentials,authenticated,loading,loadingdata,indicadores}= this.props.user; 
        
        
       if(authenticated===true&&loading===false&&loadingdata===false){
           if(credentials.tipouser==="Empresa"){
               return(
                   <>
                       <DashboardEmpresa indicadoresprops={indicadores}/>
                   </>
               )
           }
           else if (credentials.tipouser==="Ingeniero"){
              return ( 
                  <>
                  <DashboardIngeniero indicadoresprops={indicadores}/>
                  </>
              
               )
           }
           else if (credentials.tipouser==="Asistencial"){
            return ( 
                <>
                <DashboardAsistencial indicadoresprops={indicadores} clienteId={credentials.idprestador}/>
                </>
            
             )
         }
         else if (credentials.tipouser==="Compras"){
            return ( 
                <>
                <DashboardCompras indicadoresprops={indicadores}/>
                </>
            
             )
         }
         else if (credentials.tipouser==="Tecnico"){
            return ( 
                <>
                <DashboardTecnico indicadoresprops={indicadores}/>
                </>
            
             )
         }
           else {
            return(
                <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
         </div>
            )

           }
       }else if(authenticated===true&&loading===true){
           return(
            <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
           )
       }
       else if(authenticated===false&&loading===false){
           return(
            this.props.history.push('/')
           )
           
       }
       else{
        return(
            <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
           )

       }

    }
   
   
}

Dashboard.propTypes={
    getIndicadores:PropTypes.func.isRequired,
    getIndicadoresTecnico:PropTypes.func.isRequired,
    getPerfil:PropTypes.func.isRequired, 
    user: PropTypes.object.isRequired,
   
}


const mapStateToProps=state=>({
      user:state.user  
})


export default connect(mapStateToProps, { getPerfil, getIndicadores, getIndicadoresTecnico })(Dashboard);