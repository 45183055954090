import React from 'react';
import './PostCard.css';
import moment from 'moment'; 
import { Link } from 'react-router-dom';

function PostCard({post}) {



  return (
    <div className='cardblog'>
 
    <img className='imgblog' src={post.featuredImage.url}></img>    

    <div className='divblogcontentpostcard'>
    <Link to={`/dashboard/noticias/${post.id}`} className='linktitle' params={post}>
            <h1 className='tituloblog'>{post.title}</h1>
    </Link>
        
        <hr className="separadorgradienteabout"/>
        <div className='divdescriptionblog'>
        <p className="paradescriptionblog">{post.excerpt}</p>
        </div>
        
        <div className='divblogcreator'>
    
        <p className="datecreator">{moment(post.createdAt).format('MM, DD, YYYY')}</p> 

        <div className='divblogcreate'>
            <img className='imgcreator' src={post.author.photo.url}></img>
            <h3 className='txtcreator'>{post.author.name}</h3>
        </div>
    </div>
    </div>
    
    
        
    </div>
  )
}

export default PostCard