import React, { useEffect, useRef, useState } from 'react'
import './Digitaliza.css'
import { FaUsers, FaFileMedicalAlt, FaShoppingCart, FaChartLine } from 'react-icons/fa';
import imgsrc from './digitalizaimg.png'

function Digitaliza() {
    const [isVisible, setIsVisible] = useState(false);
    const textRef = useRef(null);
    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -100px 0px'
            }
        );

        if (textRef.current) observer.observe(textRef.current);
        if (imgRef.current) observer.observe(imgRef.current);

        return () => {
            if (textRef.current) observer.unobserve(textRef.current);
            if (imgRef.current) observer.unobserve(imgRef.current);
        };
    }, []);

    return (
        <div id="digitaliza" className="digitaliza">
            <div ref={textRef} className={`container2d ${isVisible ? 'visible' : ''}`}>
                <h1 className='h1-d'>
                    Toma el Control
                </h1>
                <h2 className='h2-d'>Y Avanza Hacía el Futuro</h2>
                <h2 className='h2-d'>Digitalizando tu Proceso </h2>
                <h2 className='h2-d'>De Mantenimiento Con Nosotros</h2>
                <h3 className='h3-d'>Descubre Cómo</h3>
            </div>
            <div className="container1d">
                <img ref={imgRef} src={imgsrc} alt="logo" className={`pc ${isVisible ? 'visible' : ''}`} />
            </div>
        </div>
    )
}

export default Digitaliza