import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './CardCursoRecomendado.css'


const CardCursoRecomendado = ({ imagen, titulo, precioAntes, precioAhora }) => {
  return (
    <div className="card_curso_recomendado">
        <div className='div-cursoimagenrecomendado'>
        <img src={imagen} alt="cursoimagenrecomendado" className="cursoimagenrecomendado"/>
        </div>
        <div className='div-cursorecomendado-info'>
        <h5 className="tittle-curso-recomendado">{titulo}</h5>
        <p className='precio-curso-recomendado-antes'>Antes ${precioAntes}</p>
        <p className='precio-curso-recomendado-ahora'>Ahora ${precioAhora}</p>
       
        </div>

        <div className='div-cursorecomendado-actions'>
        
        <a className="linkbtn-cursos" href={`https://wa.me/573218472298/?text=`} target="_blank">
                       
                              Comprar
                          
                          </a>
           <p></p>
            <p></p>

            </div>
     
    </div>
  );
};

export default CardCursoRecomendado;