import React from 'react';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import './ReporteMtoDocuPDF.css';
import alphalogo from '../../../utils/alphahorizontal.png'
import membretemto from '../../../utils/mtoprevtemplate.png'


const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    viewcard:{
        display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "10px",
                 marginBottom: "5px",
                
    },
    text:{
        fontSize:"9",
        color:"#03178c",
        fontWeight:"bold"
    },
    textdos:{
        fontSize:"7",
        color:"#03178c",
        fontWeight:"light"
    },
    itemelement:{
        width: "15%",
        marginRight:"1%",
        alignItems:"start"
    },
    vieWgeneral:{
        padding:"10px",
        minWidth: '100%',
        minHeight: '100%',
        height: '100%',
        width: '100%',
    },
  
    imagenlogo:{
        width:"100px",
        height:"100px"
    },
    
    pageGeneral:{
        padding:"2px",
        marginLeft:"10px",
        marginTop:"10px"
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
      },

      imagencliente:{
        width:"80px",
        height:"80px",
        borderColor:"#e2e2e2",
        borderRadius:"10%",
        objectFit:"cover",
        borderWidth:"3px"
      },
      viewmapeo:{
        minWidth: '100%',
        minHeight: '100%',
        height: '100%',
        width: '100%',
        display:'flex',
        flexDirection:'column'
      },
      cardcliente:{
        display:'flex',
        flexDirection:'row',
        marginTop:'135px',
        marginLeft:'18px',
        alignItems:'center',
      
        height:'90px',
        maxHeight:'100px',
        width:'520px'
      }
      ,
      infocliente:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'75%',
        
      }
      ,
      viewText:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        marginTop:'10px'
      },
      tituloinfo:{
        fontSize:'11',
        marginLeft:'5px',
        color:'#005695'

      },
      textinfo:{
        fontSize:'10',
        color:'#0067b2'
      },
      viewimgcliente:{
        width:'auto'
      },
      viewcardequipo:{
        display:'flex',
        flexDirection:'column',
        marginTop:'35px',
        width:'520px',
        height:'80px',
     
        marginLeft:'10px'
      },
      equipoinfo:{
        display:'flex',
        flexDirection:'row'
      },
      imagenequipo:{
        width:"80px",
        height:"80px",
        borderColor:"#e2e2e2",
        borderRadius:"10%",
        objectFit:"cover",
        borderWidth:"2px"
      },
      imagenfirma:{
        width:"150px",
        height:"40px",
        borderColor:"#e2e2e2",
        borderRadius:"10%",
        objectFit:"cover",
        borderWidth:"2px"
      },
      viewimagenquipo:{
        marginLeft:'10px',
      },
      viewinfoequipo:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'80%',
        marginLeft:'10px'
      },
      viewcardhistorial:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        marginTop:'35px',
        width:'520px',
       
        height:'100px',
        marginLeft:'15px'
      },
      viewcardtecnico:{
        display:'flex',
        flexDirection:'column',
        marginTop:'30px',
       
        marginLeft:'15px',
        width:'520px',
     
        height:'50px',
      },
      viewpruebas:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      },
      viewcardobservaciones:{
        display:'flex',
        flexDirection:'column',
        marginTop:'32px',
        width:'520px',
     
        height:'70px',
        marginLeft:'15px'
      },
      viewfecha:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      }
      ,
      viewcardpersonal:{
        display:'flex',
        flexDirection:'column',
        marginTop:'25px',
        width:'520px',
        height:'170px',

        marginLeft:'15px'
      },
      viewpersonal:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        
        width:'100%'
      },
      vierecibe:{
        display:'flex',
        flexDirection:'column',
        width:'50%',
       
        height:'180px'
      },
      viewTextObservaciones:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        marginTop:'1px',
        marginLeft:'3px'

      },
      textobs:{
        fontSize:'10',
        color:'#0067b2'
      },
      viewTextRealizado:{
        marginTop:'2px',
        display:'flex',
        flexDirection:'row'
      }

  });
  
  


const ReporteMtoDocuPDF = ({ equipo,cliente,mtopreventivo }) => {

  const imagenUrl = cliente.imagenurl ? cliente.imagenurl : 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';
  const imagenEquipoUrl=equipo.credentials.imagenurl?equipo.credentials.imagenurl : 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';
    let mapeoequipo=
    (equipo&&cliente?(
       
        <View  style={styles.viewmapeo}>
            
            <View style={styles.cardcliente}>
            <View style={styles.viewimgcliente}>
            <Image src={imagenUrl}style={styles.imagencliente} />
            </View>
            <View style={styles.infocliente}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>NOMBRE: </Text>
                <Text style={styles.textinfo}>{cliente.nombre}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CIUDAD: </Text>
                <Text style={styles.textinfo}>{cliente.ciudad}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CC/NIT: </Text>
                <Text style={styles.textinfo}>{cliente.ccnit}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>TELÉFONO: </Text>
                <Text style={styles.textinfo}>{cliente.telefono}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DIRECCIÓN: </Text>
                <Text style={styles.textinfo}>{cliente.direccion}</Text>
                </View>

               
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CODIGO REPS: </Text>
                <Text style={styles.textinfo}>{cliente.codigoreps}</Text>
                </View>
              

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>E-MAIL: </Text>
                <Text style={styles.textinfo}>{cliente.email}</Text>
                </View>
               

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SEDE: </Text>
                <Text style={styles.textinfo}>{cliente.sede}</Text>
                </View>
               
               
                

            </View>
            </View>


            <View style={styles.viewcardequipo}>
            <View style={styles.equipoinfo}>
            <View style={styles.viewimagenquipo}>
            <Image src={imagenEquipoUrl}style={styles.imagenequipo} />
            </View>
            <View style={styles.viewinfoequipo}>

            <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>NOMBRE: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.nombre}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>ID INVENTARIO: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.idinventario}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>ESTADO: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.estado}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>MARCA: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.marca}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>MODELO: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.modelo}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SERIE: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.serie}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>UBICACIÓN: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.ubicacion}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SERVICIO: </Text>
                <Text style={styles.textinfo}>{equipo.credentials.servicio}</Text>
                </View>

                
            </View>
            </View>

          
            </View>

            <View style={styles.viewcardhistorial}>
                 <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>INSPECCIÓN CHASIS: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.chasis?('Si'):('No')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>VERIFICACIÓN DE ACCESORIOS: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.vaccesorios?('Si'):('No')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>PRUEBA CUANTITATIVA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.pcuantitativa?('Si'):('No')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>VERIFICACIÓN DE CONTROLES: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.vcontroles?('Si'):('No')}</Text>
                </View>

                

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SEGURIDAD ELECTRICA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.selectrica?('Si'):('No')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>LIMPIEZA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.limpieza?('Si'):('No')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>LIMPIEZA CHASIS: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.lchasis?('Si'):('No')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>LUBRICACIÓN DE PARTES: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.lubricacion?('Si'):('No')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>LIMPIEZA ELECTRONICA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.lelectronica?('Si'):('No')}</Text>
                </View>

                

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>VERIFICACIÓN GENERAL: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.vgeneral?('Si'):('No')}</Text>
                </View>

               
            </View>

            <View style={styles.viewcardtecnico}>
               <View style={styles.viewpruebas}>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>PRUEBA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.prueba1?(mtopreventivo.prueba1):('No Registra')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>INSTRUMENTO: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.instrumento1?(mtopreventivo.instrumento1):('')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>VALOR: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.valor1?(mtopreventivo.valor1):('')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>APROBÓ: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.aprobo1?('Si'):('No')}</Text>
                </View>

                </View>

                <View style={styles.viewpruebas}>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>PRUEBA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.prueba2?(mtopreventivo.prueba2):('No Registra')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>INSTRUMENTO: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.instrumento2?(mtopreventivo.instrumento2):('')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>VALOR: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.valor2?(mtopreventivo.valor2):('0')}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>APROBÓ: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.aprobo2?('Si'):('No')}</Text>
                </View>

                </View>

               

              
            </View>

            <View style={styles.viewcardobservaciones}>

            <View style={styles.viewTextObservaciones}>
                <Text style={styles.textobs}>{mtopreventivo.observaciones?(mtopreventivo.observaciones):('Ninguna')}</Text>
                </View>

                <View style={styles.viewfecha}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>FECHA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.fecha?(mtopreventivo.fecha):('Ninguna')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>HORA INICIO: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.horainicio?(mtopreventivo.horainicio):('Ninguna')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>HORA FINAL: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.horafinal?(mtopreventivo.horafinal):('Ninguna')}</Text>
                </View>
                </View>
            </View>
            <View style={styles.viewcardpersonal}>
                 <View style={styles.viewpersonal}>
                  <View style={styles.viewrecibe}>
                  {cliente.imagenfirma ? (
                        <Image src={cliente.imagenfirma} style={styles.imagenfirma} />
                    ) : (
                      <Text style={styles.tituloinfo}>Debes Cargar tu Firma</Text>
                    )}
                  <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>RECIBE: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.firmarecibe?(mtopreventivo.firmarecibe):('Ninguna')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CARGO: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.cargorecibe?(mtopreventivo.cargorecibe):('Ninguna')}</Text>
                </View>
                  </View>
               

                  <View style={styles.viewrecibe}>
                  
                  {mtopreventivo.firmaIngeniero ? (
                        <Image src={mtopreventivo.firmaIngeniero} style={styles.imagenfirma} />
                    ) : (
                      <Text style={styles.tituloinfo}>Debes Cargar tu Firma</Text>
                    )}
                    
                     <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>REALIZA: </Text>
                <Text style={styles.textinfo}>{mtopreventivo.handleuser?(mtopreventivo.handleuser):('Ninguna')}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CARGO: </Text>
                <Text style={styles.textinfo}>Ingeniero</Text>
                </View>
                  </View>
                 
                 

                </View>
                
               

            </View>

           
            
            
        </View>
       
      
    ):(<View>
            <Text>no hay nada</Text>
    </View>)
    )
  
  
    return (

        <Document>
             <Page size="a4" style={styles.pageGeneral}>
            
            <View style={styles.vieWgeneral}>
            <Image src={membretemto} style={styles.pageBackground} />
            
         
               
               {mapeoequipo}
            </View>
            </Page>
         </Document>
        
        
        )
}

export default ReporteMtoDocuPDF