import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';
import TablaUsuarios from './TablaUsuarios';

function AdminUsers() {
    const history = useHistory();
    const paramsurl=useParams();
    
  const handleGoBack = () => {
    history.goBack(); // Esto llevará al usuario una página hacia atrás en el historial de navegación.
  };
  return (
    <div> 
    <div className="titulo_section">
    <div className="titulo_clientes">
      <h1 className="text-clientes">Administración de Usuarios</h1>
     
      <hr className="clientes_separator"></hr>
    </div>

    <div className="div_boton">
      <MyButton tip="Volver" onClick={handleGoBack}>
        <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
      </MyButton>
    </div>
  </div>

  <TablaUsuarios clienteId={paramsurl.clienteId}></TablaUsuarios>

  
  </div>
  )
}

export default AdminUsers