import React, { Component } from 'react';
import './ComprasSolicitudes.css';
import {Link } from 'react-router-dom';
import {FaLaptopMedical,FaHospital,FaTools,FaSyringe,FaCogs,FaLaptopCode,FaFileInvoiceDollar,FaFileSignature} from "react-icons/fa";



export class ComprasSolicitudes extends Component {
  render() {
    return (
      <div className="market_container">
      <div className="titulomarket_section">
      <div className="titulo_market">
      <h1 className="text-clientes">Mis Solicitudes</h1>
      <hr className="clientes_separator"></hr>  

      </div>

      <div className="divmarket_boton">
    
     


      </div>
      </div>

      <div className="market_categorias_container">

      <div className="subcontainer-market">
      <div className="dashing-div-card">
                <Link to='/dashboard/compras/solicitudes/solicitudesenviadas' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Solicitudes <br/>de Compras
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaTools color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

                <div className="dashing-div-card">
                <Link to='/dashboard/compras/solicitudes/solicitudesaprobadas' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Solicitudes<br/>Aprobadas
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaHospital color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

                <div className="dashing-div-card">
                <Link to='/dashboard/compras/solicitudes/solicitudesrechazadas' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Solicitudes<br/>Rechazadas
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaHospital color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

        
      </div>


      </div>
      
      
      
      
      </div>
    )
  }
}

export default ComprasSolicitudes