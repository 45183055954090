import React, { Component } from 'react';
import './Categorias.css';
import { Link } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {FaArrowCircleLeft } from "react-icons/fa";
import Button from '../../Elements/Button';


const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

export class Categorias extends Component {
    constructor(){
        super();
        this.state={
           
            retirado:''
         
        };
        
    };

    handleChange=(event)=>{
        this.setState({ [event.target.name]:event.target.value});
        
   };

 
    render() {
        let categoria=this.props.match.params.categoria; 

        if(categoria==="equiposmedicos"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Equipos Medicos</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona el Equipo que buscas</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='autoclaves'>Autoclaves</MenuItem>
                            <MenuItem value='basculas'>Basculas y/o Balanzas</MenuItem>
                            <MenuItem value='bombasinfusion'>Bombas de Infusión</MenuItem>
                            <MenuItem value='cilindros'>Cilindros de Oxigeno</MenuItem>
                            <MenuItem value='concentradores'>Concentradores de Oxigeno</MenuItem>
                            <MenuItem value='capnografo'>Capnógrafos</MenuItem>
                            <MenuItem value='cpap'>CPAP,BPAP y APAP</MenuItem>
                            <MenuItem value='desfibriladores'>Desfibriladores</MenuItem>
                            <MenuItem value='doplervascular'>Doppler Vascular</MenuItem>
                            <MenuItem value='doplerfetal'>Doppler Fetal</MenuItem>
                            <MenuItem value='ecografos'>Ecógrafos</MenuItem>
                            <MenuItem value='equipodeorganos'>Equipo de Organos</MenuItem>
                            <MenuItem value='electrocardiografos'>Electrocardiografos</MenuItem>
                            <MenuItem value='electrocirugia'>Electrocirugía</MenuItem>
                            <MenuItem value='espirometros'>Espirómetros</MenuItem>
                            <MenuItem value='fonendoscopios'>Fonendoscopios</MenuItem>
                            <MenuItem value='incubadorasneonatales'>Incubadoras Neonatales</MenuItem>
                            <MenuItem value='infusores'>Infusores</MenuItem>
                            <MenuItem value='lamparasmesapared'>Lámparas de Mesa/Pared</MenuItem>
                            <MenuItem value='lamparasfrontales'>Lámparas Frontales</MenuItem>
                            <MenuItem value='linternas'>Linternas de Exámen</MenuItem>
                            <MenuItem value='lamparaspieliticas'>Lámparas Pieliticas</MenuItem>
                            <MenuItem value='lamparascieliticas'>Lámparas Cieliticas</MenuItem>
                            <MenuItem value='lamparascalor'>Lámparas de Calor Radiante</MenuItem>
                            <MenuItem value='lamparasfototerapia'>Lámparas de Fototerapia</MenuItem>
                            <MenuItem value='laringoscopios'>Laringoscopios</MenuItem>
                            <MenuItem value='mantas'>Mantas Termicas</MenuItem>
                            <MenuItem value='maquinasanestesia'>Máquinas de Anestesia</MenuItem>
                            <MenuItem value='martillos'>Martillos de Reflejos</MenuItem>
                            <MenuItem value='monitorsignosvitales'>Monitores de Signos Vitales</MenuItem>
                            <MenuItem value='monitorfetal'>Monitores Fetales</MenuItem>
                            <MenuItem value='monitorgradomedico'>Monitor Grado Medico</MenuItem>
                            <MenuItem value='nebulizadores'>Nebulizadores</MenuItem>
                            <MenuItem value='reguladoresoxigeno'>Reguladores de Oxigeno</MenuItem>
                            <MenuItem value='oximetros'>Oximetros</MenuItem>
                            <MenuItem value='rayosx'>Rayos X</MenuItem>
                            <MenuItem value='tomografia'>Tomografía</MenuItem>
                            <MenuItem value='mamografia'>Mamografía</MenuItem>                                 
                            <MenuItem value='succionadores'>Succionadores</MenuItem>                      
                            <MenuItem value='tensiometros'>Tensiometros</MenuItem>
                            <MenuItem value='termohigrometros'>Termohigrometros</MenuItem>
                            <MenuItem value='temometros'>Termómetros</MenuItem>
                            <MenuItem value='ventiladores'>Ventiladores</MenuItem>
                            <MenuItem value='videocolposcopios'>Videocolposcopios</MenuItem>
                            <MenuItem value='videolaringoscopios'>Videolaringoscopios</MenuItem>
                             </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="accesoriosyrepuestos"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Accesorios y Repuestos para Equipos Médicos</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona el Accesorio y/o Repuesto que buscas</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='baterias'>Baterias</MenuItem>
                            <MenuItem value='celdas'>Celdas de Oxigeno</MenuItem>
                            <MenuItem value='accesoriosecg'>Accesorios ECG</MenuItem>
                            <MenuItem value='bombillos'>Bombillos</MenuItem>
                            <MenuItem value='brazaletes'>Brazaletes NIBP</MenuItem>
                            <MenuItem value='accesoriosmonitor'>Accesorios y/o Repuestos Monitor Paciente</MenuItem>
                            <MenuItem value='accesoriosdoplerfetal'>Accesorios y/o Repuestos Doppler Fetal</MenuItem>
                            <MenuItem value='accesorioscapnografo'>Accesorios y/o Repuestos Capnografia</MenuItem>
                            <MenuItem value='accesoriosdesfibriladores'>Accesorios y/o Repuestos Desfibriladores</MenuItem>
                            <MenuItem value='accesoriosecografos'>Accesorios y/o Repuestos Ecógrafos</MenuItem>
                            <MenuItem value='accesoriosequipodeorganos'>Accesorios y/o Repuestos Equipo de Organos</MenuItem>
                            <MenuItem value='accesorioselectrocirugia'>Accesorios y/o Repuestos Electrocirugía</MenuItem>
                            <MenuItem value='accesoriosespirometros'>Accesorios y/o Repuestos Espirómetros</MenuItem>
                            <MenuItem value='accesoriosfonendoscopios'>Accesorios y/o Repuestos Fonendoscopios</MenuItem>
                            <MenuItem value='accesoriosincubadorasneonatales'> Accesorios y/o Repuestos Incubadoras Neonatales</MenuItem>
                            <MenuItem value='accesorioslamparasmesapared'>Accesorios y/o Repuestos Lámparas de Mesa/Pared</MenuItem>
                            <MenuItem value='accesorioslamparasfrontales'>Accesorios y/o Repuestos Lámparas Frontales</MenuItem>
                            <MenuItem value='accesorioslamparaspieliticas'>Accesorios y/o Repuestos Lámparas Pieliticas</MenuItem>
                            <MenuItem value='accesorioslamparascieliticas'>Accesorios y/o Repuestos Lámparas Cieliticas</MenuItem>
                            <MenuItem value='accesorioslamparascalor'>Accesorios y/o Repuestos Lámparas de Calor Radiante</MenuItem>
                            <MenuItem value='accesorioslamparasfototerapia'>Accesorios y/o Repuestos Lámparas de Fototerapia</MenuItem>
                            <MenuItem value='accesorioslaringoscopios'>Accesorios y/o Repuestos Laringoscopios</MenuItem>
                            <MenuItem value='accesoriosmaquinasanestesia'>Accesorios y/o Repuestos Máquinas de Anestesia</MenuItem>
                            <MenuItem value='accesoriosmonitorfetal'>Accesorios y/o Repuestos Monitores Fetales</MenuItem>
                            <MenuItem value='accesoriosoximetros'>Accesorios y/o Repuestos Oximetros</MenuItem>
                            <MenuItem value='accesoriosrayosx'>Accesorios y/o Repuestos Rayos X</MenuItem>
                            <MenuItem value='accesoriostomografia'>Accesorios y/o Repuestos Tomografía</MenuItem>
                            <MenuItem value='accesoriosmamografia'>Accesorios y/o Repuestos Mamografía</MenuItem>                                 
                            <MenuItem value='accesoriossuccionadores'>Accesorios y/o Repuestos Succionadores</MenuItem>                      
                            <MenuItem value='accesoriostensiometros'>Accesorios y/o Repuestos Tensiometros</MenuItem>
        
                            <MenuItem value='accesoriosventiladores'>Accesorios y/o Repuestos Ventiladores</MenuItem>
                            <MenuItem value='accesoriosvideocolposcopios'>Accesorios y/o Repuestos Videocolposcopios</MenuItem>
                            <MenuItem value='accesoriosvideolaringoscopios'>Accesorios y/o Repuestos Videolaringoscopios</MenuItem>
                             </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="laboratorio"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria Equipos de Laboratorio</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona el Equipo que buscas</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='agitadordemanzini'>Agitador de Manzini</MenuItem>
                            <MenuItem value='analizadorgases'>Analizador de Gases Arteriales</MenuItem>
                            <MenuItem value='equipodequimica'>Analizador de Quimica Sanguinea</MenuItem>
                            <MenuItem value='analizadorgenetico'>Analizador Genético</MenuItem>
                            <MenuItem value='microscopio'>Microscopio</MenuItem>
                            <MenuItem value='contadordecelulas'>Contador de Celulas</MenuItem>
                            <MenuItem value='centrifuga'>Centrifuga</MenuItem>
                            <MenuItem value='hornodesecado'>Horno de Secado</MenuItem>
                            <MenuItem value='banoserologico'>Baño Serologico</MenuItem>
                            <MenuItem value='equipohematologia'>Equipo de Hematología</MenuItem>
                            <MenuItem value='micropipetas'>Micropipetas</MenuItem>
                            <MenuItem value='reactivos'>Reactivos</MenuItem>
                            <MenuItem value='plantasosmoticas'>Plantas Osmoticas</MenuItem>                           
                            <MenuItem value='incubadorasdebiologicos'>Incubadoras de Biologicos</MenuItem>
                            <MenuItem value='balanzasprecision'>Balanzas de Precisión</MenuItem>
                            <MenuItem value='balanzasprecision'>Cabina de Flujo Laminar</MenuItem>
                            </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="mobiliario"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Mobiliario Hospitalario</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona el Mobiliario Hospitalario que buscas</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='camasuci'>Camas UCI</MenuItem>
                            <MenuItem value='camashospitalarias'>Camas Hospitalarias</MenuItem>                            
                            <MenuItem value='camaspediatricas'>Camas Hospitalarias Pediatricas</MenuItem>
                            <MenuItem value='camillasrodantes'>Camillas Rodantes</MenuItem>
                            <MenuItem value='camillasfijas'>Camillas Fijas</MenuItem>
                            <MenuItem value='colchones'>Colchones/Colchonetas</MenuItem>
                            <MenuItem value='escritorios'>Escritorios</MenuItem>
                            <MenuItem value='archivadores'>Archivadores</MenuItem>
                            <MenuItem value='sillasoficina'>Sillas de Oficina</MenuItem>
                            <MenuItem value='sillasespera'>Sillas de Sala de Espera</MenuItem>
                            <MenuItem value='sofas'>Sofás de Descanso Médico</MenuItem>
                            <MenuItem value='atriles'>Atriles</MenuItem>
                            <MenuItem value='carrosdeparo'>Carros de Paro</MenuItem>
                            <MenuItem value='carrosdemedicamentos'>Carros de Medicamentos</MenuItem>
                            <MenuItem value='carretillasoxigeno'>Carretillas de Cilindros de Oxigeno</MenuItem>
                            <MenuItem value='negatoscopio'>Negatoscopios</MenuItem>
                            <MenuItem value='mesasauxiliares'>Mesas Auxiliares</MenuItem>
                            <MenuItem value='sillasderuedas'>Sillas de Ruedas</MenuItem>
                            <MenuItem value='mesascirugia'>Mesas de Cirugía</MenuItem>
                            <MenuItem value='camapartos'>Cama de Partos</MenuItem>                           
                            <MenuItem value='escalerillas'>Escalerillas</MenuItem>
                            <MenuItem value='mesashospitalarias'>Mesas Hospitalarias</MenuItem>
                            <MenuItem value='mesasconsultorio'>Mesas de Consultorio</MenuItem>
                            </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="insumos"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Insumos Médicos</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona los Insumos que estás buscando</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='guantesdesechables'>Guantes Desechables</MenuItem>                            
                            <MenuItem value='tapabocas'>Tapabocas</MenuItem>
                            <MenuItem value='gorrosdesechables'>Gorros Desechables</MenuItem>
                            <MenuItem value='batasquirurgicas'>Batas Quirurgicas</MenuItem>
                            <MenuItem value='bajalenguas'>Baja Lenguas</MenuItem>
                            <MenuItem value='gafasproteccion'>Gafas de Protección</MenuItem>
                            <MenuItem value='jabonesydesinfectantescx'>Jabones y/o Desinfectantes Quirurgicos</MenuItem>
                            <MenuItem value='polainas'>Polainas</MenuItem>
                            <MenuItem value='jeringas'>Jeringas</MenuItem>
                            <MenuItem value='agujas'>Agujas</MenuItem>
                            <MenuItem value='cateteres'>Catetéres</MenuItem>
                            <MenuItem value='gasas'>Gasas</MenuItem>
                            <MenuItem value='algodon'>Algodón</MenuItem>
                            <MenuItem value='trajesproteccion'>Trajes de Protección</MenuItem>
                            <MenuItem value='clorurodesodio'>Cloruro de Sodio</MenuItem>
                            <MenuItem value='sondas'>Sondas</MenuItem>
                            <MenuItem value='equiposinfusion'>Equipos de Infusión</MenuItem>
                            <MenuItem value='especulos'>Especulos</MenuItem>
                            <MenuItem value='tarrosmuestra'>Tarros de Muestra</MenuItem>
                            <MenuItem value='micropore'>Micropore</MenuItem>
                            <MenuItem value='esparadrapos'>Esparadrapos</MenuItem>
                          
                             </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="servicios"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Servicios</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona los Servicios que estás buscando</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='guantesdesechables'>Consultoria en Habilitación</MenuItem>                            
                            <MenuItem value='guantesdesechables'>Contabilidad</MenuItem>                            
                            <MenuItem value='guantesdesechables'>Mantenimiento de Equipos Médicos</MenuItem>                            
                            <MenuItem value='guantesdesechables'>Calibración de Equipos Médicos</MenuItem>                            
                            <MenuItem value='guantesdesechables'>Mantenimiento de Equipos Industriales</MenuItem>                            
                            <MenuItem value='guantesdesechables'>Mercadeo y Publicidad</MenuItem>                            
                            <MenuItem value='guantesdesechables'>Arquitectura Hospitalaria</MenuItem>                            
                            </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="odontologia"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Odontología</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona los Productos Odontologicos que estás buscando</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='unidadesodontologicas'>Unidades Odontologicas</MenuItem>                            
                            <MenuItem value='compresores'>Compresores</MenuItem>       
                            <MenuItem value='lamparasfotocurado'>Lámparas de Fotocurado</MenuItem>   
                            <MenuItem value='scaler'>Scaler</MenuItem>   
                            <MenuItem value='piezasdentales'>Piezas Dentales</MenuItem>    
                            <MenuItem value='micromotores'>Micromotores</MenuItem>                     
                            <MenuItem value='rayosxperiapical'>Rayos X Periapical</MenuItem>  
                            <MenuItem value='localizadorapice'>Localizador De Ápice</MenuItem>      
                            <MenuItem value='adhesivosodontologicos'>Materiales Adhesivos Odontologicos</MenuItem>                           
                            <MenuItem value='anéstesivosodontologicos'>Materiales Anéstesicos Odontologicos</MenuItem> 
                            <MenuItem value='endodoncia'>Materiales de Endodoncia</MenuItem> 
                            <MenuItem value='fresas'>Fresas</MenuItem>
                            <MenuItem value='grabadodental'>Materiales de Grabado Dental</MenuItem>  
                            <MenuItem value='impresiondental'>Materiales de Impresión Dental</MenuItem> 
                            <MenuItem value='obturaciondental'>Materiales de Obturación Dental</MenuItem> 
                            <MenuItem value='preventivodental'>Preventivo Odontológico</MenuItem> 
                            <MenuItem value='profilaxis'>Profilaxis</MenuItem> 
                            <MenuItem value='prostodoncia'>Prostodoncia</MenuItem> 
                            <MenuItem value='pulidodental'>Pulido Dental</MenuItem> 
                            <MenuItem value='restaurativo'>Restaurativo</MenuItem> 
                                                   
                            </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="software"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Software</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona el Software que buscas</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='softwarehistoriaclinica'>Historías Clínicas</MenuItem>
                            <MenuItem value='softwarenomina'>Nómina</MenuItem>
                            <MenuItem value='softwarefacturacion'>Facturación Electrónica</MenuItem>                            
                            <MenuItem value='softwareimagenes'>Imágenes Diagnósticas</MenuItem>                                                    
                            <MenuItem value='softwareoffice'>Office</MenuItem>                        
                             </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="osteosintesis"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Osteosintesis</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona el Producto de Osteosintesis que buscas</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='traumamiembroinferior'>Trauma Miembro Inferior</MenuItem>                            
                            <MenuItem value='traumamiembrosuperior'>Trauma Miembro Superior</MenuItem>
                            <MenuItem value='toracolumbar'>Columna Toracolumbar</MenuItem>                         
                            <MenuItem value='cervicalposterior'>Columna Cervical Posterior</MenuItem>                            
                            <MenuItem value='escoliosis'>Columna Escoliosis</MenuItem>                            
                            <MenuItem value='anterior'>Columna Cervical Anterior</MenuItem>
                            <MenuItem value='ortopediatornillos'>Ortopedia Pediatrica Tornillos</MenuItem>                            
                            <MenuItem value='ortopediaplacas'>Ortopedia Pediatrica Placas</MenuItem>                            
                            <MenuItem value='ortopediaclavos'>Ortopedia Pediatrica Clavos</MenuItem>                            
                            <MenuItem value='ortopediaaccesorios'>Ortopedia Pediatrica Accesorios</MenuItem>
                            <MenuItem value='reemplazorodillarevision'>Reemplazos Articulares Rodilla Revisión</MenuItem>
                            <MenuItem value='reemplazorodillaprimaria'>Reemplazos Articulares Rodilla Primaria</MenuItem>
                            <MenuItem value='reemplazotrabecular'>Reemplazos Articulares Trabecular Metal</MenuItem>
                            <MenuItem value='reemplazocaderaparcial'>Reemplazos Articulares Cadera Parcial</MenuItem>
                            <MenuItem value='reemplazocaderarevision'>Reemplazos Articulares Cadera Revisión</MenuItem>
                            <MenuItem value='reemplazocaderaprimaria'>Reemplazos Articulares Cadera Primaria</MenuItem>
                            <MenuItem value='reemplazocodo'>Reemplazos Articulares Codo</MenuItem>
                            <MenuItem value='fijacionreconstruccion'>Fijación Externa Reconstrucción</MenuItem>
                            <MenuItem value='fijaciontrauma'>Fijación Externa Trauma R</MenuItem>
                            <MenuItem value='artroscopiarodilla'>Artroscopia Rodilla</MenuItem>
                            <MenuItem value='artroscopiahombro'>Artroscopia Hombro</MenuItem>
                            <MenuItem value='artroscopiacuchillasfresas'>Artroscopia Cuchillas y Fresas</MenuItem>
                            <MenuItem value='sustitutososeosbiologicos'>Sustitutos Óseos Biológico</MenuItem>
                            <MenuItem value='sustitutososeossinteticos'>Sustitutos Óseos Sintético</MenuItem>


                                           
                             </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="equiposdecomputo"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Equipos de Computo</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona el Producto que buscas</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='computadores'>Computadores de Escritorio</MenuItem>  
                            <MenuItem value='laptops'>Laptops</MenuItem>               
                            <MenuItem value='accesorioscomputo'>Accesorios de Computo</MenuItem>                                
                            <MenuItem value='monitorespc'>Monitores</MenuItem>   
                            <MenuItem value='tablets'>Tablets</MenuItem> 
                            <MenuItem value='cpu'>CPU's</MenuItem>  
                            <MenuItem value='reguladoresvoltaje'>Reguladores de Voltaje y UPS</MenuItem>                                
                            <MenuItem value='impresoras'>Impresoras</MenuItem>   
                            <MenuItem value='accesoriosaudio'>Accesorios Audio y Video</MenuItem>                             
                            <MenuItem value='audioyvideo'>Audio y Video</MenuItem>                                     
                                                       
                             </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }

        else if(categoria==="aseoydesinfeccion"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Aseo y Desinfección</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona el Producto que buscas</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='contenedoresderesiduos'>Contenedores de Residuos</MenuItem>         
                            <MenuItem value='bolsasresiduos'>Bolsas Plásticas de Residuos</MenuItem>    
                            <MenuItem value='bolsasresiduos'>Bolsas para Cadáveres</MenuItem>    
                            <MenuItem value='elementosdeaseo'>Detergentes</MenuItem>    
                            <MenuItem value='alcohol'>Alcohol</MenuItem>      
                            <MenuItem value='desinfectantes'>Desinfectantes de Superficies</MenuItem>        
                            <MenuItem value='escobasytraperos'>Escobas y Traperos</MenuItem> 
                            <MenuItem value='elementosdeaseo'>Otros Elementos de Aseo</MenuItem>                            
                                   
                            </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }
        else if(categoria==="papeleria"){
            return (
                <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1 className="text-clientes"> Categoria de Papeleria</h1>
                <hr className="clientes_separator"></hr>  
                </div>
                <div className="divmarket_boton">
                <Link className="linkbtn" to={`/dashboard/homemarket`}>
                <FaArrowCircleLeft color="#03178C" className="icon-crearcliente"/>
                </Link>
                </div>
               </div>

                <div className="categorias_container">
                <FormControl className="formcontrol-nuevomto">
                <InputLabel>Selecciona el Producto que buscas</InputLabel>
                  <Select     name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                            fullWidth  MenuProps={MenuProps}>
                            <MenuItem value='accesoriosoficina'>Accesorios de Oficina</MenuItem>   
                            <MenuItem value='agendasymas'>Agendas, Programadores y Directorios</MenuItem>                           
                            <MenuItem value='archivo'>Archivo, Carpetas y Clasificación</MenuItem>    
                            <MenuItem value='equiposdeoficina'>Equipos de Oficina</MenuItem>     
                            <MenuItem value='formatospreimpresos'>Formatos Administrativos Preimpresos</MenuItem>  
                            <MenuItem value='papelesymas'>Papeles, Adhesivos y Blocks</MenuItem>           
                            <MenuItem value='utilesoficina'>Útiles de Oficina</MenuItem>                    
                           


                                           
                             </Select>
                           
            </FormControl> 

                <div className="div_boton_categorias">
                {this.state.retirado ? <Link className="linkbtn" to={`/dashboard/productos/${this.state.retirado}`}>
                    <Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></Link>:
                    <div><Button buttonSize="btn--wide" buttonColor='blue'>Buscar</Button></div>
                }
                </div>
                </div>
                </div>
            )
        }

       
    }
    
}

export default Categorias
