
import {SET_EQUIPOIA,LOADING_DATA_DATA,POST_EQUIPO_IA,EDIT_DOCLEGAL,SET_DOCSLEGAL,POST_DOCLEGAL,SET_CALIBRACIONES_TABLA,LOADING_DATA_CAL,POST_CRONOGRAMACAL_AUTO,POST_CRONOGRAMA_AUTO,LOADING_CLIENTE,SET_MTOPREV,EDIT_CLIENTE,SET_SOL_CORR,LOADING_SOL_CORR,POST_SOLICITUD_MTO,POST_EDITPROGCAL,POST_EDITPROGMTO,SET_INDICADORES_P,LOADING_INDP,LOADING_MTO, LOADING_CAL,SET_EQUIPOS_CAL,SET_EQUIPOS_MTO,SET_CALIBRACIONES,SET_PROGCALS,SET_MTOSCORRECTIVOS,POST_MTOCORRECTIVO,SET_MTOSPREV,POST_PCAL,POST_MTOPREVENTIVO,SET_PROGMTOS,POST_PMTO,SET_EQUIPOS,SET_EQUIPO,LOADING_DATA, POST_CLIENTE,SET_CLIENTES,SET_CLIENTE,POST_EQUIPO, POST_CALIBRACION, CLEAR_EQUIPOS_IA, POST_RECOMENDACION_IA, SET_REPUESTOS, LOADING_CASOS_TECNO, SET_CASO_TECNO, SET_CASOS_TECNO, POST_RUTA_APRENDIZAJE, GET_CURSOS, GET_CURSOS_RECOMENDADOS, SET_TAREAS_CLIENTE, SET_INDICADORES_NEW, GET_ANALISIS_OBSOLESCENCIA_USUARIO, GET_ANALISIS_OBSOLESCENCIA_PRESTADOR} from '../types';
const initialState={
    
    loading:true,
    loadingdata:false,
    loadingcal:true,
    loadingmto:true,
    loadingindp:true,
    loadingsolcorr:true,
    loadingcliente:true,
    loadingtecno: true,
    clientes:[],
    cliente:{},
    equipos:[],
    equipo:{},
    equiposmto:{},
    equiposcal:{},
    programto:[],
    programtos:[],
    progracal:[],
    progracals:[],
    calibracion:[],
    calibraciones:[],
    solicitudcorr:[],
    solicitudescorr:[],
    mtopreventivo:[],
    mtospreventivos:[],
    mtocorrectivo:[],
    mtoscorrectivos:[],
    indicadoresp:[],
    solicitudesmto:[],
    doclegales:[],
    equiposia:[],
    recomendacionIa: [],
    repuestos:[],
    casostecnovigilancia: [],
    casotecnovigilancia: {},
    cursosTotal: [],
    cursosRecomendados: [],
    recomendacionCursos: {},
    tareas:[],
    indicadoresnew:[],
    analisisObsolescenciaUsuario: [],
    analisisObsolescenciaPrestador: [],

}

export default function (state=initialState,action){
    switch(action.type){
        case LOADING_DATA:
            return {
                ...state,
                loading:true
            }
        case LOADING_DATA_DATA:
                return {
                    ...state,
                    loadingdata:true
            }
        case LOADING_DATA_CAL:
                return {
                    ...state,
                    loadingcal:true,
                    loading:true
                }
        case LOADING_CLIENTE:
            return{
                ...state,
                loadingcliente:true
            }
        case LOADING_CAL:
                return {
                    ...state,
                    loadingcal:true
                }
        case LOADING_MTO:
                return{
                    ...state,
                    loadingmto:true
                }
        case LOADING_INDP:
            return{
                ...state,
                loadingindp:true
            }
        case LOADING_SOL_CORR:
            return{
                ...state,
                loadingsolcorr:true
            }
        case LOADING_CASOS_TECNO:
            return {
                  ...state,
                  loadingtecno: true
            }
        case SET_SOL_CORR:
            return{
                ...state,
                loadingsolcorr:false,
                solicitudescorr:action.payload
            }
        case SET_INDICADORES_P:
            return{
                ...state,
                indicadoresp:action.payload,
                loadingindp:false,
            }
        case SET_INDICADORES_NEW:
            return{
                ...state,
                indicadoresnew:action.payload,
                loading:false,

            }
        
        case SET_CLIENTES:
            return{
                ...state,
                clientes:action.payload,
                loading:false,
                loadingdata:false
            }
        case SET_PROGMTOS:
            return{
                ...state,
                progmtos:action.payload,
                loadingmto:false
            }
        case SET_PROGCALS:
            return{
                ...state,
                progracals:action.payload,
                loadingcal:false,
            }
        case SET_CALIBRACIONES:
            return{
                ...state,
                calibraciones:action.payload,
                loadingcal:false,
             
            }
        case SET_CALIBRACIONES_TABLA:
                return{
                    ...state,
                    calibraciones:action.payload,
                    loadingcal:false,
                    loading:false
                }
        case SET_MTOSPREV:
            return {
                ...state,
                mtospreventivos:action.payload,
                loadingmto:false
            }
        case SET_MTOSCORRECTIVOS:
            return {
                ...state,
                mtoscorrectivos:action.payload,
                loadingmto:false
            }

        case SET_CLIENTE:
            return{
                ...state,
                cliente:action.payload,
                loading:false
            }

        case SET_MTOPREV:
            return{
                ...state,
                mtopreventivo:action.payload,
                loading:false
            }
        case SET_CASOS_TECNO:
                return {
                  ...state,
                  casostecnovigilancia: action.payload,
                  loadingtecno: false
            }
        case SET_CASO_TECNO:
                return {
                  ...state,
                  casotecnovigilancia: action.payload,
                  loadingtecno: false
            }
            case POST_RECOMENDACION_IA:
                return {
                  ...state,
                  recomendacionIa: action.payload
                }
        case POST_EQUIPO_IA:
                return {
                  ...state,
                  equiposia: action.payload
                }
        case POST_CLIENTE:
                return {
                  ...state,
                  clientes: [action.payload, ...state.clientes]
                };
        case POST_DOCLEGAL:
                return {
                      ...state,
                      doclegales: [action.payload, ...state.doclegales]
                };

        case SET_DOCSLEGAL:
                return{
                        ...state,
                        doclegales:action.payload,     
                        loadingdata:false,
                            
                        
                }
        case EDIT_DOCLEGAL:
            return{
                ...state,
                doclegales: [action.payload, ...state.doclegales]
            }
       
        case EDIT_CLIENTE:
                    return {
                      ...state,
                      clientes: [action.payload, ...state.clientes]
                    };
                
        case SET_EQUIPOS:
            return{
                ...state,
                equipos:action.payload,     
                loadingdata:false,
                    
                
            }

        case SET_REPUESTOS:
                return{
                    ...state,
                    repuestos:action.payload,     
                    loadingdata:false,
                        
                    
                }
        case SET_EQUIPOS_MTO:
                return{
                    ...state,
                    equiposmto:action.payload,
                    loadingmto:false
                    
                }
        case SET_EQUIPOS_CAL:
            return{
                ...state,
                    equiposcal:action.payload,
                    loadingcal:false,
                    
                    
            }
        case SET_EQUIPO:
            return{
                ...state,
                equipo:action.payload,
                loading:false
            }
         case SET_EQUIPOIA:
                return{
                    ...state,
                    equipo:action.payload,
                    loadingdata:false
                }
        case POST_EQUIPO:
            return{
                    ...state,
                    equipos: [action.payload, ...state.equipos]
    
            }
        case POST_EQUIPO_IA:
            return{
                ...state,
                equiposia:action.payload
            }
        case POST_RUTA_APRENDIZAJE:
                return {
                  ...state,
                  recomendacionCursos: action.payload,
        }
        case POST_CRONOGRAMA_AUTO:
             return{
                    ...state,
                    equiposmto:[action.payload, ...state.equiposmto]
            }
        case POST_CRONOGRAMACAL_AUTO:
                return{
                       ...state,
                       equiposcal:[action.payload, ...state.equiposcal]
            }

        case POST_SOLICITUD_MTO:
            return{
                ...state,
                solicitudesmto:[action.payload,...state.solicitudesmto]
            }
        case POST_PMTO:
            return{
                ...state,
                programto: [action.payload, ...state.programto]
            }
        case POST_EDITPROGMTO:
            return{
             ...state,
             programto:[action.payload,...state.programto]
            }
        case POST_EDITPROGCAL:
            return{
                ...state,
                progracals:[action.payload,...state.progracals]
            }
        case POST_PCAL:
            return{
                ...state,
                progracal: [action.payload, ...state.progracal]
            }

        case POST_MTOPREVENTIVO:
            return {
                ...state,
                mtopreventivo: [action.payload, ...state.mtopreventivo]
            }
        case POST_CALIBRACION:
            return {
                ...state,
                calibracion:[action.payload,...state.calibracion]
            }
        case POST_MTOCORRECTIVO:
            return {
                ...state,
                mtocorrectivo:[action.payload,...state.mtocorrectivo]
            }
    
        case CLEAR_EQUIPOS_IA:
            return{
                ...state,
                equiposia:[]
            }

         case GET_CURSOS:
                return {
                  ...state,
                  cursosTotal: action.payload
                }
          
         case GET_CURSOS_RECOMENDADOS:
                return {
                  ...state,
                  cursosRecomendados: action.payload
                }
          
        case POST_RUTA_APRENDIZAJE:
                return {
                  ...state,
                  recomendacionCursos: action.payload,
                }
        case SET_TAREAS_CLIENTE:
            return {
                ...state,
                tareas:action.payload
            }
        case GET_ANALISIS_OBSOLESCENCIA_USUARIO:
                return {
                  ...state,
                  analisisObsolescenciaUsuario: action.payload,
                  loadingdata: false
            }
        case GET_ANALISIS_OBSOLESCENCIA_PRESTADOR:
                return {
                  ...state,
                  analisisObsolescenciaPrestador: action.payload,
                  loadingdata: false
         }
        default:
            return{
                ...state
            }

        
        
            
    }
}