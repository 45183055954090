import React, { Component } from 'react'
import Button from '../../Elements/Button';
import './MarketplaceClientes.css'; 

export class MarketplaceClientes extends Component {
   
    componentDidMount() {
        const script = document.createElement("script");    
        script.async = true;   
         script.src = "https://checkout.epayco.co/checkout.js";    
         this.div.appendChild(script);  
        }

    render() {
       
   
        return (
            <div className="market_container">
                <div className="titulomarket_section">
                <div className="titulo_market">
                <h1> MarketPlace</h1>
                </div>

                <div className="divmarket_boton">
                
                <Button type="submit" buttonSize="btn--wide" buttonColor='blue'>
                           Nuevo</Button>

                </div>
                </div>

                <div  ref={el => (this.div = el)}>        
                  {<form> 
                    <script
            src="https://checkout.epayco.co/checkout.js"
            class="epayco-button"
            data-epayco-key="78709b2380b6f6fd9cbda040e3d9fbb6"
            data-epayco-amount="50000"
            data-epayco-name="Monitor de Signos Vitales"
            data-epayco-description="Monitor de Signos Vitales"
            data-epayco-currency="cop"
            data-epayco-country="co"
            data-epayco-test="true"
            data-epayco-external="true"
            data-epayco-response="https://alphaplatform-388f8.web.app/dashboard/marketplace/respuesta"
           
            >
        </script>
    </form>
    }
                </div>
                
           
               
               
               
            
               
                
               
                
            </div>
        )
    }
}

export default MarketplaceClientes
