import React,{Component} from 'react';
import imgsrc from '../Navbar/logohorizontal.svg';
import TextField from '@material-ui/core/TextField';
import Button from '../Elements/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import './Register.css'
//REDUX
import { connect} from 'react-redux';
import {signupUser} from '../../redux/actions/useractions';
import { Typography } from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class Register extends Component{
    constructor(){
        super();
        this.state={
            email:'',
            password:'',
            confirmPassword:'',
            handle:'',
            errors:{},
            showPassword: false,
            showConfirmPassword: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };


    static getDerivedStateFromProps(props, state) {
        return props.UI.errors ? {errors: props.UI.errors} : null;
    }

    handleSubmit=(event)=>{
        
        event.preventDefault(); 
       
        const newUserData={
            email:this.state.email,
            password:this.state.password,
            confirmPassword: this.state.confirmPassword,
            handle: this.state.handle 
        };

        this.props.signupUser(newUserData, this.props.history); 
      
    };
    
   handleChange=(event)=>{
    this.setState({
        [event.target.name]:event.target.value
    });
       
   };

    render(){
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;
        
        return (
            <div id="register">
            <div className="register-container">
                    <div className="register-card">
                        <div className="login-divtitulo">
                        <Link to="/" className='login-logo'>
                        <img src={imgsrc} alt="logo" />
                        </Link>
                        
                         <h1 className="login-titulo">Registrate</h1>
                        </div>
    
                        <form className="login-form" noValidate onSubmit={this.handleSubmit}>
                        <TextField className="login-input-email"
                        required 
                        name="email"id="email" 
                        label="Email" 
                        value={this.state.email} 
                        onChange={this.handleChange} 
                        fullWidth 
                        helperText={errors.email}
                        error={errors.email ? true:false}        
                        />
                        <br/>
                        <TextField
                            className="login-input-contraseña"
                            name="password"
                            id="password"
                            label="Contraseña"
                            type={this.state.showPassword ? 'text' : 'password'} // Mostrar u ocultar la contraseña según el estado
                            autoComplete="current-password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() =>
                                                this.setState({
                                                    showPassword: !this.state.showPassword,
                                                })
                                            }
                                        >
                                            {this.state.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                            <br/>
                         
                            <TextField
                                className="login-input-contraseña"
                                name="confirmPassword"
                                id="confirmPassword"
                                label="Confirmar Contraseña"
                                type={this.state.showConfirmPassword ? 'text' : 'password'} // Mostrar u ocultar la contraseña según el estado
                                autoComplete="current-password"
                                value={this.state.confirmPassword}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.confirmPassword}
                                error={errors.confirmPassword ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={() =>
                                                    this.setState({
                                                        showConfirmPassword: !this.state.showConfirmPassword,
                                                    })
                                                }
                                            >
                                                {this.state.showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <br/>
                                       
                        <TextField  className="login-input-email"
                        required name="handle"id="handle"
                        label="@Nombredeusuario" 
                      value={this.state.handle} 
                      onChange={this.handleChange} 
                      fullWidth 
                      helperText={errors.handle}
                      error={errors.handle ? true:false}
                      />
                       
                      
                        <br/>
                        {errors.general && (
                        <Typography variant="body2">{errors.general}</Typography>
                            )}
                         <br/>

    
                        {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                         <Button type="submit" buttonSize="btn--wide" buttonColor='blue'>
                           Registrarme</Button>}
                           <br></br>
                           <small className="login-text">Ya tienes una cuenta? <Link to="/login"> Ingresa aquí</Link> </small>
                         
                    </form>
    
    
                    </div>
    
                   
    
    
                </div>
            </div>
        )
    }
   
}
Register.propTypes={
    user:PropTypes.object.isRequired,
    UI:PropTypes.object.isRequired,
    signupUser:PropTypes.func.isRequired
}

const mapStateToProps=(state)=>({
    user:state.user,
    UI:state.UI
})

export default connect(mapStateToProps,{signupUser}) (Register);
