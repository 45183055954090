import React, { Component, Fragment } from 'react';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import './TablaMobiliario.css'

import { getMobiliarios, subirImagenMobiliario } from '../../../redux/actions/mobiliarioactions';
import NuevoMobiliario from './NuevoMobiliario';
import SubirMobiliarioExcel from './SubirMobiliarioExcel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import {Link} from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import {FaArrowCircleLeft, FaArrowLeft, FaChartBar, FaFilePdf, FaMagic, FaPlus} from "react-icons/fa";

export class TablaMobiliario extends Component {

    state = { 
        // Initially, no file is selected 
        selectedFile: null,
        open: false,
        mobiliarioid:''
      }; 

      componentDidMount(){
        this.props.getMobiliarios(this.props.clienteId);
      }
      //FUncion le vale tres carajos como se llamen sus variables adentro
      handleOpen=(mobiliarioid)=>{
        this.setState({open:true,mobiliarioid:mobiliarioid});
      };

      handleClose=()=>{
        this.setState({open:false,mobiliarioid:''});
     };


     onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
      }; 

      onFileUpload = () => { 

        if (this.state.selectedFile !== null && this.state.selectedFile !== undefined) {

        // Crear un objeto FormData
        const formData = new FormData(); 
        
        // Actualizar el objeto formData
        formData.append( 
          "myFile", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        );
    
        // Mostrar en consola información útil para depuración
        console.log("equipo id " + this.state.mobiliarioid);
        console.log('cliente id ' + this.props.clienteId);
        console.log('nombre de la imagen ' + this.state.selectedFile.name);
    
        // Validar que se haya seleccionado un archivo
        //valide que no sea null ni undefined 
       

          // Subir la imagen y cerrar el diálogo al completar
          this.props.subirImagenMobiliario(formData, this.state.mobiliarioid, this.props.clienteId)
          console.log("imagen subida");
          //
          this.setState({open:false});
        } else {
          // Si el archivo es null o undefined, mostrar mensaje de error
          console.log('Debes seleccionar una imagen');
          this.setState({ error: 'Por favor selecciona una imagen antes de subir.' });
        }
        
    };

        
    
    
    render() {
        const {mobiliarios,loadingdata}= this.props.dataMob;

        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            MiIcon:forwardRef((props, ref) => <AddToPhotosIcon {...props} ref={ref}/>),
            MiIcon2:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
            MiIcon3:forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
            MiIcon4:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
          };

        const columns=[
            {
                field: 'imagenurl',
                title: 'Avatar',
                render: rowData => <img src={rowData.imagenurl} style={{width: 50, borderRadius: '50%'}}/>
              },
        
            {title:'Id',field:'idinventario'},
            {title:'Nombre',field:'nombre'},
            {title:'Marca',field:'marca'},
            {title:'Modelo',field:'modelo'},
            {title:'Serie',field:'serie'},
            {title:'Ubicación',field:'ubicacion'},
            {title:'Servicio',field:'servicio'},
            {title:'Estado', field:'estado'},
            {title:'Tipo',field:'tipo'},
          
        ]
      

        let mapeomobiliario= !loadingdata ?(
        <div>
            <div className='div-container-links-inventario'>

            <NuevoMobiliario clienteId={this.props.clienteId}></NuevoMobiliario>
            <SubirMobiliarioExcel clienteId={this.props.clienteId}></SubirMobiliarioExcel>

            <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/inventariopdfmobiliario/${this.props.clienteId}`} state={mobiliarios}>
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Ver Inventario en PDF
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>

         {/*
                        <NuevoEquipo clienteId={this.props.clienteId}></NuevoEquipo>
                        <SubirEquipos clienteId={this.props.clienteId}></SubirEquipos>
                        <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/inventario/${this.props.clienteId}`} state={equipos}>
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Ver Inventario en PDF
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
                        */}
                        
                    </div>
             

        <MaterialTable
        columns={columns}
        data={mobiliarios}
        title={
            <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'bold' }}></h2>
          }
        icons={tableIcons}
        options={{
            cellStyle: {
                fontSize: 11,
                fontWeight:500,
                color:'#696B6D'
              
             
              },
              headerStyle: {
                fontSize: 12,
                fontWeight: 'bolder',
                color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
              }
          
          }}

          actions={[
            rowData => ({
                icon: () =>  <Link to={`/dashboard/perfilcliente/mobiliario/${this.props.clienteId}/${rowData.mobiliarioid}`} style={{ color: '#03178C' }} ><AssignmentIcon  /></Link>,
                tooltip: 'Ver Perfil ',
                onClick: (rowData)
              }),
            {
                icon: tableIcons.MiIcon3,
                tooltip: 'Subir Imagen',
                onClick: (event, rowData) => this.handleOpen(rowData.mobiliarioid) 
              }
             
           
          ]}
         
        >
          
        </MaterialTable>

{/*//aqui va el dialogo */}
<Dialog
                open={this.state.open}
                onClose={this.handleClose}
                 fullWidth
                maxWidth="sm"
                >
                 <DialogTitle>Subir Imagen del Equipo Mobiliaro o Industrial</DialogTitle>
                 
                <DialogContent>
                
                </DialogContent>
                <input type="file" onChange={this.onFileChange} accept="image/png,image/jpeg"   /> 
                <DialogActions>
                    <MyButton tip="Cerrar" onClick={this.handleClose}>
                    <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                    </MyButton>
                    <MyButton tip="Subir Imagen del Equipo" onClick={this.onFileUpload}>
                    <FaPlus  color="#03178C" className="icon-formulario"/>
                    </MyButton>
                    
                </DialogActions>

                </Dialog>

        
        </div>
        ):(
        <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
        )


        return (
            <div>
                {mapeomobiliario}
              
            </div>
        )
    }
}
TablaMobiliario.propTypes={
    getMobiliarios:PropTypes.func.isRequired,
    dataMob:PropTypes.object.isRequired,
    clienteId: PropTypes.string.isRequired,
    subirImagenMobiliario:PropTypes.func.isRequired
}
const mapStateToProps=state=>({
    dataMob:state.dataMob
})
export default connect(mapStateToProps,{getMobiliarios,subirImagenMobiliario}) (TablaMobiliario);

