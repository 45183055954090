import React, { useState, Fragment } from 'react';
import MyButton from '../../../utils/MyButton';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SubirMobiliarioExcel.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FaArrowLeft, FaPlus, FaDatabase, FaCheck, FaCheckCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@material-ui/core';
import { getMobiliarios, subirMobExcel } from '../../../redux/actions/mobiliarioactions';



function SubirMobiliarioExcel(props) {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xlsx'],
    },
    maxFiles: 1
  });

  const { loading } = props.UI
  const { errors } = props.UI;
  const { aprobado} = props.UI;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    console.log(props.clienteId)
    console.log(props.user.credentials.handle)
    setOpen(true);
  };

  const handleClose = () => {
    //get equipos 
    //limpiar estado ui 
    //un reset del estado aprobado y errores 
    //if hay un aprobado =>getequipos si no set open a false 
    if(aprobado){
        props.getMobiliarios(props.clienteId);
      
        setOpen(false)
    }
    
    setOpen(false);
  };


  const onFileUpload = () => {
    const formData = new FormData();
    formData.append("myFile", acceptedFiles[0], acceptedFiles[0].name);
    console.log(acceptedFiles[0].name)
    console.log(formData);
    console.log(props.clienteId);
    props.subirMobExcel(formData, props.clienteId, props.user.credentials.handle);
  };


  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (

    <Fragment>
      <div className='div-card-link' onClick={handleOpen}>
        <div className="dashing-cardcontent">
          <div className="dashing-cardtext">
            <p className="dashing-text-card">
              Subir Mobiliario en Excel
            </p>
          </div>
          <div className="dashing-cardicon-blue">
            <FaDatabase color="#FFFFFF" className="dashing-icon" />
          </div>
        </div>
      </div>
      

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Subir Equipo Mobiliario e Industrial</DialogTitle>

        {
              aprobado ? (<DialogContent>

            <div className='div-aprobado'>
              <div className='div-icono-aprobado'>
                <FaCheck color="#ffffff" className='icono-aprobado'/>
              </div>
              <h2 className='text-aprobado'>Equipos Cargados Exitosamente</h2>
             </div>

              </DialogContent>):
              (
              
              <DialogContent>
                <p>Para subir los equipos debes descargar y rellenar la siguiente plantilla: </p>
                <a href="https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/Plantilla%20Final%20Mobiliario%20e%20Industriales.xlsx?alt=media" target="_blank" rel="noopener noreferrer">Descargar Plantilla</a>
                <p></p>
                <p>En este formato cada fila es un equipo mobiliario o industrial a subir</p>
                <p>Por lo que se deben llenar todos los datos obligatorios y, en lo posible, colocar la información correspondiente en los campos opcionales</p>
                <p>Una vez el excel esté diligenciado suba el archivo aquí:</p>
      
      
                <section className="">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Arrastra y suelta el archivo excel, o da click aquí para buscarlo</p>
                    <em>(Recuerda que sólo se puede subir un archivo excel)</em>
      
      
                  </div>
                  <aside>
                    {fileRejections.length > 0 ?
                      (<>
                        <div>
                          <h4>Sólo se puede cargar un archivo</h4>
                        </div>
                      </>) : (<></>)}
      
                    {acceptedFiles.length > 0 ?
                      (<>
                        <div>
                          <h4>Archivo a cargar:</h4>
                          <ul>{acceptedFileItems}</ul>
                        </div>
                      </>) : (<></>)}
                  </aside>
                </section>
      
      
                <div>
                {errors && errors.errors && (
                    errors.errors.length > 0 ? (
                        <ul>
                        {errors.errors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                        </ul>
                    ) : (
                        <p>No se encontraron errores.</p>
                    )
                    )}
      
                 
                </div>
      
      
      
              </DialogContent>

              )
            }
        

        <DialogActions>
          <MyButton tip="Cerrar" onClick={handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
          </MyButton>

         
          {
            loading ? (
              <CircularProgress size={30} color="primary" className="login-progress" />
            ) : (
                aprobado?( 
                <MyButton tip="Continuar" onClick={handleClose}>
               <FaCheckCircle color="#20B90C" className="icon-formulario" />
              </MyButton>

                ):(
                <MyButton tip="Subir Archivo" onClick={onFileUpload}>
                                <FaPlus color="#03178C" className="icon-formulario" />
                            </MyButton>
                )
              
            )
          }
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

SubirMobiliarioExcel.propTypes = {
    subirMobExcel: PropTypes.func.isRequired,
  getMobiliarios:PropTypes.func.isRequired,
  clienteId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

export default connect(mapStateToProps, { subirMobExcel, getMobiliarios })(SubirMobiliarioExcel);
