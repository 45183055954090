import React from 'react';
import { Link } from 'react-router-dom';
import MyButton from '../../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';
import TablaCronoMtoTec from './TablaCronoMtoTec';

function CronoMtoPerfilTec(props) {
    return (
        <div>
            <div className="titulo_section">
                <div className="titulo_clientes">
                    <h1 className="text-clientes">Cronograma de Mantenimiento Preventivo</h1>
                    <hr className="clientes_separator"></hr>
                </div>

                <div className="div_boton">
                    <MyButton tip="Volver" onClick={() => props.history.goBack()}>
                        <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
                    </MyButton>
                </div>
            </div>

            <div className="div-tabla-equipos">
                <TablaCronoMtoTec clienteId={props.match.params.clienteId} history={props.history} />
            </div>
        </div>
    );
}

export default CronoMtoPerfilTec;