import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRepuestos } from '../../../redux/actions/dataactions';
import ListaRepuestosVerWebPDF from './ListaRepuestosVerWebPDF';
import ListaRepuestosDocuPDF from './ListaRepuestosDocuPDF';
import { PDFViewer } from '@react-pdf/renderer';
import './ListaRepuestosPDF.css';
import { useHistory } from 'react-router-dom';

function ListaRepuestosPDF(props) {
  const [verWeb, setVerWeb] = useState(false);
  const [verPDF, setVerPDF] = useState(false);

  useEffect(() => {
    props.getRepuestos(props.match.params.handleuser);
  }, [props.match.params.handleuser]);

  const handleOpen = () => {
    setVerWeb(!verWeb);
    setVerPDF(false);
  };

  const handleOpenPDF = () => {
    setVerPDF(!verPDF);
    setVerWeb(false);
  };

  const { repuestos, loading } = props.data;
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack(); // Esto llevará al usuario una página hacia atrás en el historial de navegación.
  };
  return (
    <div className='div-inventaripdfgeneral'>
      <div className='div-inventaripdftitulo'>
        <div className="div_boton">
        <MyButton tip="Volver" onClick={handleGoBack}>
          <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
        </MyButton>
        </div>
        <ButtonGroup variant="text" aria-label="text button group" color='primary'>
          <Button onClick={handleOpenPDF}>{verPDF ? "Ocultar PDF" : "Ver PDF"}</Button>
        </ButtonGroup>
      </div>
      <div className='div-inventariopdfcontent'>
        {repuestos ? (
          <>
            {verWeb ? <ListaRepuestosVerWebPDF repuestos={repuestos} /> : null}
            {verPDF ? (
              <PDFViewer style={{ width: "100%", height: "90vh" }}>
                <ListaRepuestosDocuPDF repuestos={repuestos} />
              </PDFViewer>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

ListaRepuestosPDF.propTypes = {
  getRepuestos: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data,
});

export default connect(mapStateToProps, { getRepuestos })(ListaRepuestosPDF);