

import React, { Component, useState }  from 'react'
import { ButtonGroup,Button } from '@material-ui/core';
import {Link} from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import {FaArrowCircleLeft} from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {getEquipos} from '../../../redux/actions/dataactions'; 

import   { PDFDownloadLink, PDFViewer }   from '@react-pdf/renderer';
import './InventarioPDFAsis.css';
import InventarioDocuPDF from '../Equipos/InventarioDocuPDF';

export class InventarioPDFAsis extends Component {
    constructor(){
        super();
        this.state={
            equipos:{},
            errors:{},
            open:false,
            verWeb:false,
            verPDF:false
        };
        
    };
    
    componentDidMount(){
       this.props.getEquipos(this.props.match.params.clienteId);
       
      
      }

      handleOpen = () => {
        if(this.state.verWeb){
            this.setState({ verWeb: false });
        }else{
            this.setState({ verWeb: true,verPDF:false});
        }
        
      };

      handleOpenPDF = () => {
        if(this.state.verPDF){
            this.setState({ verPDF: false });
        }else{
            this.setState({ verPDF: true,verWeb:false});
        }
        
      };

  render() {
    const {equipos,loading}= this.props.data;



    

    return (
      <div className='div-inventaripdfgeneral'>
      
      <div className='div-inventaripdftitulo'>


      <div className="div_boton">
                <Link to={`/dashboard/inventarioequiposasistencial`}>
                <MyButton tip="Volver">
                 <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
                </MyButton>
                </Link>
                </div> 

        <ButtonGroup variant="text" aria-label="text button group" color='primary'>

        <Button onClick={this.handleOpenPDF}>
        {this.state.verPDF? "Ocultar PDF":"Ver PDF"}</Button>
              


        </ButtonGroup>
        </div>

        <div className='div-inventariopdfcontent'>
        {equipos ? (
        <>
      
        {this.state.verPDF ?  <PDFViewer style={{ width: "100%", height: "90vh" }}>
            <InventarioDocuPDF equipos={equipos} />
          </PDFViewer>  : null}
        
        </>
        ) : null}
        </div>
       
      </div>
    )
  }
}

InventarioPDFAsis.propTypes={
    getEquipos:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired, 
}
const mapStateToProps=state=>({
    data:state.data
})
export default connect(mapStateToProps,{getEquipos}) (InventarioPDFAsis);
