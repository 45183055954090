import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div id="footer">
            <div className="footer-container">
                <div className="footer-maintext">
                <h5 className="footer-text">© Bioinnova Technologies S.A.S</h5>
                <p className="footer-para">2020</p>
                </div>

                <div className="footer-paratext">
                    <p className="footer-para">
                        Desarrollado con proposito, para un mejor futuro en la ingeniería clínica.
                    </p>

                </div>
            
            </div>
           
        </div>
    )
}

export default Footer
