import React, { Component, Fragment  } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';



import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus, FaPencilAlt, FaUserAstronaut } from "react-icons/fa";

import {addTecnico,clearErrors} from '../../../redux/actions/dataactions';


export class AddTecnico extends Component {
    constructor(){
        super();
        this.state={
            userhandle:'',
            
            errors:{},
            open:false
        };
        
    };

        
        static getDerivedStateFromProps(props, state) {
            return props.UI.errors ? {errors: props.UI.errors} : null;
        }
      
        handleOpen = () => {
            this.setState({ open: true });
          };
          handleClose = () => {
            this.setState({ open: false });
          };
         
        
          handleChange = (event) => {
            this.setState({
              [event.target.name]: event.target.value
            });
          };

          handleSubmit = (event) => {
            event.preventDefault();
                    const newClienteData={
                        userhandle:this.state.userhandle,
                       
                     
                  };
            console.log(newClienteData)
            this.props.addTecnico(newClienteData,this.props.clienteId);
          
          };
        

  render() {
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;

    return (
        <div>
        <Fragment>
        <div className="div-botoneliminarcliente">
    <MyButton tip="Añadir Personal/Contratista" onClick={this.handleOpen}>
    <FaUserAstronaut color="#03178C" className="icon-eliminarcliente"/>
     </MyButton>
     </div>
     <Dialog
     open={this.state.open}
     onClose={this.handleClose}
     fullWidth
     maxWidth="sm"
    >
   
    <DialogTitle>Añadir Personal o Contratista</DialogTitle>
                 
                <DialogContent>
                    <form>
                    <TextField className="login-input-email"
                      required
                      type="text"
                      name="userhandle"
                      id="userhandle"
                      label="Nombre De Usuario del Personal/Contratista a Añadir"
                      value={this.state.userhandle}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.userhandle}
                      error={errors.userhandle?true:false}
                      />
                      <br/>
                     
                    </form>
                </DialogContent>


                <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Añadir Personal/Contratista" onClick={this.handleSubmit}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
            
            
          </DialogActions>
    </Dialog>
    </Fragment>
    </div>
    )
  }
}
AddTecnico.propTypes = {
    addTecnico: PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

  const mapActionsToProps={addTecnico,clearErrors};

  const mapStateToProps = (state) => ({
    equipo: state.data.equipo.credentials,
    UI:state.UI
  });
  
 export default connect(mapStateToProps,mapActionsToProps)(AddTecnico);
