import React, { Component, Fragment } from 'react'
import './DocumentacionNuevo.css';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaUserPlus,FaArrowLeft,FaPlus, FaFileArchive, FaFileImport } from "react-icons/fa";

import {postDocLegal,clearErrors} from '../../../redux/actions/dataactions';

export class DocumentacionNuevo extends Component {
    constructor(){
        super();
        this.state={
            tipodoc:'',
            descripcion:'',
            consecutivo:'',
           
            errors:{},
            open:false
        };
        
    };

    static getDerivedStateFromProps(props, state) {
      return props.UI.errors ? {errors: props.UI.errors} : null;
  }

  /*  componentWillReceiveProps(nextProps) {
      if (nextProps.UI.errors) {
        this.setState({
          errors: nextProps.UI.errors
        });
      }
      if (!nextProps.UI.errors && !nextProps.UI.loading) {
        this.setState({ nombre:'',
        ccnit:'',
        email:'',
        tipouser:'',
        telefono:'',
        direccion:'',
        ciudad:'', open: false, errors: {} });
      }
  }
*/
    handleOpen=()=>{
    this.setState({open:true});
    };
    handleClose=()=>{
    this.props.clearErrors();
    this.setState({open:false,tipodoc:'',
    descripcion:'',
    consecutivo:'',errors:{}});
    };

    handleChange=(event)=>{
    this.setState({[event.target.name]:event.target.value});
    };

    handleSubmit=(event)=>{
    event.preventDefault();
    const newUserData={
      tipodoc:this.state.tipodoc,
      descripcion:this.state.descripcion,
      consecutivo:this.state.consecutivo,
      clienteid:this.props.clienteId,
      url:'Sin Url'
      
  };

  console.log(newUserData)
    this.props.postDocLegal(newUserData,this.props.clienteId); 
    //Aqui se postea al cliente

    }

    

    render() {
      const { UI:{loading}}=this.props; 
      const {errors}=this.state; 

        return (
            <Fragment>
            <MyButton tip="Nuevo Documento" onClick={this.handleOpen}>
            <FaFileImport color="#03178C" className="icon-crearcliente"/>
             </MyButton>

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Crear Nuevo Documento</DialogTitle>
                 
                <DialogContent>
                    <form>
                    

                    <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Tipo Documento</InputLabel>
                            <Select
                            required
                         name="tipodoc"
                         id="tipodoc"
                         value={this.state.tipodoc}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Contrato">Contrato</MenuItem>
                            <MenuItem value="Factura">Factura</MenuItem>
                            <MenuItem value="Hoja de Vida">Hoja de Vida Ingeniero</MenuItem>
                            <MenuItem value="Registro Invima">Registro Invima</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipodoc}</FormHelperText>
                        </FormControl>

                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="descripcion"
                      id="descripcion"
                      label="Descripción"
                      value={this.state.descripcion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.descripcion}
                      error={errors.descripcion?true:false}
                      />

                <TextField className="login-input-email"
                      required
                      type="text"
                      name="consecutivo"
                      id="consecutivo"
                      label="Consecutivo"
                      value={this.state.consecutivo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.consecutivo}
                      error={errors.consecutivo?true:false}
                      />

                       
                    

                       
                    </form>
                </DialogContent>
            
                <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Nuevo Documento" onClick={this.handleSubmit}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
            
            
          </DialogActions>

            </Dialog>


            </Fragment>
        )
    }
}

DocumentacionNuevo.propTypes={
  postDocLegal:PropTypes.func.isRequired,
  clearErrors:PropTypes.func.isRequired,
  UI:PropTypes.object.isRequired
};
const mapStateToProps=(state)=>({
  UI:state.UI
});

export default connect (mapStateToProps,{postDocLegal,clearErrors}) (DocumentacionNuevo)


