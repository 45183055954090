import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import MyButton from '../../../../utils/MyButton'
import { FaArrowCircleLeft, FaChartBar } from 'react-icons/fa'
import './InventarioPerfilTec.css'
import InventarioTablaTec from './InventarioTablaTec'

function InventarioPerfilTec(props) {
    const history = useHistory();

    useEffect(() => {
        console.log(props.match.params.clienteId);
    }, [props.match.params.clienteId]);

    return (
        <div>
            <div className="titulo_section">
                <div className="titulo_clientes">
                    <h1 className="text-clientes">Inventario de Equipos</h1>
                    <hr className="clientes_separator"></hr>
                </div>

                <div className="div_boton">
                    <MyButton tip="Volver" onClick={() => history.goBack()}>
                        <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
                    </MyButton>
                </div>
            </div>

            <div className='container-inventario-perfil'>
                <InventarioTablaTec clienteId={props.match.params.clienteId}/>
            </div>
        </div>
    )
}

export default InventarioPerfilTec;