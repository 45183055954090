import React, { Component } from 'react';
import './ListaMtoProg.css'; 
import {getprogmtos,clearErrors,deleteProgMto,postMtoTabla,editProgMto} from '../../../redux/actions/dataactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft,FaCheck,FaCheckCircle,FaPlus, FaTrash } from "react-icons/fa";
import MaterialTable from 'material-table';

import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';




export class ListaMtoProg extends Component {
    
    constructor(){
        super();
        this.state={
            chasis:false,
            vaccesorios:false,
            vcontroles:false,
            vgeneral:false,
            limpieza:false,
            lelectronica:false,
            pcuantitativa:false,
            selectrica:false,
            lchasis:false,
            lubricacion:false,
            prueba1:'',
            instrumento1:'',
            valor1:'',
            aprobo1:'',
            prueba2:'',
            instrumento2:'',
            valor2:'',
            aprobo2:'',
            observaciones:'',
            horainicio:'',
            horafinal:'',
            firmarecibe:'',
            cargorecibe:'',
            fecha:'',
            errors:{},
            aprobado:{},
            open:false,
            opendelete:false,
            progid:'',
            clienteid:''
        };
        
    };


    componentDidMount(){
       this.props.getprogmtos(this.props.equipoId);
      }
      static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }
     

    handleOpen=(rawdataprogid,rawdatafecha,rawdatahora)=>{
       

        this.setState({open:true,progid:rawdataprogid,fecha:rawdatafecha,horainicio:rawdatahora});
    
        };

     handleClose=()=>{
           // this.props.clearErrors();
           if(this.state.aprobado.mensaje){
              this.handleEditProgMto();
              this.setState({open:false,errors:{},aprobado:{}});
           }else{
            this.setState({open:false,errors:{},aprobado:{}});
           }
            
            };

    
     handleOpenDelete=(rawdataprogid,rawdataclienteid)=>{
    
                this.setState({opendelete:true,progid:rawdataprogid,clienteid:rawdataclienteid});
               
                };
        
    handleCloseDelete=()=>{
                   // this.props.clearErrors();
                    this.setState({opendelete:false,errors:{},aprobado:{}});
                    };
    handleDelete=()=>{
      const {credentials}=this.props.user;
      if(credentials.tipouser==="Tecnico"){
        console.log("Mantenimiento borrado desde:"+credentials.tipouser)
        console.log("Perfil de Ingenieria"+credentials.handleuser)
        //mandar el user de ingenieria
        this.props.deleteProgMto(this.state.clienteid,this.state.progid,this.props.equipoId,credentials.handleuser);
        
       // this.handleEditProgMto(this.state.progid); 
            }else{
              //mandar el handle user normal 
              console.log("Mantenimiento enviado desde:"+credentials.tipouser)
              console.log("Perfil de Ingenieria"+credentials.handle)
              this.props.deleteProgMto(this.state.clienteid,this.state.progid,this.props.equipoId,credentials.handle);
              //this.handleEditProgMto(this.state.progid); 
            }
     
     
    
  
    }

      handleChange=(event)=>{
                      this.setState({ [event.target.name]:event.target.value});
                      
                 };
    
    handleEditProgMto=()=>{

      this.props.editProgMto({nuevoestado:'Realizado'},this.state.progid,this.props.equipoId); 
   
    }

    handlePostMto=()=>{
   
      const {credentials}=this.props.user;
       const newMtoPreventivo={
        chasis:this.state.chasis,
        vaccesorios:this.state.vaccesorios,
        vcontroles:this.state.vcontroles,
        vgeneral:this.state.vgeneral,
        limpieza:this.state.limpieza,
        lelectronica:this.state.lelectronica,
        pcuantitativa:this.state.pcuantitativa,
        selectrica:this.state.selectrica,
        lchasis:this.state.lchasis,
        lubricacion:this.state.lubricacion,
        prueba1:this.state.prueba1,
        instrumento1:this.state.instrumento1,
        valor1:this.state.valor1,
        aprobo1:this.state.aprobo1,
        prueba2:this.state.prueba2,
        instrumento2:this.state.instrumento2,
        valor2:this.state.valor2,
        aprobo2:this.state.aprobo2,
        observaciones:this.state.observaciones,
        horainicio:this.state.horainicio,
        horafinal:this.state.horafinal,
        fecha:this.state.fecha,
        firmarecibe:this.state.firmarecibe,
        cargorecibe:this.state.cargorecibe,
           
    };

    if(credentials.tipouser==="Tecnico"){
   /* console.log("Mantenimiento enviado desde:"+credentials.tipouser)
    console.log("Handle User"+credentials.handleuser)
    //mandar el user de ingenieria
    console.log("CLiente id"+this.props.clienteId)
    console.log("Prog id"+this.state.progid)
    console.log('Equipo Id'+this.props.equipoId);*/
    
    
   
   // newmtoprev,equipoid,clienteid,progid,handle
  this.props.postMtoTabla(newMtoPreventivo,this.props.equipoId,this.props.clienteId,this.state.progid,credentials.handleuser);
    //this.props.postMto(newMtoPreventivo,this.props.equipoId,this.props.clienteId,credentials.handleuser); 
 
        }else{
          //mandar el handle user normal 
        /*  console.log("Mantenimiento enviado desde:"+credentials.tipouser)
          console.log("Handle User"+credentials.handle)
          //mandar el user de ingenieria
          console.log("CLiente id"+this.props.clienteId)
          console.log("Prog id"+this.state.progid)
          console.log('Equipo Id'+this.props.equipoId);*/
         
         this.props.postMtoTabla(newMtoPreventivo,this.props.equipoId,this.props.clienteId,this.state.progid,credentials.handle);  //postMtoTabla
        }
 
      
      

    }
 
    
     
    
    render() {
        const {progmtos,loadingmto}= this.props.data; 
      
        const {errors}=this.state;
        const{aprobado}=this.state;
        const { UI:{loading}}=this.props; 
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const columns=[
            {title:'Fecha',field:'fecha'},
            {title:'Mes',field:'mesnombre'},
            {title:'Hora',field:'hora'},
            {title:'Duración',field:'duracion'},
            {title:'Estado',field:'estado'},
            {title:'Actividades',field:'actividades'}
           
        ]


        
     let mapeoprogmtos=!loadingmto?(
         <div>
    <MaterialTable
            columns={columns}
            data={progmtos}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Mantenimientos Preventivos Programados</h2>
            }
            icons={tableIcons}
            options={{
              cellStyle: {
                  fontSize: 12,
                  fontWeight:500,
                  color:'#696B6D'
                
               
                },
                headerStyle: {
                  fontSize: 14,
                  fontWeight: 'bolder',
                  color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                }
            
            }}
    
            actions={[
                
              rowData => ({
                icon: tableIcons.Add,
                tooltip: 'Hacer Mantenimiento',
                onClick: (event, rowData) => {
                    this.handleOpen(rowData.progid,rowData.fecha,rowData.hora);
                },
                disabled: rowData.estado==='Realizado'
              })
             ,
                
                {
                  icon: tableIcons.Delete,
                  tooltip: 'Borrar Programación',
                  onClick: (event, rowData) => {
                      
                    this.handleOpenDelete(rowData.progid,this.props.clienteId);
                  }
                }
              ]}
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
       

        return (
           <div className="listamtoprog-container">
              
            <div className="listamtoprog-table">
            {mapeoprogmtos}
            </div>

            <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
              {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Cargado Exitosamente</h2>
              </div>
                
                ) : (<div>
                     <DialogTitle>Realizar Reporte de Mantenimiento Preventivo</DialogTitle>
                  <DialogContent>
                    <form>

                    <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                    <h4 className="text-title-accorion">Actividades Realizadas</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div className="div-accordion-container">
                <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Inspección Chásis</InputLabel>
                          <Select  required  name="chasis" id="chasis" value={this.state.chasis} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.chasis}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Verificar Accesorios</InputLabel>
                          <Select  required  name="vaccesorios" id="vaccesorios" value={this.state.vaccesorios} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.vaccesorios}</FormHelperText>
                    </FormControl> 
                    </div>
                    <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Verificar Controles</InputLabel>
                          <Select  required  name="vcontroles" id="vcontroles" value={this.state.vcontroles} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.vcontroles}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Prueba Cuantitativa</InputLabel>
                          <Select  required  name="pcuantitativa" id="pcuantitativa" value={this.state.pcuantitativa} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.pcuantitativa}</FormHelperText>
                    </FormControl> 
                    </div>
                    
                    <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Seguridad Eléctrica</InputLabel>
                          <Select  required  name="selectrica" id="selectrica" value={this.state.selectrica} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.selectrica}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Limpieza</InputLabel>
                          <Select  required  name="limpieza" id="limpieza" value={this.state.limpieza} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.limpieza}</FormHelperText>
                    </FormControl> 
                    </div>
                    
                    <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Lubricación de partes</InputLabel>
                          <Select  required  name="lubricacion" id="lubricacion" value={this.state.lubricacion} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.lubricacion}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Limpieza Electronica</InputLabel>
                          <Select  required  name="lelectronica" id="lelectronica" value={this.state.lelectronica} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.lelectronica}</FormHelperText>
                    </FormControl> 
                    </div>
                    
                    <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Limpieza de Chásis</InputLabel>
                          <Select  required  name="lchasis" id="lchasis" value={this.state.lchasis} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.lchasis}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Verificación General</InputLabel>
                          <Select  required  name="vgeneral" id="vgeneral" value={this.state.vgeneral} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.vgeneral}</FormHelperText>
                    </FormControl> 
                    </div>

                </div>

                  </AccordionDetails>
                  </Accordion>

                  <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                <h4 className="text-title-accorion">Mediciones Realizadas</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="div-accordion-container">
                  <div className="div-mediciones-container">
                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                            
                              type="Text"
                              name="prueba1"
                              id="prueba1"
                              label="Prueba"
                              value={this.state.prueba1}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.prueba1}
                              error={errors.prueba1?true:false}
                          
                              />
                      </div>
                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                            
                              type="Text"
                              name="instrumento1"
                              id="instrumento1"
                              label="Instrumento"
                              value={this.state.instrumento1}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.instrumento1}
                              error={errors.instrumento1?true:false}
                          
                              />
                      </div>

                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                            
                              type="Text"
                              name="valor1"
                              id="valor1"
                              label="Valor"
                              value={this.state.valor1}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.valor1}
                              error={errors.valor1?true:false}
                          
                              />
                      </div>

                      <div className="div-mediciones-item">
                      <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Aprobó</InputLabel>
                          <Select    name="aprobo1" id="aprobo1" value={this.state.aprobo1} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.aprobo1}</FormHelperText>
                    </FormControl> 


                      </div>
                    


                    </div>

                    <div className="div-mediciones-container">
                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                              
                              type="Text"
                              name="prueba2"
                              id="prueba2"
                              label="Prueba"
                              value={this.state.prueba2}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.prueba2}
                              error={errors.prueba2?true:false}
                          
                              />
                      </div>
                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                          
                              type="Text"
                              name="instrumento2"
                              id="instrumento2"
                              label="Instrumento"
                              value={this.state.instrumento2}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.instrumento2}
                              error={errors.instrumento2?true:false}
                          
                              />
                      </div>

                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                            
                              type="Text"
                              name="valor2"
                              id="valor2"
                              label="Valor"
                              value={this.state.valor2}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.valor2}
                              error={errors.valor2?true:false}
                          
                              />
                      </div>

                      <div className="div-mediciones-item">
                      <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Aprobó</InputLabel>
                          <Select    name="valor2" id="valor2" value={this.state.valor2} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.valor2}</FormHelperText>
                    </FormControl> 


                      </div>
                    


                    </div>


                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="text-title-accorion">Observaciones</h4>
                </AccordionSummary>
                <AccordionDetails>
                <TextField className="login-input-email"
                              
                              multiline
                              type="Text"
                              name="observaciones"
                              id="observaciones"
                              label="Observaciones"
                              rows={4}
                              value={this.state.observaciones}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.observaciones}
                              error={errors.observaciones?true:false}
                          
                              />
                </AccordionDetails>
              </Accordion>
                  
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                    <h4 className="text-title-accorion">Fecha y Hora</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="div-accordion-container">
                  <TextField className="login-input-email"
                              required
                              type="date"
                              name="fecha"
                              id="fecha"
                              label="Fecha de Realización"
                              value={this.state.fecha}
                              onChange={this.handleChange}
                              min="2019-01-01" max="2022-01-01"
                              fullWidth
                              helperText={errors.fecha}
                              error={errors.fecha?true:false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              />
                  <div className="div-input-doble-mtoprog">
                  <TextField className="login-input-email"
                              required
                              type="time"
                              name="horainicio"
                              id="horainicio"
                              label="Hora de Inicio"
                              value={this.state.horainicio}
                              onChange={this.handleChange}
                              min="2000-01-01" max="2022-01-01"
                              fullWidth
                              helperText={errors.horainicio}
                              error={errors.horainicio?true:false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              />
                    <TextField className="login-input-email"
                              required
                              type="time"
                              name="horafinal"
                              id="horafinal"
                              label="Hora Final"
                              value={this.state.horafinal}
                              onChange={this.handleChange}
                              min="2000-01-01" max="2022-01-01"
                              fullWidth
                              helperText={errors.horafinal}
                              error={errors.horafinal?true:false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              />
                
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                
                <h4 className="text-title-accorion">Personal que recibe la actividad</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="div-accordion-container">
                  <TextField className="login-input-email"
                              required
                              type="Text"
                              name="firmarecibe"
                              id="firmarecibe"
                              label="Nombre de Quien Recibe La Actividad"
                              value={this.state.firmarecibe}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.firmarecibe}
                              error={errors.firmarecibe?true:false}
                          
                              />

        <TextField className="login-input-email"
                              required
                              type="Text"
                              name="cargorecibe"
                              id="cargorecibe"
                              label="Cargo de Quien Recibe la Actividad"
                              value={this.state.cargorecibe}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.cargorecibe}
                              error={errors.cargorecibe?true:false}
                          
                              />
                  </div>
                </AccordionDetails>
              </Accordion>
                
                    </form>  
                  </DialogContent>
                </div>
               
                )}
            
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Realizar Mantenimiento" onClick={this.handlePostMto}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
           </DialogActions>

            </Dialog>
          
           
           
            <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-delete'>
                    <FaTrash color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Borrado Exitosamente</h2>
              </div>
                
                ):(<div>
                <DialogTitle>¿Estás seguro de borrar programación de Mto?</DialogTitle>
                </div>)}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseDelete}>
                    <FaCheckCircle color="#f9044e" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Borrar Programación" onClick={this.handleDelete}>
                    <FaTrash color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
           </DialogActions>
            </Dialog>
        
           </div>

         
        )
    }
}

ListaMtoProg.propTypes = {
  postMtoTabla:PropTypes.func.isRequired,
    getprogmtos: PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,    
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteProgMto:PropTypes.func.isRequired,
    editProgMto:PropTypes.func.isRequired
  };

const mapActionsToProps={getprogmtos,clearErrors,deleteProgMto,editProgMto,postMtoTabla};

const mapStateToProps = (state)=>({
    data:state.data,
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(ListaMtoProg);



