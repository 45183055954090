import React, { Component } from 'react'; 
import './HomeMarket.css'; 

import { Doughnut } from 'react-chartjs-2';
import {Link } from 'react-router-dom';
import Button from '../../Elements/Button';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {FaFileInvoiceDollar,FaFileSignature} from "react-icons/fa";
import NuevaCotizacion from './NuevaCotizacion';






  
export class HomeMarket extends Component {
    
    render() {
        

        return (
            <div className="market_container">
            <div className="titulomarket_section">
            <div className="titulo_market">
            <h1 className="text-clientes"> MarketPlace</h1>
            <hr className="clientes_separator"></hr>  
  
            </div>

            <div className="divmarket_boton">

            </div>
            </div>

            <div className="market_categorias_container">

            <div className="subcontainer-market">
            
            <NuevaCotizacion/>

<div className="dashing-div-card">
<Link to='/homemarket/miscotizaciones' className='linkclass-market'>
    <div className="dashing-card">
        <div className="dashing-cardcontainer-market">
            <div className="dashing-cardcontent">
                <div className="dashing-cardtext">
                    <p className="dashing-text-card">
                    Mis Cotizaciones<br/>sin Publicar
                    </p>
                   
             
                </div>
                <div className="dashing-cardicon">
                    <FaFileSignature color="#FFFFFF" className="dashing-icon"/>
                </div>
            </div>
            
        </div>            
    </div>
    </Link>
</div>
<div className="dashing-div-card">
    <Link to="/homemarket/cotizacionespublicadas"  className='linkclass-market'>
    
    <div className="dashing-card">
        <div className="dashing-cardcontainer-market">
            <div className="dashing-cardcontent">
                <div className="dashing-cardtext">
                    <p className="dashing-text-card">
                    Mis Cotizaciones <br/> Publicadas
                    </p>
              
                </div>
                <div className="dashing-cardicon">
                    <FaFileInvoiceDollar color="#FFFFFF" className="dashing-icon"/>
                </div>
            </div>
            
        </div>            
    </div>
    </Link>
</div>

            </div>
           
  <div className="subcontainer-market">
            <div className="dashing-div-card">
                <Link to="/homemarket/ofertascliente"  className='linkclass-market'>
                
                <div className="dashing-card">
                    <div className="dashing-cardcontainer-market">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Ofertas a mis <br/> Cotizaciones
                                </p>
                          
                            </div>
                            <div className="dashing-cardicon">
                                <FaFileInvoiceDollar color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
                    </div>            
                </div>
                </Link>
            </div>

            <div className="dashing-div-card">
                <Link to="/homemarket/cotizacionesaprobadas"  className='linkclass-market'>
                
                <div className="dashing-card">
                    <div className="dashing-cardcontainer-market">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Ofertas <br/> Aprobadas
                                </p>
                          
                            </div>
                            <div className="dashing-cardicon">
                                <FaFileInvoiceDollar color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
                    </div>            
                </div>
                </Link>
            </div>

            <div className="dashing-div-card">
                <Link to="/homemarket/cotizacionesrechazadas"  className='linkclass-market'>
                
                <div className="dashing-card">
                    <div className="dashing-cardcontainer-market">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Ofertas <br/> Rechazadas
                                </p>
                          
                            </div>
                            <div className="dashing-cardicon">
                                <FaFileInvoiceDollar color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
                    </div>            
                </div>
                </Link>
            </div>


            
            </div>




            </div>

            </div>
        )
    }
}

export default HomeMarket
