import React, { Component, Fragment  } from 'react';
import './EditArea.css'; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus, FaPencilAlt, FaCheck, FaCheckCircle } from "react-icons/fa";

import {editArea,getArea} from '../../../redux/actions/infraactions';
import { DataThresholdingSharp } from '@mui/icons-material';

export class EditArea extends Component {

    constructor(){
        super();
        this.state={
            nombre:'',
            servicio:'',
            piso:'',
            estado:'',
            ubicacion:'',
            errors:{},
            aprobado:{},
            open:false
        };
        
    };
    static getDerivedStateFromProps(props, state) {
      const newState = {};
  
      if (props.UI.errors) {
          newState.errors = props.UI.errors;
      }
  
      if (props.UI.aprobado) {
         newState.aprobado = props.UI.aprobado;
      }
  
      return Object.keys(newState).length > 0 ? newState : null;
  }
    
  mapUserDetailsToState = (area) => {
    this.setState({
        nombre:area.nombre?area.nombre:'',
            servicio:area.servicio?area.servicio:'',
            piso:area.piso?area.piso:'',
            estado:area.estado?area.estado:'',
            ubicacion:area.ubicacion?area.ubicacion:'',
       
    });
  };

  handleOpen = () => {
    this.setState({ open: true,errors:{},
      aprobado:{}});
    this.mapUserDetailsToState(this.props.area);
  };


  handleClose=()=>{


    if(this.state.aprobado.mensaje){
        //getEquipos this.handleEditProgMto();
        this.props.getArea(this.props.areaId,this.props.clienteId);
        this.setState({
            nombre:'',
            servicio:'',
            piso:'',
            estado:'',
            ubicacion:'',
            open:false,errors:{},aprobado:{}});
    }else{
        this.setState({
            nombre:'',
            servicio:'',
            piso:'',
            estado:'',
            ubicacion:'',
            open:false,errors:{},aprobado:{}});
    }
   
   
                
    };
 
  componentDidMount() {
    const { area } = this.props;
    this.mapUserDetailsToState(area);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const newUserData={
        nombre:this.state.nombre,
    servicio:this.state.servicio,
    piso:this.state.piso,
    estado:this.state.estado,
    ubicacion:this.state.ubicacion,           
  };
    
    this.props.editArea(newUserData,this.props.areaId);
     
   
  };


 


    render() {
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;
        return (
            <div>
                <Fragment>
            <MyButton tip="Editar Información" onClick={this.handleOpen}>
            <FaPencilAlt color="#03178C" className="icon-crearcliente"/>
             </MyButton>
             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Area editada Exitosamente</h2>
              </div>
                
                ) : (<div>
              <DialogTitle>Editar Detalles del Area</DialogTitle>
               
              <DialogContent>

<form>

<div className="flex-accordion">
<h4 className="text-title-accorion">Ingresa los datos del Area</h4>


<div className="input_divido">
<div className="input_divido_children">
<TextField className="login-input-email"
  required
  type="text"
  name="nombre"
  id="nombre"
  label="Nombre del Area"
  value={this.state.nombre}
  onChange={this.handleChange}
  fullWidth
  helperText={errors.nombre}
  error={errors.nombre?true:false}
  />



</div>
<div className="input_divido_children">
<TextField className="login-input-email"
  required
  type="text"
  name="servicio"
  id="servicio"
  label="Servicio"
  value={this.state.servicio}
  onChange={this.handleChange}
  fullWidth
  helperText={errors.servicio}
  error={errors.servicio?true:false}
  />
</div>


    

</div>
  
  <div className="input_divido">
  <div className="input_divido_children">
    <TextField className="login-input-email"
  required
  type="text"
  name="ubicacion"
  id="ubicacion"
  label="Ubicación"
  value={this.state.ubicacion}
  onChange={this.handleChange}
  fullWidth
  helperText={errors.ubicacion}
  error={errors.ubicacion?true:false}
  />
  <br/>
    </div>

    <div className="input_divido_children">
   <TextField className="login-input-email"
 required
 type="number"
 name="piso"
 id="piso"
 label="Piso"
 value={this.state.piso}
 onChange={this.handleChange}
 fullWidth
 helperText={errors.piso}
 error={errors.piso?true:false}
 />
 <br/>
   </div>


</div>



<div className="input_divido">
<FormControl className="formcontrol-nuevocliente" error={errors.estado?true:false}>
        <InputLabel>Estado</InputLabel>
        <Select
        required
     name="estado"
     id="estado"
     value={this.state.estado}
        onChange={this.handleChange}
        fullWidth
        >
        <MenuItem value="">
            <em></em>
        </MenuItem>
        <MenuItem value="construccion">Construcción</MenuItem>
        <MenuItem value="funcionando">Funcionando</MenuItem>
        <MenuItem value="remodelacion">Remodelación</MenuItem>
        <MenuItem value="inactiva">Inactiva</MenuItem>
        </Select>
        <FormHelperText>{errors.estado}</FormHelperText>
    </FormControl>
  
  

 
</div>




  

    

    
    




</div>



       




  </form>
  

</DialogContent>
                </div>)
                }

            
            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Actualizar Información" onClick={this.handleSubmit}>
                  <FaPlus  color="#03178C" className="icon-formulario"/>
                   </MyButton>
                )
              )}
         
            
            
          </DialogActions>

            </Dialog>
             </Fragment>

            </div>
        )
    }
}

EditArea.propTypes = {
    editArea: PropTypes.func.isRequired,
    getArea:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

  const mapActionsToProps={editArea,getArea};

  const mapStateToProps = (state) => ({
    area: state.datainfra.area.credentials,
    UI:state.UI
  });
  
  export default connect(mapStateToProps,mapActionsToProps)(EditArea);
