import React,{Component} from 'react';
import './Login.css';
import imgsrc from '../Navbar/logohorizontal.svg';
import TextField from '@mui/material/TextField';
import Button from '../Elements/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types'

//REDUX
import { connect} from 'react-redux';
import {loginUser} from '../../redux/actions/useractions';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

class Login extends Component{
    constructor(){
        super();
        this.state={
            email:'',
            password:'',
            errors:{},
            showPassword: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    static getDerivedStateFromProps(props, state) {
        return props.UI.errors ? {errors: props.UI.errors} : null;
    }
    /*componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({errors:nextProps.UI.errors});
        }
    }*/
    handleSubmit=(event)=>{
        
        event.preventDefault(); 
        const userData={
            email:this.state.email,
            password:this.state.password
        };
        

        this.props.loginUser(userData,this.props.history)

       
        
    };

   handleChange=(event)=>{
    this.setState({
        [event.target.name]:event.target.value
    });
       
   };

    
    render(){
        const {UI:{loading}}=this.props; 
        const {errors}=this.state;
      
        return (
            <div id="login">
            <div className="login-container">
                    <div className="login-card">
                        <div className="login-divtitulo">
                        <Link to="/" className='login-logo'>
                        <img src={imgsrc} alt="logo" />
                        </Link>
                        
                         <h1 className="login-titulo">Inicia Sesión</h1>
                        </div>
    
                        <form className="login-form" noValidate onSubmit={this.handleSubmit} >
                          
                        <TextField className="login-input-email"
                        required name="email"id="email" label="Email" 
                        value={this.state.email} 
                        onChange={this.handleChange} 
                        fullWidth 
                        helperText={errors.email?<>{errors.email}</>:<>Ingresa un Email Registrado</>}
                        error={errors.email ? true:false}
                        variant="standard"
                        />
                       
                        <TextField
                        variant="standard"
                                    className="login-input-contraseña"
                                    name="password"
                                    id="password"
                                    label="Contraseña"
                                    type={this.state.showPassword ? 'text' : 'password'} // Mostrar u ocultar la contraseña según el estado
                                    autoComplete="current-password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.password?<>{errors.password}</>:<>Ingresa un tu contraseña</>}
                                    error={errors.password ? true : false}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={() =>
                                                        this.setState({
                                                            showPassword: !this.state.showPassword,
                                                        })
                                                    }
                                                    size="large">
                                                    {this.state.showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                    ),
                                }}
                            />
                         <p className="login-perror">{errors.general}</p>
                         

    
                         {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                         <Button type="submit" buttonSize="btn--wide" buttonColor='blue'>
                           Ingresar</Button>}
                           <br></br>
                           <small className="login-text">No tienes una cuenta? <Link to="/planes"> Conoce Nuestros Planes</Link> </small>
                         
                    </form>
    
    
                    </div>
    
                   
    
    
                </div>
            </div>
        );

    }
    
}

Login.propTypes={
    loginUser:PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,
    UI:PropTypes.object.isRequired
}
const mapStateToProps=(state)=>({
    user:state.user,
    UI:state.UI
});
const mapActionsToProps={
    loginUser
}
export default connect(mapStateToProps,mapActionsToProps) (Login);

