import {SET_ERRORS,CLEAR_ERRORS, SET_AREAS, LOADING_DATA_INF, SET_OK, LOADING_UI, SET_AREA, SET_PROGMTOS_INFRA, LOADING_DATA_MTO_INFRA, SET_MTOS_INFRA, SET_MTO_INFRA, SET_CORRECTIVOS_INFRA, SET_CORRECTIVO_INFRA, SET_SOLICITUDESCORR_INFRA, LOADING_DATA_MOB, SET_MOBILIARIOS} from '../types';
import axios from 'axios'; 

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };

export const getMobiliarios =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA_MOB});

    axios.get(`/getmobiliario/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_MOBILIARIOS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const postMobiliario=(newMobiliario)=>dispatch=>{
    dispatch({type:LOADING_UI});
    axios
    .post('/crearmobiliario',newMobiliario)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });
        dispatch(clearErrors());
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });

};


 
export const subirMobExcel=(formData,clienteId,handleUser)=>(dispatch)=>{
    dispatch({ type: LOADING_UI });
    axios.post(`/subirExcelMobiliario/${handleUser}/${clienteId}`, formData)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        });
        console.log("Se ejecutó y ahora está en el getCliente");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
  }

  export const subirImagenMobiliario=(formData,equipoId,clienteId)=> (dispatch)=>{
    dispatch({type:LOADING_DATA_MOB});
    
    axios.post(`/subirimagenmobiliario/${equipoId}`,formData)
    .then(()=>{
      dispatch(getMobiliarios(clienteId));
    })
    .catch(err=>console.log(err)); 
  };

