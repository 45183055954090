import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {postCronogramaMtoAuto} from '../../../redux/actions/dataactions';
import {progMtoAutoInfra,getProgMtosInfraClienteFiltrado} from '../../../redux/actions/infraactions';
import {clearErrors} from '../../../redux/actions/dataactions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';



import { FaArrowLeft,FaPlus,FaFirstAid, FaChartBar, FaCheckCircle, FaCheck, FaCalendarWeek } from "react-icons/fa";
import './PlanMtoIndicadoresDialog.css';

export class SolicitudesIndicadores extends Component {
    constructor(){
        super();
        this.state={
            errors:{},
            aprobado:{},
            open:false
        };
        
    };

    handleOpen=()=>{
        this.setState({open:true});
        };


 handleClose=()=>{
    this.setState({
    open:false});
};

calculatePercentages = (solicitudescorr) => {
    if (!solicitudescorr || solicitudescorr.length === 0) {
        return { atendida: 0, sinAtender: 0 };
    }

    const total = solicitudescorr.length;
    const atendidas = solicitudescorr.filter(p => p.estado === 'Atendida').length;
    const sinAtender = total - atendidas;
    console.log(atendidas);
    console.log(sinAtender);
    return {
        atendida: (atendidas / total) * 100,
        sinAtender: (sinAtender / total) * 100
    };
}

calculateNumProgs=(solicitudescorr)=>{
    if (!solicitudescorr || solicitudescorr.length === 0) {
        return { atendida: 0, sinAtender: 0 };
    }

    const total = solicitudescorr.length;
    const atendidas = solicitudescorr.filter(p => p.estado === 'Atendida').length;
    const sinAtender = total - atendidas;

    return {
        atendida: atendidas,
        sinAtender: sinAtender
    };
}


render() {
        const { UI:{loading}}=this.props; 
        const {solicitudescorr}=this.props.datainfra;
        const {errors}=this.state;
        const percentages = this.calculatePercentages(solicitudescorr);
        const totalsolicitudes=solicitudescorr ? Object.keys(solicitudescorr).length : 0
        const numprogs=this.calculateNumProgs(solicitudescorr);

        const databar = {
            labels: ['Atendida', 'Sin Atender'],
            datasets: [
              {
                
                backgroundColor: [
                    "#0962d5",
                    "#338bf7",
                  ],
                
                borderWidth: 1,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff',
                data: [numprogs.atendida,
                    numprogs.sinAtender
                    ],
              },
            ],
          };

          const optionsbar = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false, // Oculta la leyenda, que incluye las etiquetas del dataset
              },
            title: {
                display: false, // Oculta el título del gráfico
              },
            scales: {
                xAxes: [{
                    gridLines: {
                        display:false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display:false
                    }  , 
                    ticks: {
                        display: false, // Oculta los valores del eje Y
                        beginAtZero: true, // Asegúrate de comenzar en cero
                      },
                }]
            },
            plugins: {
                
                tooltip: {
                enabled: false,
                mode: 'index',
                intersect: false,
              },
            },
          };
          const   datapie = {
            datasets: [
              {
                data: [percentages.atendida.toFixed(1),
                    percentages.sinAtender.toFixed(1)],
                backgroundColor: [
                    "#0962d5",
                  "#338bf7",
               
                  
        
                ],
                borderWidth: 5,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff'
              }
            ],
            labels: ['Atendida', 'Sin Atender']
          };
          const optionspie = {
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            cutoutPercentage: 5,
            layout: { padding: 5},
            tooltips: {
              enabled: true,
              mode: 'index',
              intersect: false,
              borderWidth: 2,
              borderColor: "#03178C",
              backgroundColor: "#FFF",
              titleFontColor: "#03178C",
              bodyFontColor: "#03178C",
              footerFontColor: "#03178C"
            }
          };
        return (
          

        
            <Fragment>

                  <div className='div-card-link' onClick={this.handleOpen}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                            Indicadores
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon">
                                            <FaChartBar color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>
  
  
                </div>
           {/* <MyButton tip="Crear Equipo" onClick={this.handleOpen}>
            <FaFirstAid color="#03178C" className="icon-crearcliente"/>
        </MyButton>*/}

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                 
                  <h2 className='text-aprobado'>¡Programación de Mantenimiento Exitosa!</h2>
              </div>
                
                ) : (<div>
<DialogTitle className="text-title-accorion">Indicadores</DialogTitle>
               
              <DialogContent>
               
                <div className='div-container-info-ind-mtoifra'>
                <div className="dashing-cardicon">
                                            <FaCalendarWeek color="#FFFFFF" className="dashing-icon"/>
                </div>
                <div className='div-texto-info-ind-mtoinfra'>
                <p className='text-info-ind-mtoinfra'>Numero Total de Solicitudes </p>
                <p className='text-info-ind-mtoinfra-num'>{totalsolicitudes}</p>
                </div>
                
                </div>
                <div>
                <p className='text-info-ind-mtoinfra-gray'>Solicitudes Atendidas Vs Sin Atender </p>
                <hr className="separator_indmtoinfra"></hr>
                </div>
               
                        <div className="dashing-card-data-container">
                        <div className="dashing-chart-container-mtoinfra">
                                <Bar
                                options={optionsbar}
                                data={databar}
                              
                                />
                        </div>
                        <div className="dashing-card-data">

                        <p className="dashing-text-data"> Sin Atender</p>
                        <h2 className="dashing-value-data-blue">{numprogs.sinAtender}</h2>
                        </div>


                        <div className="dashing-card-data">

                        <p className="dashing-text-data"> Atendidas</p>
                        <h2 className="dashing-value-data-blue">{numprogs.atendida}</h2>
                        </div>


                        </div>

                        <div>
                <p className='text-info-ind-mtoinfra-gray'>Porcentaje Sol. Atendidas Vs Sol. Sin Atender</p>
                <hr className="separator_indmtoinfra"></hr>
                </div>
                        <div className="dashing-chart-container">
                                <Pie
                                options={optionspie}
                                data={datapie}
                                
                                />
                               
                        </div>

                        <div className="dashing-card-data-container">

<div className="dashing-card-data">

<p className="dashing-text-data"> Atendidas</p>
<h2 className="dashing-value-data-blue">{percentages.atendida.toFixed(1)}%</h2>
</div>


<div className="dashing-card-data">

<p className="dashing-text-data"> Sin Atender</p>
<h2 className="dashing-value-data-blue">{percentages.sinAtender.toFixed(1)}%</h2>
</div>


</div>
             
             
              </DialogContent>
                </div>)}
            
          
          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

            
            
            

            
          </DialogActions>
            </Dialog>
            </Fragment>
            
        )
    }
}

SolicitudesIndicadores.propTypes = {
    
    clienteId: PropTypes.string.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

const mapActionsToProps={clearErrors};

const mapStateToProps = (state)=>({
    user:state.user,
    UI:state.UI,
    datainfra:state.datainfra
})



export default connect(mapStateToProps,mapActionsToProps)(SolicitudesIndicadores);
