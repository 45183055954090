

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {postEquipoIA,getEquipoIA,postNuevoEquipoIA,clearEquiposIA} from '../../../redux/actions/dataactions';
import {clearErrors} from '../../../redux/actions/dataactions';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, MenuItem, Select, TextField } from '@material-ui/core';
import './NuevoEquipoIA.css';
import Button from '../../Elements/Button';
import { FaArrowCircleLeft, FaArrowLeft, FaCheck, FaCheckCircle, FaCross, FaMagic, FaPlus, FaTimes } from 'react-icons/fa';
import MyButton from '../../../utils/MyButton';

import { withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import { Link} from 'react-router-dom';

import InputLabel from '@material-ui/core/InputLabel';
export class NuevoEquipoIA extends Component {
    constructor(){
        super();
        this.state={
            nombreia:'',
            marcaia:'',
            modeloia:'',
            idinventario:'',
            nombre:'',
            marca:'',
            registroinvima:'',
            modelo:'',
            serie:'',
            ubicacion:'',
            servicio:'',
            tipo:'',
            clase:'',
            riesgo:'',
            movilfijo:'',
            clasbiomedica:'',
            reqcalibracion:'',
            adquisicion:'',
            costo:'',
            fabricacion:'',
            operacion:'',
            vidautil:'',
            proveedor:'',
            proveedortel:'',
            proveedoremail:'',
            idprestador:'',
            tipotec:'',
            fuente:'',
            tensionmin:'',
            tensionmax:'',
            corrientemax:'',
            corrientemin:'',
            presion:'',
            temperatura:'',
            potencia:'',
            frecuencia:'',
            alto:'',
            ancho:'',
            largo:'',
            peso:'',
            urlmanual:'',
            urlfactura:'',
            errors:{},
            aprobado:{},
            equiposia:{},
            open:false,
            imagenurl:''
        };
        
    };

    handleChange=(event)=>{
        this.setState({[event.target.name]:event.target.value});
        };

        static getDerivedStateFromProps(props, state) {
            const newState = {};
        
            if (props.UI.errors) {
                newState.errors = props.UI.errors;
            }
        
            if (props.UI.aprobado) {
               newState.aprobado = props.UI.aprobado;
            }
        
            return Object.keys(newState).length > 0 ? newState : null;
        }


    handleSubmit=(event)=>{
        event.preventDefault();
        const newUserData={
            nombre:this.state.nombreia,
            marca:this.state.marcaia,
            modelo:this.state.modeloia
        }

        console.log(newUserData);

        this.props.postEquipoIA(newUserData);

    }
     
    handleVolverClick = () => {
        this.props.clearEquiposIA(); 
        this.props.history.goBack();
      };

   
     

      mapUserDetailsToState = (equipo) => {
        this.setState({
            nombre:equipo.nombre?equipo.nombre:'',
            imagenurl:equipo.imagenurl?equipo.imagenurl:'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media',
            nombre:equipo.nombre?equipo.nombre:'',
            marca:equipo.marca?equipo.marca:'',
                registroinvima:equipo.registroinvima?equipo.registroinvima:'',
                modelo:equipo.modelo?equipo.modelo:'',
                serie:equipo.serie?equipo.serie:'',
                ubicacion:equipo.ubicacion?equipo.ubicacion:'',
                servicio:equipo.servicio?equipo.servicio:'',
                tipo:equipo.tipo?equipo.tipo:'',
                clase:equipo.clase?equipo.clase:'',
                riesgo:equipo.riesgo?equipo.riesgo:'',
                movilfijo:equipo.movilfijo?equipo.movilfijo:'',
                clasbiomedica:equipo.clasbiomedica?equipo.clasbiomedica:'',
                reqcalibracion:equipo.reqcalibracion?equipo.reqcalibracion:'',
                adquisicion:equipo.adquisicion?equipo.adquisicion:'',
                costo:equipo.costo?equipo.costo:'',
                fabricacion:equipo.fabricacion?equipo.fabricacion:'',
                operacion:equipo.operacion?equipo.operacion:'',
                vidautil:equipo.vidautil?equipo.vidautil:'',
                proveedor:equipo.proveedor?equipo.proveedor:'',
                proveedortel:equipo.proveedortel?equipo.proveedortel:'',
                proveedoremail:equipo.proveedoremail?equipo.proveedoremail:'',
                tipotec:equipo.tipotec?equipo.tipotec:'',
                fuente:equipo.fuente?equipo.fuente:'',
                tensionmin:equipo.tensionmin?equipo.tensionmin:'',
                tensionmax:equipo.tensionmax?equipo.tensionmax:'',
                corrientemin:equipo.corrientemin?equipo.corrientemin:'',
                corrientemax:equipo.corrientemax?equipo.corrientemax:'',
                temperatura:equipo.temperatura?equipo.temperatura:'',
                frecuencia:equipo.frecuencia?equipo.frecuencia:'',
                presion:equipo.presion?equipo.presion:'',
                potencia:equipo.potencia?equipo.potencia:'',
                alto:equipo.alto?equipo.alto:'',
                ancho:equipo.ancho?equipo.ancho:'',
                largo:equipo.largo?equipo.largo:'',
                peso:equipo.peso?equipo.peso:'',
                idinventario:equipo.idinventario?equipo.idinventario:'',
                urlfactura:equipo.urlfactura?equipo.urlfactura:'',
                urlmanual:equipo.urlmanual?equipo.urlmanual:'',
        
        });
        };

        handleOpen = (equipo) => {
            return () => {
             
              console.log('Nombre'+equipo.nombre);
              this.mapUserDetailsToState(equipo);
              this.setState({open:true});
              // Aquí puedes realizar cualquier acción que desees con el equipoid
            };
          };
    
          

      handleClose=()=>{
      
       console.log('Cerre Dialog:');
        this.setState({open:false,equipoid:''});
      
     };

     handleAgain=()=>{
        this.props.clearEquiposIA(); 
        this.setState({open:false,equipoid:'', nombreia:'',
        marcaia:'',
        modeloia:'',});
     }
     handleCrearEquipo=(event)=>{
 
            event.preventDefault();
                    const newEquipoData={
                        nombre:this.state.nombre,
                        marca:this.state.marca,
                        registroinvima:this.state.registroinvima,
                        modelo:this.state.modelo,
                        serie:this.state.serie,
                        ubicacion:this.state.ubicacion,
                        servicio:this.state.servicio,
                        tipo:this.state.tipo,
                        clase:this.state.clase,
                        riesgo:this.state.riesgo,
                        movilfijo:this.state.movilfijo,
                        clasbiomedica:this.state.clasbiomedica,
                        reqcalibracion:this.state.reqcalibracion,
                        adquisicion:this.state.adquisicion,
                        costo:this.state.costo,
                        fabricacion:this.state.fabricacion,
                        operacion:this.state.operacion,
                        vidautil:this.state.vidautil,
                        proveedor:this.state.proveedor,
                        proveedortel:this.state.proveedortel,
                        proveedoremail:this.state.proveedoremail,
                        tipotec:this.state.tipotec,
                        fuente:this.state.fuente,
                        tensionmin:this.state.tensionmin,
                        tensionmax:this.state.tensionmax,
                        corrientemin:this.state.corrientemin,
                        corrientemax:this.state.corrientemax,
                        frecuencia:this.state.frecuencia,
                        temperatura:this.state.temperatura,
                        presion:this.state.presion,
                        potencia:this.state.potencia,
                        alto:this.state.alto,
                        ancho:this.state.ancho,
                        largo:this.state.largo,
                        peso:this.state.peso,
                        idinventario:this.state.idinventario,
                        urlfactura:this.state.urlfactura,
                        urlmanual:this.state.urlmanual,
                        imagenurl:this.state.imagenurl,
                        idprestador:this.props.match.params.clienteId
                     
                  };
            
            console.log(newEquipoData);
             this.props.postNuevoEquipoIA(newEquipoData);
    
           
          };
     

  render() {
    const { UI:{loading,loadingdata}}=this.props; 
    const {equiposia}=this.props.data;
   
    const {errors}=this.state;
  
 
    return (
      <div className='container-nuevoequipoia'>
         <div className="titulo_section">
        <div className="titulo_clientes">
          <h1 className="text-clientes">Crea un Equipo con Inteligencia Artificial</h1>
          <hr className="clientes_separator"></hr>
        </div>

        <div className="div_boton">
          <MyButton tip="Volver" onClick={this.handleVolverClick}>
            <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
          </MyButton>
        </div>
      </div>
        <div className='container-inputs-ia'>
        <div className='div-inputs-ia'>
           
           
            <TextField className="login-input-ia"
                      required
                      type="text"
                      name="nombreia"
                      id="nombreia"
                      label="Nombre del Equipo"
                      value={this.state.nombreia}
                      onChange={this.handleChange}
                  
                      helperText={errors.nombreia}
                      error={errors.nombreia?true:false}
                      />



           
            <TextField className="login-input-ia"
                                required
                                type="text"
                                name="marcaia"
                                id="marcaia"
                                label="Marca del Equipo"
                                value={this.state.marcaia}
                                onChange={this.handleChange}
                                
                                helperText={errors.marcaia}
                                error={errors.marcaia?true:false}
                                />

                <TextField className="login-input-ia"
                                    required
                                    type="text"
                                    name="modeloia"
                                    id="modeloia"
                                    label="Modelo del Equipo"
                                    value={this.state.modeloia}
                                    onChange={this.handleChange}
                                   
                                    helperText={errors.modeloia}
                                    error={errors.modeloia?true:false}
                                    />
            </div>
            <div className='div-button-ia'>
            {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
            <Button buttonSize="btn--wide" buttonColor='blue' onClick={this.handleSubmit}>
                       Crear Equipo
                    </Button>)}
            </div>

        </div>
            
            <div className='card-equipos-ia-container'>

            {loading ? (
                <div className='container-mensaje-bienvenida'>
                <FaMagic color="#9a9a9a" className="dashing-icon"/>
                <p className='text-bienvenida-ia'>Haciendo Magia Para Ti</p></div>
            ) : (
                equiposia && equiposia.length > 0  ? (
                    equiposia.map((equipo, index) => (
                    <div key={index} className='card-equipo-ia'>
                 
                        <img src={equipo.credentials.imagenurl} alt="profile" className="profile-image"/>
                
                        <div>
                        <p className='titulo-equipo-card-ia'>{equipo.credentials.nombre}</p>
                        </div>
                        <div>
                        <p className='text-subtitulo-ia'>Marca: {equipo.credentials.marca}</p>
                        <p className='text-subtitulo-ia'>Modelo: {equipo.credentials.modelo}</p>
                        <p className='indicador-simlitud-ia'>{parseFloat(equipo.similitud * 100).toFixed(1)}%</p>
                        <p className='text-subtitulo-ia'>Similitud </p>
                        </div>
                        <div>
                        <MyButton tip="Usar este Equipo"  onClick={this.handleOpen(equipo.credentials)}>
                        <FaPlus  color="#03178C" className="icon-formulario"/>
                        </MyButton>
                        </div>
                       
                        
                    </div>
                    ))
                ) : (
                    errors.error ? (
                        <div className='container-mensaje-bienvenida'>
                        <FaMagic color="#9a9a9a" className="dashing-icon"/>
                        <p className='text-bienvenida-ia'>No encontramos un equipo que coincida, intenta con otro</p></div>
                    ) : (
                        <div className='container-mensaje-bienvenida'>
                        <FaMagic color="#9a9a9a" className="dashing-icon"/>
                        <p className='text-bienvenida-ia'>Crea tu primer equipo gracias al asistente de IA</p></div>
                    )
                )
            )}
             
         
                    
            
            </div>

        
            

            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                 fullWidth
                maxWidth="sm"
                >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Equipo Cargado Exitosamente</h2>
                  <h2 className='text-aprobado'>¿Deseas Continuar Cargando Equipos?</h2>
              </div>
                
                ) : (<div>
                    
                 <DialogTitle>Editar Información para el Nuevo Equipo</DialogTitle>
                 
                <DialogContent>

                <img src={this.state.imagenurl} alt="profile" className="profile-image"/>

                <div className="flex-accordion">
                <h4 className="text-title-accorion">Identificación del Equipo</h4>
               
                <TextField className="login-input-email"
                      required
                      type="text"
                      name="nombre"
                      id="nombre"
                      label="Nombre del Equipo"
                      value={this.state.nombre}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombre}
                      error={errors.nombre?true:false}
                      />

                    < TextField className="login-input-email"
                      required
                      type="text"
                      name="idinventario"
                      id="idinventario"
                      label="Id Inventario"
                      value={this.state.idinventario}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.idinventario}
                      error={errors.idinventario?true:false}
                      />
                      <br/>

                      <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="serie"
                      id="serie"
                      label="Numero de Serie"
                      value={this.state.serie}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.serie}
                      error={errors.serie?true:false}
                      />
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="modelo"
                      id="modelo"
                      label="Modelo del Equipo"
                      value={this.state.modelo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.modelo}
                      error={errors.modelo?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    
                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="marca"
                      id="marca"
                      label="Marca del Equipo"
                      value={this.state.marca}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.marca}
                      error={errors.marca?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="registroinvima"
                      id="registroinvima"
                      label="Registro Invima del Equipo"
                      value={this.state.registroinvima}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.registroinvima}
                      error={errors.registroinvima?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>
                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="ubicacion"
                      id="ubicacion"
                      label="Ubicación del Equipo"
                      value={this.state.ubicacion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ubicacion}
                      error={errors.ubicacion?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="servicio"
                      id="servicio"
                      label="Servicio"
                      value={this.state.servicio}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.servicio}
                      error={errors.servicio?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                      <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente"  error={errors.tipo?true:false}>
                            <InputLabel>Tipo Equipo</InputLabel>
                            <Select
                            required
                         name="tipo"
                         id="tipo"
                         value={this.state.tipo}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="B">B</MenuItem>
                            <MenuItem value="BF">BF</MenuItem>
                            <MenuItem value="CF">CF</MenuItem>
                            <MenuItem value="N/A">N/A</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipo}</FormHelperText>
                        </FormControl>
                        </div>
                        <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.clase?true:false}>
                            <InputLabel>Clase de Aislamiento</InputLabel>
                            <Select
                            required
                         name="clase"
                         id="clase"
                         value={this.state.clase}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="I">I</MenuItem>
                            <MenuItem value="II">II</MenuItem>
                            
                            </Select>
                            <FormHelperText>{errors.clase}</FormHelperText>
                        </FormControl>
                        </div>
                        </div>

                        

                        <div className="input_divido">
                      <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.clasbiomedica?true:false}>
                            <InputLabel>Clasificación Biomédica</InputLabel>
                            <Select
                            required
                         name="clasbiomedica"
                         id="clasbiomedica"
                         value={this.state.clasbiomedica}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Diagnóstico">Diagnóstico</MenuItem>
                            <MenuItem value="Rehabilitación">Rehabilitación</MenuItem>
                            <MenuItem value="Apoyo Vital">Apoyo Vital</MenuItem>
                            <MenuItem value="Tratamiento">Tratamiento</MenuItem>
                            <MenuItem value="Prevención">Prevención</MenuItem>
                            <MenuItem value="Laboratorio">Laboratorio</MenuItem>
                            <MenuItem value="Industrial">Industrial</MenuItem>

                            </Select>
                            <FormHelperText>{errors.clasbiomedica}</FormHelperText>
                        </FormControl>
                        </div>
                        <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.reqcalibracion?true:false}>
                            <InputLabel>Requiere Calibración</InputLabel>
                            <Select
                            required
                         name="reqcalibracion"
                         id="reqcalibracion"
                         value={this.state.reqcalibracion}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="true">Si</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                            
                            </Select>
                            <FormHelperText>{errors.reqcalibracion}</FormHelperText>
                        </FormControl>
                        </div>
                        </div>
                        
                        <div className="input_divido">
                      <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.riesgo?true:false}>
                            <InputLabel>Clasificación Riesgo</InputLabel>
                            <Select
                            required
                         name="riesgo"
                         id="riesgo"
                         value={this.state.riesgo}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="I">I</MenuItem>
                            <MenuItem value="IIA">IIA</MenuItem>
                            <MenuItem value="IIB">IIB</MenuItem>
                            <MenuItem value="III">III</MenuItem>
                            </Select>
                            <FormHelperText>{errors.riesgo}</FormHelperText>
                        </FormControl>
                        </div>
                        <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.movilfijo?true:false}>
                            <InputLabel>Equipo</InputLabel>
                            <Select
                            required
                         name="movilfijo"
                         id="movilfijo"
                         value={this.state.movilfijo}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Movil">Móvil</MenuItem>
                            <MenuItem value="Fijo">Fijo</MenuItem>
                            
                            </Select>
                            <FormHelperText>{errors.movilfijo}</FormHelperText>
                        </FormControl>
                        </div>
                        </div>

                      </div>

                      <div className="flex-accordion">
                <h4 className="text-title-accorion">Historial de Adquisición</h4>
                <div className="input_divido">
                            <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.adquisicion?true:false}>
                            <InputLabel>Forma Adquisición</InputLabel>
                            <Select
                            required
                         name="adquisicion"
                         id="adquisicion"
                         value={this.state.adquisicion}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="compra">Compra</MenuItem>
                            <MenuItem value="comodato">Comodato</MenuItem>
                            <MenuItem value="donacion">Donación</MenuItem>
                            <MenuItem value="otro">Otro</MenuItem>
                            </Select>
                            <FormHelperText>{errors.adquisicion}</FormHelperText>
                        </FormControl>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="costo"
                      id="costo"
                      label="Valor del Equipo"
                      value={this.state.costo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.costo}
                      error={errors.costo?true:false}
                      />
                      <br/>
                        </div>
                        </div>
                       

                        <div className="input_divido">
                      <div className="input_divido_children">
                      <TextField className="login-input-email"
                      required
                      type="date"
                      name="fabricacion"
                      id="fabricacion"
                      label="Fecha Fabricación"
                      value={this.state.fabricacion}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.fabricacion}
                      error={errors.fabricacion?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
                        <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="date"
                      name="operacion"
                      id="operacion"
                      label="Fecha de Adquisición"
                      value={this.state.operacion}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.operacion}
                      error={errors.operacion?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
                      <br/>
                        </div>
                        </div>


                        <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="vidautil"
                      id="vidautil"
                      label="Años de Vida Útil"
                      value={this.state.vidautil}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.vidautil}
                      error={errors.vidautil?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="proveedor"
                      id="proveedor"
                      label="Proveedor del Equipo"
                      value={this.state.proveedor}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedor}
                      error={errors.proveedor?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="proveedortel"
                      id="proveedortel"
                      label="Telefono Proveedor"
                      value={this.state.proveedortel}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedortel}
                      error={errors.proveedortel?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="email"
                      name="proveedoremail"
                      id="proveedoremail"
                      label="Email proveedor del equipo"
                      value={this.state.proveedoremail}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedoremail}
                      error={errors.proveedoremail?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>
                    </div>

                    <div className="flex-accordion">
        <h4 className="text-title-accorion">Datos Técnicos del Equipo</h4>
        <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Tipo de Tecnología</InputLabel>
                            <Select
                            required
                         name="tipotec"
                         id="tipotec"
                         value={this.state.tipotec}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Electrónica">Electrónica</MenuItem>
                            <MenuItem value="Eléctrica">Eléctrica</MenuItem>
                            <MenuItem value="Mecánica">Mecánica</MenuItem>
                            <MenuItem value="Hidraulica">Hidraulica</MenuItem>                            
                            <MenuItem value="Neumatica">Neumatica</MenuItem>
                            <MenuItem value="Otra">Otra</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipotec}</FormHelperText>
                        </FormControl>

                        <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Fuente de Alimentación</InputLabel>
                            <Select
                            required
                         name="fuente"
                         id="fuente"
                         value={this.state.fuente}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="AC">AC</MenuItem>
                            <MenuItem value="DC">DC </MenuItem>
                            <MenuItem value="Gas Medicinal">Gas Medicinal</MenuItem>
                            <MenuItem value="Agua">Agua</MenuItem>                            
                            <MenuItem value="Aire comprimido">Aire comprimido</MenuItem>
                            <MenuItem value="Otra">Otra</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipotec}</FormHelperText>
                        </FormControl>

                        <TextField className="login-input-email"
                      required
                      type="tex"
                      name="urlmanual"
                      id="urlmanual"
                      label="Link del Manual o Ficha Técnica"
                      value={this.state.urlmanual}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.urlmanual}
                      error={errors.urlmanual?true:false}
                      />

              <TextField className="login-input-email"
                      required
                      type="tex"
                      name="urlfactura"
                      id="urlfactura"
                      label="Link de Factura o Declaración de Importación"
                      value={this.state.urlfactura}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.urlfactura}
                      error={errors.urlfactura?true:false}
                      />

                        <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="tensionmin"
                      id="tensionmin"
                      label="Tensión Min (V)"
                      value={this.state.tensionmin}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.tensionmin}
                      error={errors.tensionmin?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="tensionmax"
                      id="tensionmax"
                      label="Tensión Max (V)"
                      value={this.state.tensionmax}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.tensionmax}
                      error={errors.tensionmax?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>


                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="corrientemin"
                      id="corrientemin"
                      label="Corriente Min (A)"
                      value={this.state.corrientemin}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.corrientemin}
                      error={errors.corrientemin?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="corrientemax"
                      id="corrientemax"
                      label="Corriente Max (A)"
                      value={this.state.corrientemax}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.corrientemax}
                      error={errors.corrientemax?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>


                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="presion"
                      id="presion"
                      label="Presion de Entrada"
                      value={this.state.presion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.presion}
                      error={errors.presion?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="potencia"
                      id="potencia"
                      label="Potencia del Equipo (W)"
                      value={this.state.potencia}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.potencia}
                      error={errors.potencia?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="temperatura"
                      id="temperatura"
                      label="Temperatura de Operación"
                      value={this.state.temperatura}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.temperatura}
                      error={errors.temperatura?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="frecuencia"
                      id="frecuencia"
                      label="Frecuencia(Hz)"
                      value={this.state.frecuencia}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.frecuencia}
                      error={errors.frecuencia?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>


                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="alto"
                      id="alto"
                      label="Medidas Alto (cm)"
                      value={this.state.alto}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.alto}
                      error={errors.alto?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="ancho"
                      id="ancho"
                      label="Medidas Ancho (cm)"
                      value={this.state.ancho}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ancho}
                      error={errors.ancho?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="largo"
                      id="largo"
                      label="Medidas Largo (cm)"
                      value={this.state.largo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.largo}
                      error={errors.largo?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="peso"
                      id="peso"
                      label="Peso (Kg)"
                      value={this.state.peso}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.peso}
                      error={errors.peso?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>
          </div>
                </DialogContent>
                </div>)}
                <DialogActions>

             
                {loadingdata? (
                        <CircularProgress size={30} color="primary" className="login-progress" />
                    ) : (
                        this.state.aprobado.mensaje ? (
                            <div>                         
                         
                        <MyButton tip="Si" onClick={this.handleAgain}>
                            <FaCheckCircle color="#20B90C" className="icon-formulario" />
                        </MyButton>
                       
                         <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}/inventarioperfil`} className="text-link-inventariopdf">  
                        <MyButton tip="No" onClick={this.handleClose}>
                            <FaTimes color="#f70909" className="icon-formulario" />
                        </MyButton>
                        </Link>
                        </div>
                      
                        ) : (
                        <MyButton tip="Crear Equipo" onClick={this.handleCrearEquipo}>
                            <FaPlus color="#03178C" className="icon-formulario" />
                        </MyButton>
                        )
                    )}
                    
                </DialogActions>

                </Dialog>
      </div>
    )
  }
}

NuevoEquipoIA.propTypes = {
    postEquipoIA: PropTypes.func.isRequired,
    postNuevoEquipoIA:PropTypes.func.isRequired,
    clearEquiposIA:PropTypes.func.isRequired,
    getEquipoIA:PropTypes.func.isRequired,
    clienteId: PropTypes.string.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    data:PropTypes.object.isRequired  
  };

const mapActionsToProps={postEquipoIA,getEquipoIA,postNuevoEquipoIA,clearEquiposIA,clearErrors};

const mapStateToProps = (state)=>({
    user:state.user,
    data:state.data,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(withRouter(NuevoEquipoIA));
