import React, { Component , Fragment } from 'react';
import './EnviarSolicitud.css';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaUpload} from "react-icons/fa";

import {postSolicitudEnviar,clearErrors} from '../../../redux/actions/dataasistencialactions';


export class EnviarSolicitud extends Component {
    constructor(){
        super();
        this.state={
            titulo:'',
            open:false,
            openmsj:false,
            errors:{}
        };
        
    };

    
    static getDerivedStateFromProps(props, state) {
      return props.UI.errors ? {errors: props.UI.errors} : null;
      
  }

  handleOpen=()=>{
    this.setState({open:true});
    };

    handleOpenMsj=()=>{
        this.setState({openmsj:true});
        };

 handleClose=()=>{
        this.props.clearErrors();
        this.setState({ titulo:'',
        open:false,
        errors:{}});
        };

        handleCloseMsj=()=>{
           
                        this.props.clearErrors();
            this.setState({ titulo:'',
            open:false,
            errors:{}});

              
        
        }
            ;

    
 handleChange=(event)=>{
 this.setState({[event.target.name]:event.target.value});
 
 };

 
handleSubmit=(event)=>{
    event.preventDefault();
    


  console.log(this.props.solicitudId);

  console.log(this.props.history);
    this.props.postSolicitudEnviar(this.props.solicitudId,this.props.history);
 

    } 


  render() {

    const { UI:{loading}}=this.props; 
 
       
    return (
        <Fragment>
        <MyButton tip="Publicar" onClick={this.handleOpen}>
        <FaUpload color="#03178C" className="icon-crearcliente"/>
         </MyButton>

         <Dialog
         open={this.state.open}
         onClose={this.handleClose}
         fullWidth
        maxWidth="sm"
        >
        <DialogTitle className="titulo-market-text">Enviar Solicitud de Compras</DialogTitle>

        <DialogContent>
        <form>
        <h3 className="titulo-market-text">¿Esta seguro de enviar esta solicitud?</h3>
        

   
        </form>
        </DialogContent>

       
        <DialogActions>
        <MyButton tip="Cerrar" onClick={this.handleClose}>
        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
         </MyButton>

         {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                      <MyButton tip="Publicar Cotización" onClick={this.handleSubmit}>
                      <FaUpload color="#03178C" className="icon-formulario"/>
                       </MyButton>}
        
        

        
      </DialogActions>

        </Dialog>

        <Dialog
         open={this.state.openmsj}
         onClose={this.handleCloseMsj}
         fullWidth
        maxWidth="sm"
        >
        <DialogTitle className="titulo-market-text">Publicación Exitosa</DialogTitle>

        <DialogContent>
        <form>
        <h3 className="titulo-market-text">Volver al panel Principal</h3>
        

   
        </form>
        </DialogContent>

       
        <DialogActions>
        <MyButton tip="Cerrar" onClick={this.handleCloseMsj}>
        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
         </MyButton>

        
        
        

        
      </DialogActions>

        </Dialog>

        </Fragment>
    )
  }
}

EnviarSolicitud.propTypes={
    postSolicitudEnviar:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    solicitud:PropTypes.func.isRequired,
    dataAsistencial:PropTypes.object.isRequired,    
    UI:PropTypes.object.isRequired
  };
  const mapStateToProps=(state)=>({
    UI:state.UI,
    dataAsistencial:state.dataAsistencial,
    solicitud: state.dataAsistencial.solicitud.credentials
  });
  
  export default connect (mapStateToProps,{postSolicitudEnviar,clearErrors}) (EnviarSolicitud)
