import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {postCronogramaMtoAuto} from '../../../redux/actions/dataactions';
import {progMtoAutoInfra,getProgMtosInfraClienteFiltrado} from '../../../redux/actions/infraactions';
import {clearErrors} from '../../../redux/actions/dataactions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';



import { FaArrowLeft,FaPlus,FaFirstAid, FaChartBar, FaCheckCircle, FaCheck, FaCalendarWeek } from "react-icons/fa";
import './ObsolIndDialog.css';

export class ObsolIndDialog extends Component {
    constructor(){
        super();
        this.state={
            errors:{},
            aprobado:{},
            open:false
        };
        
    };

    handleOpen=()=>{
        this.setState({open:true});
        };


 handleClose=()=>{
    this.setState({
    open:false});
};

calculatePercentages = (equipos) => {
    if (!equipos || equipos.length === 0) {
        return { alta:0,media:0,baja:0 };
    }

    const total = equipos.length;
    const alta = equipos.filter(p => p.prioridad === 'Alta').length;
    const media= equipos.filter(p => p.prioridad === 'Media').length;
    const baja = total - alta -media;
    console.log(alta);
    console.log(media);
    return {
        alta: (alta / total) * 100,
        media: (media/ total) * 100,
        baja: (baja/ total) * 100
    };
}

calculateNumProgs=(equipos)=>{
    if (!equipos || equipos.length === 0) {
        return { alta:0,media:0,baja:0 };
    }

    const total = equipos.length;
    const alta = equipos.filter(p => p.prioridad === 'Alta').length;
    const media= equipos.filter(p => p.prioridad === 'Media').length;
    const baja = total - alta -media;

    return {
        alta: alta,
        media: media,
        baja: baja,
    };
}


render() {
        const { UI:{loading}}=this.props; 
        const {analisisObsolescenciaUsuario}=this.props.data;
        const {errors}=this.state;
        const percentages = this.calculatePercentages(analisisObsolescenciaUsuario);
        const totalequipos=analisisObsolescenciaUsuario ? Object.keys(analisisObsolescenciaUsuario).length : 0
        const numprogs=this.calculateNumProgs(analisisObsolescenciaUsuario);

        const databar = {
            labels: ['Alta', 'Media','Baja'],
            datasets: [
              {
                
                backgroundColor: [
                    "#0962d5",
                    "#338bf7",
                    "#06c1cc"
                  ],
                
                borderWidth: 1,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff',
                data: [numprogs.alta,
                    numprogs.media,
                    numprogs.baja
                    ],
              },
            ],
          };

          const optionsbar = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false, // Oculta la leyenda, que incluye las etiquetas del dataset
              },
            title: {
                display: false, // Oculta el título del gráfico
              },
            scales: {
                xAxes: [{
                    gridLines: {
                        display:false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display:false
                    }  , 
                    ticks: {
                        display: false,
                        beginAtZero: true, // Asegúrate de comenzar en cero
                      },
                }]
            },
            plugins: {
                
                tooltip: {
                enabled: false,
                mode: 'index',
                intersect: false,
              },
            },
          };
          const   datapie = {
            datasets: [
              {
                data: [percentages.alta.toFixed(1),
                    percentages.media.toFixed(1),
                    percentages.baja.toFixed(1),
                
                ],
                backgroundColor: [
                    "#0962d5",
                  "#338bf7",
                  "#06c1cc",
               
                  
        
                ],
                borderWidth: 5,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff'
              }
            ],
            labels: ['Alta', 'Media','Baja']
          };
          const optionspie = {
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            cutoutPercentage: 5,
            layout: { padding: 5},
            tooltips: {
              enabled: true,
              mode: 'index',
              intersect: false,
              borderWidth: 2,
              borderColor: "#03178C",
              backgroundColor: "#FFF",
              titleFontColor: "#03178C",
              bodyFontColor: "#03178C",
              footerFontColor: "#03178C"
            }
          };
        return (
          

        
            <Fragment>

                  <div className='div-card-link' onClick={this.handleOpen}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                            Indicadores
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon">
                                            <FaChartBar color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>
  
  
                </div>
           {/* <MyButton tip="Crear Equipo" onClick={this.handleOpen}>
            <FaFirstAid color="#03178C" className="icon-crearcliente"/>
        </MyButton>*/}

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                 
                  <h2 className='text-aprobado'>¡Programación de Mantenimiento Exitosa!</h2>
              </div>
                
                ) : (<div>
<DialogTitle className="text-title-accorion">Indicadores</DialogTitle>
               
              <DialogContent>
               
                <div className='div-container-info-ind-mtoifra'>
                <div className="dashing-cardicon">
                                            <FaCalendarWeek color="#FFFFFF" className="dashing-icon"/>
                </div>
                <div className='div-texto-info-ind-mtoinfra'>
                <p className='text-info-ind-mtoinfra'>Numero Total de Equipos Reemplazables </p>
                <p className='text-info-ind-mtoinfra-num'>{totalequipos}</p>
                </div>
                
                </div>
                <div>
                <p className='text-info-ind-mtoinfra-gray'>Prioridad de los Equipos</p>
                <hr className="separator_indmtoinfra"></hr>
                </div>
               
                        <div className="dashing-card-data-container">
                        <div className="dashing-chart-container-mtoinfra">
                                <Bar
                                options={optionsbar}
                                data={databar}
                              
                                />
                        </div>
                        <div className="dashing-card-data">

                        <p className="dashing-text-data"> Prioridad Alta</p>
                        <h2 className="dashing-value-data-blue">{numprogs.alta}</h2>
                        </div>


                        <div className="dashing-card-data">

                        <p className="dashing-text-data"> Prioridad Media</p>
                        <h2 className="dashing-value-data-blue">{numprogs.media}</h2>
                        </div>

                        <div className="dashing-card-data">

                        <p className="dashing-text-data"> Prioridad Baja</p>
                        <h2 className="dashing-value-data-blue">{numprogs.baja}</h2>
                        </div>


                        </div>

                        <div>
                <p className='text-info-ind-mtoinfra-gray'>Porcentaje de Priorización</p>
                <hr className="separator_indmtoinfra"></hr>
                </div>
                        <div className="dashing-chart-container">
                                <Pie
                                options={optionspie}
                                data={datapie}
                                
                                />
                               
                        </div>

                        <div className="dashing-card-data-container">

<div className="dashing-card-data">

<p className="dashing-text-data"> Prioridad Alta</p>
<h2 className="dashing-value-data-blue">{percentages.alta.toFixed(1)}%</h2>
</div>


<div className="dashing-card-data">

<p className="dashing-text-data"> Prioridad Media</p>
<h2 className="dashing-value-data-blue">{percentages.media.toFixed(1)}%</h2>
</div>

<div className="dashing-card-data">

<p className="dashing-text-data"> Prioridad Baja</p>
<h2 className="dashing-value-data-blue">{percentages.baja.toFixed(1)}%</h2>
</div>


</div>
             
             
              </DialogContent>
                </div>)}
            
          
          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

            
            
            

            
          </DialogActions>
            </Dialog>
            </Fragment>
            
        )
    }
}

ObsolIndDialog.propTypes = {
    
    clienteId: PropTypes.string.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

const mapActionsToProps={clearErrors};

const mapStateToProps = (state)=>({
    user:state.user,
    UI:state.UI,
    data:state.data
})



export default connect(mapStateToProps,mapActionsToProps)(ObsolIndDialog);
