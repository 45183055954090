import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MyButton from '../../../utils/MyButton'
import { FaArrowCircleLeft, FaChartBar } from 'react-icons/fa'
import TablaEquipos from './TablaEquipos'
import SubirEquipos from '../ClientePerfil/SubirEquipos'
import './MisRepuestos.css'
import NuevoEquipo from './NuevoEquipo'
import { Fragment } from 'react'
import TablaRepuestos from './TablaRepuestos'

export class MisRepuestos extends Component {
  render() {
    return (
        <div>
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Stock de Repuestos</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

   
        </div>

<div className='container-inventario-perfil'>

                

                                    <div className="div-tabla-equipos">
                                    
                                    <TablaRepuestos clienteId={this.props.match.params.clienteId}></TablaRepuestos>
        </div>
</div>
 
       
        
        </div>
    )
  }
}

export default MisRepuestos