import React, { useEffect,useState } from 'react';
import './HojadeVidaEquipo.css'; 
import MyButton from '../../../utils/MyButton';
import { FaFileMedical,FaArrowCircleLeft, FaCogs, FaFilePdf, FaPhoneSquareAlt, FaRegCalendarCheck, FaRocket, FaSortDown, FaSortUp, FaThermometerHalf, FaWrench} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import {getEquipo} from '../../../redux/actions/dataactions';
import ListaMtoProgAsis from './ListaMtoProgAsis';
import HistorialPrevAsis from './HistorialPrevAsis';
import HistorialCorrectivosAsis from './HistorialCorrectivosAsis';
import ListaCalProgAsis from './ListaCalProgAsis';
import ListaCalibracionesAsis from './ListaCalibracionesAsis';
import SolicitarMtoCorrectivo from './SolicitarMtoCorrectivo';
import HistorialSolicitudes from './HistorialSolicitudes';
import { Button } from '@material-ui/core';
import RecomendacionesAsis from './RecomendacionesAsis';
import CrearCasoTecno from '../Tecnovigilancia/crearCasoTecno';
import TablaTecno from '../Tecnovigilancia/TablaTecno';

function HojadeVidaEquipo({ getEquipo, data, match }) {
  const history = useHistory();
  const [accion, setAccion] = useState(null);
  useEffect(() => {
    console.log(match.params.equipoId);
    getEquipo(match.params.equipoId);
  }, [getEquipo, match.params.equipoId]);

  const { equipo, loading } = data;
  const [perfilInfoVisible, setPerfilInfoVisible] = useState(false); // Estado para controlar la visibilidad

  const [mostrarComponente, setMostrarComponente] = useState(false); // Estado para controlar la visibilidad del componente

  const toggleAccion = (nuevaAccion) => {
    if (nuevaAccion === accion && mostrarComponente) {
      // Si se hace clic en el botón que ya está seleccionado y el componente está visible, ocultarlo
      setAccion("");
      setMostrarComponente(false);
    } else {
      setAccion(nuevaAccion);
      setMostrarComponente(true);
    }
  };



    let cargarEquipo= !loading ? (
        <div className="card-equipo">
            <div className="div-informacion">
            <div className="div-imagencliente">
            <img
                src={
                  equipo.credentials.imagenurl === undefined || equipo.credentials.imagenurl === "undefined"
                    ? 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media'
                    : equipo.credentials.imagenurl
                }
                alt="equipo"
                className="img-cliente"
              />

            </div>

            <div className="div-infocontainer">
            <div className="div-titulocliente">
       
            <h3 className="text-titulo-cliente">{equipo.credentials.nombre}</h3>
            <p className="perfilequipo-text">{equipo.credentials.estado}</p>
            <p className="perfilequipo-text">{equipo.credentials.idinventario}</p>
            </div>
            

            <div className="div-equipo-info">
            <div className="div-perfil-info">
            <hr className="clientes_separator"></hr>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Modelo: </h5>    
            <p className="perfilequipo-text">{equipo.credentials.modelo}</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Serie </h5>    
            <p className="perfilequipo-text">{equipo.credentials.serie}</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Registro Invima </h5>    
            <p className="perfilequipo-text">{equipo.credentials.registroinvima}</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Clase de Aislamiento </h5>    
            <p className="perfilequipo-text">{equipo.credentials.clase}</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Ubicación</h5>    
            <p className="perfilequipo-text">{equipo.credentials.ubicacion}</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Servicio</h5>    
            <p className="perfilequipo-text">{equipo.credentials.servicio}</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Equipo</h5>    
            <p className="perfilequipo-text">{equipo.credentials.movilfijo}</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Tipo </h5>    
            <p className="perfilequipo-text">{equipo.credentials.tipo}</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Clasificación del Riesgo</h5>    
            <p className="perfilequipo-text">{equipo.credentials.riesgo}</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Clasificación Biomédica </h5>    
            <p className="perfilequipo-text">{equipo.credentials.clasbiomedica}</p>
            </div>

             </div>

      
             <div className="div-perfil-info" style={{ display: perfilInfoVisible ? 'flex' : 'none' }}>
            <hr className="clientes_separator"></hr>  
            
            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Forma de Adquisición </h5>    
            <p className="perfilequipo-text">{equipo.credentials.adquisicion}</p>
            </div>     

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Costo </h5>    
            <p className="perfilequipo-text">${equipo.credentials.costo}</p>
            </div>        

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Vida Util </h5>    
            <p className="perfilequipo-text">{equipo.credentials.vidautil} años</p>
            </div>        

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Fecha de Fabricación </h5>    
            <p className="perfilequipo-text">{equipo.credentials.fabricacion}</p>
            </div>   
            
            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Inicio de Operación</h5>    
            <p className="perfilequipo-text">{equipo.credentials.operacion}</p>
            </div>   

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Proveedor</h5>    
            <p className="perfilequipo-text">{equipo.credentials.proveedor}</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">E-mail</h5>    
            <p className="perfilequipo-text">{equipo.credentials.proveedoremail}</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Teléfono</h5>    
            <p className="perfilequipo-text">{equipo.credentials.proveedortel}</p>
            </div>  

            </div>


            <div className="div-perfil-info" style={{ display: perfilInfoVisible ? 'flex' : 'none' }}>
            <hr className="clientes_separator"></hr>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Tipo de Tecnología</h5>    
            <p className="perfilequipo-text">{equipo.credentials.tipotec}</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Fuente de Alimentación</h5>    
            <p className="perfilequipo-text">{equipo.credentials.fuente}</p>
            </div>  

            <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Link del Manual</h5>    
                <p className="perfilequipo-text">{equipo.credentials.urlmanual==='Sin Url'?<a>Sin Manual Adjunto</a>:<a href={equipo.credentials.urlmanual} target="_blank" rel="noopener noreferrer">Link del Manual</a>}</p>
                </div> 

                <div className="div-perfil-detalle">
                <h5 className="equipo-detalle">Link de Factura</h5>    
                <p className="perfilequipo-text">{equipo.credentials.urlfactura==='Sin Url'?<a>Sin Factura Adjunta</a>:<a href={equipo.credentials.urlfactura} target="_blank" rel="noopener noreferrer">Link de la Factura</a>}</p>
                </div> 

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Tensión Min</h5>    
            <p className="perfilequipo-text">{equipo.credentials.tensionmin} V</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Tensión Max</h5>    
            <p className="perfilequipo-text">{equipo.credentials.tensionmax} V</p>
            </div> 

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Corriente Min</h5>    
            <p className="perfilequipo-text">{equipo.credentials.corrientemin} A</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Corriente Max</h5>    
            <p className="perfilequipo-text">{equipo.credentials.corrientemax} A</p>
            </div>

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Potencia</h5>    
            <p className="perfilequipo-text">{equipo.credentials.potencia} W</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Temperatura</h5>    
            <p className="perfilequipo-text">{equipo.credentials.temperatura} °C</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Frecuencia</h5>    
            <p className="perfilequipo-text">{equipo.credentials.frecuencia} °C</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Presión</h5>    
            <p className="perfilequipo-text">{equipo.credentials.presion}</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Alto</h5>    
            <p className="perfilequipo-text">{equipo.credentials.alto} cm</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Ancho</h5>    
            <p className="perfilequipo-text">{equipo.credentials.ancho} cm</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Largo</h5>    
            <p className="perfilequipo-text">{equipo.credentials.largo} cm</p>
            </div>  

            <div className="div-perfil-detalle">
            <h5 className="equipo-detalle">Peso</h5>    
            <p className="perfilequipo-text">{equipo.credentials.peso} Kg</p>
            </div>  

            </div>


           </div>
            </div>
            </div>

            <div className="div-acciones-equipo">
            <RecomendacionesAsis clienteId equipoId={match.params.equipoId}></RecomendacionesAsis>
            <CrearCasoTecno clienteId={match.params.clienteId} equipoId={match.params.equipoId}></CrearCasoTecno>
                    <SolicitarMtoCorrectivo equipoid={match.params.equipoId}/>
                    <Link to={`/dashboard/inventarioequiposasistencial/hojadevidapdf/${equipo.credentials.idprestador}/${match.params.equipoId}`} className="text-link-inventariopdf">
                    <MyButton tip="Hoja de Vida PDF">
                    <FaFilePdf color="#03178C" className="icon-crearcliente"/>
                    </MyButton>
                    
                    </Link>

                    <MyButton tip="Mostrar Más" onClick={() => setPerfilInfoVisible(!perfilInfoVisible)}>
                        {perfilInfoVisible ? ( // Mostrar un icono cuando perfilInfoVisible es true
                            <FaSortUp color="#03178C" className="icon-crearcliente" />
                        ) : ( // Mostrar otro icono cuando perfilInfoVisible es false
                            <FaSortDown color="#03178C" className="icon-crearcliente" />
                        )}
                    </MyButton> 


             </div>
         
        </div>

        
        )
        
        :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);
        
        const handleVolverClick = () => {
          history.goBack();
    };  

    return (
        <div>
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Perfil Equipo</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <MyButton tip="Volver" onClick={handleVolverClick}>
                    <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
                  </MyButton>
        </div>
        </div>
    
        {cargarEquipo}
        <div className='container-botones-perfil'>
        <Button
            variant="contained"
            endIcon={<FaWrench style={{ color: accion === "MtosProg" ? "white" : "#03178c" }} />}
            onClick={() => toggleAccion("MtosProg")}
            style={{
                backgroundColor: accion === "MtosProg" ? "#0588FB" : "initial",
                color: accion === "MtosProg" ? "white" : "#03178c",
                marginRight:accion === "MtosProg" ? "10px" : "10px",
                textTransform: accion === "MtosProg" ? "initial" : "initial"
                
            }}
            >
            {accion === "MtosProg" ? "Mtos Programados" : "Mtos. Programados"}
            </Button>
        <Button
          variant="contained"
          endIcon={<FaRocket  style={{ color: accion === "MtosReal" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("MtosReal")} // Actualizar el estado al hacer clic en "MtosReal"
          style={{
            backgroundColor: accion === "MtosReal" ? "#0588FB" : "initial",
            color: accion === "MtosReal" ? "white" : "#03178c",
            marginRight:accion === "MtosReal" ? "10px" : "10px",
            textTransform: accion === "MtosReal" ? "initial" : "initial"
            
        }}
        >
          {accion === "MtosReal" ? "Mtos. Realizados" : "Mtos. Realizados"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaRegCalendarCheck  style={{ color: accion === "CalProg" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("CalProg")} // Actualizar el estado al hacer clic en "CalProg"
          style={{
            backgroundColor: accion === "CalProg" ? "#0588FB" : "initial",
            color: accion === "CalProg" ? "white" : "#03178c",
            marginRight:accion === "CalProg" ? "10px" : "10px",
            textTransform: accion === "CalProg" ? "initial" : "initial"
            
        }}
        >
          {accion === "CalProg" ? "Cal. Programadas" : "Cal. Programadas"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaThermometerHalf  style={{ color: accion === "CalReal" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("CalReal")}// Actualizar el estado al hacer clic en "CalReal"
          style={{
            backgroundColor: accion === "CalReal" ? "#0588FB" : "initial",
            color: accion === "CalReal" ? "white" : "#03178c",
            marginRight:accion === "CalReal" ? "10px" : "10px",
            textTransform: accion === "CalReal" ? "initial" : "initial"
            
        }}
        >
          {accion === "CalReal" ? "Cal. Realizadas" : "Cal. Realizadas"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaCogs  style={{ color: accion === "MtosCorr" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("MtosCorr")} // Actualizar el estado al hacer clic en "MtosCorr"
          style={{
            backgroundColor: accion === "MtosCorr" ? "#0588FB" : "initial",
            color: accion === "MtosCorr" ? "white" : "#03178c",
            marginRight:accion === "MtosCorr" ? "10px" : "10px",
            textTransform: accion === "MtosCorr" ? "initial" : "initial"
            
        }}
        >
          {accion === "MtosCorr" ? "Mtos. Correctivos" : "Mtos. Correctivos"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaPhoneSquareAlt  style={{ color: accion === "SolCorr" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("SolCorr")} // Actualizar el estado al hacer clic en "SolCorr"
          style={{
            backgroundColor: accion === "SolCorr" ? "#0588FB" : "initial",
            color: accion === "SolCorr" ? "white" : "#03178c",
            marginRight:accion === "SolCorr" ? "10px" : "10px",
            textTransform: accion === "SolCorr" ? "initial" : "initial"
            
        }}
        >
          {accion === "SolCorr" ? "Sol. Correctivos" : "Sol. Correctivos"}
        </Button>

        <Button
          variant="contained"
          endIcon={<FaFileMedical style={{ color: accion === "Tecnovigilancia" ? "white" : "#03178c" }} />}
          onClick={() => toggleAccion("Tecnovigilancia")} // Actualizar el estado al hacer clic en "Tecnovigilancia"
          style={{
            backgroundColor: accion === "Tecnovigilancia" ? "#0588FB" : "initial",
            color: accion === "Tecnovigilancia" ? "white" : "#03178c",
            marginRight: accion === "Tecnovigilancia" ? "10px" : "10px",
            textTransform: accion === "Tecnovigilancia" ? "initial" : "initial"

          }}
        >
          {accion === "Tecnovigilancia" ? "Casos Tecnovigilancia" : "Casos Tecnovigilancia"}
        </Button>
      </div>
        <div className="acordion-container-equipo">
        {mostrarComponente && (
            <div>

             <div>
             {accion === "MtosProg" ? <div>
             <ListaMtoProgAsis equipoId={match.params.equipoId} />
             </div> : null}
             </div>

            <div>
            {accion === "MtosReal" ? <div>
            <HistorialPrevAsis equipoId={match.params.equipoId} />
             </div> : null}
            </div>

            <div>
            {accion === "CalProg" ? <div>
            <ListaCalProgAsis equipoId={match.params.equipoId} />
               </div> : null}
            </div>

            <div>
              {accion === "CalReal" ? <div>
             
        <ListaCalibracionesAsis equipoId={match.params.equipoId} />
              </div> : null}
            </div>

            <div>
              {accion === "MtosCorr" ? <div>
              <HistorialCorrectivosAsis equipoId={match.params.equipoId} />
              </div> : null}
            </div>

            <div>
              {accion === "SolCorr" ? <div>
              <HistorialSolicitudes equipoId={match.params.equipoId} />
              </div> : null}
            </div>

            <div>
              {accion === "Tecnovigilancia" ? <div>
                <TablaTecno clienteId={match.params.clienteId} equipoId={match.params.equipoId} />
              </div> : null}
            </div>

           </div>

        )}
       
      

        


       
      </div>

        
        </div>

    )
  }


HojadeVidaEquipo.propTypes = {
    getEquipo: PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired
  };

const mapActionsToProps={getEquipo};

const mapStateToProps = (state)=>({
    data:state.data,
    user:state.user
})



export default connect(mapStateToProps,mapActionsToProps)(HojadeVidaEquipo);

