
import './ProgramacionConsultaCal.css';
import React, { Component, Fragment } from 'react';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getListaCalMes,clearErrors,deleteProgCalTabla,postCalibracionTabla, editProgCal} from '../../../redux/actions/dataactions';

import { FaArrowCircleLeft, FaCalendarCheck, FaCogs, FaRocket, FaRuler, FaTasks, FaWrench } from 'react-icons/fa'
import MaterialTable from 'material-table';

import MyButton from '../../../utils/MyButton';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';

import { FaArrowLeft, FaClipboardList, FaFilePdf, FaPlus, FaUpload} from "react-icons/fa";
import CronogramaCalAuto from './CronogramaCalAuto';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';


export class ProgramacionConsultaCal extends Component {
    componentDidMount(){
        this.props.getListaCalMes(this.props.match.params.clienteId,this.props.match.params.mes,this.props.match.params.year);
      }
  render() {
    const {equiposcal,loadingcal}= this.props.data;
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    const columns=[
      {title:'Id Inventario',field:'idinventario'},
        {title:'Nombre',field:'nombre'},
        {title:'Marca',field:'marca'},
        {title:'Serie',field:'serie'},
        {title:'Modelo',field:'modelo'},
        {title:'Ubicación',field:'ubicacion'},
        {title:'Fecha',field:'fecha'},
        {title:'Mes',field:'mesnombre'},
        {title:'Estado',field:'estado'},
        {title:'Lugar',field:'lugar'}
    ]
    let indicador=equiposcal.length;

    let equiposrealizados=Object.values(equiposcal).filter(equipo => equipo.estado === 'Realizado')
    let canteqreal=equiposrealizados.length;
    let porcentaje=canteqreal*100/indicador;
    let sinrealizar=indicador-canteqreal;


    let mapeoequipos=!loadingcal?(
        <div>
           <div className='div-container-links-inventario'>
           
           <div className='div-card-link' >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                Cal. Programadas {indicador}
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-blue">
                    <FaRuler color="#FFFFFF" className="dashing-icon"/>
                </div>
                                          </div>


                        </div>

                        <div className='div-card-link' >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                Cumplimiento {porcentaje}%
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                            <FaCalendarCheck color="#FFFFFF" className="dashing-icon"/>
                                            </div>
                                          </div>


                        </div>
        
            

           <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}/programacion/calibracion/${this.props.match.params.mes}/${this.props.match.params.year}/pdf`}  state={equiposcal} >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Ver en PDF
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
           </div>

           <div className='div-container-links-inventario'>

<div className='div-card-link' >
                <div className="dashing-cardcontent">
                                    <div className="dashing-cardtext">
                                        <p className="dashing-text-card">
                                      Cal. Sin Realizar {sinrealizar}
                                        </p>
                    
                                    </div>
                                    <div className="dashing-cardicon-orange">
                      <FaCogs color="#FFFFFF" className="dashing-icon"/>
                  </div>
                                </div>


              </div>

              <div className='div-card-link' >
                <div className="dashing-cardcontent">
                                    <div className="dashing-cardtext">
                                        <p className="dashing-text-card">
                                      Cal. Realizadas {canteqreal}
                                        </p>
                    
                                    </div>
                                    <div className="dashing-cardicon-blue">
                <FaTasks color="#FFFFFF" className="dashing-icon"/>
            </div>
                                </div>


              </div>

</div>

           <div className='containter-table-progconsulta'>
        <MaterialTable
        columns={columns}
        data={equiposcal}
        title='Cronograma de Calibración'
        icons={tableIcons}
        
        actions={[
                
          rowData => ({
            icon: tableIcons.Add,
            tooltip: 'Realizar Calibración',
            onClick: (event, rowData) => {
              this.handleOpen(rowData.progid,rowData.equipoid,rowData.fecha,rowData.hora,rowData.idinventario);
            },
            disabled: rowData.estado==='Realizado'
          })
         ,
            
            {
              icon: tableIcons.Delete,
              tooltip: 'Borrar Programación',
              onClick: (event, rowData) => {
                  
               this.handleOpenDelete(rowData.progid,rowData.equipoid,rowData.clienteid);
              }
            }
            ,

            {
              icon: tableIcons.DetailPanel,
              tooltip: 'Ver Perfil',
              onClick: (event, rowData) => {
                // /dashboard/perfilcliente/equipo/vRXa6GZHJDIKjw6mFKAS/AxtbN5w0e9SjAt2R7eFa
                window.location.replace(`/dashboard/perfilcliente/equipo/${this.props.clienteId}/${rowData.equipoid}`)
                
              }
            }
          ]}
        >

        </MaterialTable>
        </div>
      
        </div>
        ):(
        <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
        )


    return (
      <div>
           <div className="titulo_section">
                <div className="titulo_clientes">
                <h1 className="text-clientes">Programación Calibración mes {this.props.match.params.mes} de {this.props.match.params.year}</h1>
                <hr className="clientes_separator"></hr>            
                
                </div>

                <div className="div_boton">
                <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}/programacion`}>
                <MyButton tip="Volver">
                <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
                </MyButton>
                </Link>
                </div>
                </div>

                <div>
                    {mapeoequipos}
                </div>
      </div>
    )
  }
}


ProgramacionConsultaCal.propTypes={
    getListaCalMes:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteProgCalTabla:PropTypes.func.isRequired,
    editProgCal:PropTypes.func.isRequired,
    postCalibracionTabla:PropTypes.func.isRequired  
}

const mapActionsToProps={getListaCalMes,clearErrors,deleteProgCalTabla, editProgCal,postCalibracionTabla};
const mapStateToProps=state=>({
    data:state.data,
    UI:state.UI
})

export default connect(mapStateToProps,mapActionsToProps) (ProgramacionConsultaCal);
