import React, { Component } from 'react'
import { ButtonGroup,Button } from '@material-ui/core';
import {Link} from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import {FaArrowCircleLeft} from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {getEquipo,getCliente,getMtoPrev} from '../../../redux/actions/dataactions'; 


import   {  PDFViewer }   from '@react-pdf/renderer';
import './ReporteMtoPDFAsis.css';
import ReporteMtoDocuPDFAsis from './ReporteMtoDocuPDFAsis';

export class ReporteMtoPDFAsis extends Component {
    constructor(){
        super();
        this.state={
            equipos:{},
            errors:{},
            open:false,
            verWeb:false,
            verPDF:false
        };
        
    };

    componentDidMount(){
        //get MTO PREVENTIVO DEBE ENVIAR DESDE LA LISTA EL ID DEL REPORTE DE MTO TAMBIEN
        this.props.getMtoPrev(this.props.match.params.mtoid);
        //GET EQUIPO
       this.props.getEquipo(this.props.match.params.equipoId);
       //get cliente
       this.props.getCliente(this.props.match.params.clienteId);
      
      }

      handleOpen = () => {
        if(this.state.verWeb){
            this.setState({ verWeb: false });
        }else{
            this.setState({ verWeb: true,verPDF:false});
        }
        
      };

      handleOpenPDF = () => {
        if(this.state.verPDF){
            this.setState({ verPDF: false });
        }else{
            this.setState({ verPDF: true,verWeb:false});
        }
        
      };

  render() {
    const {equipo,cliente,mtopreventivo,loading}= this.props.data;
    return (
        <div className='div-inventaripdfgeneral'>
      
        <div className='div-inventaripdftitulo'>
  
  
        <div className="div_boton">
                  <Link to={`/dashboard/inventarioequiposasistencial/hojadevidaequipo/${this.props.match.params.equipoId}`}>
                  <MyButton tip="Volver">
                   <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
                  </MyButton>
                  </Link>
                  </div> 
  
          <ButtonGroup variant="text" aria-label="text button group" color='primary'>
  
          <Button onClick={this.handleOpenPDF}>
          {this.state.verPDF? "Ocultar PDF":"Ver PDF"}</Button>
                
  
  
          </ButtonGroup>
          </div>
  
          <div className='div-inventariopdfcontent'>
          {equipo&&cliente ? (
          <>
  
          {this.state.verPDF ?  <PDFViewer style={{ width: "100%", height: "90vh" }}>
              <ReporteMtoDocuPDFAsis equipo={equipo} cliente={cliente} mtopreventivo={mtopreventivo}/>
            </PDFViewer>  : null}
          
          </>
          ) : null}
          </div>
         
        </div>
    )
  }
}

ReporteMtoPDFAsis.propTypes={
    getEquipo:PropTypes.func.isRequired,    
    getCliente:PropTypes.func.isRequired,
    getMtoPrev:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired, 
}
const mapStateToProps=state=>({
    data:state.data
})
export default connect(mapStateToProps,{getEquipo,getCliente,getMtoPrev}) (ReporteMtoPDFAsis);