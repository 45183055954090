import React from 'react'
import Header from '../Home/HomeComponents/Header/Header';
import About from './HomeComponents/About/About';
import Caracteristicas from './HomeComponents/Caracteristicas/Caracteristicas';
import Herramientas from './HomeComponents/Herramientas/Herramientas';
import Contacto from './HomeComponents/Contacto/Contacto'; 
import './Home.css'; 
import Planes from './HomeComponents/Planes/Planes';
import Digitaliza from './HomeComponents/Digitaliza/Digitaliza';
import AboutNew from './HomeComponents/AboutNew/AboutNew';
import PlanesNew from './HomeComponents/Planes/PlanesNew';
import FooterHome from './HomeComponents/Footer/FooterHome';
function Home() {
    return (
        <div className="divhome">
            <Header/> 
            <Caracteristicas/>
            <Digitaliza/>
            <AboutNew/>
            <PlanesNew/>
            <FooterHome/>
         
           
        </div>
    )
}

export default Home
