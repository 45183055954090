import React, { Component , Fragment } from 'react';
import './ProgMtoInfra.css';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FaArrowLeft,FaPlus,FaCalendarPlus, FaCheckCircle, FaCheck, FaCogs } from "react-icons/fa";

import {postCorrectivoInfra,getMtosCorrectivosInfra,clearErrors} from '../../../redux/actions/infraactions';


export class MtoCorrInfra extends Component {

    constructor(){
        super();
        this.state={
            fechasolicitud:'',
            horasolicitud:'',
            infosolicitud:'',
            fechainicio:'',
            horainicio:'',
            fechafinal:'',
            horafinal:'',
            redElectrica:'',
            redHidraulica:'',
            redComunicaciones:'',
            redGases:'',
            redInstalaciones:'',
            redOtros:'', 
            actividades:'',                
            observaciones:'',  
            nombrerepuesto:'',
            nombrerepuesto1:'',
            nombrerepuesto2:'',
            cantidad:0,
            cantidad1:0,
            cantidad2:0,
            costo:0,
            costo1:0,
            costo2:0,
            subtotal:0,
            subtotal1:0,
            subtotal2:0,
            totalrepuesto:0,
            firmarecibe:'',
            cargorecibe:'',
            areaId:'',
            clienteid:'',
            solicitud:{},          
            errors:{},
            aprobado:{},
            open:false,
            opendelete:false
        };
        
    };
    static getDerivedStateFromProps(props, state) {
      const newState = {};
  
      if (props.UI.errors) {
          newState.errors = props.UI.errors;
      }
  
      if (props.UI.aprobado) {
         newState.aprobado = props.UI.aprobado;
      }
  
      return Object.keys(newState).length > 0 ? newState : null;
  }
  

    handleOpen=()=>{
        this.setState({open:true,aprobado:{}});
        };

        handleClose=()=>{


            if(this.state.aprobado.mensaje){
                //getEquipos this.handleEditProgMto();
                this.props.getMtosCorrectivosInfra(this.props.areaId);
                this.setState({
                    nombre:'',
                    servicio:'',
                    piso:'',
                    estado:'',
                    ubicacion:'',
                    open:false,errors:{},aprobado:{}});
            }else{
                this.setState({
                    nombre:'',
                    servicio:'',
                    piso:'',
                    estado:'',
                    ubicacion:'',
                    open:false,errors:{},aprobado:{}});
            }
           
           
                        
            };
        
     handleChange=(event)=>{
     this.setState({[event.target.name]:event.target.value});
     
     };

     
    handleSubmit=(event)=>{
        event.preventDefault();
        const {credentials}=this.props.user;
        let fechamto=new Date(this.state.fechainicio);
        let day;
        let mes;
        let newfecha;
        let year;
        let mesnombre;
        
        if(fechamto.getDate()>=30){
          day=1;
          
          mes=fechamto.getMonth()+1;
          if(mes>11){
            mes=0;
            year=fechamto.getFullYear()+1;
          }else{
            year=fechamto.getFullYear();
          }
          newfecha=fechamto.setDate(day);
          newfecha=fechamto.setMonth(mes);
          newfecha=new Date(newfecha);
          mes=mes+1;
          mesnombre=newfecha.toLocaleString('es-Es', {
            month: 'long',
          });
        }else{
          day=fechamto.getDate()+1;
          mes=fechamto.getMonth()+1;
          year=fechamto.getFullYear();
          mesnombre=fechamto.toLocaleString('es-Es', {
            month: 'long',
          });
        }
        
        
       

        const newProgramacion={
          fechainicio:this.state.fechainicio,
          horainicio:this.state.horainicio,
          dia:day,
          mes:mes,
          mesnombre:mesnombre,
          year:year,
          areaId:this.props.areaId,
          clienteId:this.props.clienteId,
          fechafinal:this.state.fechafinal,
          horafinal:this.state.horafinal,
          redElectrica:this.state.redElectrica,
          redHidraulica:this.state.redHidraulica,
          redComunicaciones:this.state.redComunicaciones,
          redGases:this.state.redGases,
          redInstalaciones:this.state.redInstalaciones,
          redOtros:this.state.redOtros, 
          actividades:this.state.actividades,                
          observaciones:this.state.observaciones,  
          nombrerepuesto:this.state.nombrerepuesto,
          nombrerepuesto1:this.state.nombrerepuesto1,
          nombrerepuesto2:this.state.nombrerepuesto2,
          cantidad:this.state.cantidad,
          cantidad1:this.state.cantidad1,
          cantidad2:this.state.cantidad2,
          costo:this.state.costo,
          costo1:this.state.costo1,
          costo2:this.state.costo2,
          subtotal:this.state.subtotal,
          subtotal1:this.state.subtotal1,
          subtotal2:this.state.subtotal2,
          totalrepuesto:this.state.totalrepuesto,
          firmarecibe:this.state.firmarecibe,
          cargorecibe:this.state.cargorecibe,
          solicitud:{
            fechasolicitud:this.state.fechasolicitud,
            horasolicitud:this.state.horasolicitud,
            infosolicitud:this.state.infosolicitud,
          },  
          imagenurl:'',   
          solicitudid:null     

             
      };

      console.log(newProgramacion);
      this.props.postCorrectivoInfra(newProgramacion,this.props.areaId,this.props.clienteId,credentials.handle);  //postMtoTabla
       
     // this.props.progMtoInfra(newProgramacion,this.props.areaId); 
        //Aqui se postea al cliente
     
    
        }
        

    render() {

        const { UI:{loading}}=this.props; 
        const {errors}=this.state;
        this.state.subtotal=this.state.cantidad*this.state.costo;
        this.state.subtotal1=this.state.cantidad1*this.state.costo1;
        this.state.subtotal2=this.state.cantidad2*this.state.costo2;
        this.state.totalrepuesto=this.state.subtotal+this.state.subtotal1+this.state.subtotal2;
        return (
            <Fragment>
            <MyButton tip="Realizar Reporte Correctivo" onClick={this.handleOpen}>
            <FaCogs color="#03178C" className="icon-crearcliente"/>
             </MyButton>

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
                {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>¡Programación de Mantenimiento Exitosa!</h2>
              </div>
                
                ) : (<div>
                    <DialogTitle>Mantenimiento Correctivo Infraestructura</DialogTitle>

                    <DialogContent>
                              <form>
                    


                              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                    <h4 className="text-title-accorion">Información General</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div className="div-accordion-container">
                
                <div className="div-input-doble-mtoprog">
                <TextField className="login-input-email"
                                required
                                type="date"
                                name="fechasolicitud"
                                id="fechasolicitud"
                                label="Fecha Solicitud de Mantenimiento"
                                value={this.state.fechasolicitud}
                                onChange={this.handleChange}
                                min="2019-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.fechasolicitud}
                                error={errors.fechasolicitud?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />

                          <TextField className="login-input-email"
                                required
                                type="time"
                                name="horasolicitud"
                                id="horasolicitud"
                                label="Hora Solicitud de Mantenimiento"
                                value={this.state.horasolicitud}
                                onChange={this.handleChange}
                                min="2000-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.horasolicitud}
                                error={errors.horasolicitud?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                    </div>
                    <TextField className="login-input-email"
                              required
                              type="Text"
                              name="infosolicitud"
                              id="infosolicitud"
                              label="Describa la solicitud realizada para la intervención"
                              value={this.state.infosolicitud}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.infosolicitud}
                              error={errors.infosolicitud?true:false}
                          
                              />
                <div className="div-input-doble-mtoprog">

                <TextField className="login-input-email"
                                required
                                type="date"
                                name="fechainicio"
                                id="fechainicio"
                                label="Fecha Inicio de Mantenimiento"
                                value={this.state.fechainicio}
                                onChange={this.handleChange}
                                min="2019-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.fechainicio}
                                error={errors.fechainicio?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />

                          <TextField className="login-input-email"
                                required
                                type="time"
                                name="horainicio"
                                id="horainicio"
                                label="Hora Inicio de Mantenimiento"
                                value={this.state.horainicio}
                                onChange={this.handleChange}
                                min="2000-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.horainicio}
                                error={errors.horainicio?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                    </div>
                    <div className="div-input-doble-mtoprog">
                <TextField className="login-input-email"
                                required
                                type="date"
                                name="fechafinal"
                                id="fechafinal"
                                label="Fecha Final del Mantenimiento"
                                value={this.state.fechafinal}
                                onChange={this.handleChange}
                                min="2019-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.fechafinal}
                                error={errors.fechafinal?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />

                          <TextField className="login-input-email"
                                required
                                type="time"
                                name="horafinal"
                                id="horafinal"
                                label="Hora Final del Mantenimiento"
                                value={this.state.horafinal}
                                onChange={this.handleChange}
                                min="2000-01-01" max="2022-01-01"
                                fullWidth
                                helperText={errors.horafinal}
                                error={errors.horafinal?true:false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                    </div>
             
                    </div>
                    </AccordionDetails>
                    </Accordion>

                    <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="text-title-accorion">Campos de Intervención</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div className="div-accordion-container">
                <div className="div-input-doble-mtoprog">
                                <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Red Electrica</InputLabel>
                                      <Select
                                      required
                                  name="redElectrica"
                                  id="redElectrica"
                                  value={this.state.redElectrica}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="Si">Si</MenuItem>
                                      <MenuItem value="No">No</MenuItem>
                                 
                                      </Select>
                                      <FormHelperText>{errors.redElectrica}</FormHelperText>
                                  </FormControl>  

                                  <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Red Gases</InputLabel>
                                      <Select
                                      required
                                  name="redGases"
                                  id="redGases"
                                  value={this.state.redGases}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="Si">Si</MenuItem>
                                      <MenuItem value="No">No</MenuItem>
                                 
                                      </Select>
                                      <FormHelperText>{errors.redGases}</FormHelperText>
                                  </FormControl>  

                                 
                </div>

                <div className="div-input-doble-mtoprog">
                                <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Red Comunicaciones</InputLabel>
                                      <Select
                                      required
                                        name="redComunicaciones"
                                        id="redComunicaciones"
                                        value={this.state.redComunicaciones}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="Si">Si</MenuItem>
                                      <MenuItem value="No">No</MenuItem>
                                 
                                      </Select>
                                      <FormHelperText>{errors.redComunicaciones}</FormHelperText>
                                  </FormControl>  

                                  <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Instalaciones Fisicas</InputLabel>
                                      <Select
                                      required
                                  name="redInstalaciones"
                                  id="redInstalaciones"
                                  value={this.state.redInstalaciones}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="Si">Si</MenuItem>
                                      <MenuItem value="No">No</MenuItem>
                                 
                                      </Select>
                                      <FormHelperText>{errors.redInstalaciones}</FormHelperText>
                                  </FormControl>  

                                 
                </div>

                <div className="div-input-doble-mtoprog">
                                <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Red Hidraulica</InputLabel>
                                      <Select
                                      required
                                        name="redHidraulica"
                                        id="redHidraulica"
                                        value={this.state.redHidraulica}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="Si">Si</MenuItem>
                                      <MenuItem value="No">No</MenuItem>
                                 
                                      </Select>
                                      <FormHelperText>{errors.redHidraulica}</FormHelperText>
                                  </FormControl>  

                                  <FormControl className="formcontrol-nuevocliente">
                                      <InputLabel>Otro</InputLabel>
                                      <Select
                                      required
                                  name="redOtros"
                                  id="redOtros"
                                  value={this.state.redOtros}
                                      onChange={this.handleChange}
                                      fullWidth
                                      >
                                      <MenuItem value="">
                                          <em></em>
                                      </MenuItem>
                                      <MenuItem value="Si">Si</MenuItem>
                                      <MenuItem value="No">No</MenuItem>
                                 
                                      </Select>
                                      <FormHelperText>{errors.redOtros}</FormHelperText>
                                  </FormControl>  

                                 
                </div>

                </div>

                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="text-title-accorion">Actividades Realizadas</h4>
                </AccordionSummary>
                <AccordionDetails>
                <TextField className="login-input-email"
                              
                              multiline
                              type="Text"
                              name="actividades"
                              id="actividades"
                              label="Actividades Realizadas"
                              rows={4}
                              value={this.state.actividades}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.actividades}
                              error={errors.actividades?true:false}
                          
                              />
                </AccordionDetails>
              </Accordion>


                    <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="text-title-accorion">Observaciones</h4>
                </AccordionSummary>
                <AccordionDetails>
                <TextField className="login-input-email"
                              
                              multiline
                              type="Text"
                              name="observaciones"
                              id="observaciones"
                              label="Observaciones"
                              rows={4}
                              value={this.state.observaciones}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.observaciones}
                              error={errors.observaciones?true:false}
                          
                              />
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="text-title-accorion">Materiales Usados</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div className="div-accordion-container">

                        <div className="div-mediciones-container">
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="nombrerepuesto"
                                    id="nombrerepuesto"
                                    label="Material"
                                    value={this.state.nombrerepuesto}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.nombrerepuesto}
                                    error={errors.nombrerepuesto?true:false}
                                
                                    />
                            </div>
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Number"
                                    name="cantidad"
                                    id="cantidad"
                                    label="Cantidad"
                                    value={this.state.cantidad}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.cantidad}
                                    error={errors.cantidad?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="costo"
                                    id="costo"
                                    label="Costo"
                                    value={this.state.costo}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.costo}
                                    error={errors.costo?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="subtotal"
                                    id="subtotal"
                                    label="subtotal"
                                    value={this.state.subtotal}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.subtotal}
                                    error={errors.subtotal?true:false}
                                    InputProps={{
                                    readOnly: true,
                                    }}
                                
                                    />
                            </div>
                        </div>


                        <div className="div-mediciones-container">
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="nombrerepuesto1"
                                    id="nombrerepuesto1"
                                    label="Material"
                                    value={this.state.nombrerepuesto1}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.nombrerepuesto1}
                                    error={errors.nombrerepuesto1?true:false}
                                
                                    />
                            </div>
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Number"
                                    name="cantidad1"
                                    id="cantidad1"
                                    label="Cantidad"
                                    value={this.state.cantidad1}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.cantidad1}
                                    error={errors.cantidad1?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="costo1"
                                    id="costo1"
                                    label="Costo"
                                    value={this.state.costo1}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.costo1}
                                    error={errors.costo1?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="subtotal1"
                                    id="subtotal1"
                                    label="subtotal"
                                    value={this.state.subtotal1}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.subtotal1}
                                    error={errors.subtotal1?true:false}
                                    InputProps={{
                                    readOnly: true,
                                    }}
                                
                                    />
                            </div>
                        </div>


                        <div className="div-mediciones-container">
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="nombrerepuesto2"
                                    id="nombrerepuesto2"
                                    label="Material"
                                    value={this.state.nombrerepuesto2}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.nombrerepuesto2}
                                    error={errors.nombrerepuesto2?true:false}
                                
                                    />
                            </div>
                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Number"
                                    name="cantidad2"
                                    id="cantidad2"
                                    label="Cantidad"
                                    value={this.state.cantidad2}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.cantidad2}
                                    error={errors.cantidad2?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="costo2"
                                    id="costo2"
                                    label="Costo"
                                    value={this.state.costo2}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.costo2}
                                    error={errors.costo2?true:false}
                                
                                    />
                            </div>

                            <div className="div-mediciones-item">
                            <TextField className="login-input-email"
                                
                                    type="Text"
                                    name="subtotal2"
                                    id="subtotal2"
                                    label="Subtotal"
                                    value={this.state.subtotal2}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.subtotal2}
                                    error={errors.subtotal2?true:false}
                                    InputProps={{
                                    readOnly: true,
                                    }}
                                
                                    />
                            </div>
                        </div>


                        <div>

                        <a>Total:  ${this.state.totalrepuesto}</a>

                        </div>





                        </div>

                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                
                <h4 className="text-title-accorion">Personal que recibe la actividad</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="div-accordion-container">
                  <TextField className="login-input-email"
                              required
                              type="Text"
                              name="firmarecibe"
                              id="firmarecibe"
                              label="Nombre de Quien Recibe La Actividad"
                              value={this.state.firmarecibe}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.firmarecibe}
                              error={errors.firmarecibe?true:false}
                          
                              />

        <TextField className="login-input-email"
                              required
                              type="Text"
                              name="cargorecibe"
                              id="cargorecibe"
                              label="Cargo de Quien Recibe la Actividad"
                              value={this.state.cargorecibe}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.cargorecibe}
                              error={errors.cargorecibe?true:false}
                          
                              />
                  </div>
                </AccordionDetails>
              </Accordion>
                             
                      

                    

                                 




                      </form>
                      </DialogContent>
                 
                </div>
               
                )}
            

           
            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Realizar Programación" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            

            
          </DialogActions>

            </Dialog>

            </Fragment>
        )
    }
}


//progMto
MtoCorrInfra.propTypes={
    postCorrectivoInfra:PropTypes.func.isRequired,
    getMtosCorrectivosInfra:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,   
  };
  const mapStateToProps=(state)=>({
    UI:state.UI,
    equipo: state.data.equipo.credentials,
    user:state.user,
  });
  
  export default connect (mapStateToProps,{postCorrectivoInfra,getMtosCorrectivosInfra,clearErrors}) (MtoCorrInfra)
  
  
  

