import React, { Component } from 'react';
import './SolicitudesClienteAprobadas.css';
import MyButton from '../../../utils/MyButton';
import {FaArrowCircleLeft} from "react-icons/fa";
import {Link} from 'react-router-dom';
import { forwardRef } from 'react';
import { FaArrowLeft,FaPlus } from "react-icons/fa";
import MaterialTable from 'material-table';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import {getSolicitudesClienteAprobadas,clearErrors} from '../../../redux/actions/dataasistencialactions';


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';

export class SolicitudesClienteAprobadas extends Component {

    constructor(){
        super();
        this.state={
            
            errors:{},
            open:false,
            opendelete:false,
            cotizacionid:'',
            selectedFile: null,
        };
        
    };

    componentDidMount(){
        this.props.getSolicitudesClienteAprobadas(this.props.match.params.clienteId);
       }
  render() {
    const {solicitudes,loadingdataasistencial}= this.props.dataAsistencial; 
    const {errors}=this.state;
    const { UI:{loading}}=this.props; 
 
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

      const columns=[
        {title:'Titulo',field:'titulo'},
        {title:'Area',field:'area'},
        {title:'Servicio',field:'servicio'}
       
    ]


    
 let mapeosolicitudes=!loadingdataasistencial ?(
     <div>
<MaterialTable
        columns={columns}
        data={solicitudes}
        title='Solicitudes de Compras Aprobadas'
        icons={tableIcons}

        actions={[
            
          rowData => ({
            icon: tableIcons.Add,
            tooltip: 'Ver Solicitud',
            onClick: (event, rowData) => window.location.href=`/dashboard/perfilcliente/${this.props.match.params.clienteId}/solicitud/${rowData.solicitudid}`,
            
          })
         
          ]}

        >

        </MaterialTable>

 
     </div>
       

    ):(  <div className="div_cargando">
        <CircularProgress size={50}color="primary" className="login-progress" />
        </div>);


    return (
        <div>
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Mis Solicitudes de Compras Aprobadas</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>


        <div className='table_container'>

            {mapeosolicitudes}

        </div>
        </div>
    )
  }
}

SolicitudesClienteAprobadas.propTypes = {
    
    getSolicitudesClienteAprobadas: PropTypes.func.isRequired,
    dataAsistencial:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    
  };

const mapActionsToProps={getSolicitudesClienteAprobadas,clearErrors};

const mapStateToProps = (state)=>({
    dataAsistencial:state.dataAsistencial,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(SolicitudesClienteAprobadas);

