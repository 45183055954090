import React, { Component } from 'react';
import './CategoriasMarket.css';


import {Link } from 'react-router-dom';


import {FaLaptopMedical,FaHospital,FaTools,FaSyringe,FaCogs,FaLaptopCode,FaFileInvoiceDollar,FaFileSignature} from "react-icons/fa";

import {FaArrowCircleLeft} from "react-icons/fa";

import { forwardRef } from 'react';
import MyButton from '../../../utils/MyButton';






export class CategoriasMarket extends Component {
  render() {
    return (
        <div className="market_container">
        <div className="titulomarket_section">
        <div className="titulo_market">
        <h1 className="text-clientes">Categorias de Venta</h1>
        <hr className="clientes_separator"></hr>  

        </div>

        <div className="divmarket_boton">
      
        <Link to={`/dashboard/homemarketempresa`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>


        </div>
        </div>

        <div className="market_categorias_container">

        <div className="subcontainer-market">

        <div className="dashing-div-card">
                <Link to='/homemarketempresa/categoriasmarket/cotipublicadas/equiposmedicos' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                   Equipos <br/>Médicos
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaLaptopMedical color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

                <div className="dashing-div-card">
                <Link to='/homemarketempresa/categoriasmarket/cotipublicadas/serviciosmtocal' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Mantenimiento y<br/>Calibración
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaTools color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

                <div className="dashing-div-card">
                <Link to='/homemarketempresa/categoriasmarket/cotipublicadas/mobiliario' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Mobiliario<br/>Hospitalario
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaHospital color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

        
        
        
        </div>

        <div className="subcontainer-market">

        <div className="dashing-div-card">
                <Link to='/homemarketempresa/categoriasmarket/cotipublicadas/insumosdispositivos' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Insumos/Dispositivos<br/>Médicos
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaSyringe color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

                <div className="dashing-div-card">
                <Link to='/homemarketempresa/categoriasmarket/cotipublicadas/computo' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Equipos de<br/>Cómputo
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaLaptopCode color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>


                <div className="dashing-div-card">
                <Link to='/homemarketempresa/categoriasmarket/cotipublicadas/repuestos' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Repuestos<br/>y Refracciones
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaCogs color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>




        </div>

      </div>
      </div>
    
    )
  }
}

export default CategoriasMarket