import React, { Component , Fragment } from 'react';
import './AddItem.css';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus } from "react-icons/fa";

import {postItem,clearErrors} from '../../../redux/actions/datasalesactions';

export class AddItem extends Component {
    constructor(){
        super();
        this.state={
            nombre:'',
            marca:'',
            cantidad:'',
            descripcion:'',
            estado:'',
            precio:'',
            open:false,
            errors:{}
        };
        
    };
    static getDerivedStateFromProps(props, state) {
      return props.UI.errors ? {errors: props.UI.errors} : null;
      
  }

  handleOpen=()=>{
    this.setState({open:true});
    };

 handleClose=()=>{
        this.props.clearErrors();
        this.setState({ nombre:'',
        marca:'',
        cantidad:'',
        descripcion:'',
        estado:'',
        precio:'',
        open:false,
        errors:{}});
        };
    
 handleChange=(event)=>{
 this.setState({[event.target.name]:event.target.value});
 
 };

 
handleSubmit=(event)=>{
    event.preventDefault();
    const newItem={
    nombre:this.state.nombre,
    marca:this.state.marca,
    descripcion:this.state.descripcion,
    cantidad:this.state.cantidad         
  };

  console.log(newItem);
  console.log(this.props.cotizacionId);
  this.props.postItem(newItem,this.props.cotizacionId);
  this.handleClose();

  // this.props.progMto(newProgramacion,this.props.clienteId,this.props.equipoId); 
    //Aqui se postea al cliente
 

    }
  render() {

    const { UI:{loading}}=this.props; 
        const {errors}=this.state;


    return (
      <Fragment>
            <MyButton tip="Añadir un Item" onClick={this.handleOpen}>
            <FaPlus color="#03178C" className="icon-crearcliente"/>
             </MyButton>

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Añadir Item</DialogTitle>

            <DialogContent>
            <form>
            <TextField className="login-input-email"
                  required
                  type="text"
                  name="nombre"
                  id="nombre"
                  label="Nombre del Item"
                  value={this.state.nombre}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.nombre}
                  error={errors.nombre?true:false}                 
                  />
                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="marca"
                  id="marca"
                  label="Marca"
                  value={this.state.marca}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.marca}
                  error={errors.marca?true:false}                 
                  />
                  <TextField className="login-input-email"
                  required
                  type="number"
                  name="cantidad"
                  id="cantidad"
                  label="Cantidad"
                  value={this.state.cantidad}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.cantidad}
                  error={errors.cantidad?true:false}                 
                  />
<TextField className="login-input-email"
                  required
                  type="text"
                  name="descripcion"
                  id="descripcion"
                  label="Descripcion/Observaciones"
                  value={this.state.descripcion}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.descripcion}
                  error={errors.descripcion?true:false} 
                  multiline
                  rows={3}                
                  />
                   
 
       
            </form>
            </DialogContent>

           
            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Añadir Item" onClick={this.handleSubmit}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
            
            

            
          </DialogActions>

            </Dialog>

            </Fragment>
    )
  }
}

AddItem.propTypes={
    postItem:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired
  };
  const mapStateToProps=(state)=>({
    UI:state.UI,
    cotizacion: state.dataSales.cotizacion.credentials
  });
  
  export default connect (mapStateToProps,{postItem,clearErrors}) (AddItem)
