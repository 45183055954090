import React, { Component } from 'react'

import './SolicitudCorrectivoLista.css'; 
import MyButton from '../../../utils/MyButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import {getSolicitudesCorrectivosCliente,clearErrors} from '../../../redux/actions/dataactions';


import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FaArrowLeft,FaPlus } from "react-icons/fa";
export class SolicitudCorrectivoLista extends Component {
    constructor(){
        super();
        this.state={
            
            errors:{},
            open:false,
            opendelete:false,
            idsolicitud:'',
            fechasolicitud:'',
            horasolicitud:'',
            numsolicitud:'',
            fallareportada:'',
            obsolescencia:false,
            malaoperacion:false,
            malainst:false,
            accesorios:false,
            caida:false,
            desgaste:false,
            corto:false,
            sinfalla:false,
            paciente:false,
            desconocido:false,
            otro:'',
            descactividad:'',
            descfalla:'',
            observaciones:'',
            apto:'',
            retirado:'',
            nombrerepuesto:'',
            nombrerepuesto1:'',
            nombrerepuesto2:'',
            cantidad:0,
            cantidad1:0,
            cantidad2:0,
            costo:0,
            costo1:0,
            costo2:0,
            subtotal:0,
            subtotal1:0,
            subtotal2:0,
            totalrepuesto:0,
            horainicio:'',
            horafinal:'',
            firmarecibe:'',
            cargorecibe:'',
            fecha:'',
            solicitud:{},
            tiemporespuesta:'',
            tiemporespuestahora:''
        };
        
    };

    componentDidMount(){
        this.props.getSolicitudesCorrectivosCliente(this.props.match.params.clienteId);
       }

       handleOpen=(rawdata)=>{
        const rawdatastate=rawdata; 
        console.log(rawdata);
        console.log(rawdatastate);
       
        let fecharaw=new Date(rawdata.datecreation); 
        let fecha=fecharaw.toLocaleDateString(); 
        let hora=fecharaw.toLocaleTimeString();
        
        this.setState({open:true,solicitud:rawdatastate,solicitudid:rawdatastate.solicitudid,fechasolicitud:fecha,fallareportada:rawdatastate.fallareportada,horasolicitud:hora});
        console.log(this.state);
        };

        handleChange=(event)=>{
            this.setState({ [event.target.name]:event.target.value});
            
       };

       handleClose=()=>{
        // this.props.clearErrors();
         this.setState({open:false,errors:{}});
         };

         handlePostMto=()=>{
            const newMtoCorrectivo={
              fechasolicitud:this.state.fechasolicitud,
              horasolicitud:this.state.horasolicitud,
              numsolicitud:this.state.numsolicitud,
              fallareportada:this.state.fallareportada,
              obsolescencia:this.state.obsolescencia,
              malaoperacion:this.state.malaoperacion,
              malainst:this.state.malainst,
              accesorios:this.state.accesorios,
              caida:this.state.caida,
              desgaste:this.state.desgaste,
              corto:this.state.corto,
              sinfalla:this.state.sinfalla,
              paciente:this.state.paciente,
              desconocido:this.state.desconocido,
              otro:this.state.otro,
              descactividad:this.state.descactividad,
              descfalla:this.state.descfalla,
              apto:this.state.apto,
              retirado:this.state.retirado,
              nombrerepuesto:this.state.nombrerepuesto,
              nombrerepuesto1:this.state.nombrerepuesto1,
              nombrerepuesto2:this.state.nombrerepuesto2,
              cantidad:this.state.cantidad,
              cantidad1:this.state.cantidad1,
              cantidad2:this.state.cantidad2,
              costo:this.state.costo,
              costo1:this.state.costo1,
              costo2:this.state.costo2,
              subtotal:this.state.subtotal,
              subtotal1:this.state.subtotal1,
              subtotal2:this.state.subtotal2, 
              totalrepuesto:this.state.totalrepuesto,
              observaciones:this.state.observaciones,
             horainicio:this.state.horainicio,
             horafinal:this.state.horafinal,
             firmarecibe:this.state.firmarecibe,
             cargorecibe:this.state.cargorecibe,
             fecha:this.state.fecha,
             solicitud:this.state.solicitud
                
         };

       

        let segundosatiempo=(s)=>{

            const addZ=(n)=>{
                return (n<10? '0':'') + n;       
                
            }
            var ms = s % 1000;
	         s = (s - ms) / 1000;
	        var secs = s % 60;
	         s = (s - secs) / 60;
	         var mins = s % 60;
	         var hrs = (s - mins) / 60;

	  return addZ(hrs) + ':' + addZ(mins) + ':' + addZ(secs)+ '.' + addZ(ms);

        }
        
           let tiempoinicial=newMtoCorrectivo.solicitud.fechasolicitudmilis;
           let tiempofinal=Date.now();
           let tiemporespuesta= tiempofinal-tiempoinicial; 

           let conversion = segundosatiempo(tiemporespuesta);

           newMtoCorrectivo.tiemporespuesta=tiemporespuesta;
           newMtoCorrectivo.tiemporespuestahora=conversion 

           console.log(newMtoCorrectivo)
        
           console.log(newMtoCorrectivo.solicitud.equipoid); 
           console.log(newMtoCorrectivo.solicitud.idprestador);
          this.props.postMtoCorrectivoConSolicitud(newMtoCorrectivo,newMtoCorrectivo.solicitud.equipoid,newMtoCorrectivo.solicitud.idprestador,this.state.solicitudid); 
       
           
     
         }

  render() {
    
    const {solicitudescorr,loadingsolcorr}= this.props.data; 
    const {errors}=this.state;
    const { UI:{loading}}=this.props; 
    this.state.subtotal=this.state.cantidad*this.state.costo;
    this.state.subtotal1=this.state.cantidad1*this.state.costo1;
    this.state.subtotal2=this.state.cantidad2*this.state.costo2;
    this.state.totalrepuesto=this.state.subtotal+this.state.subtotal1+this.state.subtotal2; 
    
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />),
        MiIcon2:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
      };
    
    const columns=[
        {title:'Fecha',field:'datecreation'},
        {title:'Solicitante',field:'nombresolicita'},
        {title:'Falla',field:'fallareportada'},
        {title:'Estado',field:'estado'}
       
    ]

    let mapeosolicitudescorrectivos=!loadingsolcorr ?(
        <div>
   <MaterialTable
           columns={columns}
           data={solicitudescorr}
           title={
            <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'bold' }}>Solicitudes de Mantenimiento Correctivo</h2>
          }
           icons={tableIcons}

           options={{
            cellStyle: {
                fontSize: 11,
                fontWeight:500,
                color:'#696B6D'
              
             
              },
              headerStyle: {
                fontSize: 12,
                fontWeight: 'bolder',
                color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
              }
          
          }}
   
           actions={[
            rowData => ({
                icon: () =>  <Link to={`/dashboard/inventarioequiposasistencial/hojadevidaequipo/${rowData.equipoid}`} style={{ color: '#03178C' }} ><ChevronRight  /></Link>,
                tooltip: 'Ver Perfil ',
                onClick: (rowData)
              }),
           
             
           
          ]}

         
         
         
   
           >
   
           </MaterialTable>

    
        </div>
          

       ):(  <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
           </div>);



    return (
        <div>
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Solicitudes de Mantenimiento Correctivo</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <Link to={`/dashboard/equiposasistencial`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>

        <div className='div-tablacorr'>
            {mapeosolicitudescorrectivos}
        </div>

      
    


        </div>
    )
  }
}

SolicitudCorrectivoLista.propTypes = {
    
    getSolicitudesCorrectivosCliente: PropTypes.func.isRequired,
   
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
  };

const mapActionsToProps={getSolicitudesCorrectivosCliente,clearErrors};

const mapStateToProps = (state)=>({
    data:state.data,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(SolicitudCorrectivoLista);
