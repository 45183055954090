import React, { Component } from 'react'
import './ClientesTecnico.css'; 
import ListaClientesTecnico from './ListaClientesTecnico';
//import NuevoCliente from './NuevoCliente'; 
//import ListaClientes from './ListaClientes'; 

export class ClientesTecnico extends Component {
   
    

    render() {
       
   
        return (
            <div className="clientes_container">
                <div className="titulo_section">
                <div className="titulo_clientes">
                <h1 className="text-clientes"> Mis Prestadores</h1>
                <hr className="clientes_separator"></hr>            
                
                </div>

                <div className="div_boton">
            
                </div>
               
                </div>
                
                

                <div className="listaclientes_container">
             <ListaClientesTecnico />
                </div>

               
                </div>
                
           
               
               
               
            
               
                
               
                
           
        )
    }
}

export default ClientesTecnico
