import React from 'react';
import PropTypes from 'prop-types';

import './LayoutD.css'; 


import Footer from './Footer/Footer'
import Sidebar from './Sidebar/Sidebar'
import Topbar from './Topbar/Topbar'

const LayoutD= props => {
    
    const { children } = props;
  
    
    
  

    return (
        <div>
            <Topbar/>
            <div className="layout-container">
            <Sidebar />  

                <div className="children-container">
                   {children}
                   <Footer />
               </div>
            </div>
            
        </div>
    )
}


LayoutD.propTypes = {
    children: PropTypes.node
  };
  
export default LayoutD;
