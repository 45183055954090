import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEquipo, getCliente } from '../../../redux/actions/dataactions';
import { PDFViewer } from '@react-pdf/renderer';
import './InventarioPDF.css';
import HojaDeVidaDocuPDF from './HojaDeVidaDocuPDF';
import { useHistory } from 'react-router-dom';

function HojaDeVidaPDF(props) {
  const [verWeb, setVerWeb] = useState(false);
  const [verPDF, setVerPDF] = useState(false);

  useEffect(() => {
    // get equipo
    props.getEquipo(props.match.params.equipoId);
    // get cliente
    props.getCliente(props.match.params.clienteId);
  }, []);

  const handleOpen = () => {
    setVerWeb(!verWeb);
    setVerPDF(false);
  };

  const handleOpenPDF = () => {
    setVerPDF(!verPDF);
    setVerWeb(false);
  };

  const { equipo, cliente, loading } = props.data;
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack(); // Esto llevará al usuario una página hacia atrás en el historial de navegación.
  };
  return (
    <div className="div-inventaripdfgeneral">
      <div className="div-inventaripdftitulo">
        <div className="div_boton">
            <MyButton tip="Volver" onClick={handleGoBack}>
          <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
        </MyButton>
        </div>

        <ButtonGroup variant="text" aria-label="text button group" color="primary">
          <Button onClick={handleOpenPDF}>{verPDF ? 'Ocultar PDF' : 'Ver PDF'}</Button>
        </ButtonGroup>
      </div>

      <div className="div-inventariopdfcontent">
        {equipo && cliente ? (
          <>
            {verPDF ? (
              <PDFViewer style={{ width: '100%', height: '90vh' }}>
                <HojaDeVidaDocuPDF equipo={equipo} cliente={cliente} />
              </PDFViewer>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

HojaDeVidaPDF.propTypes = {
  getEquipo: PropTypes.func.isRequired,
  getCliente: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, { getEquipo, getCliente })(HojaDeVidaPDF);
