import React, { Component } from 'react';
import './PerfilInfraAsistencial.css';


import {Link } from 'react-router-dom';


import {FaLaptopMedical,FaHospital,FaTools,FaSyringe,FaCogs,FaLaptopCode,FaFileInvoiceDollar,FaFileSignature, FaUserNurse, FaCalendarWeek, FaCalendar, FaPhoneAlt, FaRuler, FaWrench, FaShoppingCart, FaChartBar} from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {getCliente} from '../../../redux/actions/dataactions';

import {FaArrowCircleLeft} from "react-icons/fa";

import { forwardRef } from 'react';
import MyButton from '../../../utils/MyButton';
import { CircularProgress } from '@material-ui/core';
import TablaAreasAsistencial from './TablaAreasAsistencial';


export class PerfilInfraAsistencial extends Component {
    componentDidMount(){
       this.props.getCliente(this.props.user.credentials.idprestador);
      }


    





  render() {
    const {cliente,loading}= this.props.data;         
    const idcliente=this.props.user.credentials.idprestador; 

    let cargarPerfil= !loading ? (
        <div className="card-cliente">
            <div className="div-informacion">
            <div className="div-imagencliente">
              <img src={cliente.imagenurl} alt="equipo" className="img-cliente"/>
            </div>

            <div className="div-flex-start">

            
            <div className="div-infocontainer">
            <div className="div-titulocliente">
            <h3 className="text-titulo-cliente">{cliente.nombre}</h3>
            <h5 className="text-ciudad-cliente">{cliente.ciudad}</h5>
            </div>
            <div className="div-cliente-info">
            <p className="cliente-text">CC/NIT: {cliente.ccnit}</p>
            <p className="cliente-text">Dirección: {cliente.direccion}</p>
            <p className="cliente-text">Email: {cliente.email}</p>
            <p className="cliente-text">Teléfono: {cliente.telefono}</p>
            <p className="cliente-text">Código REPS: {cliente.codigoreps}</p>
            <p className="cliente-text">Sede: {cliente.sede}</p>
            </div>
            </div>

            <div className="acciones-cliente-perfil">
                
                <div className="div_boton">
              
               </div>

             

            </div>
            </div>
            </div>
     
        </div>
        )
        
        :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);

    return (
        <div className="market_container">
        <div className="titulomarket_section">
        <div className="titulo_market">
        <h1 className="text-clientes">Mis Equipos</h1>
        <hr className="clientes_separator"></hr>  

        </div>

    
        </div>

        {cargarPerfil}

        <div className='div-container-tabla-asis-infra'>
        <TablaAreasAsistencial clienteId={this.props.user.credentials.idprestador}></TablaAreasAsistencial>
            

</div>

        
      </div>
    )
  }
}

PerfilInfraAsistencial.propTypes = {
    getCliente: PropTypes.func.isRequired,
    data:PropTypes.object.isRequired
  };

const mapActionsToProps={getCliente};

const mapStateToProps = (state)=>({
    data:state.data,
    user:state.user
})



export default connect(mapStateToProps,mapActionsToProps)(PerfilInfraAsistencial);
