import React from 'react';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import './MtoCorrInfraDocuPDF.css';
import alphalogo from '../../../utils/alphahorizontal.png'
import membretemto from '../../../utils/mtocorrinfratemplate.png'


const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    viewcard:{
        display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "10px",
                 marginBottom: "5px",
                
    },
    text:{
        fontSize:"9",
        color:"#03178c",
        fontWeight:"bold"
    },
    textdos:{
        fontSize:"7",
        color:"#03178c",
        fontWeight:"light"
    },
    itemelement:{
        width: "15%",
        marginRight:"1%",
        alignItems:"start"
    },
    vieWgeneral:{
        padding:"10px",
        minWidth: '100%',
        minHeight: '100%',
        height: '100%',
        width: '100%',
    },
  
    imagenlogo:{
        width:"100px",
        height:"100px"
    },
    
    pageGeneral:{
        padding:"2px",
        marginLeft:"10px",
        marginTop:"10px"
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
      },

      imagencliente:{
        width:"80px",
        height:"80px",
        borderColor:"#e2e2e2",
        borderRadius:"10%",
        objectFit:"cover",
        borderWidth:"3px"
      },
      viewmapeo:{
        minWidth: '100%',
        minHeight: '100%',
        height: '100%',
        width: '100%',
        display:'flex',
        flexDirection:'column'
      },
      cardcliente:{
        display:'flex',
        flexDirection:'row',
        marginTop:'93px',
        marginLeft:'25px',
        alignItems:'center',
       
        minHeight:'100px',
        maxHeight:'110px',
        minWidth:'500px',
        maxWidth:'500px'
      }
      ,

      cardsolicitud:{
        display:'flex',
        flexDirection:'column',
        marginTop:'20px',
        marginLeft:'25px',
        alignItems:'center',
       
        minHeight:'54px',
        maxHeight:'55px',
        minWidth:'500px',
        maxWidth:'500px'
      }
      ,
      infosolicitud:{
        display:'flex',
        width:'100%',
        flexDirection:'row',
        alignItems:'flex-start'

      }
      ,
      fechasolicitud:{
        display:'flex',
        width:'100%',
        flexDirection:'row',
        justifyContent:'space-between'

      }
      ,
      cardcampos:{
        display:'flex',
        flexDirection:'row',
        marginTop:'20px',
        marginLeft:'25px',
        alignItems:'center',
      
        minHeight:'54px',
        maxHeight:'55px',
        minWidth:'500px',
        maxWidth:'500px'
      }
      ,

      cardactividadesmto:{
        display:'flex',
        flexDirection:'row',
        marginTop:'20px',
        marginLeft:'25px',
        alignItems:'flex-start',
       
        minHeight:'70px',
        maxHeight:'72px',
        minWidth:'500px',
        maxWidth:'500px'
      }
      ,
      cardmateriales:{
        display:'flex',
        flexDirection:'column',
        marginTop:'25px',
        marginLeft:'25px',
        alignItems:'center',
        
        minHeight:'77px',
        maxHeight:'77px',
        minWidth:'500px',
        maxWidth:'500px'
      }
      ,

      cardobservaciones:{
        display:'flex',
        flexDirection:'column',
        marginTop:'20px',
        marginLeft:'25px',
        alignItems:'flex-start',
       
        minHeight:'70px',
        maxHeight:'70px',
        minWidth:'500px',
        maxWidth:'500px'
      }
      ,

      cardpersonal:{
        display:'flex',
        flexDirection:'column',
        marginTop:'20px',
        marginLeft:'25px',
        alignItems:'flex-start',
       
        minHeight:'65px',
        maxHeight:'65px',
        minWidth:'500px',
        maxWidth:'500px'
      }
      ,
      infocliente:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'75%',
        
      }
      ,
      infocampos:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'100%',
        alignItems:'flex-start',
       
        height:'100%'
        
      },
      viewText:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        marginTop:'10px'
      },
      tituloinfo:{
        fontSize:'11',
        marginLeft:'5px',
        color:'#005695'

      },
      textinfo:{
        fontSize:'10',
        color:'#0067b2'
      },
      viewimgcliente:{
        width:'auto'
      },
      viewcardequipo:{
        display:'flex',
        flexDirection:'column',
        marginTop:'20px',
        width:'90%',
        marginLeft:'15px',
        backgroundColor:'#00d7ff',
      },
      equipoinfo:{
        display:'flex',
        flexDirection:'row'
      },
      imagenequipo:{
        width:"80px",
        height:"80px",
        borderColor:"#e2e2e2",
        borderRadius:"10%",
        objectFit:"cover",
        borderWidth:"2px"
      },
      viewimagenquipo:{
        marginLeft:'10px',
      },
      viewinfoequipo:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'80%',
        marginLeft:'10px'
      },
      viewcardhistorial:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        marginTop:'28px',
        width:'90%',
        marginLeft:'15px'
      },
      viewcardtecnico:{
        display:'flex',
        flexDirection:'column',
        marginTop:'30px',
        width:'90%',
        marginLeft:'15px'
      },
      viewpruebas:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      },
      viewcardobservaciones:{
        display:'flex',
        flexDirection:'column',
        marginTop:'40px',
        width:'90%',
        marginLeft:'15px'
      },
      viewfecha:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      }
      ,
      viewcardpersonal:{
        display:'flex',
        flexDirection:'column',
        marginTop:'40px',
        width:'90%',
        marginLeft:'15px'
      },
      viewpersonal:{
        display:'flex',
        flexDirection:'row',
        alignContent:'space-between',
        justifyContent:'space-between',
        width:'100%'
      },
      viewTextObservaciones:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        marginTop:'10px',
        marginLeft:'3px'

      },
      textobs:{
        fontSize:'10',
        color:'#0067b2'
      },
      viewTextRealizado:{
        marginTop:'2px',
        display:'flex',
        flexDirection:'row'
      },
      viewTextActividades:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        marginTop:'5px',
        marginBottom:'5px'
      },
      textact:{
        fontWeight:'800',
        fontSize:'12',
        color:'#0067b2'
      }

  });
  
  


const MtoCorrInfraDocuPDF = ({ area,cliente,mto }) => {

  const imagenUrl = cliente.imagenurl ? cliente.imagenurl : 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';
  
  let mapeoequipo=
    (mto&&cliente?(
       
        <View  style={styles.viewmapeo}>
            
            <View style={styles.cardcliente}>
            <View style={styles.viewimgcliente}>
            <Image src={imagenUrl}style={styles.imagencliente} />
            </View>
            <View style={styles.infocliente}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>PRESTADOR: </Text>
                <Text style={styles.textinfo}>{cliente.nombre}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>AREA: </Text>
                <Text style={styles.textinfo}>{area.nombre}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SERVICIO: </Text>
                <Text style={styles.textinfo}>{area.servicio}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>UBICACION: </Text>
                <Text style={styles.textinfo}>{area.ubicacion}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>PISO: </Text>
                <Text style={styles.textinfo}>{area.piso}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CIUDAD: </Text>
                <Text style={styles.textinfo}>{cliente.ciudad}</Text>
                </View>

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>CC/NIT: </Text>
                <Text style={styles.textinfo}>{cliente.ccnit}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DIRECCIÓN: </Text>
                <Text style={styles.textinfo}>{cliente.direccion}</Text>
                </View>
             

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>TELÉFONO: </Text>
                <Text style={styles.textinfo}>{cliente.telefono}</Text>
                </View>

               

                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>SEDE: </Text>
                <Text style={styles.textinfo}>{cliente.sede}</Text>
                </View>
               
               
                

            </View>
            </View>

            <View style={styles.cardsolicitud}>

                <View style={styles.fechasolicitud}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>FECHA SOLICITUD: </Text>
                <Text style={styles.textinfo}>{mto.solicitud.fechasolicitud}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>HORA SOLICITUD: </Text>
                <Text style={styles.textinfo}>{mto.solicitud.horasolicitud}</Text>
                </View>

                </View>
                <View style={styles.infosolicitud}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>DESCRIPCIÓN: </Text>
                <Text style={styles.textinfo}>{mto.solicitud.infosolicitud}</Text>
                </View>
                </View>
                </View>

                <View style={styles.cardcampos}>
                <View style={styles.infocampos}>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>RED ELECTRICA: </Text>
                <Text style={styles.textinfo}>{mto.redElectrica}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>RED HIDRAULICA: </Text>
                <Text style={styles.textinfo}>{mto.redHidraulica}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>RED DE GASES: </Text>
                <Text style={styles.textinfo}>{mto.redGases}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>RED ELECTRICA: </Text>
                <Text style={styles.textinfo}>{mto.redElectrica}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>INSTALACIONES FISICAS: </Text>
                <Text style={styles.textinfo}>{mto.redInstalaciones}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>RED COMUNICACIONES: </Text>
                <Text style={styles.textinfo}>{mto.redComunicaciones}</Text>
                </View>
                <View style={styles.viewText}>
                <Text style={styles.tituloinfo}>OTRA: </Text>
                <Text style={styles.textinfo}>{mto.redOtros}</Text>
                </View>


                </View>
                </View>

                <View style={styles.cardactividadesmto}>
                <Text style={styles.textobs}>{mto.actividades?(mto.actividades):('Ninguna')}</Text>
                </View>
       

            <View style={styles.cardmateriales}>
            <View style={styles.viewpersonal}>
            <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>MATERIAL: </Text>
                    <Text style={styles.textinfo}>{mto.nombrerepuesto?(mto.nombrerepuesto):('No Registra')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>COSTO: </Text>
                    <Text style={styles.textinfo}>{mto.costo?(mto.costo):('0')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>CANTIDAD: </Text>
                    <Text style={styles.textinfo}>{mto.cantidad?(mto.cantidad):('0')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>SUBTOTAL: </Text>
                    <Text style={styles.textinfo}>{mto.subtotal?(mto.subtotal):('0')}</Text>
                    </View>

                </View>
                <View style={styles.viewpersonal}>
            <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>MATERIAL: </Text>
                    <Text style={styles.textinfo}>{mto.nombrerepuesto1?(mto.nombrerepuesto1):('No Registra')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>COSTO: </Text>
                    <Text style={styles.textinfo}>{mto.costo1?(mto.costo1):('0')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>CANTIDAD: </Text>
                    <Text style={styles.textinfo}>{mto.cantidad1?(mto.cantidad1):('0')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>SUBTOTAL: </Text>
                    <Text style={styles.textinfo}>{mto.subtotal1?(mto.subtotal1):('0')}</Text>
                    </View>

                </View>
                <View style={styles.viewpersonal}>
            <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>MATERIAL: </Text>
                    <Text style={styles.textinfo}>{mto.nombrerepuesto2?(mto.nombrerepuesto2):('No Registra')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>COSTO: </Text>
                    <Text style={styles.textinfo}>{mto.costo2?(mto.costo2):('0')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>CANTIDAD: </Text>
                    <Text style={styles.textinfo}>{mto.cantidad2?(mto.cantidad2):('0')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>SUBTOTAL: </Text>
                    <Text style={styles.textinfo}>{mto.subtotal2?(mto.subtotal2):('0')}</Text>
                    </View>

                </View>

            </View>

            <View style={styles.cardobservaciones}>
            <Text style={styles.textobs}>{mto.observaciones?(mto.observaciones):('Ninguna')}</Text>
            </View>

            <View style={styles.cardpersonal}>

            <View style={styles.viewpersonal}>
            <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>FECHA INICIO: </Text>
                    <Text style={styles.textinfo}>{mto.fechainicio?(mto.fechainicio):('Ninguna')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>FECHA FINAL: </Text>
                    <Text style={styles.textinfo}>{mto.fechafinal?(mto.fechafinal):('Ninguna')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>HORA INICIO: </Text>
                    <Text style={styles.textinfo}>{mto.horainicio?(mto.horainicio):('Ninguna')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>HORA FINAL: </Text>
                    <Text style={styles.textinfo}>{mto.horafinal?(mto.horafinal):('Ninguna')}</Text>
                    </View>

                </View>

                 <View style={styles.viewpersonal}>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>RECIBIDO POR: </Text>
                    <Text style={styles.textinfo}>{mto.firmarecibe?(mto.firmarecibe):('Ninguna')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>CARGO: </Text>
                    <Text style={styles.textinfo}>{mto.cargorecibe?(mto.cargorecibe):('Ninguna')}</Text>
                    </View>
                    <View style={styles.viewText}>
                    <Text style={styles.tituloinfo}>APROBÓ: </Text>
                    <Text style={styles.textinfo}>{mto.aprobado?('Si'):('No')}</Text>
                    </View>
                 

                </View>
                
                <View style={styles.viewTextRealizado}>
                <Text style={styles.tituloinfo}>REALIZADO POR: @</Text>
                <Text style={styles.textinfo}>{mto.handleuser?(mto.handleuser):('Ninguna')}</Text>
                </View>
            </View>
            

      

           
            
            
        </View>
       
      
    ):(<View>
            <Text>no hay nada</Text>
    </View>)
    )
  
  
    return (

        <Document>
             <Page size="a4" style={styles.pageGeneral}>
            
            <View style={styles.vieWgeneral}>
            <Image src={membretemto} style={styles.pageBackground} />
            
         
               
               {mapeoequipo}
            </View>
            </Page>
         </Document>
        
        
        )
}

export default MtoCorrInfraDocuPDF