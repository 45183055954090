import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft, FaLightbulb } from "react-icons/fa";

import { ingenieriaGemini } from '../../../redux/actions/dataactions';

import './RecomendacionesGemini.css'

export class RecomendacionesGemini extends Component {

  constructor() {
    super();
    this.state = {
      nombre: '',
      marca: '',
      modelo: '',
      errors: {},
      aprobado: {},
      open: false
    };

  };
  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (props.UI.errors) {
      newState.errors = props.UI.errors;
    }

    if (props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }

  componentDidMount() {
    const { equipo } = this.props;
    this.setState({
      nombre: equipo.nombre ? equipo.nombre : '',
      marca: equipo.marca ? equipo.marca : '',
      modelo: equipo.modelo ? equipo.modelo : '',
    });
  }

  handleOpen = () => {
    this.setState({
      open: true, errors: {},
      aprobado: {}
    });
    const dataequipo = {
      nombre: this.state.nombre,
      marca: this.state.marca,
      modelo: this.state.modelo
    }
    console.log(this.props.tipouser)
    console.log(dataequipo);
    this.props.ingenieriaGemini(dataequipo);
  };

  handleClose = () => {
    this.setState({
      open: false, errors: {},
      aprobado: {}
    });
    console.log(this.props.recomendacionesIa)
  };





  render() {
    const { UI: { loading } } = this.props;
    const { errors } = this.state;
    const { recomendacionIa } = this.props;

    console.log(this.props.tipouser)
    return (
      <div>
        <Fragment>
          <MyButton
            tip="Recomendaciones de mantenimiento"
            onClick={this.handleOpen}
          >
            <FaLightbulb color="#03178C" className="icon-crearcliente" />
          </MyButton>


          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              <h2 className='title-recomendaciones'>Recomendaciones de mantenimiento del equipo</h2>
            </DialogTitle>

            {loading ? (
              <CircularProgress size={30} color="primary" className="login-progress-recomendation" />
            ) : (
              <div>
                <DialogContent>
                  <div className='container-recomendaciones'>
                    {recomendacionIa !== undefined ? (

                      recomendacionIa.map((recomendacion, index) => (
                        <div key={index}>
                          <h3 className='text-titulo-cliente'>{recomendacion.actividad}</h3>
                          <h4 className='recomendacion-detalle'> Periodicidad: {recomendacion.periodicidad}</h4>
                          <p className='recomendacion-text'>{recomendacion.descripcion}</p>
                        </div>
                      ))
                    ) : (<></>)}
                  </div>
                </DialogContent>
              </div>
            )
            }

            <DialogActions>
              <MyButton tip="Cerrar" onClick={this.handleClose}>
                <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
              </MyButton>
            </DialogActions>

          </Dialog>
        </Fragment>
      </div >
    )
  }
}

RecomendacionesGemini.propTypes = {
  ingenieriaGemini: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  tipouser: PropTypes.string.isRequired,
  recomendacionesIa: PropTypes.array.isRequired
};

const mapActionsToProps = { ingenieriaGemini };

const mapStateToProps = (state) => ({
  equipo: state.data.equipo.credentials,
  UI: state.UI,
  tipouser: state.user.credentials.tipouser,
  recomendacionIa: state.data.recomendacionIa,
});

export default connect(mapStateToProps, mapActionsToProps)(RecomendacionesGemini);