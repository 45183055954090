import React, { Component } from 'react'
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUsuarios, eliminarUsuario, editarUsuario, editPassword } from '../../../redux/actions/useractions';
import { clearErrors } from '../../../redux/actions/dataactions';

import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PhotoIcon from '@material-ui/icons/Photo';

import './TablaUsuarios.css'
import { Link } from 'react-router-dom';

import { FaArrowLeft, FaCheckCircle, FaFilePdf, FaTrashAlt, FaUserEdit, FaRegEdit, FaCheck } from "react-icons/fa";
import { Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import MyButton from '../../../utils/MyButton';
import CrearUsuario from './CrearUsuario';
import AgregarTecnico from './AgregarTecnico';

export class TablaUsuarios extends Component {
    constructor() {
        super();
        this.state = {
          errors: {},
          aprobado: {},
          openData: false,
          openDelete: false,
          openEditPassword: false,
          nombre: '',
          email: '',
          handle: '',
          tipouser: '', //falta
          ccnit: '',
          ciudad: '',
          direccion: '',
          telefono: '',
    
        }
      }
    
      componentDidMount() {
        console.log(this.props.clienteId)
        this.props.getUsuarios(this.props.clienteId);
      }

      static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
          newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
          newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
      }
    
      handleOpenData = (data) => {
        this.setState({ openData: true });
        this.setState({
    
          nombre: data.nombre,
          email: data.email,
          handle: data.handle,
          tipouser: data.tipouser,
          ccnit: data.ccnit,
          ciudad: data.ciudad,
          direccion: data.direccion,
          nombre: data.nombre,
          telefono: data.telefono,
    
        })
      };
      handleCloseData=(data)=>{
        if(this.state.aprobado.mensaje){
            this.props.getUsuarios(this.props.clienteId);
            this.setState({openData: false,errors:{},aprobado:{}});
         }else{
          this.setState({openData: false,errors:{},aprobado:{}});
         }
      }
    
      handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
      };
    
      handleClose = () => {
        this.props.clearErrors();
        console.log(this.state.aprobado)
        this.setState({
          openData: false,
          openDelete: false,
          openEditPassword: false,
          errors: {},
          aprobado: {},
    
          nombre: '',
          email: '',
          handle: '',
          tipouser: '',
          ccnit: '',
          ciudad: '',
          direccion: '',
          nombre: '',
          telefono: '',
        });
      };

      handleCloseDelete=()=>{
           // this.props.clearErrors();
        if(this.state.aprobado.mensaje){
            this.props.getUsuarios(this.props.clienteId);
            this.setState({openDelete: false,errors:{},aprobado:{}});
         }else{
          this.setState({openDelete: false,errors:{},aprobado:{}});
         }
      }
    
      handleOpenDelete = (data) => {
        this.setState({
          openDelete: true,
          handle: data.handle,
        })
        console.log(this.state.aprobado)
      };
    
      handleConfirmDelete = () => {
        let handle = this.state.handle
        console.log(handle, this.props.clienteId)
        this.props.eliminarUsuario(handle, this.props.clienteId);
      }
    
      handleEditData = () => {
        let Data = {
          ciudad: this.state.ciudad,
          direccion: this.state.direccion,
          nombre: this.state.nombre,
          telefono: this.state.telefono,
          handle: this.state.handle,
        }
        console.log(Data, this.props.clienteId)
        this.props.editarUsuario(Data, this.props.clienteId);
      }
    
      handleOpenEditCredentials = (email, handle) => {
        this.setState({
          openEditPassword: true,
          email: email,
          password: "",
          confirmpassword: "",
          handle: handle
        })
        console.log(email, handle)
      };
    
      handleEditPassword = () => {
        let Data = {
          password: this.state.password,
          confirmpassword: this.state.confirmpassword,
          email: this.state.email
        }
        this.props.editPassword(Data);
      }
  render() {
    
    const { usuarios, loading } = this.props.user;
    const {errors,aprobado}=this.props.UI;

    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      MiIcon3: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),


      AssignmentIndIcon: forwardRef((props, ref) => <AssignmentIndIcon {...props} ref={ref} />),
      AddAPhoto: forwardRef((props, ref) => <AddAPhotoIcon {...props} ref={ref} />),
      photo: forwardRef((props, ref) => <PhotoIcon {...props} ref={ref} />),
      editRegister: forwardRef((props, ref) => <FaRegEdit {...props} ref={ref} />)

    };

    const columns = [
      {
        field: 'imagenurl',
        title: 'Avatar',
        render: rowData => <img src={rowData.imageUrl} style={{ width: 50, borderRadius: '50%' }} />
      },

      { title: 'Id', field: 'idUsuario' },
      { title: 'Nombre', field: 'nombre' },
      { title: 'Tipo', field: 'tipouser' },
      { title: 'email', field: 'email' },
      { title: 'Ciudad', field: 'ciudad' },
      { title: 'telefono', field: 'telefono' },

    ]

    let mapeousuarios = !loading ? (
        !errors?(
            <div>
          <div className='div-container-links-inventario'>
          <CrearUsuario clienteId={this.props.clienteId}></CrearUsuario>
          <AgregarTecnico clienteId={this.props.clienteId}></AgregarTecnico>
  
          </div>
          <div className="div-tabla-equipos">
  
            <MaterialTable
              columns={columns}
              data={usuarios}
              title={
                <h2 style={{ fontSize: '16px', color: '#03178c', fontWeight: 'bold' }}></h2>
              }
              icons={tableIcons}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  fontSize: 12,
                  fontWeight: 'bolder',
                  color: "#03178c", // Ajusta el tamaño de fuente según tus necesidades
                  textAlign: 'center',
                },
                cellStyle: {
                  fontSize: 11,
                  fontWeight: 500,
                  color: '#696B6D'
                },
              }}
  
              actions={[
  
                rowData => ({
                  title: "SeeProfile",
                  icon: tableIcons.AssignmentIndIcon,
                  tooltip: 'Ver Perfil',
                  onClick: (event, rowData) => this.handleOpenData(rowData)
                }),
                {
                  title: "EditCredentials",
                  icon: tableIcons.editRegister,
                  tooltip: 'Editar las credenciales de acceso del usuario',
                  onClick: (event, rowData) => this.handleOpenEditCredentials(rowData.email, rowData.idUsuario)
                },
                {
                  title: "DeleteUser",
                  icon: tableIcons.Delete,
                  tooltip: 'Eliminar usuario',
                  onClick: (event, rowData) => this.handleOpenDelete(rowData)
                }
              ]}
            >
  
            </MaterialTable>
  
  
            {/*//aqui va el dialogo */}
  
          </div>
        </div>
        ):(<div>
             <div className='div-container-links-inventario'>
          <CrearUsuario clienteId={this.props.clienteId}></CrearUsuario>
  
          </div>
            No hay Usuarios Registrados, agrega uno para empezar</div>)
        
      ) : (
        <div className="div_cargando">
          <CircularProgress size={50} color="primary" className="login-progress" />
        </div>
      )
    return (
      <div>

{mapeousuarios}

<Dialog
          open={this.state.openData}
          onClose={this.handleCloseData}
          fullWidth
          maxWidth="sm"
        >

          <DialogTitle> Detalles del usuario </DialogTitle>

          <DialogContent>
            {aprobado ? (

              <div className='div-aprobado'>
                <div className='div-icono-aprobado'>
                  <FaCheck color="#ffffff" className='icono-aprobado' />
                </div>
                <h2 className='text-aprobado'>¡Usuario creado exitosamente!</h2>
              </div>

            ) : (
              <div>

                <TextField className="login-input-email"
                  required
                  type="text"
                  name="nombre"
                  id="nombre"
                  autoComplete="name"
                  label="Nombre de la persona"
                  value={this.state.nombre}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={this.state.errors.nombre}
                  error={this.state.errors.nombre ? true : false}
                />

                <div className="input_divido">
                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      disabled
                      required
                      type="email"
                      name="email"
                      id="email"
                      label="Email"
                      autoComplete="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={this.state.email}
                    />
                    <br />
                  </div>
                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      disabled
                      required
                      type="text"
                      name="handle"
                      id="handle"
                      autoComplete="username"
                      label="Handle/nombre del usuario a crear"
                      value={this.state.handle}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={this.state.handle}
                    />
                    <br />
                  </div>
                </div>

                <div className="input_divido">
                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      disabled
                      required
                      type="number"
                      name="ccnit"
                      id="ccnit"
                      label="Cédula o NIT"
                      value={this.state.ccnit}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={this.state.errors.ccnit}
                    />
                    <br />
                  </div>
                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      required
                      type="text"
                      name="ciudad"
                      id="ciudad"
                      label="Ciudad"
                      value={this.state.ciudad}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={this.state.errors.ciudad}
                      error={this.state.errors.ciudad ? true : false}
                    />
                    <br />
                  </div>
                </div>

                <div className="input_divido">
                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      required
                      type="text"
                      name="direccion"
                      id="direccion"
                      label="Dirección"
                      autoComplete='street-address'
                      value={this.state.direccion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={this.state.errors.direccion}
                      error={this.state.errors.direccion ? true : false}
                    />
                    <br />
                  </div>

                  <div className="input_divido_children">
                    <TextField className="login-input-email"
                      required
                      type="number"
                      name="telefono"
                      id="telefono"
                      autoComplete='tel'
                      label="Teléfono del usuario"
                      value={this.state.telefono}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={this.state.errors.telefono}
                      error={this.state.errors.telefono ? true : false}
                    />
                    <br />
                  </div>
                </div>
              </div>
            )}
          </DialogContent>

          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseData}>
              <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
            </MyButton>
            {this.props.UI.loading ? (
              <CircularProgress size={30} color="primary" className="login-progress" />
            ) : (
                aprobado ? (
                <MyButton tip="Continuar" onClick={this.handleCloseData}>
                  <FaCheckCircle color="#20B90C" className="icon-formulario" />
                </MyButton>
              ) : (
                <MyButton tip="Actualizar información" onClick={this.handleEditData}>
                  <FaUserEdit color="#03178C" className="icon-formulario" />
                </MyButton>
              )
            )}
          </DialogActions>

        </Dialog>

        <Dialog
          open={this.state.openDelete}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >

          <DialogTitle> ¿Estás seguro de eliminar este usuario? </DialogTitle>

          <DialogContent>
            {aprobado ? (

              <div className='div-aprobado'>
                <div className='div-icono-aprobado'>
                  <FaCheck color="#ffffff" className='icono-aprobado' />
                </div>
                <h2 className='text-aprobado'>¡Usuario eliminado exitosamente!</h2>
              </div>

            ) : (
              <p>Recuerda que al eliminar este usuario no vas a volver a poder acceder a él, sin embargo la información asociada a este se conservará como los indicadores, los reportes, las solicitudes y demás.</p>
            )}
          </DialogContent>

          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
              <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
            </MyButton>

            {this.props.UI.loading ? (
              <CircularProgress size={30} color="primary" className="login-progress" />
            ) : (
              aprobado ? (
                <MyButton tip="Continuar" onClick={this.handleCloseDelete}>
                  <FaCheckCircle color="#20B90C" className="icon-formulario" />
                </MyButton>
              ) : (
                <MyButton tip="Confirmar" onClick={this.handleConfirmDelete}>
                  <FaTrashAlt className="icon-formulario" />
                </MyButton>
              )
            )}
          </DialogActions>

        </Dialog>

        <Dialog
          open={this.state.openEditPassword}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle> Actualizar las credenciales del usuario: {this.state.handle}</DialogTitle>

          <DialogContent>
            {aprobado ? (

              <div className='div-aprobado'>
                <div className='div-icono-aprobado'>
                  <FaCheck color="#ffffff" className='icono-aprobado' />
                </div>
                <h2 className='text-aprobado'>¡Contraseña actualizada exitosamente!</h2>
              </div>

            ) : (
              <div>

                <TextField className="login-input-email"
                  required
                  type="text"
                  name="password"
                  id="password"
                  autoComplete="new-password"
                  label="Nueva contraseña"
                  value={this.state.password}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={this.state.errors.password || this.state.errors.passwords}
                  error={this.state.errors.password || this.state.errors.passwords ? true : false}
                />
                <TextField className="login-input-email"
                  required
                  type="text"
                  name="confirmpassword"
                  id="confirmpassword"
                  autoComplete="new-password"
                  label="Confirmar contraseña"
                  value={this.state.confirmpassword}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={this.state.errors.confirmpassword || this.state.errors.passwords}
                  error={this.state.errors.confirmpassword || this.state.errors.passwords ? true : false}
                />
              </div>

            )}
          </DialogContent>

          <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
              <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
            </MyButton>
            {this.props.UI.loading ? (
              <CircularProgress size={30} color="primary" className="login-progress" />
            ) : (
              aprobado ? (
                <MyButton tip="Continuar" onClick={this.handleClose}>
                  <FaCheckCircle color="#20B90C" className="icon-formulario" />
                </MyButton>
              ) : (
                <MyButton tip="Actualizar información" onClick={this.handleEditPassword}>
                  <FaUserEdit color="#03178C" className="icon-formulario" />
                </MyButton>
              )
            )}
          </DialogActions>

        </Dialog>


      </div>
    )
  }
}

TablaUsuarios.propTypes = {
    getUsuarios: PropTypes.func.isRequired,
    eliminarUsuario: PropTypes.func.isRequired,
    editarUsuario: PropTypes.func.isRequired,
    editPassword: PropTypes.func.isRequired,
  
  
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    clienteId: PropTypes.string.isRequired,
  }
  
  const mapStateToProps = state => ({
    user: state.user,
    data: state.data,
    UI: state.UI,
  })
  
  const mapActionsToProps = { getUsuarios, eliminarUsuario, editarUsuario, clearErrors, editPassword };
  
  export default connect(mapStateToProps, mapActionsToProps)(TablaUsuarios);