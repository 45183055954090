import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MyButton from '../../../utils/MyButton'
import { FaArrowCircleLeft, FaHospital, FaTools } from 'react-icons/fa';
import "./ComprasPerfil.css"
import NuevaSolicitudCliente from './NuevaSolicitudCliente';

export class ComprasPerfil extends Component {
  render() {
    return (
        <div>
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Sección de Compras</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>

        <div className="market_categorias_container">

<div className="subcontainer-market">

  <NuevaSolicitudCliente history={this.props.history} clienteId={this.props.match.params.clienteId}></NuevaSolicitudCliente>
<div className="dashing-div-card">
        <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}/solicitudesinenviar`} className='linkclass-market'>
            <div className="dashing-card">
                <div className="dashing-cardcontainer-market">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Mis Solicitudes <br/>Sin Enviar
                            </p>
                        
                    
                        </div>
                        <div className="dashing-cardicon">
                            <FaTools color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                    
                </div>            
            </div>
            </Link>
        </div>

</div>

<div className="subcontainer-market">
<div className="dashing-div-card">
        <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}/solicitudesenviadas`} className='linkclass-market'>
            <div className="dashing-card">
                <div className="dashing-cardcontainer-market">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Mis Solicitudes <br/>Enviadas
                            </p>
                        
                    
                        </div>
                        <div className="dashing-cardicon">
                            <FaTools color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                    
                </div>            
            </div>
            </Link>
        </div>

        <div className="dashing-div-card">
        <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}/solicitudesaprobadas`} className='linkclass-market'>
            <div className="dashing-card">
                <div className="dashing-cardcontainer-market">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Mis Solicitudes<br/>Aprobadas
                            </p>
                        
                    
                        </div>
                        <div className="dashing-cardicon">
                            <FaHospital color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                    
                </div>            
            </div>
            </Link>
        </div>

        <div className="dashing-div-card">
        <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}/solicitudesrechazadas`} className='linkclass-market'>
            <div className="dashing-card">
                <div className="dashing-cardcontainer-market">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Mis Solicitudes<br/>Rechazadas
                            </p>
                        
                    
                        </div>
                        <div className="dashing-cardicon">
                            <FaHospital color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                    
                </div>            
            </div>
            </Link>
        </div>

</div>
</div>
        </div>
    )
  }
}

export default ComprasPerfil