import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MyButton from '../../../utils/MyButton'
import { FaArrowCircleLeft, FaChartBar } from 'react-icons/fa'
import './MobiliarioHome.css'
import TablaMobiliario from './TablaMobiliario'
//import TablaEquipos from './TablaEquipos'
export class MobiliaroHome extends Component {
  render() {
    return (
        <div>
        <div className="titulo_section">
        <div className="titulo_clientes">
        <h1 className="text-clientes">Inventario de Mobiliario e Industriales</h1>
        <hr className="clientes_separator"></hr>            
        
        </div>

        <div className="div_boton">
        <Link to={`/dashboard/perfilcliente/${this.props.match.params.clienteId}`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>
        </div>
        </div>

<div className='container-inventario-perfil'>

<TablaMobiliario clienteId={this.props.match.params.clienteId}></TablaMobiliario>
{/**<div className="div-tabla-equipos">
                               
        </div> */}
                                    
</div>
 
       
        
        </div>
    )
  }
  }


export default MobiliaroHome
