import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const CardCurso = ({ imagen, titulo, precioAntes, precioAhora }) => {
  return (
    <Card className="card_cursos">
      <CardActionArea>
        <CardMedia className="imagen-card" image={imagen} title="Contemplative Reptile">
          <div className="imagen-card-filter" />
        </CardMedia>

        <CardContent>
          <h5 className="tittle-cursos">{titulo}</h5>
          <Typography variant="body2" color="textSecondary" component="p">
            Antes $̶{precioAntes}̶ Ahora ${precioAhora}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Comprar
        </Button>
        <Button size="small" color="primary">
          Descripción
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardCurso;