import React, { Component, Fragment  } from 'react';
import MyButton from '../../../utils/MyButton';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SubirImgEquipo.css'; 


import {subirImagenEquipo} from '../../../redux/actions/dataactions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FaPlusCircle,FaArrowLeft,FaPlus} from "react-icons/fa";


export class SubirImgEquipo extends Component {

    state = { 
        // Initially, no file is selected 
        selectedFile: null,
        open: false
      }; 
    handleOpen=()=>{
        this.setState({open:true});
    };
    
    handleClose=()=>{
       this.setState({open:false});
    };

    onFileChange = event => { 
     
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] }); 
       
    }; 

    onFileUpload = () => { 
     
        // Create an object of formData 
        const formData = new FormData(); 
       
        // Update the formData object 
        formData.append( 
          "myFile", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        ); 
   
        this.props.subirImagenEquipo(formData,this.props.equipoId);
        //Imagen subida
        console.log("imagen subida");
        //
        this.setState({open:false});

    }; 
    render() {
        return (
        
                 <div>
               <Fragment>
               <div className="div-botonsubirimagenequipo">
                <MyButton tip="Editar Imagen de Perfil" onClick={this.handleOpen} >
                  <FaPlusCircle color="#03178C" className="icon-subirimagencliente"></FaPlusCircle>
                  </MyButton>
                </div>

                <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                 fullWidth
                maxWidth="sm"
                >
                 <DialogTitle>Subir Imagen del Equipo</DialogTitle>
                 
                <DialogContent>
                
                </DialogContent>
                <input type="file" onChange={this.onFileChange} accept="image/png,image/jpeg"   /> 
                <DialogActions>
                    <MyButton tip="Cerrar" onClick={this.handleClose}>
                    <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                    </MyButton>
                    <MyButton tip="Subir Imagen del Equipo" onClick={this.onFileUpload}>
                    <FaPlus  color="#03178C" className="icon-formulario"/>
                    </MyButton>
                    
                </DialogActions>

                </Dialog>

            


               </Fragment>
                
            </div>
       
        )
    }
}
SubirImgEquipo.propTypes = {
    subirImagenEquipo: PropTypes.func.isRequired,
    equipoId: PropTypes.string.isRequired  
  };

const mapActionsToProps={subirImagenEquipo};

const mapStateToProps = (state)=>({
    user:state.user
})



export default connect(mapStateToProps,mapActionsToProps)(SubirImgEquipo);
