import { LOADING_DATA_MOB, LOADING_DATA_MTO_MOB, SET_MOBILIARIOS } from '../types';

const initialState={
    loadingdata:true,
    loadingmto:true,
    mobiliarios:[],
    mobiliario:{},
    progracalmobiliario:[],
    progcalsmobiliario:[],
    programtomobiliario:[],
    progmtosmobiliario:[],
    solicitudcorrmobiliario:[],
    solicitudescorrmobiliario:[],
    mtomobiliario:{},
    mtosmobiliario:[],
    mtocorrectivomobiliario:{},
    mtoscorrectivosmobiliario:[],
    solicitudesmtomobiliario:[],
    }


    export default function (state=initialState,action){
        switch(action.type){
            case LOADING_DATA_MOB:
                return {
                    ...state,
                    loadingdata:true
                }
            case LOADING_DATA_MTO_MOB:
                    return {
                        ...state,
                        loadingmto:true
                    }

            case SET_MOBILIARIOS:
                    return{
                        ...state,
                        mobiliarios:action.payload,
                        loadingdata:false,
                     
                    }
                
                
            default:
                return{
                    ...state
                }
        
        }
     
    }

               
            