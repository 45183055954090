import React, { Component } from 'react'
import './HistorialCorrectivosAsis.css'; 
import {getmtoscorrectivos,clearErrors,deleteMtoCorrectivo} from '../../../redux/actions/dataactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaFilePdf } from "react-icons/fa";
import MaterialTable from 'material-table';

import {Link} from 'react-router-dom';



import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/Photo';

export class HistorialCorrectivosAsis extends Component {

  constructor(){
    super();
    this.state={
    
      mtoid: '',

      
      opensee: false,
      imageUrl: null,
      

    };
    
};

    componentDidMount(){
        this.props.getmtoscorrectivos(this.props.equipoId);
       }

       
       handleOpenSee = (rawdatamtoid, rawimageurl) => {
        this.setState({ opensee: true, mtoid: rawdatamtoid, imageUrl: rawimageurl });
    
      };
    
      handleCloseSee = () => {
        // this.props.clearErrors();
        this.setState({ opensee: false, errors: {}, imageUrl:null });
      };
    
               
       
         
    
    render() {


        const {mtoscorrectivos,loadingmto,equipo}= this.props.data; 
        
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />),
            photo: forwardRef((props, ref) => <PhotoIcon {...props} ref={ref} style={{ color: '#03178c' }} />)
          };
        
        const columns=[
            {title:'Fecha',field:'fecha'},
            {title:'Hora Inicio',field:'horainicio'},
            {title:'Hora Final',field:'horafinal'},
           
        ]


        
     let mapeomtoscorrectivos=!loadingmto ?(
         <div>
    <MaterialTable
            columns={columns}
            data={mtoscorrectivos}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Mantenimientos Correctivos Realizados</h2>
            }
            icons={tableIcons}
    
            actions={[
              rowData => ({
                icon: () =>  <Link to={`/dashboard/perfilcliente/equipo/hojadevidapdf/reportepdfcorr/${equipo.credentials.idprestador}/${this.props.equipoId}/${rowData.mtoid}`} style={{ color: '#03178C' }} ><FaFilePdf  /></Link>,
                tooltip: 'Ver Reporte PDF ',
                onClick: (rowData)
              }),
              {
                title: "EvidenciaCargada",
                icon: tableIcons.photo,
                tooltip: 'Ver evidencia cargada',
                onClick: (event, rowData) => {
                  this.handleOpenSee(rowData.mtoid,rowData.imageUrl)
                 
                },
              },
              ]}

              options={{
                cellStyle: {
                    fontSize: 12,
                    fontWeight:500,
                    color:'#696B6D'
                  
                 
                  },
                  headerStyle: {
                    fontSize: 14,
                    fontWeight: 'bolder',
                    color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                  }
              
              }}
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
        return (
            <div className="listamtoprog-container">
              
            <div className="listamtoprog-table">
            {mapeomtoscorrectivos}
            </div>
            
            <Dialog
             open={this.state.opensee}
             onClose={this.handleCloseSee}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Evidencia Cargada del Mto</DialogTitle>
              <DialogContent>
            {  this.state.imageUrl ? (
                  <div>
                    <p> Esta es la evidencia cargada para esta solicitud </p>
                    <div className='div-image-carga'>

                      <img src={this.state.imageUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                    </div>
                  </div>
                ) : (
                  <p>No hay evidencia cargada para mostrar</p>
                )
                }
            
            </DialogContent>
            </Dialog>



            

          </div>
        )
    }
}

HistorialCorrectivosAsis.propTypes = {
    
    getmtoscorrectivos: PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteMtoCorrectivo:PropTypes.func.isRequired
  };

const mapActionsToProps={getmtoscorrectivos,clearErrors,deleteMtoCorrectivo};

const mapStateToProps = (state)=>({
    data:state.data,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(HistorialCorrectivosAsis);



