import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {FaChartLine,FaBoxes,FaShippingFast,FaChartBar,FaCartArrowDown,FaStar,FaRegCalendarCheck,FaRegClock} from "react-icons/fa";


export class DashboardEmpresa extends Component {
    render() {
        return (
            <div id="dashboard-ingeniero">


        <div className="dashing-container">
        <div className="dashing-rowindicators">   
            <div className="dashing-div-card">
                <div className="dashing-card">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Total Ventas
                                </p>
                            <h3 className="dashing-value-card">{this.props.indicadoresprops.indicadoresprincipales.totalventas}</h3>
                            </div>
                            <div className="dashing-cardicon">
                                <FaChartBar color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
                    </div>            
                </div>
            </div>
   
       
        <div className="dashing-div-card">
            <div className="dashing-card">
                <div className="dashing-cardcontainer">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Ingresos Totales
                            </p>
        <h3 className="dashing-value-card">{this.props.indicadoresprops.indicadoresprincipales.ingresostotales}</h3>
                        </div>
                        <div className="dashing-cardicon">
                            <FaChartLine color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                    

                </div>
                
            </div>
            </div>

            <div className="dashing-div-card">



            <div className="dashing-card">
                <div className="dashing-cardcontainer">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                           Productos
                            </p>
                            <h3 className="dashing-value-card">{this.props.indicadoresprops.indicadoresprincipales.productos}</h3>
                        </div>
                        <div className="dashing-cardicon">
                            <FaCartArrowDown color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                   

                </div>
                
            </div>
            </div>

            <div className="dashing-div-card">
            
            <div className="dashing-card">
                <div className="dashing-cardcontainer">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Puntuación
                            </p>
        <h3 className="dashing-value-card">{this.props.indicadoresprops.indicadoresprincipales.puntuacion}</h3>
                        </div>
                        <div className="dashing-cardicon">
                            <FaStar color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                   

                </div>
                
            </div>
            
            </div>

        </div>  

        <div className="dashing-div-secondindicators">
        <div className="dashing-div-secondindicators">
                
                <div className="dashign-div-secondcard">
                <div className="dashing-card">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                               Pedidos
                                </p>
                             <h3 className="dashing-value-card">{this.props.indicadoresprops.indicadoresprincipales.pedidos}</h3>
                            </div>
                            <div className="dashing-cardicon-orange">
                                <FaBoxes color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
    
                    </div>
                    
                </div>
                
                </div>
                <div className="dashign-div-secondcard">
    
                
                
                <div className="dashing-card">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Pedidos en Preparación
                                </p>
                                <h3 className="dashing-value-card">{this.props.indicadoresprops.indicadoresprincipales.pedidospreparados} </h3>
                            </div>
                            <div className="dashing-cardicon-green">
                                <FaRegClock color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
    
                    </div>
                    
                </div>
                </div>
                <div className="dashign-div-secondcard">
                <div className="dashing-card">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                              Pedidos Enviados
                                </p>
              <h3 className="dashing-value-card">{this.props.indicadoresprops.indicadoresprincipales.pedidosenviados}</h3>
                            </div>
                            <div className="dashing-cardicon-blue">
                                <FaShippingFast color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
    
                    </div>
                    
                </div>
                   
                
                </div>
                <div className="dashign-div-secondcard">
    
                <div className="dashing-card">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Pedidos Terminados
                                </p>
              <h3 className="dashing-value-card">{this.props.indicadoresprops.indicadoresprincipales.pedidosterminados}</h3>
                            </div>
                            <div className="dashing-cardicon-blue">
                                <FaRegCalendarCheck color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                   
                </div>    
                
                </div>   
    
                </div>   
        
        </div>  
        
        </div>  
        )
    }
}

DashboardEmpresa.propTypes = {    
    data:PropTypes.object.isRequired
  };



const mapStateToProps = (state)=>({
    data:state.data
})



export default connect(mapStateToProps)(DashboardEmpresa);

