import React, { useEffect, useRef, useState } from 'react';
import './AboutNew.css';
import { FaUsers, FaFileMedicalAlt, FaShoppingCart, FaChartLine } from 'react-icons/fa';

function AboutNew() {
    const [isVisible, setIsVisible] = useState(false);
    const aboutRef = useRef(null);
    const h1Ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                console.log("Intersection detected", entry.isIntersecting);
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -100px 0px'  // Detecta cuando el elemento está 100px antes de entrar en el viewport
            }
        );

        const currentAboutRef = aboutRef.current;
        const currentH1Ref = h1Ref.current;

        if (currentAboutRef) {
            observer.observe(currentAboutRef);
        }

        return () => {
            if (currentAboutRef) {
                observer.unobserve(currentAboutRef);
            }
        };
    }, []);

    return (
        <div id="aboutnew" className="aboutnew">
            <div className="container1about">
                <h3 ref={aboutRef} className={`h3-about ${isVisible ? 'visible' : ''}`}>
                    Alpha Biomédica es un ecosistema digital de mantenimiento hospitalario, que brinda herramientas inteligentes para la gestión eficiente de tus activos. Nuestra misión es generar un espacio de trabajo digital que integre a profesionales en ingeniería clínica, profesionales en salud, hospitales y proveedores para hacer más eficiente este proceso y seguir avanzando hacia el futuro de la salud digital.
                </h3>
            </div>
            <div className='container2about'>
                <h1 ref={h1Ref} className={`h1-about ${isVisible ? 'visible' : ''}`}>
                    Te Contamos
                </h1>
                <h2 ref={h1Ref} className={`h2-about ${isVisible ? 'visible' : ''}`}>Quienes Somos y Nuestra Misión</h2>
            </div>
        </div>
    );
}

export default AboutNew;