import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {postRepuesto} from '../../../redux/actions/dataactions';
import {clearErrors} from '../../../redux/actions/dataactions';
import CircularProgress from '@material-ui/core/CircularProgress';



import { FaArrowLeft,FaPlus,FaFirstAid, FaChartBar, FaCheck, FaCheckCircle } from "react-icons/fa";
import './NuevoRepuesto.css';

export class NuevoRepuesto extends Component {
    constructor(){
        super();
        this.state={
            codigo:'',
            nombre:'',
            marca:'',
            modelo:'',
            serie:'',
            cantidad:'',
            valorunitario:'',
            proveedor:'',
            proveedortel:'',
            proveedoremail:'',
            errors:{},
            aprobado:{},
            open:false
        };
        
    };

    static getDerivedStateFromProps(props, state) {
      const newState = {};
  
      if (props.UI.errors) {
          newState.errors = props.UI.errors;
      }
  
      if (props.UI.aprobado) {
         newState.aprobado = props.UI.aprobado;
      }
  
      return Object.keys(newState).length > 0 ? newState : null;
  }

 

    handleOpen=()=>{
        this.setState({open:true});
        };
 handleClose=()=>{
  
            this.props.clearErrors();
            this.setState({
                codigo:'',
            nombre:'',
            marca:'',
            modelo:'',
            serie:'',
            cantidad:'',
            valorunitario:'',
            proveedor:'',
            proveedortel:'',
            proveedoremail:'',
            open:false,errors:{},aprobado:{}});
 };
        handleChange=(event)=>{
            this.setState({[event.target.name]:event.target.value});
            };


    handleSubmit=(event)=>{
            event.preventDefault();
            const newUserData={
                codigo:this.state.codigo,
            nombre:this.state.nombre,
            marca:this.state.marca,
            modelo:this.state.modelo,
            serie:this.state.serie,
            cantidad:this.state.cantidad,
            valorunitario:this.state.valorunitario,
            proveedor:this.state.proveedor,
            proveedortel:this.state.proveedortel,
            proveedoremail:this.state.proveedoremail,
            imagenUrl:""             
          };
          console.log(newUserData)
          console.log(this.props.user.credentials.handle)
          this.props.postRepuesto(newUserData,this.props.user.credentials.handle);
           
           
         
     }

    render() {
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;
        return (
          

        
            <Fragment>

                  <div className='div-card-link' onClick={this.handleOpen}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                            Nuevo Repuesto
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon">
                                            <FaFirstAid color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>
  
  
                </div>
                
                <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >
 {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Equipo Cargado Exitosamente</h2>
                  
                 
              </div>
                
                ) : (<div>
                    
               
            
               
            <DialogContent>

                    <form>
                
          <div className="flex-accordion">
          <h4 className="text-title-accorion">Ingresa los datos del Repuesto</h4>
          <TextField className="login-input-email"
                      required
                      type="text"
                      name="nombre"
                      id="nombre"
                      label="Nombre del Repuesto"
                      value={this.state.nombre}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombre}
                      error={errors.nombre?true:false}
                      />

<div className="input_divido">
<div className="input_divido_children">

<TextField className="login-input-email"
                      required
                      type="text"
                      name="codigo"
                      id="codigo"
                      label="Codigo del Repuesto"
                      value={this.state.codigo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.codigo}
                      error={errors.codigo?true:false}
                      />

</div>

<div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="marca"
                      id="marca"
                      label="Marca del Repuesto"
                      value={this.state.marca}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.marca}
                      error={errors.marca?true:false}
                      />
                      <br/>
                        </div>
                        
    
    </div>
                      
                      <div className="input_divido">
                       
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="modelo"
                      id="modelo"
                      label="Modelo del Repuesto"
                      value={this.state.modelo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.modelo}
                      error={errors.modelo?true:false}
                      />
                      <br/>
                        </div>
                    
                        <div className="input_divido_children">
                       <TextField className="login-input-email"
                     required
                     type="text"
                     name="serie"
                     id="serie"
                     label="Serie del Repuesto"
                     value={this.state.serie}
                     onChange={this.handleChange}
                     fullWidth
                     helperText={errors.serie}
                     error={errors.serie?true:false}
                     />
                     <br/>
                       </div>
                    </div>

                   

                   <div className="input_divido">
                       
                       <div className="input_divido_children">
                       <TextField className="login-input-email"
                     required
                     type="number"
                     name="cantidad"
                     id="cantidad"
                     label="Cantidad"
                     value={this.state.cantidad}
                     onChange={this.handleChange}
                     fullWidth
                     helperText={errors.cantidad}
                     error={errors.cantidad?true:false}
                     />
                     <br/>
                       </div>

                       <div className="input_divido_children">
                       <TextField className="login-input-email"
                     required
                     type="number"
                     name="valorunitario"
                     id="valorunitario"
                     label="Valor Unitario con IVA"
                     value={this.state.valorunitario}
                     onChange={this.handleChange}
                     fullWidth
                     helperText={errors.valorunitario}
                     error={errors.valorunitario?true:false}
                     />
                     <br/>
                       </div>
                   
                     
                   </div>

                   <div className="input_divido">
                      
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="proveedor"
                      id="proveedor"
                      label="Proveedor del Repuesto"
                      value={this.state.proveedor}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedor}
                      error={errors.proveedor?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="proveedortel"
                      id="proveedortel"
                      label="Telefono Proveedor"
                      value={this.state.proveedortel}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedortel}
                      error={errors.proveedortel?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="email"
                      name="proveedoremail"
                      id="proveedoremail"
                      label="Email Proveedor"
                      value={this.state.proveedoremail}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedoremail}
                      error={errors.proveedoremail?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>
                  
                    
                      

                        

                        
                        




          </div>
        
                    

                           




                      </form>
                      
            
            </DialogContent>
          

           
                </div>
               
                )}

<DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                              
                  <MyButton tip="Ir a Inventario" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                  
                ) : (
                  <MyButton tip="Crear Equipo" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            
          </DialogActions>
                 </Dialog>
          
            </Fragment>
            
        )
    }
}

NuevoRepuesto.propTypes = {
    postRepuesto: PropTypes.func.isRequired,
    handleUser: PropTypes.string.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

const mapActionsToProps={postRepuesto,clearErrors};

const mapStateToProps = (state)=>({
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(NuevoRepuesto);


