import {SET_ERRORS_DATA,LOADING_DATA_UI,SET_OK_DATA,STOP_LOADING_UI,SET_ERRORS,CLEAR_ERRORS,LOADING_UI,SET_OK, STOP_LOADING_DATA} from '../types'; 

const initialState={
    loading:false,
    loadingdata:false,
    errors:null,
    aprobado:null
}; 

export default function(state= initialState,action){
    switch(action.type){
        case SET_ERRORS:
            return {
                ...state,
                loading:false,
                errors:action.payload,
                aprobado:null
            };

        case SET_ERRORS_DATA:
            return{
                ...state,
                loadingdata:false,
                errors:action.payload
            }
        case SET_OK:
                return {
                    ...state,
                    loading:false,
                    aprobado:action.payload
                };

          case SET_OK_DATA:
                    return {
                        ...state,
                        loadingdata:false,
                        aprobado:action.payload
           };
        case CLEAR_ERRORS: 
        return {
            ...state,
            loading:false,
            errors:null,
            aprobado:null
        }; 
        case LOADING_UI:
            return { 
                ...state,
                loading:true 
            }

            case LOADING_DATA_UI:
                return { 
                    ...state,
                    loadingdata:true 
                }
        case STOP_LOADING_UI:
            return{
                ...state,
                loading:false
            }

        case STOP_LOADING_DATA:
            return {
                  ...state,
                  loadingdata: false
            }
        default: return state ; 
    }
}