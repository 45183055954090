import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {postCronogramaCalAuto} from '../../../redux/actions/dataactions';
import {clearErrors} from '../../../redux/actions/dataactions';
import CircularProgress from '@material-ui/core/CircularProgress';



import { FaArrowLeft,FaPlus,FaFirstAid, FaChartBar } from "react-icons/fa";
import './CronogramaMtoAuto.css';

export class CronogramaCalAuto extends Component {
    constructor(){
        super();
        this.state={
            servicio:'',
            idprestador:'',
            fecha:'',
            lugar:'',
            errors:{},
            open:false
        };
        
    };

    static getDerivedStateFromProps(props, state) {
      return props.UI.errors ? {errors: props.UI.errors} : null;
  }

 

    handleOpen=()=>{
        this.setState({open:true});
        };
 handleClose=()=>{
            this.props.clearErrors();
            this.setState({
            servicio:'',
            idprestador:'',
            fecha:'',
            lugar:'',
            open:false,
            errors:{}});
 };
        handleChange=(event)=>{
            this.setState({[event.target.name]:event.target.value});
            };


    handleSubmit=(event)=>{
            event.preventDefault();
            let fechamto=new Date(this.state.fecha);
            let day;
            let mes;
            let newfecha;
            let year;
            let mesnombre;
    
            if(fechamto.getDate()>=30){
              day=1;
              
              mes=fechamto.getMonth()+1;
              if(mes>11){
                mes=0;
                year=fechamto.getFullYear()+1;
              }else{
                year=fechamto.getFullYear();
              }
              newfecha=fechamto.setDate(day);
              newfecha=fechamto.setMonth(mes);
              newfecha=new Date(newfecha);
              mes=mes+1;
              mesnombre=newfecha.toLocaleString('es-Es', {
                month: 'long',
              });
            }else{
              day=fechamto.getDate()+1;
              mes=fechamto.getMonth()+1;
              year=fechamto.getFullYear();
              mesnombre=fechamto.toLocaleString('es-Es', {
                month: 'long',
              });
            }
            
            const newUserData={
                servicio:this.state.servicio,
                fecha:this.state.fecha,
                lugar:this.state.lugar,
                idprestador:this.props.clienteId,
                dia:day?day:"",
                mes:mes?mes:"",
                year:year?year:"",
                mesnombre:mesnombre?mesnombre:""         
             
          };

          console.log(newUserData);
          console.log(this.props.clienteId);
        this.props.postCronogramaCalAuto(newUserData,this.props.clienteId);
           
           
         
     }

    render() {
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;
        return (
          

        
            <Fragment>

                  <div className='div-card-link' onClick={this.handleOpen}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                            Generar Cronograma Automático
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon">
                                            <FaFirstAid color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>
  
  
                </div>
           {/* <MyButton tip="Crear Equipo" onClick={this.handleOpen}>
            <FaFirstAid color="#03178C" className="icon-crearcliente"/>
        </MyButton>*/}

             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >
            <DialogTitle className="text-title-accorion">Generar Cronograma de Calibración Automático</DialogTitle>
               
            <DialogContent>

                    <form>
                
          <div className="flex-accordion">
          <h4 className="text-title-accorion">Servicio a Programar Calibración</h4>
          <TextField className="login-input-email"
                      required
                      type="text"
                      name="servicio"
                      id="servicio"
                      label="Servicio a Programar Escribe Todos para programar todas los servicios"
                      value={this.state.servicio}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.servicio}
                      error={errors.servicio?true:false}
                      />
    
              
        

          </div>
        
                    

         
      
       
        <div className="flex-accordion">
        <h4 className="text-title-accorion">Fecha a Programar Calibración</h4>
        <div className="input_divido">


                      <div className="input_divido_children">
                      <TextField className="login-input-email"
                      required
                      type="date"
                      name="fecha"
                      id="fecha"
                      label="Fecha de Mantenimiento"
                      value={this.state.fecha}
                      onChange={this.handleChange}
                      min="2019-01-01" max="2029-01-01"
                      fullWidth
                      helperText={errors.fecha}
                      error={errors.fecha?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
                     
                        </div>
                        
                        </div>
                       

 </div>

<div className="flex-accordion">
 <h4 className="text-title-accorion">Lugar de Calibración</h4>
 <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Lugar de Calibracion</InputLabel>
                            <Select
                            required
                         name="lugar"
                         id="lugar"
                         value={this.state.lugar || ''}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Laboratorio">Laboratorio</MenuItem>
                            <MenuItem value="En Sitio">En Sitio</MenuItem>
                      
                         
                            </Select>
                            <FormHelperText>{errors.lugar}</FormHelperText>
                        </FormControl>  
 
 </div>

 </form>
                      
            
            </DialogContent>
            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Generar Cronograma" onClick={this.handleSubmit}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
            
            
          </DialogActions>

            </Dialog>
            </Fragment>
            
        )
    }
}

CronogramaCalAuto.propTypes = {
    postCronogramaCalAuto: PropTypes.func.isRequired,
    clienteId: PropTypes.string.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

const mapActionsToProps={postCronogramaCalAuto,clearErrors};

const mapStateToProps = (state)=>({
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(CronogramaCalAuto);
