import React, { Component , Fragment } from 'react';
import './NuevaCotizacion.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaHospital,FaArrowLeft,FaPlus,FaFileUpload} from "react-icons/fa";

import {postCotizacion,clearErrors} from '../../../redux/actions/datasalesactions';

export class NuevaCotizacion extends Component {
    constructor(){
        super();
        this.state={
            fechavencimiento:'',
            formadepago:'',
            tipocotizacion:'',
            terminos:'',
            observaciones:'',
            direccionentrega:'',
            departamento:'',
            ciudad:'',
            categoria:'',
            nombreresponsable:'',
            cargoresponsable:'',
            telefonoresponsable:'',
            emailresponsable:'',
            titulo:'',
            nit:'',
            errors:{},
            open:false,
            openalert:false
        };
        
    };
    static getDerivedStateFromProps(props, state) {
      return props.UI.errors ? {errors: props.UI.errors} : null;
  }
  /* componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
          this.setState({
            errors: nextProps.UI.errors
          });
        }
        if (!nextProps.UI.errors && !nextProps.UI.loading) {
          this.setState({ fecha:'',
          hora:'',
          duracion:'',
          open: false, errors: {} });
        }
    }*/

   

    handleOpen=()=>{
        this.setState({open:true});
        };
    handleOpenAlert=()=>{
            this.setState({openalert:true});
            };

     handleClose=()=>{
            this.props.clearErrors();
            this.setState({fechavencimiento:'',
            formadepago:'',
            tipocotizacion:'',
            terminos:'',
            observaciones:'',
            direccionentrega:'',
            departamento:'',
            ciudad:'',
            categoria:'',
            nombreresponsable:'',
            cargoresponsable:'',
            telefonoresponsable:'',
            emailresponsable:'',
            titulo:'',
            nit:'',open:false,errors:{}});
            };
     handleCloseAlert=()=>{
                this.props.clearErrors();
                this.setState({openalert:false,errors:{}});
    };
        
     handleChange=(event)=>{
     this.setState({[event.target.name]:event.target.value});
     
     };

     handlePostCotizacion=()=>{
        const newCotizacion={
            fechavencimiento:this.state.fechavencimiento,
            formadepago:this.state.formadepago,
            tipocotizacion:this.state.tipocotizacion,
            terminos:this.state.terminos,
            observaciones:this.state.observaciones,
            direccionentrega:this.state.direccionentrega,
            departamento:this.state.departamento,
            ciudad:this.state.ciudad,
            categoria:this.state.categoria,
            nombreresponsable:this.state.nombreresponsable,
            cargoresponsable:this.state.cargoresponsable,
            telefonoresponsable:this.state.telefonoresponsable,
            emailresponsable:this.state.emailresponsable,
            titulo:this.state.titulo,
            nit:this.state.nit
         
            
     };
       console.log(newCotizacion);
       this.props.postCotizacion(newCotizacion);  
      this.handleClose(); 
      this.handleOpenAlert();

   
       
 
     }

     

 render(){
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;

    return(
        
        <Fragment>
        
        <div className="dashing-div-card">
                <div className="dashing-card-market" onClick={this.handleOpen}>
                    <div className="dashing-cardcontainer-market">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Crear nueva Solicitud de Cotizacion
                                </p>
                             </div>
                            <div className="dashing-cardicon-green">
                                <FaFileUpload color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
                    </div>            
                </div>
            </div>

            <Dialog
         open={this.state.openalert}
         onClose={this.handleCloseAlert}
         fullWidth
        maxWidth="sm"
        >
        <DialogTitle>Tu cotización fue publicada con Exito</DialogTitle>

        <DialogContent>
            <a>Ahora dirigete al panel de Mis Cotizaciones, Añade los items que deseas y Publica tu Cotización</a>
        </DialogContent>

        <DialogActions>
        <MyButton tip="Cerrar" onClick={this.handleCloseAlert}>
        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
         </MyButton>
         </DialogActions>
       
        </Dialog>




         <Dialog
         open={this.state.open}
         onClose={this.handleClose}
         fullWidth
        maxWidth="sm"
        >
        <DialogTitle>Nueva Solicitud de Cotización</DialogTitle>

        <DialogContent>
                <form>
                <TextField className="login-input-email"
                  required
                  type="text"
                  name="titulo"
                  id="titulo"
                  label="Titulo de la Cotización"
                  value={this.state.titulo}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.titulo}
                  error={errors.titulo?true:false}                 
                  />


                <TextField className="login-input-email"
                  required
                  type="date"
                  name="fechavencimiento"
                  id="fechavencimiento"
                  label="Fecha de Vencimiento de Cotización"
                  value={this.state.fechavencimiento}
                  onChange={this.handleChange}
                  min="2022-01-01" max="2024-01-01"
                  fullWidth
                  helperText={errors.fechavencimiento}
                  error={errors.fechavencimiento?true:false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />

           
         

        <FormControl className="formcontrol-nuevocliente">
                        <InputLabel>Categoria*</InputLabel>
                        <Select
                        required
                     name="categoria"
                     id="categoria"
                     value={this.state.categoria}
                        onChange={this.handleChange}
                        fullWidth
                        >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value="equiposmedicos">Equipos Medicos</MenuItem>
                        <MenuItem value="repuestos">Repuestos</MenuItem>
                        <MenuItem value="serviciosmtocal">Servicios Mantenimiento/Calibración</MenuItem>
                        <MenuItem value="insumosdispositivos">Insumos/Dispositivos Medicos</MenuItem>
                        <MenuItem value="computo">Equipos de Computo</MenuItem>
                        <MenuItem value="mobiliario">Mobiliario Hospitalario</MenuItem>
                       
                     
                        </Select>
                        <FormHelperText>{errors.categoria}</FormHelperText>
                    </FormControl>  

                <div className='divdoblecotizacion'>
                    <div className='divdobleunit'>
                    <TextField className="login-input-email"
                  required
                  type="text"
                  name="departamento"
                  id="departamento"
                  label="Departamento"
                  value={this.state.departamento}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.departamento}
                  error={errors.departamento?true:false}                 
                  />
                    </div>
                
                    <div className='divdobleunit'>
                    <TextField className="login-input-email"
                  required
                  type="text"
                  name="ciudad"
                  id="ciudad"
                  label="Ciudad"
                  value={this.state.ciudad}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.ciudad}
                  error={errors.ciudad?true:false}                 
                  />


                        </div>
                


                </div>



                <div className='divdoblecotizacion'>
                    <div className='divdobleunit'>
                    <FormControl className="formcontrol-nuevocliente">
                        <InputLabel>Forma de Pago*</InputLabel>
                        <Select
                        required
                     name="formadepago"
                     id="formadepago"
                     value={this.state.formadepago}
                        onChange={this.handleChange}
                        fullWidth
                        >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value="A convenir">A convenir</MenuItem>
                        <MenuItem value="A la entrega">A la entrega</MenuItem>
                        <MenuItem value="50-50">50 anticipado 50 a la entrega</MenuItem>
                        <MenuItem value="Efectivo">Efectivo</MenuItem>
                        <MenuItem value="10 dias">10 días</MenuItem>
                        <MenuItem value="15 dias">15 días</MenuItem>
                        <MenuItem value="20 dias">20 días</MenuItem>
                        <MenuItem value="30 dias">30 días</MenuItem>
                        <MenuItem value="45 dias">45 días</MenuItem>
                        <MenuItem value="60 dias">60 días</MenuItem>
                        <MenuItem value="90 dias">90 días</MenuItem>
                        <MenuItem value="120 dias">120 días</MenuItem>
                        <MenuItem value="180 dias">180 días</MenuItem>
                        

                       
                       
                     
                        </Select>
                        <FormHelperText>{errors.formadepago}</FormHelperText>
                    </FormControl>  
                        </div>

                    <div className='divdobleunit'>
                    <FormControl className="formcontrol-nuevocliente">
                        <InputLabel>Tipo de Cotización*</InputLabel>
                        <Select
                        required
                     name="tipocotizacion"
                     id="tipocotizacion"
                     value={this.state.tipocotizacion}
                        onChange={this.handleChange}
                        fullWidth
                        >
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        <MenuItem value="De Urgencia">De Urgencia</MenuItem>
                        <MenuItem value="Normal">Normal</MenuItem>
                        <MenuItem value="Estudio de Mercado">Estudio de Mercado</MenuItem>
                     
                     
                        </Select>
                        <FormHelperText>{errors.tipocotizacion}</FormHelperText>
                    </FormControl>  

                    </div>
                    </div>

                    <TextField className="login-input-email"
                  required
                  type="text"
                  name="nit"
                  id="nit"
                  label="Nit"
                  value={this.state.nit}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.nit}
                  error={errors.nit?true:false}                 
                  />
                    <TextField className="login-input-email"
                  required
                  type="text"
                  name="direccionentrega"
                  id="direccionentrega"
                  label="Dirección de Entrega"
                  value={this.state.direccionentrega}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.direccionentrega}
                  error={errors.direccionentrega?true:false}                 
                  />


                    <TextField className="login-input-email"
                  required
                  type="text"
                  name="terminos"
                  id="terminos"
                  label="Terminos y Condiciones"
                  value={this.state.terminos}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.terminos}
                  error={errors.terminos?true:false}
                  multiline
                  rows={4}
                                  
                 
                  />
                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="observaciones"
                  id="observaciones"
                  label="Observaciones"
                  value={this.state.observaciones}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.observaciones}
                  error={errors.observaciones?true:false}
                  multiline
                  rows={4}
                
                               
                 
                  />
                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="nombreresponsable"
                  id="nombreresponsable"
                  label="Nombre Responsable"
                  value={this.state.nombreresponsable}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.nombreresponsable}
                  error={errors.nombreresponsable?true:false}                 
                  />
                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="emailresponsable"
                  id="emailresponsable"
                  label="Email del Responsable"
                  value={this.state.emailresponsable}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.emailresponsable}
                  error={errors.emailresponsable?true:false}                 
                  />
                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="cargoresponsable"
                  id="cargoresponsable"
                  label="Cargo Responsable"
                  value={this.state.cargoresponsable}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.cargoresponsable}
                  error={errors.cargoresponsable?true:false}                 
                  />
                  <TextField className="login-input-email"
                  required
                  type="text"
                  name="telefonoresponsable"
                  id="telefonoresponsable"
                  label="Telefono"
                  value={this.state.telefonoresponsable}
                  onChange={this.handleChange}
                  fullWidth
                  helperText={errors.telefonoresponsable}
                  error={errors.telefonoresponsable?true:false}                 
                  />

   
        </form>
        </DialogContent>

       
        <DialogActions>
        <MyButton tip="Cerrar" onClick={this.handleClose}>
        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
         </MyButton>

         {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                      <MyButton tip="Programar Mantenimiento" onClick={this.handlePostCotizacion}>
                      <FaPlus  color="#03178C" className="icon-formulario"/>
                       </MyButton>}
        
        

        
      </DialogActions>

        </Dialog>

        </Fragment>
       )


 }
}

NuevaCotizacion.propTypes={
    postCotizacion:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired
  };
  const mapStateToProps=(state)=>({
    UI:state.UI,
    equipo: state.data.equipo.credentials
  });
  
  export default connect (mapStateToProps,{postCotizacion,clearErrors}) (NuevaCotizacion)
