import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ClientePerfilTec.css';
import MyButton from '../../../../utils/MyButton';
import {FaArrowCircleLeft, FaCalendar, FaCalendarPlus, FaCalendarWeek, FaChartBar, FaList, FaPhoneAlt, FaRocket, FaRuler, FaShoppingCart, FaUserNurse, FaWrench} from "react-icons/fa";
import {Link} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';





import {getCliente} from '../../../../redux/actions/dataactions';


import {FaLaptopMedical,FaHospital,FaTools,FaSyringe,FaCogs,FaLaptopCode,FaFileInvoiceDollar,FaFileSignature} from "react-icons/fa";




export class ClientePerfilTec extends Component {

    componentDidMount(){
        this.props.getCliente(this.props.match.params.clienteId);
      }
    
    
    render() {
        const {cliente,loading}= this.props.data;         
        const idcliente=this.props.match.params.clienteId; 


        let cargarPerfil= !loading ? (
        <div className="card-cliente">
            <div className="div-informacion">
            <div className="div-imagencliente">
              <img src={cliente.imagenurl} alt="equipo" className="img-cliente"/>
            </div>

            <div className="div-flex-start">

            
            <div className="div-infocontainer">
            <div className="div-titulocliente">
            <h3 className="text-titulo-cliente">{cliente.nombre}</h3>
            <h5 className="text-ciudad-cliente">{cliente.ciudad}</h5>
            </div>
            <div className="div-cliente-info">
            <p className="cliente-text">CC/NIT: {cliente.ccnit}</p>
            <p className="cliente-text">Dirección: {cliente.direccion}</p>
            <p className="cliente-text">Email: {cliente.email}</p>
            <p className="cliente-text">Teléfono: {cliente.telefono}</p>
            <p className="cliente-text">Código REPS: {cliente.codigoreps}</p>
            <p className="cliente-text">Sede: {cliente.sede}</p>
            </div>
            </div>

            <div className="acciones-cliente-perfil">
                
                <div className="div_boton">
              
               </div>

             

            </div>
            </div>
            </div>
     
        </div>
        )
        
        :(<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>);
        
        return (
            <div className="clienteperfilcontainer">
                <div className="titulo_section">
                <div className="titulo_clientes">
                <h1 className="text-clientes"> Perfil Prestador desde Tecnico</h1>
                <hr className="clientes_separator"></hr>            
                
                </div>

                <div className="div_boton">
                <Link to={`/dashboard/clientes/tecnico`}>
                <MyButton tip="Volver">
                 <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
                </MyButton>
                </Link>
                </div>

                </div>
                       

                {cargarPerfil}
               
               <div className='div-container-general'>

                <div className='div-container-links'>
                  
                <Link className='link-div-card-link' to={`/dashboard/perfilclientetec/${idcliente}/indicadoresperfil`}>
                  <div className="dashing-cardcontent">
                                      <div className="dashing-cardtext">
                                          <p className="dashing-text-card">
                                          Indicadores del Prestador
                                          </p>
                      
                                      </div>
                                      <div className="dashing-cardicon-green">
                                          <FaChartBar color="#FFFFFF" className="dashing-icon"/>
                                      </div>
                                  </div>


                                  </Link>
                  
                   
                    
                   
                    <Link className='link-div-card-link' to={`/dashboard/perfilclientetec/${idcliente}/inventarioperfil`}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                            Inventario de Equipos
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon">
                                            <FaLaptopMedical color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>
                                    </Link>

                                   


                   
                    

                    
                    

                
            
                </div>
                <div className='div-container-links'>

                    
                <Link className='link-div-card-link' to={`/dashboard/perfilclientetec/${idcliente}/cronomtoperfil`}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                            Cronograma de Mantenimiento 
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon-blue">
                                            <FaWrench color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>


                                    </Link>

                                    <Link className='link-div-card-link' to={`/dashboard/perfilcliente/${idcliente}/cronocalperfil`}>
                    <div className="dashing-cardcontent">
                                        <div className="dashing-cardtext">
                                            <p className="dashing-text-card">
                                           Cronograma de Calibración
                                            </p>
                        
                                        </div>
                                        <div className="dashing-cardicon-blue">
                                            <FaRuler color="#FFFFFF" className="dashing-icon"/>
                                        </div>
                                    </div>


                                    </Link>
                  
                <Link className='link-div-card-link' to={`/dashboard/perfilcliente/${idcliente}/solicitudesmto`}>
                  <div className="dashing-cardcontent">
                                      <div className="dashing-cardtext">
                                          <p className="dashing-text-card">
                                          Solicitudes de Mantenimiento
                                          </p>
                      
                                      </div>
                                      <div className="dashing-cardicon-orange">
                                          <FaPhoneAlt color="#FFFFFF" className="dashing-icon"/>
                                      </div>
                                  </div>
                                  </Link>
                   
                    
                   
                
                 

                                  
                  
                  

              
          
              </div>

              <div className='div-container-links'>

                    
<Link className='link-div-card-link' to={`/dashboard/perfilcliente/${idcliente}/programacion`}>
    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Programación Mensual
                            </p>
        
                        </div>
                        <div className="dashing-cardicon">
                            <FaCalendar color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>


                    </Link>

                    <Link className='link-div-card-link' to={`/dashboard/perfilcliente/${idcliente}/documentacionlegal`}>
    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Documentación Legal
                            </p>
        
                        </div>
                        <div className="dashing-cardicon">
                            <FaCalendarWeek color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>


                    </Link>
  
                    <Link className='link-div-card-link' to={`/dashboard/perfilcliente/${idcliente}/registrosTecno`}>
              <div className="dashing-cardcontent">
                <div className="dashing-cardtext">
                  <p className="dashing-text-card">
                    Tecnovigilancia
                  </p>

                </div>
                <div className="dashing-cardicon-orange">
                  <FaUserNurse color="#FFFFFF" className="dashing-icon" />
                </div>
              </div>
            </Link>
   
    
   

 

                  
  
  



</div>


               

                


               </div>

                

       
                



            </div>
        )
    }
}

//en propiedades recibe el cliente id 
ClientePerfilTec.propTypes = {
    getCliente: PropTypes.func.isRequired,
    data:PropTypes.object.isRequired
  };

const mapActionsToProps={getCliente};

const mapStateToProps = (state)=>({
    data:state.data
})



export default connect(mapStateToProps,mapActionsToProps)(ClientePerfilTec);


