import React, { useState } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEquipo, getCliente } from '../../../redux/actions/dataactions';
import { PDFViewer } from '@react-pdf/renderer';
import './HojadeVidaPDFAsis.css';
import HojadeVidaDocuPDFAsis from './HojadeVidaDocuPDFAsis';

function HojadeVidaPDFAsis(props) {
  const [state, setState] = useState({
    equipos: {},
    errors: {},
    open: false,
    verWeb: false,
    verPDF: false,
  });

  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const { equipo, cliente, loading } = props.data;

  return (
    <div className='div-inventaripdfgeneral'>
      <div className='div-inventaripdftitulo'>
        <div className='div_boton'>
          <MyButton tip='Volver' onClick={handleGoBack}>
            <FaArrowCircleLeft color='#03178C' className='icon-eliminarcliente' />
          </MyButton>
        </div>

        <ButtonGroup variant='text' aria-label='text button group' color='primary'>
          <Button onClick={() => setState({ ...state, verPDF: !state.verPDF })}>
            {state.verPDF ? 'Ocultar PDF' : 'Ver PDF'}
          </Button>
        </ButtonGroup>
      </div>

      <div className='div-inventariopdfcontent'>
        {equipo && cliente ? (
          <>
            {state.verPDF ? (
              <PDFViewer style={{ width: '100%', height: '90vh' }}>
                <HojadeVidaDocuPDFAsis equipo={equipo} cliente={cliente} />
              </PDFViewer>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

HojadeVidaPDFAsis.propTypes = {
  getEquipo: PropTypes.func.isRequired,
  getCliente: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, { getEquipo, getCliente })(HojadeVidaPDFAsis);
