import React, { Component } from 'react';
import './SolicitudesAsistencial.css';

import {Link } from 'react-router-dom';
import {FaLaptopMedical,FaHospital,FaTools,FaSyringe,FaCogs,FaLaptopCode,FaFileInvoiceDollar,FaFileSignature} from "react-icons/fa";


import NuevaSolicitud from './NuevaSolicitud';


export class SolicitudesAsistencial extends Component {
  render() {
    return (
        <div className="market_container">
        <div className="titulomarket_section">
        <div className="titulo_market">
        <h1 className="text-clientes">Mis Solicitudes</h1>
        <hr className="clientes_separator"></hr>  

        </div>

        <div className="divmarket_boton">
      
       


        </div>
        </div>

        <div className="market_categorias_container">

        <div className="subcontainer-market">

            <NuevaSolicitud history={this.props.history}/>
       

                <div className="dashing-div-card">
                <Link to='/dashboard/solicitudesasistencial/missolicitudessinenviar' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Mis Solicitudes <br/>Sin Enviar
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaTools color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>
        </div>

        <div className="subcontainer-market">

     

                <div className="dashing-div-card">
                <Link to='/dashboard/solicitudesasistencial/missolicitudesenviadas' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Mis Solicitudes <br/>Enviadas
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaTools color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

                <div className="dashing-div-card">
                <Link to='/dashboard/solicitudesasistencial/missolicitudesaprobadas' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Mis Solicitudes<br/>Aprobadas
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaHospital color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

                <div className="dashing-div-card">
                <Link to='/dashboard/solicitudesasistencial/missolicitudesrechazadas' className='linkclass-market'>
                    <div className="dashing-card">
                        <div className="dashing-cardcontainer-market">
                            <div className="dashing-cardcontent">
                                <div className="dashing-cardtext">
                                    <p className="dashing-text-card">
                                    Mis Solicitudes<br/>Rechazadas
                                    </p>
                                
                            
                                </div>
                                <div className="dashing-cardicon">
                                    <FaHospital color="#FFFFFF" className="dashing-icon"/>
                                </div>
                            </div>
                            
                        </div>            
                    </div>
                    </Link>
                </div>

        
        
        
        </div>


      </div>
      </div>
    )
  }
}

export default SolicitudesAsistencial