import React, { Component } from 'react';
import './ListaClientes.css';
import { connect } from 'react-redux';

import {getClientes} from '../../../redux/actions/dataactions';
import {Link } from 'react-router-dom';


import SubirFotoCliente from './SubirFotoCliente';
import PropTypes from 'prop-types';
import  EliminarCliente  from './EliminarCliente';
import VerPerfil from './VerPerfil';
import CircularProgress from '@material-ui/core/CircularProgress';
import DetallesCliente from './DetallesCliente';
import AddTecnico from './AddTecnico';
import MyButton from '../../../utils/MyButton';
import { FaUserAstronaut, FaUsers } from 'react-icons/fa';
import CargarFirmaCliente from './CargarFirmaCliente';



export class ListaClientes extends Component {

    componentDidMount(){
        this.props.getClientes();
      }
      
    render() {

        const {clientes,loading}= this.props.data; 

        let mapeoclientes=!loading?
        (clientes.length>0?(
            clientes.map((cliente)=>
            <div key={cliente.clienteid} className="cliente-card">
            <div className="div-info">
            <div className="div-cliente-imagen">
              <img src={cliente.imagenurl} alt="equipo" className="imagen-cliente"/>
              <SubirFotoCliente clienteId={cliente.clienteid}/>
            </div>

            <div className="div-cliente-infocontainer">
            <div className="div-cliente-titulo">
            <Link to={`/dashboard/perfilcliente/${cliente.clienteid}`} className="link_nombre_cliente"> <h3 className="titulo-cliente-text">{cliente.nombre}</h3>  </Link> 
            <h5 className="ciudad-cliente-text">{cliente.ciudad}</h5>
            </div>
            <div className="div-cliente-info">
            <p className="cliente-text">CC/NIT: {cliente.ccnit}</p>
            <p className="cliente-text">Dirección: {cliente.direccion}</p>
            <p className="cliente-text">Email: {cliente.email}</p>
            <p className="cliente-text">Telefono: {cliente.telefono}</p>
            <p className="cliente-text">Codigo REPS: {cliente.codigoreps? cliente.codigoreps:'No Registra'}</p>
            <p className="cliente-text">Sede: {cliente.sede?cliente.sede:'No Registra'}</p>
            </div>


            </div>
            

           

            </div>

            <div className="div-acciones">
                <CargarFirmaCliente cliente={cliente} clienteId={cliente.clienteid} />
            <DetallesCliente cliente={cliente} clienteId={cliente.clienteid}/>
            <div className="div-botoneliminarcliente">
            <Link to={`/dashboard/clientes/adminusers/${cliente.clienteid}`} >
            <MyButton tip="Usuarios">
            <FaUsers color="#03178C" className="icon-eliminarcliente"/>
            </MyButton>
            </Link>
            </div>
            <VerPerfil clienteId={cliente.clienteid}/>
            <EliminarCliente clienteId={cliente.clienteid}/>
           
            
            
            </div>          
            
            
              
              </div>
             )

           
        
        ):( <div className="item-market-card">
            <div className='item-market-container'>
            <div className='item-market-descripcion'>
            <h3 className="titulo-market-text">Añade un Prestador/Cliente para Empezar</h3>
        </div>
            </div>
            </div>)):
        (<div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>)
           


        return (
            <div>
                {mapeoclientes}
            </div>
        )
    }
}

ListaClientes.propTypes={
    getClientes:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired
}
const mapStateToProps=state=>({
    data:state.data
})
export default connect(mapStateToProps,{getClientes}) (ListaClientes);

