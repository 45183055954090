
import React, { Component } from 'react'
import './DashboardTecnico.css';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';


import {Link } from 'react-router-dom';
import Button from '../../Elements/Button';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {FaCogs,FaHospital,FaLaptopMedical,FaWrench,FaRocket,FaPhoneSquareAlt,FaRegClock, FaNotesMedical, FaBookReader} from "react-icons/fa";

import {getIndicadoresNew,clearErrors} from '../../../redux/actions/dataactions';



export class DashboardTecnico extends Component {
  
  
    componentDidMount(){
        console.log(this.props.user.credentials.handle);
        this.props.getIndicadoresNew(this.props.user.credentials.handle);
       }


    

    render() {
      const indicadoresnew=this.props.data.indicadoresnew; 

      
          const optionspie = {
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            cutoutPercentage: 5,
            layout: { padding: 5},
            tooltips: {
              enabled: true,
              mode: 'index',
              intersect: false,
              borderWidth: 2,
              borderColor: "#03178C",
              backgroundColor: "#FFF",
              titleFontColor: "#03178C",
              bodyFontColor: "#03178C",
              footerFontColor: "#03178C"
            }
          };
          const optionspiecal = {
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            cutoutPercentage: 5,
            layout: { padding: 5},
            tooltips: {
              enabled: true,
              mode: 'index',
              intersect: false,
              borderWidth: 2,
              borderColor: "#03178C",
              backgroundColor: "#FFF",
              titleFontColor: "#03178C",
              bodyFontColor: "#03178C",
              footerFontColor: "#03178C"
            }
          };
        

          const   datapie = {
            datasets: [
              {
                data: [indicadoresnew.totalPrevRealizados,
                  
                    indicadoresnew.totalProgMtosPrev],
                backgroundColor: [
                    "#0962d5",
                  "#338bf7",
               
                  
        
                ],
                borderWidth: 10,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff'
              }
            ],
            labels: ['Realizados', 'Programados']
          };

          const   datapiecal = {
            datasets: [
              {
                data: [indicadoresnew.totalProgCals,
                  indicadoresnew.totalCals],
                backgroundColor: [
                    "#069ad5",
                  "#06c1cc",
               
                  
        
                ],
                borderWidth: 10,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff'
              }
            ],
            labels: ['Programadas', 'Realizadas']
          };

          const databar = {
            labels: ['Funcionando', 'Correctivo', 'De Baja'],
            datasets: [
              {
                
                backgroundColor: [
                    "#18941C",
                  "#F2AC29",
                  "#E0231C"
                  ],
                
                borderWidth: 1,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff',
                data: [indicadoresnew.totalFuncionando,
                  indicadoresnew.totalCorrectivo,
                  indicadoresnew.totalDeBaja
                    ],
              },
            ],
          };

          const optionsbar = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false, // Oculta la leyenda, que incluye las etiquetas del dataset
              },
            title: {
                display: false, // Oculta el título del gráfico
              },
            scales: {
                xAxes: [{
                    gridLines: {
                        display:false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display:false
                    }  , 
                    ticks: {
                        display: false, // Oculta los valores del eje Y
                      },
                }]
            },
            plugins: {
                
                tooltip: {
                enabled: false,
                mode: 'index',
                intersect: false,
              },
            },
          };





    
      let segundosatiempo=(s)=>{

        const addZ=(n)=>{
            return (n<10? '0':'') + n;       
            
        }
        var ms = s % 1000;
         s = (s - ms) / 1000;
        var secs = s % 60;
         s = (s - secs) / 60;
         var mins = s % 60;
         var hrs = (s - mins) / 60;

  return addZ(hrs) + 'h:' + addZ(mins) + 'm:' + addZ(secs)+ 's';

    }
    let tiemporespuestahora = indicadoresnew?.averageTimeResponse != null 
    ? segundosatiempo(indicadoresnew.averageTimeResponse) 
    : "0";

       
  return (
    <div id="dashboard-ingeniero">


        <div className="dashing-container">

        <div className="dashing-rowindicators">   
           
            <div className="dashing-div-card">
            <Link className='link-card' to={`/dashboard/clientes`}>
                 
                <div className="dashing-card">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Total Prestadores
                                </p>
                            <h3 className="dashing-value-card">{indicadoresnew.clientCount?indicadoresnew.clientCount:0}</h3>
                            </div>
                            <div className="dashing-cardicon">
                                <FaHospital color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
                    </div>            
                </div>
                </Link>
            </div>
   
       
        <div className="dashing-div-card">
            <div className="dashing-card">
                <div className="dashing-cardcontainer">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Total Equipos
                            </p>
                         <h3 className="dashing-value-card">{indicadoresnew.totalEquipos?indicadoresnew.totalEquipos:0}</h3>
                        </div>
                        <div className="dashing-cardicon">
                            <FaLaptopMedical color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                    

                </div>
                
            </div>
            </div>

            <div className="dashing-div-card">



            <div className="dashing-card">
                <div className="dashing-cardcontainer">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                           Tareas Realizadas
                            </p>
                            <h3 className="dashing-value-card">{indicadoresnew.taskRealizadas?indicadoresnew.taskRealizadas:0}</h3>
                        </div>
                        <div className="dashing-cardicon">
                            <FaWrench color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                   

                </div>
                
            </div>
            </div>

            <div className="dashing-div-card">
            
            <div className="dashing-card">
                <div className="dashing-cardcontainer">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Tareas Pendientes
                            </p>
        <h3 className="dashing-value-card">{indicadoresnew.taskNoRealizadas?indicadoresnew.taskNoRealizadas:0}</h3>
                        </div>
                        <div className="dashing-cardicon">
                            <FaRocket color="#FFFFFF" className="dashing-icon"/>
                        </div>
                    </div>
                   

                </div>
                
            </div>
            
            </div>

        </div>  

        <div className="dashing-secondindicators">
                
                <div className="dashing-cardestado">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardestadotitulo">
                                <p className="dashing-text-card">
                                        Estado Equipos
                                        </p>
                                </div>
                                <div className="dashing-chart-container">
                                <Bar
                                options={optionsbar}
                                data={databar}
                              
                                />
                        </div>
                        

                        <div className="dashing-card-data-container">

            <div className="dashing-card-data">
           
            <p className="dashing-text-data"> Funcionando</p>
          <h2 className="dashing-value-data-green">{indicadoresnew.totalFuncionando?indicadoresnew.totalFuncionando:0}</h2>
            </div>


            <div className="dashing-card-data">
           
            <p className="dashing-text-data"> En Correctivo</p>
          <h2 className="dashing-value-data-yellow">{indicadoresnew.totalCorrectivo?indicadoresnew.totalCorrectivo:0}</h2>
            </div>

            <div className="dashing-card-data">
           
            <p className="dashing-text-data"> De Baja</p>
          <h2 className="dashing-value-data-red">{indicadoresnew.totalDeBaja?indicadoresnew.totalDeBaja:0}</h2>
            </div>
        </div>

                    
                    </div>
                </div>

                <div className="dashing-cardestado">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardestadotitulo">
                                <p className="dashing-text-card">
                                       Mtos Preventivos
                                        </p>
                                </div>
                                <div className="dashing-chart-container">
                                <Pie
                                options={optionspie}
                                data={datapie}
                                
                                />
                               
                        </div>
                        

                        <div className="dashing-card-data-container">

            <div className="dashing-card-data">
           
            <p className="dashing-text-data"> Programados</p>
          <h2 className="dashing-value-data-blue">{indicadoresnew.totalProgMtosPrev?indicadoresnew.totalProgMtosPrev:0}</h2>
            </div>


            <div className="dashing-card-data">
            
            <p className="dashing-text-data"> Realizados</p>
          <h2 className="dashing-value-data-blue">{indicadoresnew.totalPrevRealizados?indicadoresnew.totalPrevRealizados:0}</h2>
            </div>

          
        </div>

                    
                    </div>
                </div>

                <div className="dashing-cardestado">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardestadotitulo">
                                <p className="dashing-text-card">
                                      Calibraciones
                                        </p>
                                </div>
                                <div className="dashing-chart-container">
                                <Pie
                                options={optionspiecal}
                                data={datapiecal}
                                
                                />
                               
                        </div>
                        

                        <div className="dashing-card-data-container">

            <div className="dashing-card-data">
           
            <p className="dashing-text-data"> Programadas</p>
          <h2 className="dashing-value-data-blue">{indicadoresnew.totalProgCals?indicadoresnew.totalProgCals:0}</h2>
            </div>


            <div className="dashing-card-data">
            
            <p className="dashing-text-data"> Realizadas</p>
          <h2 className="dashing-value-data-blue">{indicadoresnew.totalCals?indicadoresnew.totalCals:0}</h2>
            </div>

          
        </div>

                    
                    </div>
                </div>
       

       

        </div>

      


        <div className="dashing-rowindicators">  
     

                <div className="dashing-div-card">
            <div className="dashing-card">
                <div className="dashing-cardcontainer">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Mtos Correctivos Realizados
                            </p>
                           < h3 className="dashing-value-card">{indicadoresnew.totalMtosCorr?indicadoresnew.totalMtosCorr:0}</h3>
                        </div>
                        <div className="dashing-cardicon-orange">
                                <FaCogs color="#FFFFFF" className="dashing-icon"/>
                            </div>
                    </div>
                    

                </div>
                
            </div>
            </div>

            <div className="dashing-div-card">
            <div className="dashing-card">
                <div className="dashing-cardcontainer">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                           Solicitudes
                            </p>
                         <h3 className="dashing-value-card">{indicadoresnew.totalSoliCorr?indicadoresnew.totalSoliCorr:0}</h3>
                        </div>
                        <div className="dashing-cardicon-orange">
                                <FaPhoneSquareAlt color="#FFFFFF" className="dashing-icon"/>
                            </div>
                    </div>
                    

                </div>
                
            </div>
            </div>

            <div className="dashing-div-card">
            <div className="dashing-card">
                <div className="dashing-cardcontainer">
                    <div className="dashing-cardcontent">
                        <div className="dashing-cardtext">
                            <p className="dashing-text-card">
                            Tiempo de Respuesta
                            </p>
                         <h3 className="dashing-value-card">{tiemporespuestahora}</h3>
                        </div>
                        <div className="dashing-cardicon-green">
                                <FaRegClock color="#FFFFFF" className="dashing-icon"/>
                            </div>
                    </div>
                    

                </div>
                
            </div>
            </div>

         </div>
        

         <div className="dashing-rowindicators">   
           
        
            <div className="dashing-div-card">
            <Link className='link-card' to={`/dashboard/cursos`}>
                 
                <div className="dashing-card">
                    <div className="dashing-cardcontainer">
                        <div className="dashing-cardcontent">
                            <div className="dashing-cardtext">
                                <p className="dashing-text-card">
                                Mi Ruta de Aprendizaje
                                </p>
                            <h3 className="dashing-value-card">con IA</h3>
                            </div>
                            <div className="dashing-cardicon-blue">
                                <FaBookReader color="#FFFFFF" className="dashing-icon"/>
                            </div>
                        </div>
                        
                    </div>            
                </div>
                </Link>
            </div>
   
       
        

            

        </div>  
    </div>
    </div>
)
    }
}

DashboardTecnico.propTypes = {
    getIndicadoresNew:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired
  };

const mapActionsToProps={ getIndicadoresNew,clearErrors};

const mapStateToProps = (state)=>({
  data:state.data,
  UI:state.UI,
  user:state.user,
})



export default connect(mapStateToProps,mapActionsToProps)(DashboardTecnico);



